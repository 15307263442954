import Cookies from 'js-cookie';
import React from 'react';
import * as Sentry from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import {Button, Form, Grid, Header, Loader, Message, Image} from 'semantic-ui-react';
import {createFragmentContainer} from 'react-relay';
import {withTranslation} from 'react-i18next';
import getCountryCodeByIp from '../helpers/getCountryCodeByIp';
import API from '../helpers/api';
import {getCookieDomain} from '../helpers';
import CookieBanner from './CookieBanner';

class Login extends React.Component {
  state = {
    password: '',
    email: '',
    isLoading: false,
    hasSetLocale: false,
    error: null,
  };

  constructor(props) {
    super(props);

    const userLocale = Cookies.get('USER_LOCALE');
    if (userLocale) {
      this.state.hasSetLocale = true;
    }
  }

  async componentDidMount() {
    // set locale
    if (!this.state.hasSetLocale) {
      const {i18n} = this.props;

      // TODO: Add a new endpoint called getUserLocaleByIp
      const countryCode = await getCountryCodeByIp();
      const domain = getCookieDomain();
      Cookies.set('USER_LOCALE', countryCode, {expires: 365, domain});

      try {
        await i18n.changeLanguage(countryCode);
      } catch (e) {
        Sentry.captureException(e);
      }

      this.setState({hasSetLocale: true});
    }
  }

  handleSumbit = (e) => {
    this.setState({isLoading: true});

    const {email, password} = this.state;
    const {i18n} = this.props;

    const body = JSON.stringify({email, password});
    API.post('auth/web', {body})
      .then(({json: data, response}) => {
        if (response.ok) {
          const domain = getCookieDomain();
          Cookies.set('OAUTH_TOKEN', data.accessToken, {expires: 365, domain}); //, secure: !global.__DEV__});
          Cookies.set('OAUTH_REFRESH_TOKEN', data.refreshAccessToken, {expires: 365, domain}); //,secure:!global.__DE

          Cookies.set('USER_ID', data.id, {expires: 365, domain}); //, secure: !global.__DEV__});
          Cookies.set('USER_LOCALE', data.settings.locale, {expires: 365, domain});
          i18n.changeLanguage(data.settings.locale, (err, t) => {
            if (err) {
              Sentry.captureException(err);
            }

            this.props.router.push('/');
          });

          window._dcq.push([
            'identify',
            {
              user_id: data.id,
            },
          ]);
          window._dcq.push(['track', 'Logged in', {platform: 'web'}]);
        } else {
          this.setState({password: '', error: data.error, isLoading: false});
        }
      })
      .catch((e) => {
        this.setState({password: '', error: e.message, isLoading: false});
      });
  };

  handleEmailChange = (e) => {
    this.setState({email: e.target.value.toLowerCase()});
  };

  handlePasswordChange = (e) => {
    this.setState({password: e.target.value});
  };

  render() {
    const {t} = this.props;
    const {email, password, hasSetLocale, isLoading, error} = this.state;

    if (!hasSetLocale) {
      return (
        <Loader active size="massive">
          {t('common.loading')}
        </Loader>
      );
    }

    return (
      <Grid centered padded="horizontally" verticalAlign="middle" style={{width: '100vw', height: '100vh'}}>
        <Grid.Row columns={2}>
          <Grid.Column width={6} style={{paddingLeft: 40, paddingRight: 40}}>
            <Header style={{fontSize: 60}} color="black" textAlign="left">
              {t('common.welcome')}
            </Header>
            <Form onSubmit={this.handleSumbit} size="large" error={!!error}>
              <Header color="black" textAlign="left">
                {t('common.login_message')}
              </Header>
              <Message error header={'Incorrect email or password'} content="Please try again" />
              <Form.Input
                fluid
                value={email}
                icon="user"
                onChange={this.handleEmailChange}
                placeholder={t('common.email')}
              />
              <Form.Input
                fluid
                value={password}
                icon="lock"
                type="password"
                onChange={this.handlePasswordChange}
                placeholder={t('common.password')}
              />
              <Button type="submit" fluid loading={isLoading} style={{backgroundColor: '#3f51b5', color: 'white'}}>
                {t('actions.login')}
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column width={10}>
            <Image src="/login-bg.png" />
          </Grid.Column>
        </Grid.Row>
        <CookieBanner />
      </Grid>
    );
  }
}

const LoginContainer = createFragmentContainer(withTranslation()(Login), {
  viewer: graphql`
    fragment Login_viewer on User {
      ... on Provider {
        id
        settings {
          locale
        }
      }
    }
  `,
});

export default LoginContainer;
