/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePlacesInput = {|
  clientMutationId?: ?string,
  places?: ?$ReadOnlyArray<?any>,
|};
export type UpdatePlacesMutationVariables = {|
  input: UpdatePlacesInput
|};
export type UpdatePlacesMutationResponse = {|
  +updatePlaces: ?{|
    +places: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +phoneNumber: ?string,
      +email: ?string,
      +address: ?{|
        +placeId: ?string,
        +formatted: ?string,
      |},
    |}>
  |}
|};
export type UpdatePlacesMutation = {|
  variables: UpdatePlacesMutationVariables,
  response: UpdatePlacesMutationResponse,
|};
*/


/*
mutation UpdatePlacesMutation(
  $input: UpdatePlacesInput!
) {
  updatePlaces(input: $input) {
    places {
      id
      name
      phoneNumber
      email
      address {
        placeId
        formatted
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formatted",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePlacesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePlacesPayload",
        "kind": "LinkedField",
        "name": "updatePlaces",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "places",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePlacesMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePlacesPayload",
        "kind": "LinkedField",
        "name": "updatePlaces",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "places",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1b313da0f44c6467e6ee647e9812eea7",
    "id": null,
    "metadata": {},
    "name": "UpdatePlacesMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePlacesMutation(\n  $input: UpdatePlacesInput!\n) {\n  updatePlaces(input: $input) {\n    places {\n      id\n      name\n      phoneNumber\n      email\n      address {\n        placeId\n        formatted\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '340d661c8ea31bd17b0bea0d7ee453dd';

module.exports = node;
