/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Payments_viewer$ref = any;
export type PaymentsQuery_QueryVariables = {||};
export type PaymentsQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Payments_viewer$ref
  |}
|};
export type PaymentsQuery_Query = {|
  variables: PaymentsQuery_QueryVariables,
  response: PaymentsQuery_QueryResponse,
|};
*/


/*
query PaymentsQuery_Query {
  viewer {
    __typename
    ...Payments_viewer
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment General_viewer on User {
  __isUser: __typename
  ... on Provider {
    clients {
      total
    }
    services {
      total
    }
    organization {
      pricingPlans {
        id
        name
        period
        currency
        tiers {
          value
          minQuantity
          maxQuantity
        }
      }
      providers {
        total
      }
      subscription {
        id
        status
        validTo
        currentTier {
          value
        }
        pricingPlan {
          name
          period
          currency
          id
        }
        paymentMethod {
          type
          brand
          last4
          id
        }
        lastPayment {
          createdAt
          amount
          currency
          invoiceNumber
          invoiceLink
          id
        }
      }
      id
    }
  }
}

fragment Payments_viewer on User {
  __isUser: __typename
  ... on Provider {
    organization {
      subscription {
        status
        id
      }
      id
    }
    ...General_viewer
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "period",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Payments_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PaymentsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subscription",
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClientConnection",
                        "kind": "LinkedField",
                        "name": "clients",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ServiceConnection",
                        "kind": "LinkedField",
                        "name": "services",
                        "plural": false,
                        "selections": (v1/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PricingPlan",
                            "kind": "LinkedField",
                            "name": "pricingPlans",
                            "plural": true,
                            "selections": [
                              (v0/*: any*/),
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PricingPlanTier",
                                "kind": "LinkedField",
                                "name": "tiers",
                                "plural": true,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "minQuantity",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "maxQuantity",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProviderConnection",
                            "kind": "LinkedField",
                            "name": "providers",
                            "plural": false,
                            "selections": (v1/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Subscription",
                            "kind": "LinkedField",
                            "name": "subscription",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "validTo",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PricingPlanTier",
                                "kind": "LinkedField",
                                "name": "currentTier",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PricingPlan",
                                "kind": "LinkedField",
                                "name": "pricingPlan",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrganizationPaymentMethod",
                                "kind": "LinkedField",
                                "name": "paymentMethod",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "brand",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "last4",
                                    "storageKey": null
                                  },
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SubscriptionPayment",
                                "kind": "LinkedField",
                                "name": "lastPayment",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "createdAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "amount",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "invoiceNumber",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "invoiceLink",
                                    "storageKey": null
                                  },
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Provider",
                    "abstractKey": null
                  }
                ],
                "type": "User",
                "abstractKey": "__isUser"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "77e0fa2d93171c362c6194adc02869ed",
    "id": null,
    "metadata": {},
    "name": "PaymentsQuery_Query",
    "operationKind": "query",
    "text": "query PaymentsQuery_Query {\n  viewer {\n    __typename\n    ...Payments_viewer\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment General_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    clients {\n      total\n    }\n    services {\n      total\n    }\n    organization {\n      pricingPlans {\n        id\n        name\n        period\n        currency\n        tiers {\n          value\n          minQuantity\n          maxQuantity\n        }\n      }\n      providers {\n        total\n      }\n      subscription {\n        id\n        status\n        validTo\n        currentTier {\n          value\n        }\n        pricingPlan {\n          name\n          period\n          currency\n          id\n        }\n        paymentMethod {\n          type\n          brand\n          last4\n          id\n        }\n        lastPayment {\n          createdAt\n          amount\n          currency\n          invoiceNumber\n          invoiceLink\n          id\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment Payments_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    organization {\n      subscription {\n        status\n        id\n      }\n      id\n    }\n    ...General_viewer\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6d1c0767cf6eb3fa9285d2043acd2cde';

module.exports = node;
