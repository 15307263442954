import {Route, makeRouteConfig} from 'found';
import Cookies from 'js-cookie';
import React from 'react';

import App from './components/App';
import Login from './components/Login';
import Analytics from './components/Analytics';
import Payments from './components/Payments/Payments';
import MyProfile from './components/MyProfile/MyProfile';
import Calendar from './components/Calendar/Calendar';
import Clients from './components/Clients';
import ClientView from './components/ClientView';
import Services from './components/Services/Services';
import ServiceView from './components/Services/ServiceView';
import VacationHourView from './components/Services/VacationHourView';
import Logout from './components/Logout';
import Settings from './components/Settings/Settings';
import StaffMembers from './components/StaffMembers';

import {
  AnalyticsQuery,
  AppQuery,
  CalendarQuery,
  ClientsQuery,
  ClientViewQuery,
  PaymentsQuery,
  MyProfileQuery,
  SettingsQuery,
  ServicesQuery,
  ServiceViewQuery,
  VacationHourViewQuery,
  LoginQuery,
  StaffMembersQuery,
} from './queries';

const requireLogin = ({Component, props, data, match, ...rest}) => {
  const accessToken = Cookies.get('OAUTH_TOKEN') || null;

  if (!accessToken) {
    match.router.replace('/login');
    return;
  }

  return <Component {...match} {...props} />;
};

const loginIfToken = ({Component, props, data, match}) => {
  const currentLocation = match.location.pathname;
  const accessToken = Cookies.get('OAUTH_TOKEN') || null;

  // HACK: We have hardcoded the default logged in route, otherwise at / we will see the <Route Component={Login} />
  if (accessToken && (currentLocation === '/' || currentLocation === '/login')) {
    match.router.replace('/analytics');
  }

  return null;
  // // HACK: Make sure there is a viewer
  // props = {
  //   viewer: null,
  //   ...props
  // };

  // console.log("component", Component);
};

export default makeRouteConfig(
  <Route path="/" render={loginIfToken}>
    <Route Component={Login} query={LoginQuery} />
    <Route path="logout" Component={Logout} />
    <Route path="login" Component={Login} query={LoginQuery} />
    <Route Component={App} query={AppQuery} render={requireLogin}>
      <Route
        path="analytics"
        Component={Analytics}
        query={AnalyticsQuery}
        prepareVariables={Analytics.getInitialVariables}
      />
      <Route path="clients" Component={Clients} query={ClientsQuery} prepareVariables={Clients.getVariables} />
      <Route
        path="clients/:clientId"
        Component={ClientView}
        query={ClientViewQuery}
        prepareVariables={ClientView.getVariables}
      />
      <Route
        path="calendar/:appointmentId?"
        Component={Calendar}
        query={CalendarQuery}
        prepareVariables={Calendar.getVariables}
      />
      <Route path="payments" Component={Payments} query={PaymentsQuery} />
      <Route path="myprofile" Component={MyProfile} query={MyProfileQuery} />
      <Route path="services" Component={Services} query={ServicesQuery} prepareVariables={Services.getVariables} />
      <Route path="staff-members" Component={StaffMembers} query={StaffMembersQuery} />
      <Route path="settings" Component={Settings} query={SettingsQuery} />
      <Route
        path="service/:serviceId?"
        Component={ServiceView}
        query={ServiceViewQuery}
        prepareVariables={ServiceView.getVariables}
      />
      <Route
        path="vacationHour/:vacationHourId?"
        Component={VacationHourView}
        query={VacationHourViewQuery}
        prepareVariables={VacationHourView.getVariables}
      />
    </Route>
  </Route>,
);
