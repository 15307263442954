import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import * as Sentry from '@sentry/react';
import translations from './translations.json';
import Cookies from 'js-cookie';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    lng: Cookies.get('USER_LOCALE') || 'en',
    fallbackLng: 'en',
    saveMissing: true,

    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('missingKey', function (lngs, namespace, key, res) {
  Sentry.captureException(`Missing translation key: ${key}`);
});

// i18n.use(reactI18nextModule).init({
//   // we init with resources
//   resources: translations,
//   lng: 'en',
//   debug: true,
//   keySeparator: false, // we use content as keys

//   interpolation: {
//     escapeValue: false, // not needed for react!!
//     formatSeparator: ',',
//   },
// });

export default i18n;
