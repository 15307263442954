/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteRecurringTimeSlotInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type DeleteRecurringTimeSlotMutationVariables = {|
  connections: $ReadOnlyArray<string>,
  input: DeleteRecurringTimeSlotInput,
|};
export type DeleteRecurringTimeSlotMutationResponse = {|
  +deleteRecurringTimeSlot: ?{|
    +clientMutationId: ?string,
    +id: string,
  |}
|};
export type DeleteRecurringTimeSlotMutation = {|
  variables: DeleteRecurringTimeSlotMutationVariables,
  response: DeleteRecurringTimeSlotMutationResponse,
|};
*/


/*
mutation DeleteRecurringTimeSlotMutation(
  $input: DeleteRecurringTimeSlotInput!
) {
  deleteRecurringTimeSlot(input: $input) {
    clientMutationId
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteRecurringTimeSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteRecurringTimeSlotPayload",
        "kind": "LinkedField",
        "name": "deleteRecurringTimeSlot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteRecurringTimeSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteRecurringTimeSlotPayload",
        "kind": "LinkedField",
        "name": "deleteRecurringTimeSlot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "80f0a94a0af4f5193ffdd4955d372454",
    "id": null,
    "metadata": {},
    "name": "DeleteRecurringTimeSlotMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteRecurringTimeSlotMutation(\n  $input: DeleteRecurringTimeSlotInput!\n) {\n  deleteRecurringTimeSlot(input: $input) {\n    clientMutationId\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2aacdc3e05f34cb4470ad636ded17daf';

module.exports = node;
