import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation OfferAlternativeHoursMutation($input: OfferAlternativeHoursInput!) {
    offerAlternativeHours(input: $input) {
      appointment {
        id
        rowId
        status
        startAt
        updatedAt
        service {
          id
        }
        endAt
        status
        client {
          id
        }
        service {
          id
        }
        providerId
        provider {
          id
          name
        }
      }
    }
  }
`;

const OfferAlternativeHoursMutation = (payload, success, failure) => {
  const {input} = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input,
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default OfferAlternativeHoursMutation;
