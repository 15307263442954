/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type NotificationItem_notification$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type NotificationsSidebar_viewer$ref: FragmentReference;
declare export opaque type NotificationsSidebar_viewer$fragmentType: NotificationsSidebar_viewer$ref;
export type NotificationsSidebar_viewer = {|
  +id?: string,
  +notifications?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +createdAt: ?any,
        +isRead: ?boolean,
        +$fragmentRefs: NotificationItem_notification$ref,
      |}
    |}>
  |},
  +$refType: NotificationsSidebar_viewer$ref,
|};
export type NotificationsSidebar_viewer$data = NotificationsSidebar_viewer;
export type NotificationsSidebar_viewer$key = {
  +$data?: NotificationsSidebar_viewer$data,
  +$fragmentRefs: NotificationsSidebar_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldFetch"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": "after",
        "direction": "forward",
        "path": [
          "notifications"
        ]
      }
    ]
  },
  "name": "NotificationsSidebar_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "condition": "shouldFetch",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "notifications",
              "args": null,
              "concreteType": "NotificationConnection",
              "kind": "LinkedField",
              "name": "__NotificationSidebar_notifications_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NotificationEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Notification",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "createdAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isRead",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "NotificationItem_notification"
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UFPageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
})();
// prettier-ignore
(node/*: any*/).hash = '29abacabb3f2a98b3944917a58923cb7';

module.exports = node;
