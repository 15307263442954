import graphql from 'babel-plugin-relay/macro';

const SettingsQuery = graphql`
  query SettingsQuery_Query {
    viewer {
      ...Settings_viewer
    }
  }
`;

export default SettingsQuery;
