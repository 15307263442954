import graphql from 'babel-plugin-relay/macro';

const ServiceViewQuery = graphql`
  query ServiceViewQuery_Query($serviceId: ID, $shouldFetchService: Boolean!, $first: Int) {
    viewer {
      ...ServiceView_viewer @arguments(serviceId: $serviceId, shouldFetchService: $shouldFetchService, first: $first)
    }
  }
`;

export default ServiceViewQuery;
