import './AppointmentModal.css';
import {Button} from 'semantic-ui-react';

const SelectedProviderView = ({provider, isDeselectButtonDisabled, onDeselectProvider}) => {
  return (
    <div style={{position: 'relative'}}>
      <div className="details-subcontainer">
        <div className="details-info">
          <div className="details-header">{provider.name}</div>
        </div>
      </div>
      <Button
        disabled={isDeselectButtonDisabled}
        size="mini"
        className="close-button"
        circular
        icon="close"
        onClick={onDeselectProvider}
      />
    </div>
  );
};

export default SelectedProviderView;
