import moment from 'moment';
import HourCell from './HourCell';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  position: sticky;
  left: 73px;
  background-color: white;
  z-index: 2;
`;

const GridTimeLines = ({earliestHour, latestHour}) => {
  const time = moment(earliestHour, 'HH:mm:ss');
  const _latestHour = moment(latestHour, 'HH:mm:ss');

  const times = [];

  times.push(time.format('HH:mm'));
  while (time.isBefore(_latestHour)) {
    times.push(time.add(60, 'm').format('HH:mm'));
  }

  return (
    <Container>
      <HourCell />
      {times.map((time, index) => (
        <HourCell key={index} yOffset={100 * index} hour={time} />
      ))}
    </Container>
  );
};

export default GridTimeLines;
