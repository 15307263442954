/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ActivateSubscriptionInput = {|
  clientMutationId?: ?string,
  pricingPlanId: string,
|};
export type ActivateSubscriptionMutationVariables = {|
  input: ActivateSubscriptionInput
|};
export type ActivateSubscriptionMutationResponse = {|
  +activateSubscription: ?{|
    +clientSecret: ?string,
    +subscription: ?{|
      +id: string,
      +status: ?string,
      +validTo: ?any,
      +pricingPlan: ?{|
        +name: ?string,
        +period: ?number,
        +currency: ?string,
      |},
    |},
  |}
|};
export type ActivateSubscriptionMutation = {|
  variables: ActivateSubscriptionMutationVariables,
  response: ActivateSubscriptionMutationResponse,
|};
*/


/*
mutation ActivateSubscriptionMutation(
  $input: ActivateSubscriptionInput!
) {
  activateSubscription(input: $input) {
    clientSecret
    subscription {
      id
      status
      validTo
      pricingPlan {
        name
        period
        currency
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientSecret",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "validTo",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "period",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivateSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivateSubscriptionPayload",
        "kind": "LinkedField",
        "name": "activateSubscription",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PricingPlan",
                "kind": "LinkedField",
                "name": "pricingPlan",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivateSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ActivateSubscriptionPayload",
        "kind": "LinkedField",
        "name": "activateSubscription",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Subscription",
            "kind": "LinkedField",
            "name": "subscription",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PricingPlan",
                "kind": "LinkedField",
                "name": "pricingPlan",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6becfa96e49872b12d1036768781b08a",
    "id": null,
    "metadata": {},
    "name": "ActivateSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation ActivateSubscriptionMutation(\n  $input: ActivateSubscriptionInput!\n) {\n  activateSubscription(input: $input) {\n    clientSecret\n    subscription {\n      id\n      status\n      validTo\n      pricingPlan {\n        name\n        period\n        currency\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1fb15439aecdc7a5168f72e16313bd01';

module.exports = node;
