export default function getTierForNUnits(tiers, nUnits) {
  const _tiers = tiers.slice().sort((a, b) => a.minQuantity - b.minQuantity);

  for (const tier of _tiers) {
    const {minQuantity, maxQuantity} = tier;
    if (nUnits >= minQuantity && nUnits <= maxQuantity) {
      return tier;
    }
  }

  return null;
}
