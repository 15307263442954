import graphql from 'babel-plugin-relay/macro';

const StaffMembersQuery = graphql`
  query StaffMembersQuery_Query {
    viewer {
      ...StaffMembers_viewer
    }
  }
`;

export default StaffMembersQuery;
