import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {createFragmentContainer} from 'react-relay';
import {Message, Header, Form, Button} from 'semantic-ui-react';
import Container from '../Container';
import moment from 'moment';
import {isEqual, get} from 'lodash';
import {DateTimePicker} from '@atlaskit/datetime-picker';
import {UpdateVacationHourMutation, AddVacationHourMutation} from '../../mutations';
import {withTranslation} from 'react-i18next';
import getFormattedTimes from '../../helpers/getFormattedTimes';

class VacationHourView extends React.Component {
  state = {
    selectedServices: [],
    selectedProviders: [],
    startAtTime: null,
    endAtTime: null,
    startAtDate: null,
    endAtDate: null,
    error: null,
    isLoading: false,
  };

  constructor(props) {
    super(props);

    this.times = getFormattedTimes();

    const {vacationHour} = this.props.viewer;
    if (vacationHour) {
      const {startAt, provider, endAt} = vacationHour;
      this.state = {
        selectedProviders: [provider.id],
        startAtTime: moment(startAt).format('HH:mm'),
        startAtDate: moment(startAt).format('YYYY-MM-DD'),
        endAtDate: moment(endAt).format('YYYY-MM-DD'),
        endAtTime: moment(endAt).format('HH:mm'),
      };
    }
  }

  handleDropdownChange = (e, data) => {
    const {value} = data;

    this.setState({selectedProviders: value, error: null});
  };

  handleStartAtTimeSelected = (time) => {
    this.setState({startAtTime: time, error: null});
  };

  handleStartAtDateSelected = (date) => {
    let {endAtDate} = this.state;

    // TODO: Forbit selecting dates in the past
    // Note: Null endAt if new startAt > endAt
    if (endAtDate && date > endAtDate) {
      endAtDate = null;
    }

    this.setState({startAtDate: date, endAtDate, error: null});
  };

  handleEndAtDateSelected = (date) => {
    let {startAtDate} = this.state;
    // Note: Null startAt if new endAt < startAt

    // TODO: Forbit selecting dates in the past
    // Note: Null startAt if new endAt < startAt
    if (startAtDate && date < startAtDate) {
      startAtDate = null;
    }

    this.setState({startAtDate, endAtDate: date, error: null});
  };

  handleEndAtTimeSelected = (time) => {
    this.setState({endAtTime: time, error: null});
  };

  handleFormSubmit = () => {
    const {t} = this.props;
    const {vacationHour, id: viewerId} = this.props.viewer;
    const {selectedProviders, startAtDate, endAtDate, endAtTime, startAtTime} = this.state;

    this.setState({isLoading: true});

    if (!startAtDate || !endAtDate || selectedProviders.length === 0) {
      return this.setState({error: t('common.error_incorrect_values'), isLoading: false});
    }

    const onSuccess = () => {
      this.setState({isLoading: false});
      this.props.router.go(-1);
    };

    const onFailure = (error) => {
      const message = get(error, '[0].message', t('common.error'));
      return this.setState({error: message, isLoading: false});
    };

    const formattedStartAt = moment(`${startAtDate} ${startAtTime}`).toISOString();
    const formattedEndAt = moment(`${endAtDate} ${endAtTime}`).toISOString();

    if (moment(formattedEndAt).isSameOrBefore(formattedStartAt)) {
      return this.setState({error: t('common.error_incorrect_values'), isLoading: false});
    }

    const input = {
      providerIds: selectedProviders,
      startAt: formattedStartAt,
      endAt: formattedEndAt,
    };

    if (vacationHour) {
      input.id = vacationHour.id;
      UpdateVacationHourMutation({input}, onSuccess, onFailure);
    } else {
      const payload = {input, connectionName: 'VacationHours_vacationHours', viewerId};
      AddVacationHourMutation(payload, onSuccess, onFailure);
    }
  };

  handleSelectAllProviders = () => {
    const selectedProviders = this.props.viewer.providers.edges.map(({node}) => node.id);

    this.setState({selectedProviders});
  };

  handleUnselectAllProviders = () => this.setState({selectedProviders: []});

  hasUnsavedChanges = () => {
    const {selectedProviders, startAtDate, endAtDate, startAtTime, endAtTime} = this.state;
    const {vacationHour} = this.props.viewer;

    if (vacationHour) {
      const {startAt, provider, endAt} = vacationHour;

      if (!isEqual(selectedProviders, [provider.id])) {
        return true;
      }

      return (
        moment(startAt).toISOString() !== moment(`${startAtDate} ${startAtTime}`).toISOString() ||
        moment(endAt).toISOString() !== moment(`${endAtDate} ${endAtTime}`).toISOString()
      );
    }

    return (
      selectedProviders.length > 0 &&
      startAtDate !== null &&
      endAtDate !== null &&
      startAtTime !== null &&
      endAtTime !== null
    );
  };

  render() {
    const {t, viewer} = this.props;
    const {locale} = viewer.settings;
    const {error, isLoading, startAtDate, startAtTime, endAtDate, endAtTime, selectedProviders} = this.state;

    const hasUnsavedChanges = this.hasUnsavedChanges();

    const options = this.props.viewer.providers.edges.map(({node}) => ({
      key: node.id,
      text: node.name,
      value: node.id,
    }));

    return (
      <Container>
        <Form error={error}>
          <Header style={{marginTop: 100}}>{t('titles.select_provider')}</Header>
          <Message error header={t('common.error')} content={error} />
          <Button
            style={{marginBottom: 10}}
            disabled={selectedProviders.length === 0}
            floated="right"
            onClick={this.handleUnselectAllProviders}
          >
            {t('common.unselect_all')}
          </Button>
          <Button style={{marginBottom: 10}} floated="right" onClick={this.handleSelectAllProviders}>
            {t('common.select_all')}
          </Button>
          <Form.Dropdown
            placeholder={t('titles.providers')}
            fluid
            multiple
            selection
            onChange={this.handleDropdownChange}
            value={selectedProviders}
            options={options}
          />

          <Header style={{marginTop: 50}}>{t('actions.set_date_limits')}</Header>
          <Form.Group widths="equal">
            <Form.Field>
              {t('common.from')}
              <DateTimePicker
                placeholder={t('actions.pick_date')}
                locale={locale}
                timePickerProps={{
                  value: startAtTime || undefined,
                  times: this.times,
                  placeholder: t('common.time'),
                  onChange: this.handleStartAtTimeSelected,
                }}
                datePickerProps={{
                  onChange: this.handleStartAtDateSelected,
                  placeholder: t('common.date'),
                  minDate: moment().format('YYYY-MM-DD'),
                  value: startAtDate || undefined,
                }}
              />
            </Form.Field>
            <Form.Field>
              {t('common.until')}
              <DateTimePicker
                locale={locale}
                placeholder={t('actions.pick_date')}
                timePickerProps={{
                  value: endAtTime || undefined,
                  times: this.times,
                  placeholder: t('common.time'),
                  onChange: this.handleEndAtTimeSelected,
                }}
                datePickerProps={{
                  onChange: this.handleEndAtDateSelected,
                  placeholder: t('common.date'),
                  minDate: moment().format('YYYY-MM-DD'),
                  value: endAtDate || undefined,
                }}
              />
            </Form.Field>
          </Form.Group>
          <Button loading={isLoading} disabled={!hasUnsavedChanges} fluid color="blue" onClick={this.handleFormSubmit}>
            {t('common.save')}
          </Button>
        </Form>
      </Container>
    );
  }
}

const VacationHoursViewContainer = createFragmentContainer(withTranslation()(VacationHourView), {
  viewer: graphql`
    fragment VacationHourView_viewer on User @argumentDefinitions(vacationHourId: {type: "ID"}) {
      ... on Provider {
        id
        settings {
          locale
        }
        vacationHour(id: $vacationHourId) {
          id
          startAt
          endAt
          provider {
            id
            name
          }
        }
        providers(first: 1000) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  `,
});

VacationHoursViewContainer.getVariables = (props) => ({vacationHourId: props.vacationHourId});

export default VacationHoursViewContainer;
