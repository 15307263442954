import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {get} from 'lodash';
import {TimePicker} from '@atlaskit/datetime-picker';
import {Table, Checkbox, Button, Select} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';

const formatTime = (time) => (time && time.length > 5 ? time.slice(0, -3) : time);

class WorkHourRow extends React.PureComponent {
  isWorkHourDirty = (workHourId) => this.props.unsavedChanges[workHourId] !== undefined;

  render() {
    const {workHour, daysOfTheWeek, dayOptions, t, unsavedChanges, times, locale} = this.props;
    const {isOpen, dayOfTheWeek, providerId, openTime, closeTime, id} = workHour;

    const formattedStartAt = formatTime(openTime);
    const formattedEndAt = formatTime(closeTime);

    const editedOpenTime = get(unsavedChanges[id], 'openTime');
    const editedCloseTime = get(unsavedChanges[id], 'closeTime');
    const editedIsOpen = get(unsavedChanges[id], 'isOpen');
    const editedWorkDay = get(unsavedChanges[id], 'dayOfTheWeek');

    // NOTE: id could be undefined when trying to add work hour after error
    const isWorkHourDirty = this.isWorkHourDirty(id) || !id;
    const isDeleteButtonLoading = this.props.deleteButtonsLoading[id];
    const isAddOrDeleteButtonLoading = this.props.addOrUpdateButtonsLoading[id];

    return (
      <Table.Row key={id}>
        <Table.Cell>
          <Select
            fluid
            value={editedWorkDay !== undefined ? daysOfTheWeek[editedWorkDay] : daysOfTheWeek[dayOfTheWeek]}
            selection
            placeholder={t('actions.select_day')}
            options={dayOptions}
            onChange={(e, {value}) => this.props.onDaySelect(value, id, providerId)}
          />
        </Table.Cell>
        <Table.Cell>
          <TimePicker
            locale={locale || 'en'}
            hideIcon={true}
            placeholder={t('actions.select_time')}
            timeIsEditable={true}
            value={editedOpenTime || formattedStartAt}
            times={times}
            onChange={(value) => this.props.onStartTimeChange(value, id, providerId)}
          />
        </Table.Cell>
        <Table.Cell>
          <TimePicker
            locale={locale || 'en'}
            hideIcon={true}
            placeholder={t('actions.select_time')}
            timeIsEditable={true}
            value={editedCloseTime || formattedEndAt}
            times={times}
            onChange={(value) => this.props.onCloseTimeChange(value, id, providerId)}
          />
        </Table.Cell>
        <Table.Cell collapsing>
          <Checkbox
            color="green"
            checked={editedIsOpen !== undefined ? editedIsOpen : isOpen}
            toggle
            onChange={(e, {checked}) => this.props.onUpdateWorkHourDay(e, id, checked)}
          />
        </Table.Cell>
        <Table.Cell collapsing>
          {isWorkHourDirty && (
            <Button
              color="green"
              loading={isAddOrDeleteButtonLoading}
              disabled={isAddOrDeleteButtonLoading}
              icon="check"
              onClick={() => this.props.onUpdateWorkHour(id, providerId)}
            />
          )}
          <Button
            icon="remove"
            disabled={isDeleteButtonLoading}
            loading={isDeleteButtonLoading}
            onClick={() => this.props.onDeleteWorkHour(id, providerId)}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default createFragmentContainer(withTranslation()(WorkHourRow), {
  workHour: graphql`
    fragment WorkHourRow_workHour on WorkHour {
      id
      providerId
      dayOfTheWeek
      openTime
      closeTime
      isOpen
    }
  `,
});
