import React from 'react';
import {Table, Message, Button} from 'semantic-ui-react';
import {get} from 'lodash';
import {withTranslation} from 'react-i18next';
import {AddWorkHourMutation, DeleteWorkHourMutation, UpdateWorkHourMutation} from '../../mutations';
import WorkHourRow from './WorkHourRow';
import moment from 'moment';

const mergeWorkHours = (source, defaults) => {
  const merged = defaults.map((slot) => {
    const sourceWorkHour = source.find((el) => el.id === slot.id);
    if (sourceWorkHour) {
      return {
        ...slot,
        ...sourceWorkHour,
      };
    }
    return slot;
  });

  const newWorkHours = source.filter((slot) => !defaults.find((el) => el.id === slot.id));

  return [...merged, ...newWorkHours];
};

class ProviderWorkHoursTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteButtonsLoading: {},
      addOrUpdateButtonsLoading: {},
      unsavedChanges: {},
      error: null,
      localWorkHours: [],
    };
  }

  handleAddWorkHour = (providerId) => {
    this.setState((prevState) => ({
      localWorkHours: [
        ...prevState.localWorkHours,
        {
          id: null,
          dayOfTheWeek: -999, // Smaller than size of daysOfTheWeek but !== null in order for <Select/> to be controlled
          openTime: '',
          closeTime: '',
          providerId,
          isOpen: true,
        },
      ],
    }));
  };

  validateWorkHours = (id) => {
    const {t} = this.props;
    const {unsavedChanges} = this.state;
    const {workHours} = this.props;

    const currentWorkHour = workHours.find((workHour) => workHour.id === id);

    const openTime = get(unsavedChanges[id], 'openTime') || get(currentWorkHour, 'openTime');
    const closeTime = get(unsavedChanges[id], 'closeTime') || get(currentWorkHour, 'closeTime');

    if (moment(openTime, 'HH:mm').isSameOrAfter(moment(closeTime, 'HH:mm'))) {
      return t('common.work_hours_error');
    }
  };

  handleStartTimeChange = (value, id, providerId) => {
    if (!id) {
      // New work hour
      let _index = 0;
      const localWorkHour = this.state.localWorkHours.find((workHour, index) => {
        if (workHour.providerId === Number(providerId)) {
          _index = index;
          return true;
        }
        return false;
      });

      return this.setState((prevState) => {
        const modifiedWorkHour = {
          ...localWorkHour,
          openTime: value,
        };

        prevState.localWorkHours[_index] = modifiedWorkHour;
        return {
          localWorkHours: [...prevState.localWorkHours],
        };
      });
    }

    this.setState((prevState) => ({
      unsavedChanges: {
        ...prevState.unsavedChanges,
        [id]: {
          ...prevState.unsavedChanges[id],
          openTime: value,
        },
      },
    }));
  };

  handleUpdateWorkHourDay = (e, id, checked) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState((prevState) => ({
      unsavedChanges: {
        ...prevState.unsavedChanges,
        [id]: {
          ...prevState.unsavedChanges[id],
          isOpen: checked,
        },
      },
    }));
  };

  handleCloseTimeChange = (value, id, providerId) => {
    if (!id) {
      // New work hour
      let _index = 0;
      const localWorkHour = this.state.localWorkHours.find((workHour, index) => {
        if (workHour.providerId === Number(providerId)) {
          _index = index;
          return true;
        }
        return false;
      });

      return this.setState((prevState) => {
        const modifiedWorkHour = {
          ...localWorkHour,
          closeTime: value,
        };
        prevState.localWorkHours[_index] = modifiedWorkHour;
        return {
          localWorkHours: [...prevState.localWorkHours],
        };
      });
    }

    this.setState((prevState) => {
      return {
        unsavedChanges: {
          ...prevState.unsavedChanges,
          [id]: {
            ...prevState.unsavedChanges[id],
            closeTime: value,
          },
        },
      };
    });
  };

  handleDeleteWorkHour = (id, providerId) => {
    if (!id) {
      const filteredLocalWorkHours = this.state.localWorkHours.filter((workHour) => workHour.providerId !== providerId);
      return this.setState({localWorkHours: filteredLocalWorkHours, error: null});
    }

    this.setState((prevState) => ({
      deleteButtonsLoading: {
        ...prevState.deleteButtonsLoading,
        [id]: true,
      },
    }));

    const onSuccess = (data) => {
      this.setState((prevState) => ({
        unsavedChanges: {
          ...prevState.unsavedChanges,
          [id]: undefined,
        },
        deleteButtonsLoading: {
          ...prevState.deleteButtonsLoading,
          [id]: undefined,
        },
        error: null,
      }));
    };

    const onFailure = (error) => {
      this.setState((prevState) => ({
        deleteButtonsLoading: {
          ...prevState.deleteButtonsLoading,
          [id]: undefined,
          error: 'Unknown error. Please try again!',
        },
      }));
    };

    const input = {id};
    DeleteWorkHourMutation(
      {
        input,
        connectionId: this.props.connectionId,
      },
      onSuccess,
      onFailure,
    );
  };

  handleUpdateWorkHour = (id, providerId) => {
    const {t} = this.props;
    const {localWorkHours, unsavedChanges} = this.state;

    this.setState((prevState) => ({
      addOrUpdateButtonsLoading: {
        ...prevState.addOrUpdateButtonsLoading,
        [id]: true,
      },
    }));

    const onSuccess = (data) => {
      this.setState((prevState) => ({
        unsavedChanges: {
          ...prevState.unsavedChanges,
          [id]: undefined,
        },
        addOrUpdateButtonsLoading: {
          ...prevState.addOrUpdateButtonsLoading,
          [id]: undefined,
        },
        error: null,
        localWorkHours: [],
      }));
    };

    const onFailure = (error) => {
      const message = get(error, '[0].message');

      this.setState((prevState) => ({
        addOrUpdateButtonsLoading: {
          ...prevState.addOrUpdateButtonsLoading,
          [id]: undefined,
        },
        error: message,
      }));
    };

    // new work hour
    if (!id) {
      const localWorkHour = localWorkHours.find((workHour) => workHour.providerId === Number(providerId));

      if (localWorkHour.dayOfTheWeek < 0) {
        return this.setState((prevState) => ({
          addOrUpdateButtonsLoading: {
            ...prevState.addOrUpdateButtonsLoading,
            [id]: undefined,
          },
          error: t('actions.select_day'),
        }));
      }

      const {openTime, closeTime} = localWorkHour;
      if (!openTime || !closeTime) {
        return this.setState((prevState) => ({
          addOrUpdateButtonsLoading: {
            ...prevState.addOrUpdateButtonsLoading,
            [id]: undefined,
          },
          error: t('common.work_hours_error'),
        }));
      }

      if (moment(openTime, 'HH:mm').isSameOrAfter(moment(closeTime, 'HH:mm'))) {
        return this.setState((prevState) => ({
          addOrUpdateButtonsLoading: {
            ...prevState.addOrUpdateButtonsLoading,
            [id]: undefined,
          },
          error: t('common.work_hours_error'),
        }));
      }

      const input = localWorkHour;
      delete input.id; // Remove id since we are adding new work hour

      return AddWorkHourMutation({input, connectionId: this.props.connectionId}, onSuccess, onFailure);
    }

    const error = this.validateWorkHours(id);
    if (error) {
      return this.setState({error});
    }

    const input = unsavedChanges[id];
    if (!input.id) {
      input.id = id;
    }

    UpdateWorkHourMutation({input}, onSuccess, onFailure);
  };

  isWorkHourDirty = (workHourId) => this.state.unsavedChanges[workHourId] !== undefined;

  handleDaySelect = (value, id, providerId) => {
    const {key: day} = this.props.dayOptions.find(({value: _value}) => _value === value);
    if (!id) {
      return this.handleNewWorkHourDaySelect(id, day, providerId);
    }

    this.setState((prevState) => ({
      unsavedChanges: {
        ...prevState.unsavedChanges,
        [id]: {
          ...prevState.unsavedChanges[id],
          dayOfTheWeek: day,
        },
      },
    }));
  };

  handleNewWorkHourDaySelect = (id, day, providerId) => {
    let _index = 0;
    const localWorkHour = this.state.localWorkHours.find((workHour, index) => {
      if (workHour.providerId === Number(providerId)) {
        _index = index;
        return true;
      }
      return false;
    });

    this.setState((prevState) => {
      const modifiedWorkHour = {
        ...localWorkHour,
        dayOfTheWeek: day,
      };
      prevState.localWorkHours[_index] = modifiedWorkHour;
      return {
        localWorkHours: [...prevState.localWorkHours],
      };
    });
  };

  render() {
    const {t} = this.props;
    const {dayOptions, times, locale, daysOfTheWeek, currentProvider, workHours} = this.props;

    const currentProviderId = get(currentProvider, 'rowId');
    const {unsavedChanges, localWorkHours, error} = this.state;

    workHours.sort((a, b) => a.dayOfTheWeek - b.dayOfTheWeek || (a.openTime < b.openTime ? -1 : 1));

    // local work hour for current table
    const localWorkHour = localWorkHours.filter((workHour) => workHour.providerId === Number(currentProviderId));
    const mergedWorkHours = localWorkHour.length ? mergeWorkHours(localWorkHour, workHours) : workHours;
    const providerName = get(currentProvider, 'name', '');

    return (
      <>
        {error && <Message error content={error} />}
        <h5 style={{marginTop: 50}}>{providerName}</h5>
        <Table celled compact selectable padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common.day')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.open_time')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.close_time')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.open')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {mergedWorkHours &&
              mergedWorkHours.map((workHour) => {
                return (
                  <WorkHourRow
                    key={workHour.id}
                    workHour={workHour}
                    dayOptions={dayOptions}
                    times={times}
                    locale={locale}
                    deleteButtonsLoading={this.state.deleteButtonsLoading}
                    addOrUpdateButtonsLoading={this.state.addOrUpdateButtonsLoading}
                    unsavedChanges={unsavedChanges}
                    daysOfTheWeek={daysOfTheWeek}
                    onDeleteWorkHour={this.handleDeleteWorkHour}
                    onStartTimeChange={this.handleStartTimeChange}
                    onCloseTimeChange={this.handleCloseTimeChange}
                    onDaySelect={this.handleDaySelect}
                    onUpdateWorkHourDay={this.handleUpdateWorkHourDay}
                    onUpdateWorkHour={this.handleUpdateWorkHour}
                  />
                );
              })}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan="5">
                <Button
                  disabled={localWorkHours && localWorkHours.length > 0}
                  primary
                  fluid
                  onClick={() => this.handleAddWorkHour(currentProviderId)}
                >
                  {t('actions.add_work_hour')}
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </>
    );
  }
}

export default withTranslation()(ProviderWorkHoursTable);
