import graphql from 'babel-plugin-relay/macro';

const NotificationsSidebarQuery = graphql`
  query NotificationsSidebarQuery_Query(
    $orderBy: [[String]]
    $filterBy: NotificationFilterInput
    $cursor: String
    $count: Int
    $shouldFetch: Boolean!
  ) {
    viewer {
      ...NotificationsSidebar_viewer
        @arguments(orderBy: $orderBy, filterBy: $filterBy, after: $cursor, count: $count, shouldFetch: $shouldFetch)
    }
  }
`;

export default NotificationsSidebarQuery;
