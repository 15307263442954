import graphql from 'babel-plugin-relay/macro';

const AppointmentModalQuery = graphql`
  query AppointmentModalQuery_Query($timeSlotsFilterBy: ProviderTimeSlotFilterInput, $first: Int) {
    viewer {
      ...AppointmentModal_viewer @arguments(timeSlotsFilterBy: $timeSlotsFilterBy, first: $first)
    }
  }
`;

export default AppointmentModalQuery;
