/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PersonalSettings_viewer$ref = any;
export type UpdateProviderSettingsInput = {|
  clientMutationId?: ?string,
  id: string,
  providerId?: ?string,
  locale?: ?string,
  notifications?: ?any,
  timezone?: ?string,
  emails?: ?any,
  viewType?: ?string,
  appointmentRequiresApproval?: ?boolean,
|};
export type UpdateProviderSettingsMutationVariables = {|
  input: UpdateProviderSettingsInput
|};
export type UpdateProviderSettingsMutationResponse = {|
  +updateProviderSettings: ?{|
    +viewer: ?{|
      +$fragmentRefs: PersonalSettings_viewer$ref
    |}
  |}
|};
export type UpdateProviderSettingsMutation = {|
  variables: UpdateProviderSettingsMutationVariables,
  response: UpdateProviderSettingsMutationResponse,
|};
*/


/*
mutation UpdateProviderSettingsMutation(
  $input: UpdateProviderSettingsInput!
) {
  updateProviderSettings(input: $input) {
    viewer {
      __typename
      ...PersonalSettings_viewer
      ... on Node {
        __isNode: __typename
        id
      }
    }
  }
}

fragment PersonalSettings_viewer on User {
  __isUser: __typename
  ... on Provider {
    id
    settings {
      locale
      timezone
      viewType
      appointmentRequiresApproval
      emails
      notifications {
        reminder
        changeOfAppointment
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProviderSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProviderSettingsPayload",
        "kind": "LinkedField",
        "name": "updateProviderSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PersonalSettings_viewer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProviderSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProviderSettingsPayload",
        "kind": "LinkedField",
        "name": "updateProviderSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isUser"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProviderSettings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locale",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timezone",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "appointmentRequiresApproval",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emails",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProviderSettingsNotifications",
                        "kind": "LinkedField",
                        "name": "notifications",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "reminder",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "changeOfAppointment",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Provider",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79fc8f218aa34817f90b262867aa638d",
    "id": null,
    "metadata": {},
    "name": "UpdateProviderSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProviderSettingsMutation(\n  $input: UpdateProviderSettingsInput!\n) {\n  updateProviderSettings(input: $input) {\n    viewer {\n      __typename\n      ...PersonalSettings_viewer\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment PersonalSettings_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    settings {\n      locale\n      timezone\n      viewType\n      appointmentRequiresApproval\n      emails\n      notifications {\n        reminder\n        changeOfAppointment\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '972c020ff904019dfecb7e6b55dace9d';

module.exports = node;
