/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Settings_viewer$ref = any;
export type SettingsQuery_QueryVariables = {||};
export type SettingsQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Settings_viewer$ref
  |}
|};
export type SettingsQuery_Query = {|
  variables: SettingsQuery_QueryVariables,
  response: SettingsQuery_QueryResponse,
|};
*/


/*
query SettingsQuery_Query {
  viewer {
    __typename
    ...Settings_viewer
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment PersonalSettings_viewer on User {
  __isUser: __typename
  ... on Provider {
    id
    settings {
      locale
      timezone
      viewType
      appointmentRequiresApproval
      emails
      notifications {
        reminder
        changeOfAppointment
      }
      id
    }
  }
}

fragment Settings_viewer on User {
  __isUser: __typename
  ... on Provider {
    ...PersonalSettings_viewer
    role {
      sets
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Settings_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sets",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProviderSettings",
                        "kind": "LinkedField",
                        "name": "settings",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locale",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "timezone",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "appointmentRequiresApproval",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emails",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProviderSettingsNotifications",
                            "kind": "LinkedField",
                            "name": "notifications",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "reminder",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "changeOfAppointment",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Provider",
                    "abstractKey": null
                  }
                ],
                "type": "User",
                "abstractKey": "__isUser"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef622886daf3760b612dd67b609ae344",
    "id": null,
    "metadata": {},
    "name": "SettingsQuery_Query",
    "operationKind": "query",
    "text": "query SettingsQuery_Query {\n  viewer {\n    __typename\n    ...Settings_viewer\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment PersonalSettings_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    settings {\n      locale\n      timezone\n      viewType\n      appointmentRequiresApproval\n      emails\n      notifications {\n        reminder\n        changeOfAppointment\n      }\n      id\n    }\n  }\n}\n\nfragment Settings_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    ...PersonalSettings_viewer\n    role {\n      sets\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8c0ed2b05de54205ab3f1a9672ab31e4';

module.exports = node;
