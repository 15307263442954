import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {createRefetchContainer} from 'react-relay';
import {Button, Table} from 'semantic-ui-react';
import VacationHourRow from './VacationHourRow';
import {DeleteVacationHourMutation} from '../../mutations';
import {withTranslation} from 'react-i18next';
import {withRelay} from '../../relay';
import Pagination from '../Pagination';

const refetchVariables = {
  filterBy: {includeNonActive: false},
  orderBy: [['startAt', 'DESC']],
  page: 1,
  first: 50,
  limit: 50,
};

class VacationHours extends React.Component {
  handleRowClick = (vacationHour) => this.props.router.push(`/vacationHour/${vacationHour.id}`);

  handlePageSelected = (page) => {
    refetchVariables.page = page;

    this.props.relay.refetch(refetchVariables);
  };

  handleDeleteClick = (vacationHour) => {
    // TODO: Add a prompt ("Are you sure...?")
    const onSuccess = () => null;
    const onFailure = (error) => console.log('Failure', error);

    DeleteVacationHourMutation(
      {
        input: {id: vacationHour.id},
        proxyId: this.props.viewer.id,
        connectionName: 'VacationHours_vacationHours',
        filters: [],
      },
      onSuccess,
      onFailure,
    );
  };

  render() {
    const {viewer, t, router} = this.props;

    const {vacationHours = []} = viewer;
    const {total, currentPage, start, end} = vacationHours;

    return (
      <div>
        <Button style={{marginBottom: 20}} color="blue" floated="right" onClick={() => router.push('/vacationHour')}>
          {t('actions.add_vacation_period')}
        </Button>
        <Table sortable padded selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('titles.providers')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.from')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.until')}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {vacationHours.edges.map(({node}) => (
              <VacationHourRow
                vacationHour={node}
                key={node.id}
                onRowClick={this.handleRowClick}
                onDeleteClick={this.handleDeleteClick}
              />
            ))}
          </Table.Body>
          {total > Pagination.getLimit() && (
            <Pagination
              currentPage={currentPage}
              start={start}
              end={end}
              total={total}
              onPageSelected={this.handlePageSelected}
            />
          )}
        </Table>
      </div>
    );
  }
}

const VacationHoursQuery = graphql`
  query VacationHoursRefetchQuery(
    $filterBy: VacationHoursFilterInput
    $orderBy: [[String]]
    $page: Int
    $first: Int
    $limit: Int
  ) {
    viewer {
      ...VacationHours_viewer
        @arguments(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit)
    }
  }
`;

const VacationHoursContainer = createRefetchContainer(
  withTranslation()(VacationHours),
  {
    viewer: graphql`
      fragment VacationHours_viewer on User
      @argumentDefinitions(
        first: {type: "Int"}
        page: {type: "Int"}
        filterBy: {type: "VacationHoursFilterInput"}
        orderBy: {type: "[[String]]"}
        limit: {type: "Int"}
      ) {
        ... on Provider {
          id
          vacationHours(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit)
            @connection(key: "VacationHours_vacationHours", filters: []) {
            edges {
              node {
                id
                ...VacationHourRow_vacationHour
              }
            }
            total
            currentPage
            start
            end
            total
          }
        }
      }
    `,
  },
  VacationHoursQuery,
);

VacationHoursContainer.getVariables = (props) => refetchVariables;

export default withRelay(VacationHoursContainer, VacationHoursQuery);
