/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Login_viewer$ref: FragmentReference;
declare export opaque type Login_viewer$fragmentType: Login_viewer$ref;
export type Login_viewer = {|
  +id?: string,
  +settings?: ?{|
    +locale: ?string
  |},
  +$refType: Login_viewer$ref,
|};
export type Login_viewer$data = Login_viewer;
export type Login_viewer$key = {
  +$data?: Login_viewer$data,
  +$fragmentRefs: Login_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Login_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProviderSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locale",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
// prettier-ignore
(node/*: any*/).hash = 'c9fc7d27731b34efe532aef850a5cd3f';

module.exports = node;
