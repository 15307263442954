import {Modal, Select, Message, Button} from 'semantic-ui-react';

const AppointmentPaymentMethodModal = (props) => {
  return (
    <Modal open={props.isAppointmentPaymentMethodsModalOpen} closeIcon onClose={props.handleModalClose}>
      <Modal.Header>{props.t('actions.choose_appointment_payment_method')}</Modal.Header>
      <Modal.Content>
        <Select
          placeholder={props.t('common.payment_method')}
          style={{width: '50%'}}
          onChange={props.onAppointmentPaymentMethodSelect}
          options={props.placePaymentMethodOptions}
        />
        {props.error && <Message error content={props.t('common.error')} />}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={props.onMarkAppointmentAsPaid}
          disabled={props.isConfirmButtonDisabled}
          loading={props.isLoading}
          primary
        >
          {props.t('actions.confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AppointmentPaymentMethodModal;
