import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Tab} from 'semantic-ui-react';
import Container from '../Container';
import ServicesList from './ServicesList';
import VacationHours from './VacationHours';
import WorkHours from './WorkHours';
import {withTranslation} from 'react-i18next';

class Services extends React.Component {
  state = {activeIndex: 0, includeGeneral: true};

  constructor(props) {
    super(props);
    const {t} = props;

    this.panes = [
      {menuItem: `${t('titles.my_services')}`, render: () => <ServicesList router={this.props.router} />},
      {
        menuItem: `${t('titles.work_hours')}`,
        render: () => <WorkHours />,
        param: 'work_hours',
      },
      {
        menuItem: `${t('titles.vacation_periods')}`,
        param: 'vacation_periods',
        render: () => <VacationHours viewer={this.props.viewer} router={this.props.router} />,
      },
    ];

    const {location} = props.match;
    if (location.query) {
      const {tab} = location.query;
      const index = this.panes.findIndex((item) => item.param === tab);
      if (index) {
        this.state = {...this.state, activeIndex: index};
      }
    }
  }

  handleTabChange = (e, data) => {
    const {activeIndex} = data;
    const activePan = data.panes[activeIndex];
    if (activePan.param) {
      this.props.router.push(`/services?tab=${activePan.param}`);
    } else {
      this.props.router.push(`/services`);
    }

    this.setState({activeIndex});
  };

  render() {
    return (
      <Container style={{marginBottom: '40px'}}>
        <Tab
          menu={{secondary: true, pointing: true}}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
          panes={this.panes}
        />
      </Container>
    );
  }
}

const ServicesContainer = createFragmentContainer(withTranslation()(Services), {
  viewer: graphql`
    fragment Services_viewer on User {
      ... on Provider {
        # eslint-disable-next-line relay/unused-fields
        id
      }
    }
  `,
});

export default ServicesContainer;
