import graphql from 'babel-plugin-relay/macro';
import moment from 'moment';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Table, Image} from 'semantic-ui-react';

class ClientRow extends React.Component {
  render() {
    const {id, rowId, name, profilePhotoUrl, lastActiveAt, clientSince, totalAppointmentsCount} = this.props.client;

    const formattedDate = moment(clientSince).format('LL');
    const formattedLastActive = lastActiveAt ? moment(lastActiveAt).format('LL') : null;
    const imageUrl = profilePhotoUrl; // TODO: Add a placeholder

    return (
      <Table.Row onClick={() => this.props.onRowClick(id)}>
        <Table.Cell>{rowId}</Table.Cell>
        <Table.Cell>
          <Image style={{backgroundColor: 'lightgray'}} src={imageUrl} avatar />
          {name}
        </Table.Cell>
        <Table.Cell>{formattedLastActive || ''}</Table.Cell>
        <Table.Cell>{formattedDate}</Table.Cell>
        <Table.Cell>{totalAppointmentsCount}</Table.Cell>
      </Table.Row>
    );
  }
}

export default createFragmentContainer(ClientRow, {
  client: graphql`
    fragment ClientRow_client on Client {
      id
      rowId
      name
      profilePhotoUrl
      lastActiveAt
      clientSince
      totalAppointmentsCount
    }
  `,
});
