/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ServiceView_viewer$ref = any;
export type ServiceViewQuery_QueryVariables = {|
  serviceId?: ?string,
  shouldFetchService: boolean,
  first?: ?number,
|};
export type ServiceViewQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ServiceView_viewer$ref
  |}
|};
export type ServiceViewQuery_Query = {|
  variables: ServiceViewQuery_QueryVariables,
  response: ServiceViewQuery_QueryResponse,
|};
*/


/*
query ServiceViewQuery_Query(
  $serviceId: ID
  $shouldFetchService: Boolean!
  $first: Int
) {
  viewer {
    __typename
    ...ServiceView_viewer_28MtsP
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment ServiceView_viewer_28MtsP on User {
  __isUser: __typename
  ... on Provider {
    id
    workHours {
      dayOfTheWeek
      openTime
      closeTime
      id
    }
    places {
      edges {
        node {
          settings {
            showServiceCategories
            id
          }
          categories {
            edges {
              node {
                rowId
                id
                name
              }
            }
          }
          id
        }
      }
    }
    providers(first: 1000) {
      edges {
        node {
          rowId
          id
          name
        }
      }
    }
    service(id: $serviceId) @include(if: $shouldFetchService) {
      id
      title
      description
      coverPhotoUrl
      displayPrice
      price
      duration
      capacity
      visibleRangeMax
      providerIds
      category {
        id
        rowId
        name
      }
      providers(first: 1000) {
        edges {
          node {
            rowId
            id
            name
          }
        }
      }
      recurringTimeSlots(first: $first) {
        edges {
          node {
            id
            day
            startAt
            endAt
            capacity
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    settings {
      locale
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "serviceId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldFetchService"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  (v5/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "ProviderConnection",
  "kind": "LinkedField",
  "name": "providers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProviderEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Provider",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "providers(first:1000)"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v11 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceViewQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/),
              {
                "kind": "Variable",
                "name": "serviceId",
                "variableName": "serviceId"
              },
              {
                "kind": "Variable",
                "name": "shouldFetchService",
                "variableName": "shouldFetchService"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ServiceView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ServiceViewQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkHour",
                "kind": "LinkedField",
                "name": "workHours",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dayOfTheWeek",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "openTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "closeTime",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaceConnection",
                "kind": "LinkedField",
                "name": "places",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlaceEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Place",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PlaceSettings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "showServiceCategories",
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ServiceCategoryConnection",
                            "kind": "LinkedField",
                            "name": "categories",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ServiceCategoryEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ServiceCategory",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": (v8/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProviderSettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locale",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "condition": "shouldFetchService",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "serviceId"
                      }
                    ],
                    "concreteType": "Service",
                    "kind": "LinkedField",
                    "name": "service",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "coverPhotoUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "visibleRangeMax",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "providerIds",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ServiceCategory",
                        "kind": "LinkedField",
                        "name": "category",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": (v11/*: any*/),
                        "concreteType": "TimeSlotConnection",
                        "kind": "LinkedField",
                        "name": "recurringTimeSlots",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimeSlotEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeSlot",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "day",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startAt",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endAt",
                                    "storageKey": null
                                  },
                                  (v10/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UFPageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__id",
                                "storageKey": null
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v11/*: any*/),
                        "filters": [],
                        "handle": "connection",
                        "key": "ServiceView_recurringTimeSlots",
                        "kind": "LinkedHandle",
                        "name": "recurringTimeSlots"
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "879b7d67862adb0c8d11148a941d6957",
    "id": null,
    "metadata": {},
    "name": "ServiceViewQuery_Query",
    "operationKind": "query",
    "text": "query ServiceViewQuery_Query(\n  $serviceId: ID\n  $shouldFetchService: Boolean!\n  $first: Int\n) {\n  viewer {\n    __typename\n    ...ServiceView_viewer_28MtsP\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment ServiceView_viewer_28MtsP on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    workHours {\n      dayOfTheWeek\n      openTime\n      closeTime\n      id\n    }\n    places {\n      edges {\n        node {\n          settings {\n            showServiceCategories\n            id\n          }\n          categories {\n            edges {\n              node {\n                rowId\n                id\n                name\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    providers(first: 1000) {\n      edges {\n        node {\n          rowId\n          id\n          name\n        }\n      }\n    }\n    service(id: $serviceId) @include(if: $shouldFetchService) {\n      id\n      title\n      description\n      coverPhotoUrl\n      displayPrice\n      price\n      duration\n      capacity\n      visibleRangeMax\n      providerIds\n      category {\n        id\n        rowId\n        name\n      }\n      providers(first: 1000) {\n        edges {\n          node {\n            rowId\n            id\n            name\n          }\n        }\n      }\n      recurringTimeSlots(first: $first) {\n        edges {\n          node {\n            id\n            day\n            startAt\n            endAt\n            capacity\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    settings {\n      locale\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac676012d9310fedbe7c4424200e4d84';

module.exports = node;
