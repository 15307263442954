/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ServicesList_viewer$ref = any;
export type ServicesFilterInput = {|
  categoryId?: ?string,
  searchWord?: ?string,
  includeNonActive?: ?boolean,
  providerId?: ?string,
|};
export type ServicesListRefetchQueryVariables = {|
  filterBy?: ?ServicesFilterInput,
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  page?: ?number,
  first?: ?number,
  limit?: ?number,
|};
export type ServicesListRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: ServicesList_viewer$ref
  |}
|};
export type ServicesListRefetchQuery = {|
  variables: ServicesListRefetchQueryVariables,
  response: ServicesListRefetchQueryResponse,
|};
*/


/*
query ServicesListRefetchQuery(
  $filterBy: ServicesFilterInput
  $orderBy: [[String]]
  $page: Int
  $first: Int
  $limit: Int
) {
  viewer {
    __typename
    ...ServicesList_viewer_18RA4p
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment ServiceRow_service on Service {
  id
  title
  description
  duration
  capacity
  isActive
}

fragment ServicesList_viewer_18RA4p on User {
  __isUser: __typename
  ... on Provider {
    id
    services(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit) {
      edges {
        node {
          id
          ...ServiceRow_service
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v5 = [
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filterBy"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServicesListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "ServicesList_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ServicesListRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "ServiceConnection",
                "kind": "LinkedField",
                "name": "services",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Service",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "capacity",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isActive",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UFPageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "ServicesList_services",
                "kind": "LinkedHandle",
                "name": "services"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1b19480bfa33b7a6298cb63c2423d57",
    "id": null,
    "metadata": {},
    "name": "ServicesListRefetchQuery",
    "operationKind": "query",
    "text": "query ServicesListRefetchQuery(\n  $filterBy: ServicesFilterInput\n  $orderBy: [[String]]\n  $page: Int\n  $first: Int\n  $limit: Int\n) {\n  viewer {\n    __typename\n    ...ServicesList_viewer_18RA4p\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment ServiceRow_service on Service {\n  id\n  title\n  description\n  duration\n  capacity\n  isActive\n}\n\nfragment ServicesList_viewer_18RA4p on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    services(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit) {\n      edges {\n        node {\n          id\n          ...ServiceRow_service\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '410d84adc33afabe3453ee8f15f723ea';

module.exports = node;
