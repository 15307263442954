import React from 'react';
import {List} from 'semantic-ui-react';
import {createFragmentContainer} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import moment from 'moment';
import styled from 'styled-components';
import logo from '../images/logo.png';
// NOTE: Image imported due to issue with react-scripts: https://github.com/facebook/create-react-app/issues/9992
import {ModalContext} from './ModalContext';

const AvatarContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
  margin-right: 10px;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;

class NotificationItem extends React.Component {
  handleNotificationItemClick = (objectType, objectId, openModal) => {
    if (objectType === 'appointment') {
      const {payload} = this.props.notification;
      const isPartOfRecurringSequence = payload && payload.isPartOfRecurringSequence ? true : false;
      openModal(objectId, isPartOfRecurringSequence);
    } else {
      // TODO: Handle opening different type of notification
    }
  };

  render() {
    const {isRead} = this.props;
    const {body, objectType, objectId, id, createdAt} = this.props.notification;
    const formattedCreatedAt = moment(createdAt).fromNow();
    const backgroundColor = isRead ? 'white' : 'lightgrey';

    return (
      <ModalContext.Consumer>
        {({openModal}) => (
          <List.Item
            key={id}
            style={{flex: 1, display: 'flex', flexDirection: 'row', backgroundColor}}
            onClick={() => this.handleNotificationItemClick(objectType, objectId, openModal)}
          >
            <AvatarContainer>
              <Avatar src={logo} />
            </AvatarContainer>
            <div style={{marginTop: 2}}>
              <List.Header>{body}</List.Header>
              <div style={{display: 'flex', flexDirection: 'row', marginTop: 2}}>
                <List.Icon name={'calendar alternate outline'} />
                <List.Description>{formattedCreatedAt}</List.Description>
              </div>
            </div>
          </List.Item>
        )}
      </ModalContext.Consumer>
    );
  }
}

export default createFragmentContainer(NotificationItem, {
  notification: graphql`
    fragment NotificationItem_notification on Notification {
      body
      objectType
      payload
      objectId
      createdAt
    }
  `,
});
