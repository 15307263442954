import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation UpdateServiceMutation($input: UpdateServiceInput!) {
    updateService(input: $input) {
      service {
        isActive
        id
        title
        description
        shortDescription
        duration
        coverPhotoUrl
        capacity
        price
        visibleRangeMax
        providerIds
        providers(first: 1000) {
          edges {
            node {
              rowId
              id
              name
              phoneNumber
            }
          }
        }
        ...ServiceRow_service
      }
    }
  }
`;

const UpdateServiceMutation = (payload, success, failure) => {
  const {input, uploadables} = payload;

  commitMutation(environment, {
    mutation,
    variables: {input},
    uploadables,
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default UpdateServiceMutation;
