import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Tab, Menu, Label} from 'semantic-ui-react';
import Container from '../Container';
import General from './General';
import History from './History';
import {withTranslation} from 'react-i18next';

class Payments extends React.Component {
  state = {activeIndex: 0, includeGeneral: true};

  handleTabChange = (e, {activeIndex}) => this.setState({activeIndex});

  render() {
    const {activeIndex} = this.state;
    const {t} = this.props;
    const {subscription} = this.props.viewer.organization;

    const isSubscriptionActive = subscription && subscription.status === 'active';

    const panes = [
      {
        menuItem: (
          <Menu.Item key="1">
            {t('common.general')}
            {!isSubscriptionActive && (
              <Label floating circular color="orange">
                !
              </Label>
            )}
          </Menu.Item>
        ),
        render: () => <General viewer={this.props.viewer} />,
      },
      {menuItem: `${t('common.history')}`, render: () => <History />},
    ];

    return (
      <Container>
        <Tab
          menu={{secondary: true, pointing: true}}
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}
          panes={panes}
        />
      </Container>
    );
  }
}

const PaymentsContainer = createFragmentContainer(withTranslation()(Payments), {
  viewer: graphql`
    fragment Payments_viewer on User @argumentDefinitions(includeGeneral: {type: "Boolean", defaultValue: true}) {
      ... on Provider {
        organization {
          subscription {
            status
          }
        }
        ...General_viewer @include(if: $includeGeneral)
      }
    }
  `,
});

export default PaymentsContainer;
