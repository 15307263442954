import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {Segment, Icon, Header, Grid} from 'semantic-ui-react';
import moment from 'moment';
import {createFragmentContainer} from 'react-relay';

const getFormattedTimeWindow = (appointment) => {
  const {startAt, endAt} = appointment;

  const formattedStartAt = moment(startAt).format('hh:mm');
  const formattedEndAt = moment(endAt).format('hh:mm');

  return `${formattedStartAt} - ${formattedEndAt}`;
};

class AppointmentRow extends React.Component {
  render() {
    const {key, appointment, segmentProps = {}, iconName, primaryTextColor, secondaryTextColor} = this.props;
    const {startAt, service, duration} = appointment;

    return (
      <Segment {...segmentProps} size="massive" key={key}>
        <Grid columns={2} stackable>
          <Grid.Column>
            <Header as="h4" style={{color: primaryTextColor}}>
              <Icon name={iconName} size="small" />
              <Header.Content>
                {getFormattedTimeWindow(appointment)}
                <Header.Subheader style={{color: secondaryTextColor}}>
                  {moment(startAt).format('MMM Do YYYY')}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column verticalAlign="middle">
            <Header as="h4" style={{color: primaryTextColor}}>
              <Header.Content>
                {service.title}
                <Header.Subheader style={{color: secondaryTextColor}}>{duration} mins</Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid>
      </Segment>
    );

    // return (
    //   <Segment raised size="massive">
    //     <Grid columns={2} stackable>
    //       <Grid.Column>
    //         <Header as="h5" color="grey">
    //           <Icon name="calendar alternate outline" color="grey" size="small" />
    //           {formattedStartAt} - {formattedEndAt}
    //         </Header>
    //       </Grid.Column>
    //       <Grid.Column verticalAlign="middle">
    //         <Header color="blue" as="h4">
    //           <Image avatar src={profilePhotoUrl} />
    //           {name}
    //         </Header>
    //       </Grid.Column>
    //     </Grid>

    //     <Divider vertical />
    //   </Segment>
    // );
  }
}

export default createFragmentContainer(AppointmentRow, {
  appointment: graphql`
    fragment AppointmentRow_appointment on Appointment {
      startAt
      endAt
      duration
      service {
        title
      }
    }
  `,
});
