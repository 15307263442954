/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConfirmInitialSubscriptionPaymentInput = {|
  clientMutationId?: ?string,
  paymentIntentId: string,
  subscriptionId: string,
  subscriptionRefId: string,
|};
export type ConfirmInitialSubscriptionPaymentMutationVariables = {|
  input: ConfirmInitialSubscriptionPaymentInput
|};
export type ConfirmInitialSubscriptionPaymentMutationResponse = {|
  +confirmInitialSubscriptionPayment: ?{|
    +subscription: ?any
  |}
|};
export type ConfirmInitialSubscriptionPaymentMutation = {|
  variables: ConfirmInitialSubscriptionPaymentMutationVariables,
  response: ConfirmInitialSubscriptionPaymentMutationResponse,
|};
*/


/*
mutation ConfirmInitialSubscriptionPaymentMutation(
  $input: ConfirmInitialSubscriptionPaymentInput!
) {
  confirmInitialSubscriptionPayment(input: $input) {
    subscription
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ConfirmInitialSubscriptionPaymentPayload",
    "kind": "LinkedField",
    "name": "confirmInitialSubscriptionPayment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subscription",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmInitialSubscriptionPaymentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmInitialSubscriptionPaymentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ce10d8387739685899f7309f193dafe",
    "id": null,
    "metadata": {},
    "name": "ConfirmInitialSubscriptionPaymentMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmInitialSubscriptionPaymentMutation(\n  $input: ConfirmInitialSubscriptionPaymentInput!\n) {\n  confirmInitialSubscriptionPayment(input: $input) {\n    subscription\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '811aa420a8697f6da33bc15b8765c041';

module.exports = node;
