/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Services_viewer$ref = any;
export type ServicesQuery_QueryVariables = {||};
export type ServicesQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Services_viewer$ref
  |}
|};
export type ServicesQuery_Query = {|
  variables: ServicesQuery_QueryVariables,
  response: ServicesQuery_QueryResponse,
|};
*/


/*
query ServicesQuery_Query {
  viewer {
    __typename
    ...Services_viewer
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment Services_viewer on User {
  __isUser: __typename
  ... on Provider {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServicesQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Services_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ServicesQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": (v0/*: any*/),
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v0/*: any*/),
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2eb4f8a1fc75350e3efd0db9877fe910",
    "id": null,
    "metadata": {},
    "name": "ServicesQuery_Query",
    "operationKind": "query",
    "text": "query ServicesQuery_Query {\n  viewer {\n    __typename\n    ...Services_viewer\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment Services_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '86212efb4b7fd8733c61b648946297a2';

module.exports = node;
