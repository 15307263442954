import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation AddWorkHourMutation($connections: [ID!]!, $input: AddWorkHourInput!) {
    addWorkHour(input: $input) {
      workHourEdge @prependEdge(connections: $connections) {
        node {
          id
          dayOfTheWeek
          openTime
          closeTime
          providerId
          provider {
            name
          }
          isOpen
        }
      }
    }
  }
`;

const AddWorkHourMutation = (payload, success, failure) => {
  const {input, connectionId} = payload;

  commitMutation(environment, {
    mutation,
    variables: {input, connections: [connectionId]},
    updater: (store) => {
      const payload = store.getRootField('addWorkHour');
      if (!payload) {
        return;
      }
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default AddWorkHourMutation;
