import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';
import {ConnectionHandler} from 'relay-runtime';

const mutation = graphql`
  mutation AddRecurringTimeSlotMutation($connections: [ID!]!, $input: AddRecurringTimeSlotInput!) {
    addRecurringTimeSlot(input: $input) {
      timeSlotEdge @prependEdge(connections: $connections) {
        node {
          id
          day
          startAt
          endAt
          capacity
        }
      }
    }
  }
`;

const AddRecurringTimeSlotMutation = (payload, success, failure) => {
  const {input, connectionName, serviceId: proxyId} = payload;
  const connectionId = ConnectionHandler.getConnectionID(proxyId, connectionName);

  commitMutation(environment, {
    mutation,
    variables: {input, connections: [connectionId]},
    updater: (store) => {
      const payload = store.getRootField('addRecurringTimeSlot');
      if (!payload) {
        return;
      }
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default AddRecurringTimeSlotMutation;
