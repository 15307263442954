import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {createFragmentContainer} from 'react-relay';
import {Form, Icon, Message, Button} from 'semantic-ui-react';
import {get, pickBy, pick} from 'lodash';
import isEmpty from '../../helpers/isEmpty';
import {CoverPhotoButton, CoverIcon, ProfilePhotoButton, AvatarIcon} from './components';
import {UpdateOrganizationMutation} from '../../mutations';
import isMatchWith from '../../helpers/isMatchWith';

const formFields = ['name', 'email', 'phoneNumber', 'categoryId'];

class Organization extends React.Component {
  state = {
    name: null,
    email: null,
    phoneNumber: null,
    categoryId: null,
    validatePassword: null,
    isLoading: false,
    errorContent: null,
    isModalOpen: false,
    error: false,
  };

  constructor(props) {
    super(props);

    this.coverPhotoInputRef = React.createRef();
    this.profilePhotoInputRef = React.createRef();
  }

  resetForm = () =>
    this.setState({
      name: null,
      email: null,
      phoneNumber: null,
      categoryId: null,
      validatePassword: null,
      isLoading: false,
      isModalOpen: false,
      error: false,
    });

  getFieldValue = (fieldName) =>
    isEmpty(get(this.state, fieldName)) && get(this.state, fieldName) !== ''
      ? get(this.props.organization, fieldName)
      : get(this.state, fieldName);

  handleFieldChange = (i, data) => {
    if (this.state.error) {
      this.setState({error: null});
    }

    this.setState({[data.name]: data.value});
  };

  handleCoverPhotoInputRef = (e) => {
    this.coverPhotoInputRef.current.click();
  };

  handleProfilePhotoInputRef = (e) => {
    this.profilePhotoInputRef.current.click();
  };

  handleCoverPhotoUpload = (e) => {
    const {t} = this.props;

    const fileButton = document.getElementById('cover-input');
    const coverPhoto = fileButton ? fileButton.files[0] : null;

    const onSuccess = (data) => {
      this.coverPhotoInputRef.current.value = '';
      this.setState({isLoading: false, error: false, errorContent: null});
    };

    const onFailure = (errors) => {
      this.coverPhotoInputRef.current.value = '';

      this.setState({
        isLoading: false,
        error: true,
        errorContent: `${t('common.something_went_wrong')} ${t('common.try_again')}`,
      });
    };

    UpdateOrganizationMutation(
      {input: {id: this.props.organization.id}, uploadables: {coverPhoto}},
      onSuccess,
      onFailure,
    );
  };

  handleProfilePhotoUpload = (e) => {
    const {t} = this.props;

    const fileButton = document.getElementById('profile-input');
    const profilePhoto = fileButton ? fileButton.files[0] : null;

    const onSuccess = (data) => {
      this.profilePhotoInputRef.current.value = '';
      this.setState({isLoading: false, errorContent: null, error: false});
    };

    const onFailure = (errors) => {
      this.profilePhotoInputRef.current.value = '';
      this.setState({
        isLoading: false,
        error: true,
        errorContent: `${t('common.something_went_wrong')} ${t('common.try_again')}`,
      });
    };

    UpdateOrganizationMutation(
      {input: {id: this.props.organization.id}, uploadables: {profilePhoto}},
      onSuccess,
      onFailure,
    );
  };

  handleSubmit = () => {
    const {t, organization} = this.props;
    const stateValues = pick(this.state, formFields);
    const input = pickBy(stateValues, (value) => !isEmpty(value));
    input.id = organization.id;

    const onSuccess = (data) => {
      this.resetForm();
    };

    const onFailure = (errors) => {
      // TODO: Handle errors
      this.setState({
        isLoading: false,
        error: `${t('common.something_went_wrong')} ${t('common.try_again')}`,
      });
    };

    if (this.state.phoneNumber) {
      if (this.state.phoneNumber.match(/\d/g).length < 10) {
        return this.setState({error: true, errorContent: t('common.phone_number_error'), isLoading: false});
      }

      input.phoneNumber = this.state.phoneNumber.trim().replace(/[^+\d]+/g, '');
    }

    if (this.state.email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(this.state.email)) {
        return this.setState({error: true, errorContent: t('common.email_error'), isLoading: false});
      }
    }

    UpdateOrganizationMutation({input}, onSuccess, onFailure);
  };

  hasUnsavedChanges = () => {
    const propsValues = pick(this.props.organization, formFields);
    const stateValues = pick(this.state, formFields);

    return !isMatchWith(propsValues, stateValues);
  };

  render() {
    const {organization, t} = this.props;
    const {isLoading, errorContent} = this.state;
    const {coverPhotoUrl, profilePhotoUrl} = organization;

    const categoryOptions = organization.categories.map((category) => ({
      key: category.rowId,
      text: category.name,
      value: category.rowId,
    }));

    const unsavedChanges = this.hasUnsavedChanges();

    return (
      <div style={{position: 'relative'}}>
        <CoverPhotoButton
          src={coverPhotoUrl}
          id="cover"
          type="button"
          onClick={this.handleCoverPhotoInputRef}
          htmlFor="cover-input"
        >
          <CoverIcon>
            <Icon style={{color: 'white'}} name="upload" />
          </CoverIcon>
        </CoverPhotoButton>
        <input
          hidden
          ref={this.coverPhotoInputRef}
          id="cover-input"
          type="file"
          accept="image/*"
          onChange={this.handleCoverPhotoUpload}
        />
        <ProfilePhotoButton
          src={profilePhotoUrl}
          id="avatar"
          type="button"
          onClick={this.handleProfilePhotoInputRef}
          htmlFor="profile-input"
        >
          <AvatarIcon>
            <Icon name="upload" style={{color: 'white'}} />
          </AvatarIcon>
        </ProfilePhotoButton>
        <input
          hidden
          id="profile-input"
          ref={this.profilePhotoInputRef}
          type="file"
          accept="image/*"
          onChange={this.handleProfilePhotoUpload}
        />
        <Form error={this.state.error} style={{marginTop: 40}}>
          <Message error header={t('common.error')} content={errorContent} />
          <Form.Group widths="equal">
            <Form.Input
              required
              fluid
              name="name"
              label={t('common.name')}
              onChange={this.handleFieldChange}
              value={this.getFieldValue('name')}
            />
            <Form.Input
              required
              fluid
              name="email"
              type="email"
              label={t('common.email')}
              onChange={this.handleFieldChange}
              value={this.getFieldValue('email')}
            />
            <Form.Input
              required
              fluid
              name="phoneNumber"
              type="tel"
              label={t('common.phone_number')}
              onChange={this.handleFieldChange}
              value={this.getFieldValue('phoneNumber')}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              name="categoryId"
              label={t('common.category')}
              options={categoryOptions}
              placeholder="Select Category"
              selection
              onChange={this.handleFieldChange}
              value={this.getFieldValue('categoryId')}
            />
          </Form.Group>
        </Form>
        <Button fluid color="blue" loading={isLoading} disabled={!unsavedChanges} onClick={this.handleSubmit}>
          {t('common.save')}
        </Button>
      </div>
    );
  }
}

export default createFragmentContainer(withTranslation()(Organization), {
  organization: graphql`
    fragment Organization_organization on Organization {
      id
      name
      # eslint-disable-next-line relay/unused-fields
      email
      # eslint-disable-next-line relay/unused-fields
      phoneNumber
      coverPhotoUrl
      profilePhotoUrl
      # eslint-disable-next-line relay/unused-fields
      categoryId
      categories {
        rowId
        id
        name
      }
    }
  `,
});
