/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Places_organization$ref: FragmentReference;
declare export opaque type Places_organization$fragmentType: Places_organization$ref;
export type Places_organization = {|
  +places: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +phoneNumber: ?string,
    +email: ?string,
    +profilePhotoUrl: ?string,
    +coverPhotoUrl: ?string,
    +address: ?{|
      +placeId: ?string,
      +formatted: ?string,
    |},
  |}>,
  +$refType: Places_organization$ref,
|};
export type Places_organization$data = Places_organization;
export type Places_organization$key = {
  +$data?: Places_organization$data,
  +$fragmentRefs: Places_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Places_organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Place",
      "kind": "LinkedField",
      "name": "places",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhotoUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coverPhotoUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "placeId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "formatted",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9b6bd267f08dbd01aa5ec76e3e861a78';

module.exports = node;
