import {useTranslation} from 'react-i18next';
import moment from 'moment';
import styled from 'styled-components';
import ClientCard from '../ClientCard';
import getAppointmentObjectsForTimeSlotsMemoized from '../../helpers/getAppointmentObjectsForTimeSlots';
import AppointmentPaymentDetails from './AppointmentPaymentDetails';

const Container = styled.div`
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: left;
`;

const DateText = styled.div`
  font-size: 18px;
`;

const TimeText = styled.div`
  font-size: 22px;
`;

const TimeContainer = styled.div`
  margin: 0px 0px 10px 0px;
`;

const Separator = styled.div`
  margin: 10px 0px 11px 0px;
  width: 100%;
  height: 1px;
  background-color: lightgray;
`;

const ServiceTitle = styled.div`
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const ProviderName = styled.div`
  font-size: 16px;
`;

const CreatedByName = styled.div`
  font-size: 16px;
`;

export default function AppointmentInfo(props) {
  const {t} = useTranslation();

  const {
    payments,
    client,
    service,
    provider,
    timeSlots,
    cancellationReason,
    createdBy,
    formattedRecurrencePattern,
  } = props;

  const tentativeAppointments = getAppointmentObjectsForTimeSlotsMemoized(timeSlots);

  return (
    <Container>
      <ClientCard client={client} />
      <div className="confirmation-details">
        <ServiceTitle> {service.title}</ServiceTitle>
        {cancellationReason && (
          <p style={{wordBreak: 'break-word', maxWidth: '95%'}}>{cancellationReason && `- ${cancellationReason}`}</p>
        )}
        <TimeContainer>
          {tentativeAppointments.map((tentativeAppointment) => (
            <div style={{marginTop: 6}} key={tentativeAppointment.startAt}>
              <DateText>{moment(tentativeAppointment.date, 'YYYY-MM-DD').format('Do MMMM YYYY')}</DateText>
              <TimeText>
                {tentativeAppointment.startAt} - {tentativeAppointment.endAt}
              </TimeText>
              {formattedRecurrencePattern && (
                <div>
                  {t('common.repeating_appointment')}: {formattedRecurrencePattern}
                </div>
              )}
            </div>
          ))}
        </TimeContainer>
        <Separator />
        <ProviderName>
          {t('common.provider')}: {provider.name}
        </ProviderName>
        {createdBy && (
          <CreatedByName>
            {t('common.created_by')}: {createdBy.name}{' '}
            <span style={{color: 'grey'}}>({t(`common.${createdBy.type}`)})</span>
          </CreatedByName>
        )}
        {payments && payments.length > 0 && payments.map((payment) => <AppointmentPaymentDetails payment={payment} />)}
      </div>
    </Container>
  );
}
