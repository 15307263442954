import graphql from 'babel-plugin-relay/macro';

const CalendarQuery = graphql`
  query CalendarQuery_Query($appointmentId: ID, $filterBy: ProviderAppointmentFilterInput, $orderBy: [[String]]) {
    viewer {
      ...Calendar_viewer @arguments(appointmentId: $appointmentId, filterBy: $filterBy, orderBy: $orderBy)
    }
  }
`;

export default CalendarQuery;
