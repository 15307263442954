/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Analytics_viewer$ref = any;
export type AnalyticsFilterInput = {|
  date?: ?RangeDateFilter
|};
export type RangeDateFilter = {|
  from?: ?string,
  to?: ?string,
|};
export type AnalyticsRefetchQueryVariables = {|
  filterBy?: ?AnalyticsFilterInput
|};
export type AnalyticsRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Analytics_viewer$ref
  |}
|};
export type AnalyticsRefetchQuery = {|
  variables: AnalyticsRefetchQueryVariables,
  response: AnalyticsRefetchQueryResponse,
|};
*/


/*
query AnalyticsRefetchQuery(
  $filterBy: AnalyticsFilterInput
) {
  viewer {
    __typename
    ...Analytics_viewer_2T86qt
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment Analytics_viewer_2T86qt on User {
  __isUser: __typename
  ... on Provider {
    analytics(filterBy: $filterBy) {
      appointments
      totalNumberClients
      newClients
      hours
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filterBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnalyticsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "Analytics_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnalyticsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "Analytics",
                "kind": "LinkedField",
                "name": "analytics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appointments",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalNumberClients",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "newClients",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hours",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a2d6bdd1d7b94122dee24b5d5e282bc7",
    "id": null,
    "metadata": {},
    "name": "AnalyticsRefetchQuery",
    "operationKind": "query",
    "text": "query AnalyticsRefetchQuery(\n  $filterBy: AnalyticsFilterInput\n) {\n  viewer {\n    __typename\n    ...Analytics_viewer_2T86qt\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment Analytics_viewer_2T86qt on User {\n  __isUser: __typename\n  ... on Provider {\n    analytics(filterBy: $filterBy) {\n      appointments\n      totalNumberClients\n      newClients\n      hours\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fed92fcffceba0304e2f566f8b4a5664';

module.exports = node;
