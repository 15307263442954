/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RemoveClientInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type RemoveClientMutationVariables = {|
  input: RemoveClientInput
|};
export type RemoveClientMutationResponse = {|
  +removeClient: ?{|
    +client: ?{|
      +id: string
    |}
  |}
|};
export type RemoveClientMutation = {|
  variables: RemoveClientMutationVariables,
  response: RemoveClientMutationResponse,
|};
*/


/*
mutation RemoveClientMutation(
  $input: RemoveClientInput!
) {
  removeClient(input: $input) {
    client {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveClientPayload",
    "kind": "LinkedField",
    "name": "removeClient",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Client",
        "kind": "LinkedField",
        "name": "client",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveClientMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveClientMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "71b899b345785bdc8e319e2b98457068",
    "id": null,
    "metadata": {},
    "name": "RemoveClientMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveClientMutation(\n  $input: RemoveClientInput!\n) {\n  removeClient(input: $input) {\n    client {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6111448d773f949eea24672b5ad2a2e5';

module.exports = node;
