import graphql from 'babel-plugin-relay/macro';
import {requestSubscription} from 'react-relay';
import {environment} from '../relay';

const updateAppointmentSubscription = graphql`
  subscription UpdateAppointmentSubscription($input: UpdateAppointmentSubscriptionInput!) {
    updateAppointment(input: $input) {
      id
      appointmentEdge {
        node {
          id
          providerId
          serviceId
          service {
            profilePhotoUrl
            title
            duration
            capacity
          }
          capacity
          startAt
          status
          endAt
          clientId
          isRecurring
          provider {
            id
            rowId
            name
          }
          client {
            id
            name
            profilePhotoUrl
          }
        }
      }
    }
  }
`;

const UpdateAppointmentSubscription = (viewerId, connectionName, filters, callback) => {
  const requestSubscriptionResult = requestSubscription(environment, {
    subscription: updateAppointmentSubscription,
    variables: {
      input: {},
    },
    onNext: (...rest) => {
      if (callback && callback.onSuccess) {
        callback.onSuccess();
      }
    },
  });

  return {
    dispose: requestSubscriptionResult.dispose,
  };
};

export default UpdateAppointmentSubscription;
