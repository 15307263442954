/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRecurringTimeSlotInput = {|
  clientMutationId?: ?string,
  id: string,
  day?: ?number,
  startAt?: ?string,
  endAt?: ?string,
  capacity?: ?number,
|};
export type UpdateRecurringTimeSlotMutationVariables = {|
  input: UpdateRecurringTimeSlotInput
|};
export type UpdateRecurringTimeSlotMutationResponse = {|
  +updateRecurringTimeSlot: ?{|
    +timeSlot: ?{|
      +id: string,
      +day: ?number,
      +startAt: ?any,
      +endAt: ?any,
      +capacity: ?number,
    |}
  |}
|};
export type UpdateRecurringTimeSlotMutation = {|
  variables: UpdateRecurringTimeSlotMutationVariables,
  response: UpdateRecurringTimeSlotMutationResponse,
|};
*/


/*
mutation UpdateRecurringTimeSlotMutation(
  $input: UpdateRecurringTimeSlotInput!
) {
  updateRecurringTimeSlot(input: $input) {
    timeSlot {
      id
      day
      startAt
      endAt
      capacity
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateRecurringTimeSlotPayload",
    "kind": "LinkedField",
    "name": "updateRecurringTimeSlot",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TimeSlot",
        "kind": "LinkedField",
        "name": "timeSlot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "day",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "capacity",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRecurringTimeSlotMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRecurringTimeSlotMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a9d3acc79f25b4cfe6796856fe9a99e",
    "id": null,
    "metadata": {},
    "name": "UpdateRecurringTimeSlotMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRecurringTimeSlotMutation(\n  $input: UpdateRecurringTimeSlotInput!\n) {\n  updateRecurringTimeSlot(input: $input) {\n    timeSlot {\n      id\n      day\n      startAt\n      endAt\n      capacity\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03fc023082167ef44dd8288ae934ace5';

module.exports = node;
