/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VacationHourRow_vacationHour$ref = any;
export type UpdateVacationHourInput = {|
  clientMutationId?: ?string,
  id: string,
  providerIds: $ReadOnlyArray<?string>,
  startAt: string,
  endAt: string,
|};
export type UpdateVacationHourMutationVariables = {|
  input: UpdateVacationHourInput
|};
export type UpdateVacationHourMutationResponse = {|
  +updateVacationHour: ?{|
    +vacationHour: ?{|
      +id: string,
      +startAt: ?any,
      +endAt: ?any,
      +provider: ?{|
        +id: string,
        +name: ?string,
      |},
      +$fragmentRefs: VacationHourRow_vacationHour$ref,
    |}
  |}
|};
export type UpdateVacationHourMutation = {|
  variables: UpdateVacationHourMutationVariables,
  response: UpdateVacationHourMutationResponse,
|};
*/


/*
mutation UpdateVacationHourMutation(
  $input: UpdateVacationHourInput!
) {
  updateVacationHour(input: $input) {
    vacationHour {
      id
      startAt
      endAt
      provider {
        id
        name
      }
      ...VacationHourRow_vacationHour
    }
  }
}

fragment VacationHourRow_vacationHour on VacationHour {
  id
  provider {
    id
    name
  }
  startAt
  endAt
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Provider",
  "kind": "LinkedField",
  "name": "provider",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateVacationHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateVacationHourPayload",
        "kind": "LinkedField",
        "name": "updateVacationHour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VacationHour",
            "kind": "LinkedField",
            "name": "vacationHour",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "VacationHourRow_vacationHour"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateVacationHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateVacationHourPayload",
        "kind": "LinkedField",
        "name": "updateVacationHour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VacationHour",
            "kind": "LinkedField",
            "name": "vacationHour",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67265b882ba92c4165c7007e52c55847",
    "id": null,
    "metadata": {},
    "name": "UpdateVacationHourMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateVacationHourMutation(\n  $input: UpdateVacationHourInput!\n) {\n  updateVacationHour(input: $input) {\n    vacationHour {\n      id\n      startAt\n      endAt\n      provider {\n        id\n        name\n      }\n      ...VacationHourRow_vacationHour\n    }\n  }\n}\n\nfragment VacationHourRow_vacationHour on VacationHour {\n  id\n  provider {\n    id\n    name\n  }\n  startAt\n  endAt\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '42536e108a182cc39f31f6f1b6b61a6b';

module.exports = node;
