import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation UpdatePlacesSettingsMutation($input: UpdatePlacesSettingsInput!) {
    updatePlacesSettings(input: $input) {
      places {
        id
        name
        settings {
          currency
          locale
          timezone
          viewType
          showServiceCategories
        }
      }
    }
  }
`;

const UpdatePlacesSettingsMutation = (payload, success, failure) => {
  const {input} = payload;

  commitMutation(environment, {
    mutation,
    variables: {input},
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default UpdatePlacesSettingsMutation;
