/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Personal_viewer$ref = any;
export type UpdateProviderInput = {|
  clientMutationId?: ?string,
  id: string,
  name?: ?string,
  email?: ?string,
  validatePassword?: ?string,
  password?: ?string,
  phoneNumber?: ?string,
  placeId?: ?string,
  formatted?: ?string,
  latitude?: ?number,
  settings?: ?ProviderSettingsInput,
  longitude?: ?number,
  categoryId?: ?string,
  notificationsToken?: ?string,
  appVersion?: ?string,
|};
export type ProviderSettingsInput = {|
  providerId?: ?string,
  locale?: ?string,
  notifications?: ?any,
  timezone?: ?string,
  emails?: ?any,
  appointmentRequiresApproval?: ?boolean,
|};
export type UpdateProviderMutationVariables = {|
  input: UpdateProviderInput
|};
export type UpdateProviderMutationResponse = {|
  +updateProvider: ?{|
    +viewer: ?{|
      +$fragmentRefs: Personal_viewer$ref
    |}
  |}
|};
export type UpdateProviderMutation = {|
  variables: UpdateProviderMutationVariables,
  response: UpdateProviderMutationResponse,
|};
*/


/*
mutation UpdateProviderMutation(
  $input: UpdateProviderInput!
) {
  updateProvider(input: $input) {
    viewer {
      __typename
      ...Personal_viewer
      ... on Node {
        __isNode: __typename
        id
      }
    }
  }
}

fragment Personal_viewer on User {
  __isUser: __typename
  ... on Provider {
    id
    name
    email
    profilePhotoUrl
    phoneNumber
    settings {
      locale
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProviderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProviderPayload",
        "kind": "LinkedField",
        "name": "updateProvider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Personal_viewer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProviderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateProviderPayload",
        "kind": "LinkedField",
        "name": "updateProvider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isUser"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "profilePhotoUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProviderSettings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locale",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Provider",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3455d809210a3b4dfea2687fb7af65df",
    "id": null,
    "metadata": {},
    "name": "UpdateProviderMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProviderMutation(\n  $input: UpdateProviderInput!\n) {\n  updateProvider(input: $input) {\n    viewer {\n      __typename\n      ...Personal_viewer\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment Personal_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    name\n    email\n    profilePhotoUrl\n    phoneNumber\n    settings {\n      locale\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4242a0bbfa9914fdfdfe88578e812b86';

module.exports = node;
