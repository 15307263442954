import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';
import {ConnectionHandler} from 'relay-runtime';

const mutation = graphql`
  mutation AddClientMutation($connections: [ID!]!, $input: AddClientInput!) {
    addClient(input: $input) {
      clientEdge @prependEdge(connections: $connections) {
        node {
          # TODO: Optmize these fields
          id
          rowId
          name
          profilePhotoUrl
          phoneNumber
        }
      }
    }
  }
`;

const AddClientMutation = (payload, success, failure) => {
  const connectionName = payload.connectionName;
  const {input, filters, viewer} = payload;

  const connectionId = ConnectionHandler.getConnectionID(viewer.id, connectionName, filters);

  commitMutation(environment, {
    mutation,
    variables: {
      input,
      connections: [connectionId],
    },
    updater: (store) => {
      // Add to connection
      const payload = store.getRootField('addClient');
      if (!payload) {
        return;
      }
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default AddClientMutation;
