import API from './api';

export default async function getCountryCodeByIp() {
  try {
    const {json} = await API.get('utils/geoip', {timeout: 2000});

    return json.countryCode;
  } catch (e) {
    console.log('error when getting geo', e);
  }

  return 'us';
}
