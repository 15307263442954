import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {ConnectionHandler} from 'relay-runtime';
import {environment} from '../relay';

const mutation = graphql`
  mutation AddProviderMutation($input: AddProviderInput!, $connections: [ID!]!) {
    addProvider(input: $input) {
      provider @appendNode(connections: $connections, edgeTypeName: "Provider") {
        id
        ...StaffMemberRow_provider
      }
    }
  }
`;

const AddProviderMutation = (payload, success, failure) => {
  const {connectionName, viewerId, filters} = payload;
  const connectionId = ConnectionHandler.getConnectionID(viewerId, connectionName, filters);

  commitMutation(environment, {
    mutation,
    variables: {input: payload.input, connections: [connectionId]},
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default AddProviderMutation;
