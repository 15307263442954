import {Select} from 'semantic-ui-react';

const ClientsDropdown = (props) => {
  const {clients, t} = props;

  if (!clients.edges) {
    return null;
  }

  const clientsOptions = [];
  clientsOptions.push({key: 0, text: t('common.guest'), value: undefined});

  clients.edges.forEach(({node: client}) => {
    clientsOptions.push({
      key: client.rowId,
      text: client.name,
      value: client.rowId,
    });
    // image: {avatar: true, src: client.profilePhotoUrl},
  });

  return (
    <Select
      placeholder={t('actions.select_client')}
      fluid
      search
      name="clientId"
      onChange={props.onClientChange}
      options={clientsOptions}
    />
  );
};

export default ClientsDropdown;
