/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClientRow_client$ref: FragmentReference;
declare export opaque type ClientRow_client$fragmentType: ClientRow_client$ref;
export type ClientRow_client = {|
  +id: string,
  +rowId: number,
  +name: ?string,
  +profilePhotoUrl: ?string,
  +lastActiveAt: ?any,
  +clientSince: ?any,
  +totalAppointmentsCount: ?string,
  +$refType: ClientRow_client$ref,
|};
export type ClientRow_client$data = ClientRow_client;
export type ClientRow_client$key = {
  +$data?: ClientRow_client$data,
  +$fragmentRefs: ClientRow_client$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClientRow_client",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePhotoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastActiveAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAppointmentsCount",
      "storageKey": null
    }
  ],
  "type": "Client",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0be1d56f37827a5dda9b946ce79497a3';

module.exports = node;
