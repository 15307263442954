import React from 'react';
import {ScrollMenu, VisibilityContext} from 'react-horizontal-scrolling-menu';
import {Icon, Header, List} from 'semantic-ui-react';
import moment from 'moment';
import {isEmpty} from 'lodash';
import './TimeSlotsCalendar.css';
import {withTranslation} from 'react-i18next';

function TimeSlotsCalendar(props) {
  const {timeSlots, t, forwardedRef} = props;

  const handleSlotClick = (timeSlot) => props.onTimeSlotClick(timeSlot);

  const isItemActive = (timeSlotId) => (props.selectedTimeSlots || []).some((slot) => slot.id === timeSlotId);

  function renderMenuItems() {
    const {timeSlots} = props;

    const dates = Object.keys(timeSlots);
    dates.sort();

    const items = dates.map((date) => {
      const _timeSlots = timeSlots[date];
      const _date = moment(date);

      return (
        <div className="menu-item" itemId={date} key={date}>
          <div className="date">
            <p>{_date.format('ddd')}</p>
            <p>{_date.format('DD')}</p>
          </div>
          <List>
            {_timeSlots.map((timeSlot) => (
              <List.Item
                className="list-item"
                active={isItemActive(timeSlot.id)}
                key={timeSlot.id}
                onClick={() => handleSlotClick(timeSlot)}
              >
                {timeSlot.startAt}
              </List.Item>
            ))}
          </List>
        </div>
      );
    });

    return items;
  }

  function RightArrow() {
    const {isLastItemVisible, visibleItemsWithoutSeparators, scrollNext} = React.useContext(VisibilityContext);

    if (visibleItemsWithoutSeparators.length) {
      props.onUpdate(visibleItemsWithoutSeparators[0]);
    }

    return (
      <Icon
        style={{cursor: 'pointer'}}
        disabled={isLastItemVisible}
        name="chevron right"
        size="large"
        onClick={() => (isLastItemVisible ? null : scrollNext())}
      />
    );
  }

  function LeftArrow() {
    const {isFirstItemVisible, visibleItemsWithoutSeparators, scrollPrev} = React.useContext(VisibilityContext);

    if (visibleItemsWithoutSeparators.length) {
      props.onUpdate(visibleItemsWithoutSeparators[0]);
    }

    return (
      <Icon
        disabled={isFirstItemVisible}
        style={{cursor: 'pointer'}}
        name="chevron left"
        size="large"
        onClick={() => (isFirstItemVisible ? null : scrollPrev())}
      />
    );
  }

  if (isEmpty(timeSlots)) {
    return (
      <div className="menu-item">
        <Header as="h5">{t('common.no_time_slots')}</Header>
      </div>
    );
  }

  return (
    <ScrollMenu apiRef={forwardedRef} RightArrow={RightArrow} LeftArrow={LeftArrow}>
      {renderMenuItems()}
    </ScrollMenu>
  );
}

export default withTranslation()(TimeSlotsCalendar);
