/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateWorkHourInput = {|
  clientMutationId?: ?string,
  id: string,
  isOpen?: ?boolean,
  dayOfTheWeek?: ?number,
  openTime?: ?string,
  closeTime?: ?string,
|};
export type UpdateWorkHourMutationVariables = {|
  input: UpdateWorkHourInput
|};
export type UpdateWorkHourMutationResponse = {|
  +updateWorkHour: ?{|
    +workHour: ?{|
      +dayOfTheWeek: ?number,
      +openTime: ?string,
      +closeTime: ?string,
      +isOpen: ?boolean,
      +providerId: ?number,
      +provider: ?{|
        +name: ?string
      |},
    |}
  |}
|};
export type UpdateWorkHourMutation = {|
  variables: UpdateWorkHourMutationVariables,
  response: UpdateWorkHourMutationResponse,
|};
*/


/*
mutation UpdateWorkHourMutation(
  $input: UpdateWorkHourInput!
) {
  updateWorkHour(input: $input) {
    workHour {
      dayOfTheWeek
      openTime
      closeTime
      isOpen
      providerId
      provider {
        name
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayOfTheWeek",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "openTime",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closeTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOpen",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateWorkHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkHourPayload",
        "kind": "LinkedField",
        "name": "updateWorkHour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkHour",
            "kind": "LinkedField",
            "name": "workHour",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Provider",
                "kind": "LinkedField",
                "name": "provider",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateWorkHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateWorkHourPayload",
        "kind": "LinkedField",
        "name": "updateWorkHour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkHour",
            "kind": "LinkedField",
            "name": "workHour",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Provider",
                "kind": "LinkedField",
                "name": "provider",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7540eb4e4d7ac3ebbfab875c8a9bec94",
    "id": null,
    "metadata": {},
    "name": "UpdateWorkHourMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateWorkHourMutation(\n  $input: UpdateWorkHourInput!\n) {\n  updateWorkHour(input: $input) {\n    workHour {\n      dayOfTheWeek\n      openTime\n      closeTime\n      isOpen\n      providerId\n      provider {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cd54450903704969efbada8b9716f673';

module.exports = node;
