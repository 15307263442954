/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAppointmentWithTimeSlotsInput = {|
  clientMutationId?: ?string,
  id?: ?string,
  timeSlots: $ReadOnlyArray<?TimeSlotInput>,
  paymentId?: ?string,
  paymentMethod?: ?string,
  status?: ?string,
  fieldsData?: ?any,
|};
export type TimeSlotInput = {|
  id: string,
  date: string,
  startAt: string,
  endAt: string,
  providerId: string,
  serviceId: string,
  price?: ?number,
  clientId?: ?string,
  recurringTimeSlotId?: ?string,
  timestamp?: ?number,
  capacity?: ?number,
  duration?: ?number,
|};
export type UpdateAppointmentWithTimeSlotsMutationVariables = {|
  input: UpdateAppointmentWithTimeSlotsInput
|};
export type UpdateAppointmentWithTimeSlotsMutationResponse = {|
  +updateAppointmentWithTimeSlots: ?{|
    +newAppointments: ?$ReadOnlyArray<?{|
      +id: string,
      +rowId: string,
      +serviceId: ?number,
      +service: ?{|
        +title: ?string
      |},
      +startAt: ?any,
      +endAt: ?any,
      +timeSlots: ?$ReadOnlyArray<?{|
        +id: string,
        +startAt: ?any,
        +endAt: ?any,
        +date: ?any,
        +duration: ?number,
      |}>,
      +provider: ?{|
        +id: string,
        +rowId: number,
        +name: ?string,
        +profilePhotoUrl: ?string,
      |},
      +status: ?string,
      +clientId: ?number,
      +client: ?{|
        +id: string,
        +name: ?string,
      |},
    |}>,
    +updatedAppointment: ?{|
      +id: string,
      +rowId: string,
      +serviceId: ?number,
      +providerId: ?number,
      +provider: ?{|
        +id: string,
        +name: ?string,
        +profilePhotoUrl: ?string,
      |},
      +service: ?{|
        +title: ?string
      |},
      +startAt: ?any,
      +endAt: ?any,
      +timeSlots: ?$ReadOnlyArray<?{|
        +id: string,
        +startAt: ?any,
        +endAt: ?any,
        +date: ?any,
        +duration: ?number,
      |}>,
      +status: ?string,
      +clientId: ?number,
      +client: ?{|
        +id: string,
        +name: ?string,
      |},
    |},
  |}
|};
export type UpdateAppointmentWithTimeSlotsMutation = {|
  variables: UpdateAppointmentWithTimeSlotsMutationVariables,
  response: UpdateAppointmentWithTimeSlotsMutationResponse,
|};
*/


/*
mutation UpdateAppointmentWithTimeSlotsMutation(
  $input: UpdateAppointmentWithTimeSlotsInput!
) {
  updateAppointmentWithTimeSlots(input: $input) {
    newAppointments {
      id
      rowId
      serviceId
      service {
        title
        id
      }
      startAt
      endAt
      timeSlots {
        id
        startAt
        endAt
        date
        duration
      }
      provider {
        id
        rowId
        name
        profilePhotoUrl
      }
      status
      clientId
      client {
        id
        name
      }
    }
    updatedAppointment {
      id
      rowId
      serviceId
      providerId
      provider {
        id
        name
        profilePhotoUrl
      }
      service {
        title
        id
      }
      startAt
      endAt
      timeSlots {
        id
        startAt
        endAt
        date
        duration
      }
      status
      clientId
      client {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": [
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeSlot",
  "kind": "LinkedField",
  "name": "timeSlots",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhotoUrl",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Provider",
  "kind": "LinkedField",
  "name": "provider",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Provider",
  "kind": "LinkedField",
  "name": "provider",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAppointmentWithTimeSlotsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAppointmentWithTimeSlotsPayload",
        "kind": "LinkedField",
        "name": "updateAppointmentWithTimeSlots",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "newAppointments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "updatedAppointment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAppointmentWithTimeSlotsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAppointmentWithTimeSlotsPayload",
        "kind": "LinkedField",
        "name": "updateAppointmentWithTimeSlots",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "newAppointments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v18/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "updatedAppointment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ed55299e23415c94f30d9db829e63fd",
    "id": null,
    "metadata": {},
    "name": "UpdateAppointmentWithTimeSlotsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAppointmentWithTimeSlotsMutation(\n  $input: UpdateAppointmentWithTimeSlotsInput!\n) {\n  updateAppointmentWithTimeSlots(input: $input) {\n    newAppointments {\n      id\n      rowId\n      serviceId\n      service {\n        title\n        id\n      }\n      startAt\n      endAt\n      timeSlots {\n        id\n        startAt\n        endAt\n        date\n        duration\n      }\n      provider {\n        id\n        rowId\n        name\n        profilePhotoUrl\n      }\n      status\n      clientId\n      client {\n        id\n        name\n      }\n    }\n    updatedAppointment {\n      id\n      rowId\n      serviceId\n      providerId\n      provider {\n        id\n        name\n        profilePhotoUrl\n      }\n      service {\n        title\n        id\n      }\n      startAt\n      endAt\n      timeSlots {\n        id\n        startAt\n        endAt\n        date\n        duration\n      }\n      status\n      clientId\n      client {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '418f846bc96edc0b9b99c2f0933833b9';

module.exports = node;
