import graphql from 'babel-plugin-relay/macro';

const ServicesQuery = graphql`
  query ServicesQuery_Query {
    viewer {
      ...Services_viewer
    }
  }
`;

export default ServicesQuery;
