import {sortBy} from 'lodash';
import {Select} from 'semantic-ui-react';

const ProvidersDropdown = (props) => {
  const {services, serviceId, t} = props;

  const selectedService = serviceId && services.edges.find(({node}) => node.rowId === serviceId).node;

  // TODO: Fix this on the server
  const sortedProviders = selectedService
    ? sortBy(
        selectedService.providers.edges.map(({node}) => node),
        ['createdAt'],
      )
    : [];

  const providersOptions = sortedProviders.map((provider) => ({
    key: provider.rowId,
    text: provider.name,
    value: provider.rowId,
  }));

  return (
    <Select
      placeholder={t('titles.select_provider')}
      fluid
      search
      disabled={!providersOptions || !providersOptions.length > 0}
      onChange={props.onProviderChange}
      options={providersOptions}
    />
  );
};

export default ProvidersDropdown;
