import graphql from 'babel-plugin-relay/macro';

const AnalyticsQuery = graphql`
  query AnalyticsQuery_Query($filterBy: AnalyticsFilterInput) {
    viewer {
      ...Analytics_viewer @arguments(filterBy: $filterBy)
    }
  }
`;

export default AnalyticsQuery;
