/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddWorkHourInput = {|
  clientMutationId?: ?string,
  dayOfTheWeek: number,
  openTime: string,
  providerId?: ?number,
  closeTime: string,
  isOpen: boolean,
|};
export type AddWorkHourMutationVariables = {|
  connections: $ReadOnlyArray<string>,
  input: AddWorkHourInput,
|};
export type AddWorkHourMutationResponse = {|
  +addWorkHour: ?{|
    +workHourEdge: ?{|
      +node: ?{|
        +id: string,
        +dayOfTheWeek: ?number,
        +openTime: ?string,
        +closeTime: ?string,
        +providerId: ?number,
        +provider: ?{|
          +name: ?string
        |},
        +isOpen: ?boolean,
      |}
    |}
  |}
|};
export type AddWorkHourMutation = {|
  variables: AddWorkHourMutationVariables,
  response: AddWorkHourMutationResponse,
|};
*/


/*
mutation AddWorkHourMutation(
  $input: AddWorkHourInput!
) {
  addWorkHour(input: $input) {
    workHourEdge {
      node {
        id
        dayOfTheWeek
        openTime
        closeTime
        providerId
        provider {
          name
          id
        }
        isOpen
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dayOfTheWeek",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "openTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closeTime",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOpen",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddWorkHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkHourPayload",
        "kind": "LinkedField",
        "name": "addWorkHour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkHourEdge",
            "kind": "LinkedField",
            "name": "workHourEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkHour",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Provider",
                    "kind": "LinkedField",
                    "name": "provider",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddWorkHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddWorkHourPayload",
        "kind": "LinkedField",
        "name": "addWorkHour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkHourEdge",
            "kind": "LinkedField",
            "name": "workHourEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkHour",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Provider",
                    "kind": "LinkedField",
                    "name": "provider",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "workHourEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67c82f83cfef03865c0a6b470b3aca99",
    "id": null,
    "metadata": {},
    "name": "AddWorkHourMutation",
    "operationKind": "mutation",
    "text": "mutation AddWorkHourMutation(\n  $input: AddWorkHourInput!\n) {\n  addWorkHour(input: $input) {\n    workHourEdge {\n      node {\n        id\n        dayOfTheWeek\n        openTime\n        closeTime\n        providerId\n        provider {\n          name\n          id\n        }\n        isOpen\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b27b10064b354b029a0a69717482aca3';

module.exports = node;
