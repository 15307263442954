import graphql from 'babel-plugin-relay/macro';

const VacationHourViewQuery_Query = graphql`
  query VacationHourViewQuery_Query($vacationHourId: ID) {
    viewer {
      ...VacationHourView_viewer @arguments(vacationHourId: $vacationHourId)
    }
  }
`;

export default VacationHourViewQuery_Query;
