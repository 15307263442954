/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddAppointmentInput = {|
  clientMutationId?: ?string,
  clientId?: ?string,
  providerId?: ?string,
  paymentId?: ?string,
  serviceId: string,
  placeId?: ?string,
  fieldsData?: ?any,
  startAt?: ?string,
  endAt?: ?string,
|};
export type AddAppointmentMutationVariables = {|
  input: AddAppointmentInput
|};
export type AddAppointmentMutationResponse = {|
  +addAppointment: ?{|
    +appointmentEdge: ?{|
      +node: ?{|
        +id: string,
        +rowId: string,
        +serviceId: ?number,
        +service: ?{|
          +title: ?string
        |},
        +startAt: ?any,
        +isRecurring: ?boolean,
        +capacity: ?number,
        +endAt: ?any,
        +clientId: ?number,
        +providerId: ?number,
        +status: ?string,
        +provider: ?{|
          +rowId: number,
          +id: string,
          +name: ?string,
        |},
        +client: ?{|
          +id: string,
          +name: ?string,
        |},
      |}
    |}
  |}
|};
export type AddAppointmentMutation = {|
  variables: AddAppointmentMutationVariables,
  response: AddAppointmentMutationResponse,
|};
*/


/*
mutation AddAppointmentMutation(
  $input: AddAppointmentInput!
) {
  addAppointment(input: $input) {
    appointmentEdge {
      node {
        id
        rowId
        serviceId
        service {
          title
          id
        }
        startAt
        isRecurring
        capacity
        endAt
        clientId
        providerId
        status
        provider {
          rowId
          id
          name
        }
        client {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Provider",
  "kind": "LinkedField",
  "name": "provider",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v2/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddAppointmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAppointmentPayload",
        "kind": "LinkedField",
        "name": "addAppointment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AppointmentEdge",
            "kind": "LinkedField",
            "name": "appointmentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Service",
                    "kind": "LinkedField",
                    "name": "service",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddAppointmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAppointmentPayload",
        "kind": "LinkedField",
        "name": "addAppointment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AppointmentEdge",
            "kind": "LinkedField",
            "name": "appointmentEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Service",
                    "kind": "LinkedField",
                    "name": "service",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01228866fc64fad6dc4bd7e76a587d75",
    "id": null,
    "metadata": {},
    "name": "AddAppointmentMutation",
    "operationKind": "mutation",
    "text": "mutation AddAppointmentMutation(\n  $input: AddAppointmentInput!\n) {\n  addAppointment(input: $input) {\n    appointmentEdge {\n      node {\n        id\n        rowId\n        serviceId\n        service {\n          title\n          id\n        }\n        startAt\n        isRecurring\n        capacity\n        endAt\n        clientId\n        providerId\n        status\n        provider {\n          rowId\n          id\n          name\n        }\n        client {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '60155c8497e738efc2ce35b389a2bde0';

module.exports = node;
