import {isPlainObject} from 'lodash';
import {Search} from 'semantic-ui-react';
import React from 'react';
import {withTranslation} from 'react-i18next';

const _convertGoogleGeocodedAddressComponents = (addressComponents) => {
  //: Array<Object> = []): ?Object {
  const MAPPINGS = {country: 'long_name'};

  const convertedComponents = addressComponents.reduce((p, c) => {
    const type = (c.types && c.types[0]) || null;
    if (!type) {
      return p;
    }

    const key = MAPPINGS[type] || 'short_name';
    const value = c[key] || null;

    return {
      ...(p || {}),
      [type]: value,
    };
  }, null);

  return convertedComponents;
};

// This is needed to remove __data__ property that is added somewhere from Relay
const cleanOptions = (obj) =>
  isPlainObject(obj)
    ? Object.keys(obj).reduce((p, c) => (c.substr(0, 2) === '__' ? {...p} : {...p, [c]: cleanOptions(obj[c])}), {})
    : obj;

// TODO:
// type Props = {
//   details: boolean,
//   name: string,
//   notes: boolean,
//   options: object,
//   onChange: () => void,
//   value: string,
// };

class GooglePlacesAutocompleteInput extends React.Component {
  static defaultProps = {
    details: false,
    notes: false,
    options: {
      types: ['geocode'],
    },
  };

  // props: Props;

  state = {
    isLoading: false,
    place: null,
    results: [],
    value: null,
  };

  detailsCache = {};
  predictionsCache = {};

  componentDidMount() {
    this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    this.autocompleteService = new window.google.maps.places.AutocompleteService();

    if (this.props.details) {
      const map = new window.google.maps.Map(document.createElement('div'));
      this.placesService = new window.google.maps.places.PlacesService(map);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      state.value === null ||
      (state.value === '' && props.value !== '') ||
      (state.value !== '' && props.value === '')
    ) {
      return {
        value: props.value,
      };
    }

    return null;
  }

  autocompleteCallback = (value) => (predictions, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      predictions = predictions.map((v) => ({
        id: Math.random(),
        title: v.description,
        value: v.place_id,
        'data-object': v,
      }));
    } else {
      predictions = [];
    }

    this.predictionsCache[value] = predictions;

    this.setState({
      isLoading: false,
      results: predictions,
    });
  };

  handleResultSelect = (e, {result}) => {
    if (this.props.details) {
      const {reference} = result['data-object'];
      const cachedValue = this.detailsCache[reference];
      if (cachedValue) {
        this.setState({value: result.title});

        this.props.onChange &&
          this.props.onChange(e, {
            name: this.props.name,
            value: result.title,
            place: cachedValue,
          });
      } else {
        this.placesService.getDetails(
          {
            reference,
            sessionToken: this.sessionToken,
          },
          (details, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const location = (details && details.geometry && details.geometry.location) || null;
              const latitude = (location && location.lat && location.lat().toFixed(6)) || null;
              const longitude = (location && location.lng && location.lng().toFixed(6)) || null;
              const components =
                (details &&
                  details.address_components &&
                  _convertGoogleGeocodedAddressComponents(details.address_components)) ||
                {};

              this.setState({value: result.title});
              this.props.onChange &&
                this.props.onChange(e, {
                  name: this.props.name,
                  value: result.title,
                  place: {
                    latitude,
                    longitude,
                    components,
                    placeId: details && details.place_id,
                  },
                });
            } else {
              console.log('Could not get Place Details.');
              this.props.onChange &&
                this.props.onChange(e, {
                  name: this.props.name,
                  value: result.title,
                  place: result['data-object'],
                });
            }
          },
        );
      }
    } else {
      this.setState({value: result.title});
      this.props.onChange &&
        this.props.onChange(e, {
          name: this.props.name,
          value: result.title,
          place: result['data-object'],
        });
    }

    // Request a new token:
    // https://stackoverflow.com/questions/50398801/how-long-do-the-new-places-api-session-tokens-last
    this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
  };

  handleSearchChange = (e, extra) => {
    // HACK: IE + React issue
    if (e.target.tagName !== 'INPUT') {
      return;
    }

    const value = String(e.target.value);

    if (value.trim() === '') {
      this.setState({
        isLoading: false,
        results: [],
        value,
      });
    } else if (typeof this.predictionsCache[value] !== 'undefined') {
      this.setState({
        isLoading: false,
        results: this.predictionsCache[value],
        value,
      });
    } else {
      this.setState({
        isLoading: true,
        value,
      });

      const options = cleanOptions(this.props.options || {});

      const params = {
        ...options,
        input: value,
        sessionToken: this.sessionToken,
      };

      this.autocompleteService.getPlacePredictions(params, this.autocompleteCallback(value));
    }

    if (this.props.onChange && this.state.value !== value) {
      this.props.onChange(e, {
        name: this.props.name,
        value,
      });
    }
  };

  render() {
    const {t} = this.props;
    const {isLoading, results, value} = this.state;

    return (
      <Search
        // defaultValue={this.props.defaultValue || this.props.value || ''}
        fluid
        label={this.props.label || t('common.address')}
        loading={isLoading}
        name={this.props.name}
        onFocus={this.handleSearchChange}
        onResultSelect={this.handleResultSelect}
        onSearchChange={this.handleSearchChange}
        placeholder={this.props.placeholder || t('common.address')}
        results={results}
        value={value || ''}
      />
    );
  }
}

export default withTranslation()(GooglePlacesAutocompleteInput);
