import React from 'react';
import {Button, Divider, Header, Dropdown, Image, Icon, Label, Modal, Grid} from 'semantic-ui-react';
import graphql from 'babel-plugin-relay/macro';
import {createPaginationContainer} from 'react-relay';
import {throttle} from 'lodash';
import moment from 'moment';
import AppointmentRow from './AppointmentRow';
import {RemoveClientMutation} from '../mutations';
import {withTranslation} from 'react-i18next';

const LIMIT = 8;
const MAX_ACTIVE_APPOINTMENTS = 3;

class ClientView extends React.Component {
  state = {
    isModalOpen: false,
    isDropdownOpen: false,
  };

  constructor(props) {
    super(props);

    this.throttleScroll = throttle(this.handleScroll, 300);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    const nearBottom = e.target.scrollHeight - e.target.scrollTop - 180 <= e.target.clientHeight;

    if (!this.props.relay.isLoading() && nearBottom) {
      if (this.props.relay.hasMore()) {
        this.props.relay.loadMore(LIMIT, (error) => {
          if (error) {
            console.log('error', error);
          }
        });
      }
    }
  };

  onScroll = (e) => {
    e.persist = () => {};

    this.throttleScroll(e);
  };

  handleModalOpen = () => {
    this.setState({isModalOpen: true, isDropdownOpen: false});
  };

  handleModalClose = () => {
    this.setState({isDropdownOpen: false, isModalOpen: false});
  };

  handleDropdownOpen = () => {
    this.setState({isDropdownOpen: true});
  };

  handleRemoveClient = () => {
    const {id: clientId} = this.props.viewer.client;
    const input = {
      id: clientId,
    };

    const onSuccess = (data) => {
      console.log('Success', data);
      this.props.router.push('/clients');
    };

    const onFailure = (errors) => {
      // TODO: Handle error message
      console.log('Failure', errors);
    };

    RemoveClientMutation({input}, onSuccess, onFailure);
  };

  getFormattedTimeWindow = (appointment) => {
    const {startAt, endAt} = appointment;

    const formattedStartAt = moment(startAt).format('HH:MM');
    const formattedEndAt = moment(endAt).format('HH:MM');

    return `${formattedStartAt} - ${formattedEndAt}`;
  };

  render() {
    const {viewer, t} = this.props;
    const {client, appointments = []} = viewer;
    const {createdAt, lastActiveAt, name, phoneNumber, profilePhotoUrl, totalAppointmentsCount} = client;

    const options = [{key: 'delete', text: t('titles.remove_client'), value: 'remove'}];

    const activeAppointments = [];
    const passedAppointments = [];

    appointments.edges.forEach(({node: appointment}) =>
      moment(appointment.startAt).isSameOrAfter(moment()) && activeAppointments.length < MAX_ACTIVE_APPOINTMENTS
        ? // Limit displayed active appointments to 3
          activeAppointments.push(appointment)
        : passedAppointments.push(appointment),
    );

    return (
      <div>
        <Grid padded>
          <Grid.Column width={10} style={{backgroundColor: '#F0F8FF'}}>
            <Header as="h2" textAlign="left" style={{marginTop: '20px', marginBottom: '30px'}}>
              {t('common.active_appointments')} <span style={{color: 'red'}}>*</span>
            </Header>
            {activeAppointments.length > 0 ? (
              activeAppointments.map((appointment) => (
                <AppointmentRow
                  appointment={appointment}
                  segmentProps={{color: 'blue', inverted: true, raised: true}}
                  iconName="clock outline"
                  primaryTextColor="white"
                  secondaryTextColor="white"
                  key={appointment.id}
                />
              ))
            ) : (
              <p>{t('common.no_active_appointments')}</p>
            )}
            <Divider vertical />

            <Header as="h2" textAlign="left" style={{marginTop: '90px'}}>
              {t('common.appointments_history')}
              <Label circular color="grey">
                {passedAppointments.length}
              </Label>
            </Header>
            {passedAppointments.length > 0 ? (
              passedAppointments.map((appointment) => (
                <AppointmentRow
                  appointment={appointment}
                  segmentProps={{raised: true}}
                  iconName="calendar alternate outline"
                  primaryTextColor="black"
                  secondaryTextColor="grey"
                  key={appointment.id}
                />
              ))
            ) : (
              <p>{t('common.no_appointments_history')}</p>
            )}
          </Grid.Column>

          <Grid.Column width={4} floated="right" style={{paddingTop: 80}}>
            <Modal
              onClose={this.handleModalClose}
              open={this.state.isModalOpen}
              closeIcon
              trigger={
                <Dropdown
                  direction="left"
                  icon="cog"
                  search={true}
                  onClick={this.handleDropdownOpen}
                  onChange={this.handleModalOpen}
                  options={this.state.isDropdownOpen ? options : null}
                  multiple
                  style={{position: 'absolute', right: 0, color: 'grey', backgroundColor: 'white'}}
                />
              }
            >
              <Header content={t('titles.remove_client')} />
              <Modal.Content>{t('actions.are_you_sure_you_want_to_remove_client')}</Modal.Content>
              <Modal.Actions>
                <Button onClick={this.handleModalClose} color="red">
                  <Icon name="remove" /> {t('common.no')}
                </Button>
                <Button onClick={this.handleRemoveClient} color="green">
                  <Icon name="checkmark" /> {t('common.yes')}
                </Button>
              </Modal.Actions>
            </Modal>
            <Image
              centered
              style={{borderRadius: '50%', width: 200, objectFit: 'cover', height: 200, overflow: 'hidden'}}
              src={profilePhotoUrl}
            />
            <Header as="h2" textAlign="center">
              {name}
            </Header>
            <Header as="h3" textAlign="center">
              {t('common.phone_number')}: {phoneNumber}
            </Header>
            <Header as="h3" textAlign="center">
              {t('common.last_active')}: {lastActiveAt ? moment(lastActiveAt).format('LL') : ''}
            </Header>
            <Header as="h3" textAlign="center" style={{marginTop: 0}}>
              {t('common.client_since')}: {moment(createdAt).format('LL')}
            </Header>
            <Header as="h3" textAlign="center" style={{marginTop: 0}}>
              {t('common.total_appointments')}: {totalAppointmentsCount}
            </Header>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const ClientViewContainer = createPaginationContainer(
  withTranslation()(ClientView),
  {
    viewer: graphql`
      fragment ClientView_viewer on User
      @argumentDefinitions(
        clientId: {type: "ID"}
        orderBy: {type: "[[String]]"}
        filterBy: {type: "ProviderAppointmentFilterInput"}
        count: {type: "Int", defaultValue: 3}
        after: {type: "String"}
      ) {
        ... on Provider {
          client(id: $clientId) {
            id
            name
            lastActiveAt
            createdAt
            totalAppointmentsCount
            phoneNumber
            profilePhotoUrl
          }
          # // TODO: Fix this
          appointments(filterBy: $filterBy, orderBy: $orderBy, first: $count, after: $after)
            @connection(key: "ClientView_appointments") {
            edges {
              node {
                id
                ...AppointmentRow_appointment
                startAt
              }
            }
            pageInfo {
              hasNextPage
              endCursor
              hasPreviousPage
              startCursor
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    query: graphql`
      query ClientViewForwardQuery(
        $clientId: ID
        $orderBy: [[String]]
        $filterBy: ProviderAppointmentFilterInput
        $cursor: String
        $count: Int
      ) {
        viewer {
          ...ClientView_viewer
            @arguments(clientId: $clientId, orderBy: $orderBy, filterBy: $filterBy, after: $cursor, count: $count)
        }
      }
    `,
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.appointments;
    },
    getFragmentVariables(previousVariables, totalCount) {
      return {
        ...previousVariables,
        count: totalCount,
      };
    },
    getVariables(props, {count, cursor}, fragmentVariables) {
      return {
        count,
        cursor,
        orderBy: fragmentVariables.orderBy,
        clientId: fragmentVariables.clientId,
        filterBy: fragmentVariables.filterBy,
      };
    },
  },
);

ClientViewContainer.getVariables = (props) => {
  return {
    orderBy: [['startAt', 'DESC']],
    count: 10,
    clientId: props.clientId, // NOTE both clientId props
    filterBy: {
      clientId: props.clientId,
      date: {
        from: moment().subtract(3, 'month').format('YYYY-MM-DD'),
        to: moment().add(4, 'week').format('YYYY-MM-DD'),
      },
    },
  };
};

export default ClientViewContainer;
