/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateRecurringAppointmentWithTimeSlotsInput = {|
  clientMutationId?: ?string,
  id?: ?string,
  timeSlots: $ReadOnlyArray<?TimeSlotInput>,
  updateAll: boolean,
  paymentMethod?: ?string,
  recurrenceOptions?: ?any,
  status?: ?string,
  fieldsData?: ?any,
|};
export type TimeSlotInput = {|
  id: string,
  date: string,
  startAt: string,
  endAt: string,
  providerId: string,
  serviceId: string,
  price?: ?number,
  clientId?: ?string,
  recurringTimeSlotId?: ?string,
  timestamp?: ?number,
  capacity?: ?number,
  duration?: ?number,
|};
export type UpdateRecurringAppointmentWithTimeSlotsMutationVariables = {|
  connections: $ReadOnlyArray<string>,
  input: UpdateRecurringAppointmentWithTimeSlotsInput,
|};
export type UpdateRecurringAppointmentWithTimeSlotsMutationResponse = {|
  +updateRecurringAppointmentWithTimeSlots: ?{|
    +updatedAppointment: ?{|
      +id: string,
      +rowId: string,
      +serviceId: ?number,
      +isPartOfRecurringSequence: ?boolean,
      +providerId: ?number,
      +provider: ?{|
        +id: string,
        +name: ?string,
        +profilePhotoUrl: ?string,
      |},
      +service: ?{|
        +title: ?string
      |},
      +startAt: ?any,
      +endAt: ?any,
      +timeSlots: ?$ReadOnlyArray<?{|
        +id: string,
        +startAt: ?any,
        +endAt: ?any,
        +date: ?any,
        +duration: ?number,
      |}>,
      +status: ?string,
      +clientId: ?number,
      +client: ?{|
        +id: string,
        +name: ?string,
      |},
    |},
    +newAppointment: ?{|
      +rowId: string,
      +id: string,
      +startAt: ?any,
      +status: ?string,
      +isPartOfRecurringSequence: ?boolean,
      +providerId: ?number,
      +provider: ?{|
        +id: string,
        +name: ?string,
        +profilePhotoUrl: ?string,
      |},
      +service: ?{|
        +title: ?string
      |},
      +endAt: ?any,
      +timeSlots: ?$ReadOnlyArray<?{|
        +id: string,
        +startAt: ?any,
        +endAt: ?any,
        +date: ?any,
        +duration: ?number,
      |}>,
      +clientId: ?number,
      +client: ?{|
        +id: string,
        +name: ?string,
      |},
    |},
  |}
|};
export type UpdateRecurringAppointmentWithTimeSlotsMutation = {|
  variables: UpdateRecurringAppointmentWithTimeSlotsMutationVariables,
  response: UpdateRecurringAppointmentWithTimeSlotsMutationResponse,
|};
*/


/*
mutation UpdateRecurringAppointmentWithTimeSlotsMutation(
  $input: UpdateRecurringAppointmentWithTimeSlotsInput!
) {
  updateRecurringAppointmentWithTimeSlots(input: $input) {
    updatedAppointment {
      id
      rowId
      serviceId
      isPartOfRecurringSequence
      providerId
      provider {
        id
        name
        profilePhotoUrl
      }
      service {
        title
        id
      }
      startAt
      endAt
      timeSlots {
        id
        startAt
        endAt
        date
        duration
      }
      status
      clientId
      client {
        id
        name
      }
    }
    newAppointment {
      rowId
      id
      startAt
      status
      isPartOfRecurringSequence
      providerId
      provider {
        id
        name
        profilePhotoUrl
      }
      service {
        title
        id
      }
      endAt
      timeSlots {
        id
        startAt
        endAt
        date
        duration
      }
      clientId
      client {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPartOfRecurringSequence",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Provider",
  "kind": "LinkedField",
  "name": "provider",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePhotoUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": [
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeSlot",
  "kind": "LinkedField",
  "name": "timeSlots",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateRecurringAppointmentWithTimeSlotsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRecurringAppointmentWithTimeSlotsPayload",
        "kind": "LinkedField",
        "name": "updateRecurringAppointmentWithTimeSlots",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "updatedAppointment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "newAppointment",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v11/*: any*/),
              (v14/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateRecurringAppointmentWithTimeSlotsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRecurringAppointmentWithTimeSlotsPayload",
        "kind": "LinkedField",
        "name": "updateRecurringAppointmentWithTimeSlots",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "updatedAppointment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v17/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "newAppointment",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v11/*: any*/),
              (v14/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v8/*: any*/),
              (v17/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "newAppointment",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "Appointment"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d827d85d33e9e8ecfb84c55c6a0bb44",
    "id": null,
    "metadata": {},
    "name": "UpdateRecurringAppointmentWithTimeSlotsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateRecurringAppointmentWithTimeSlotsMutation(\n  $input: UpdateRecurringAppointmentWithTimeSlotsInput!\n) {\n  updateRecurringAppointmentWithTimeSlots(input: $input) {\n    updatedAppointment {\n      id\n      rowId\n      serviceId\n      isPartOfRecurringSequence\n      providerId\n      provider {\n        id\n        name\n        profilePhotoUrl\n      }\n      service {\n        title\n        id\n      }\n      startAt\n      endAt\n      timeSlots {\n        id\n        startAt\n        endAt\n        date\n        duration\n      }\n      status\n      clientId\n      client {\n        id\n        name\n      }\n    }\n    newAppointment {\n      rowId\n      id\n      startAt\n      status\n      isPartOfRecurringSequence\n      providerId\n      provider {\n        id\n        name\n        profilePhotoUrl\n      }\n      service {\n        title\n        id\n      }\n      endAt\n      timeSlots {\n        id\n        startAt\n        endAt\n        date\n        duration\n      }\n      clientId\n      client {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '092da29d708ce1ad8c5999d1f8499deb';

module.exports = node;
