import {isMatchWith as _isMatchWith} from 'lodash';
import isEmpty from './isEmpty';

export default function isMatchWith(object, source) {
  return _isMatchWith(object, source, (objValue, srcValue) => {
    if (isEmpty(srcValue)) {
      return true;
    }

    if (typeof objValue === 'object') {
      return isMatchWith(objValue, srcValue);
    }
  });
}
