import graphql from 'babel-plugin-relay/macro';
import {requestSubscription} from 'react-relay';
import {environment} from '../relay';
import {deleteEdgeFromConnection} from '../helpers/SharedUpdater';
import {get} from 'lodash';

const deleteAppointmentSubscription = graphql`
  subscription DeleteAppointmentSubscription($input: DeleteAppointmentSubscriptionInput!) {
    deleteAppointment(input: $input) {
      id
      deletedAppointments {
        id
      }
    }
  }
`;

const DeleteAppointmentSubscription = (viewerId, connectionName, filters, callback) => {
  const requestSubscriptionResult = requestSubscription(environment, {
    subscription: deleteAppointmentSubscription,
    variables: {
      input: {},
    },
    updater: (store, data) => {
      const deletedAppointments = get(data, 'deleteAppointment.deletedAppointments') || [];
      const deletedAppointmentIds = deletedAppointments.map((o) => o.id);

      deleteEdgeFromConnection(store, viewerId, deletedAppointmentIds, connectionName, filters);
    },
  });

  return {
    dispose: requestSubscriptionResult.dispose,
  };
};

export default DeleteAppointmentSubscription;
