import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {Divider} from 'semantic-ui-react';
import ProviderWorkHoursTable from './ProviderWorkHoursTable';
import {createFragmentContainer} from 'react-relay';
import {withRelay} from '../../relay';
import {withTranslation} from 'react-i18next';
import getFormattedTimes from '../../helpers/getFormattedTimes';
import {isEmpty} from 'lodash';

class WorkHours extends React.Component {
  state = {
    unsavedChanges: {},
    error: null,
    localWorkHours: [],
  };

  constructor(props) {
    super(props);

    const {t} = props;

    this.times = getFormattedTimes();

    this.daysOfTheWeek = [
      t('common.monday'),
      t('common.tuesday'),
      t('common.wednesday'),
      t('common.thursday'),
      t('common.friday'),
      t('common.saturday'),
      t('common.sunday'),
    ];

    this.dayOptions = this.daysOfTheWeek.map((day, index) => ({
      key: index,
      text: day,
      value: day,
    }));
  }

  render() {
    const {viewer} = this.props;
    const {settings, providers} = viewer;
    const {locale} = settings;

    if (isEmpty(providers)) {
      return null;
    }

    return (
      <div style={{width: '80%'}}>
        {providers.edges.map(({node: provider}, index) => {
          const providerWorkHours = provider.workHoursConnection.edges
            ? provider.workHoursConnection.edges.map(({node: workHour}) => workHour)
            : [];

          const connectionId = provider.workHoursConnection.__id;
          return (
            <React.Fragment key={index}>
              <ProviderWorkHoursTable
                key={index}
                currentProvider={provider}
                workHours={providerWorkHours}
                times={this.times}
                connectionId={connectionId}
                locale={locale}
                daysOfTheWeek={this.daysOfTheWeek}
                dayOptions={this.dayOptions}
              />
              <Divider />
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}

const WorkHoursQuery = graphql`
  query WorkHoursQuery($first: Int) {
    viewer {
      ...WorkHours_viewer @arguments(first: $first)
    }
  }
`;

const WorkHoursContainer = createFragmentContainer(withTranslation()(WorkHours), {
  viewer: graphql`
    fragment WorkHours_viewer on User @argumentDefinitions(first: {type: "Int"}) {
      ... on Provider {
        settings {
          locale
        }
        providers(first: $first) {
          edges {
            node {
              # eslint-disable-next-line relay/unused-fields
              id
              # eslint-disable-next-line relay/unused-fields
              rowId
              # eslint-disable-next-line relay/unused-fields
              name
              workHoursConnection(first: $first) @connection(key: "WorkHours_workHoursConnection") {
                __id
                edges {
                  node {
                    # eslint-disable-next-line relay/unused-fields
                    id
                    # eslint-disable-next-line relay/unused-fields
                    providerId
                    # eslint-disable-next-line relay/unused-fields
                    openTime
                    # eslint-disable-next-line relay/unused-fields
                    closeTime
                    # eslint-disable-next-line relay/unused-fields
                    dayOfTheWeek
                    # eslint-disable-next-line relay/unused-fields
                    ...WorkHourRow_workHour
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
});

WorkHoursContainer.getVariables = (props) => {
  return {first: 10000};
};

export default withRelay(WorkHoursContainer, WorkHoursQuery);
