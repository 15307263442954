/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type HistoryRow_payment$ref: FragmentReference;
declare export opaque type HistoryRow_payment$fragmentType: HistoryRow_payment$ref;
export type HistoryRow_payment = {|
  +id: string,
  +rowId: number,
  +createdAt: ?any,
  +currency: ?string,
  +amount: ?number,
  +paymentMethod: ?{|
    +brand: ?string,
    +last4: ?string,
  |},
  +$refType: HistoryRow_payment$ref,
|};
export type HistoryRow_payment$data = HistoryRow_payment;
export type HistoryRow_payment$key = {
  +$data?: HistoryRow_payment$data,
  +$fragmentRefs: HistoryRow_payment$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HistoryRow_payment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationPaymentMethod",
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last4",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SubscriptionPayment",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '6320d72f556c1f1a5950c3c7830eab52';

module.exports = node;
