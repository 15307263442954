import graphql from 'babel-plugin-relay/macro';

const ClientViewQuery = graphql`
  query ClientViewQuery_Query(
    $clientId: ID
    $filterBy: ProviderAppointmentFilterInput
    $orderBy: [[String]]
    $cursor: String
    $count: Int
  ) {
    viewer {
      ...ClientView_viewer
        @arguments(clientId: $clientId, filterBy: $filterBy, orderBy: $orderBy, after: $cursor, count: $count)
    }
  }
`;

export default ClientViewQuery;
