import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation UpdateAppointmentWithTimeSlotsMutation($input: UpdateAppointmentWithTimeSlotsInput!) {
    updateAppointmentWithTimeSlots(input: $input) {
      newAppointments {
        id
        rowId
        serviceId
        service {
          title
        }
        startAt
        endAt
        timeSlots {
          id
          startAt
          endAt
          date
          duration
        }
        provider {
          id
          rowId
          name
          profilePhotoUrl
        }
        status
        clientId
        client {
          id
          name
        }
      }
      updatedAppointment {
        id
        rowId
        serviceId
        providerId
        provider {
          id
          name
          profilePhotoUrl
        }
        service {
          title
        }
        startAt
        endAt
        timeSlots {
          id
          startAt
          endAt
          date
          duration
        }
        status
        clientId
        client {
          id
          name
        }
      }
    }
  }
`;

const UpdateAppointmentWithTimeSlotsMutation = (payload, success, failure) => {
  const {input} = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input,
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default UpdateAppointmentWithTimeSlotsMutation;
