/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NotificationItem_notification$ref = any;
export type CreateNotificationInput = {|
  clientSubscriptionId?: ?string
|};
export type CreateNotificationSubscriptionVariables = {|
  input: CreateNotificationInput
|};
export type CreateNotificationSubscriptionResponse = {|
  +createNotification: ?{|
    +id: ?string,
    +viewer: ?{|
      +unreadNotificationsCount?: ?number
    |},
    +notificationEdge: ?{|
      +node: ?{|
        +id: string,
        +createdAt: ?any,
        +isRead: ?boolean,
        +$fragmentRefs: NotificationItem_notification$ref,
      |}
    |},
  |}
|};
export type CreateNotificationSubscription = {|
  variables: CreateNotificationSubscriptionVariables,
  response: CreateNotificationSubscriptionResponse,
|};
*/


/*
subscription CreateNotificationSubscription(
  $input: CreateNotificationInput!
) {
  createNotification(input: $input) {
    id
    viewer {
      __typename
      ... on Provider {
        unreadNotificationsCount
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
    notificationEdge {
      node {
        id
        createdAt
        isRead
        ...NotificationItem_notification
      }
    }
  }
}

fragment NotificationItem_notification on Notification {
  body
  objectType
  payload
  objectId
  createdAt
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadNotificationsCount",
      "storageKey": null
    }
  ],
  "type": "Provider",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRead",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateNotificationSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNotificationPayload",
        "kind": "LinkedField",
        "name": "createNotification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationEdge",
            "kind": "LinkedField",
            "name": "notificationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Notification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "NotificationItem_notification"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "SocketSubscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateNotificationSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNotificationPayload",
        "kind": "LinkedField",
        "name": "createNotification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationEdge",
            "kind": "LinkedField",
            "name": "notificationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Notification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "objectType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payload",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "objectId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "94b86f3ed61b2bc2c9b1c576b12e6015",
    "id": null,
    "metadata": {},
    "name": "CreateNotificationSubscription",
    "operationKind": "subscription",
    "text": "subscription CreateNotificationSubscription(\n  $input: CreateNotificationInput!\n) {\n  createNotification(input: $input) {\n    id\n    viewer {\n      __typename\n      ... on Provider {\n        unreadNotificationsCount\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    notificationEdge {\n      node {\n        id\n        createdAt\n        isRead\n        ...NotificationItem_notification\n      }\n    }\n  }\n}\n\nfragment NotificationItem_notification on Notification {\n  body\n  objectType\n  payload\n  objectId\n  createdAt\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2ca127568b84f152833fc733fb1d8125';

module.exports = node;
