import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation UpdateAppointmentMutation($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      appointment {
        id
        rowId
        serviceId
        service {
          title
        }
        startAt
        endAt
        status
        clientId
        client {
          id
          name
        }
      }
    }
  }
`;

const UpdateAppointmentMutation = (payload, success, failure) => {
  const {input, uploadables} = payload;

  commitMutation(environment, {
    mutation,
    variables: {input},
    uploadables,
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default UpdateAppointmentMutation;
