/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MarkAppointmentAsPaidInput = {|
  clientMutationId?: ?string,
  appointmentId: string,
  placePaymentMethodId: string,
|};
export type MarkAppointmentAsPaidMutationVariables = {|
  input: MarkAppointmentAsPaidInput
|};
export type MarkAppointmentAsPaidMutationResponse = {|
  +markAppointmentAsPaid: ?{|
    +appointment: ?{|
      +id: string,
      +amountPaid: ?number,
      +payments: ?$ReadOnlyArray<?{|
        +amount: ?number,
        +status: ?string,
        +currency: ?string,
        +paymentMethod: ?string,
        +type: ?string,
      |}>,
    |}
  |}
|};
export type MarkAppointmentAsPaidMutation = {|
  variables: MarkAppointmentAsPaidMutationVariables,
  response: MarkAppointmentAsPaidMutationResponse,
|};
*/


/*
mutation MarkAppointmentAsPaidMutation(
  $input: MarkAppointmentAsPaidInput!
) {
  markAppointmentAsPaid(input: $input) {
    appointment {
      id
      amountPaid
      payments {
        amount
        status
        currency
        paymentMethod
        type
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountPaid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentMethod",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkAppointmentAsPaidMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkAppointmentAsPaidPayload",
        "kind": "LinkedField",
        "name": "markAppointmentAsPaid",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "appointment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AppointmentPayment",
                "kind": "LinkedField",
                "name": "payments",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkAppointmentAsPaidMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkAppointmentAsPaidPayload",
        "kind": "LinkedField",
        "name": "markAppointmentAsPaid",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "appointment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AppointmentPayment",
                "kind": "LinkedField",
                "name": "payments",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4faa565dfa5f19faf4beb3b20b7746cf",
    "id": null,
    "metadata": {},
    "name": "MarkAppointmentAsPaidMutation",
    "operationKind": "mutation",
    "text": "mutation MarkAppointmentAsPaidMutation(\n  $input: MarkAppointmentAsPaidInput!\n) {\n  markAppointmentAsPaid(input: $input) {\n    appointment {\n      id\n      amountPaid\n      payments {\n        amount\n        status\n        currency\n        paymentMethod\n        type\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6abe09572d5429c3eb99cbd51835f48f';

module.exports = node;
