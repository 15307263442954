/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteVacationHourInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type DeleteVacationHourMutationVariables = {|
  connections: $ReadOnlyArray<string>,
  input: DeleteVacationHourInput,
|};
export type DeleteVacationHourMutationResponse = {|
  +deleteVacationHour: ?{|
    +deletedId: ?string
  |}
|};
export type DeleteVacationHourMutation = {|
  variables: DeleteVacationHourMutationVariables,
  response: DeleteVacationHourMutationResponse,
|};
*/


/*
mutation DeleteVacationHourMutation(
  $input: DeleteVacationHourInput!
) {
  deleteVacationHour(input: $input) {
    deletedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteVacationHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteVacationHourPayload",
        "kind": "LinkedField",
        "name": "deleteVacationHour",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteVacationHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteVacationHourPayload",
        "kind": "LinkedField",
        "name": "deleteVacationHour",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5badef0a1b14eb4a7aeb3f2c1ac33a28",
    "id": null,
    "metadata": {},
    "name": "DeleteVacationHourMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteVacationHourMutation(\n  $input: DeleteVacationHourInput!\n) {\n  deleteVacationHour(input: $input) {\n    deletedId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b23480606e970b9076ec95b4f4a0fca0';

module.exports = node;
