import {TextArea} from 'semantic-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const Input = styled(TextArea)`
  min-height: 80px;
  max-height: 200px;
  max-width: 100%;
  border-color: lightgray;
  border-radius: 4px;
  padding: 6px;
`;

export default function AppointmentFieldInput({fieldData, value, onChange}) {
  return (
    <Container>
      <Input
        id={fieldData.rowId}
        key={fieldData.rowId}
        maxLength="120"
        value={value}
        onChange={(e, {value}) => onChange(value, fieldData.rowId)}
        style={{}}
        placeholder={fieldData.prompt}
      />
    </Container>
  );
}
