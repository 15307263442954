import graphql from 'babel-plugin-relay/macro';

const LoginQuery = graphql`
  query LoginQuery_Query {
    viewer {
      ...Login_viewer
    }
  }
`;

export default LoginQuery;
