import graphql from 'babel-plugin-relay/macro';
import moment from 'moment';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Card, Button, Icon, Grid, Header, Modal} from 'semantic-ui-react';
import {
  ActivateSubscriptionMutation,
  CancelSubscriptionMutation /*ChargeCustomerMutation/*/,
  GetSubscriptionSetupIntent,
} from '../../mutations';
import {withTranslation} from 'react-i18next';
import PricingTable from '../PricingTable';
import {get} from 'lodash';
import CheckoutModal from './CheckoutModal';
import Status from '../Status';

const renderPaymentIcon = (paymentType) => {
  if (!paymentType) {
    return 'N/A';
  }

  if (paymentType.toLowerCase().indexOf('visa') !== -1) {
    return <Icon size="big" name="cc visa" color="blue" />;
  } else if (paymentType.toLowerCase().indexOf('mastercard') !== -1) {
    return <Icon size="big" name="cc mastercard" color="blue" />;
  } else if (paymentType.toLowerCase().indexOf('paypal') !== -1) {
    return <Icon size="big" name="paypal" color="blue" />;
  }

  return null;
};

const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return 'green';
    case 'canceled':
      return 'red';
    default:
      return 'red';
  }
};

const getStatusText = (status, t) => {
  switch (status) {
    case 'active':
      return t('common.status_active');
    case 'canceled':
      return t('common.status_canceled');
    case 'incomplete_expired':
    case 'incomplete':
    default:
      return t('common.status_inactive');
  }
};

class General extends React.Component {
  state = {
    isCancelPlanModalOpen: false,
    isCompleted: false,
    isPaymentLoading: false,
    isPaymentModalOpen: false,
    isPickPricingPlanModalOpen: false,
    isCheckoutOpen: false, // false
    pricingPlanId: null,
    clientSecret: null,
    intentType: null,
    // clientSecret: 'pi_3LgwFnElaZEDqCVj12gGxKPD_secret_YGGFMTTxKMOfJQ15rFt64Shwm',
  };

  handlePaymentModalClose = () => {
    this.setState({isPaymentModalOpen: false});
  };

  handleCancelPlanClicked = () => {
    const {id: subscriptionId} = this.props.viewer.organization.subscription;
    const input = {id: subscriptionId};

    const onSuccess = (data) => {
      // TODO: handle success message
      console.log('Success', data);
    };

    const onFailure = (errors) => {
      // TODO: Handle error message
      // const _error = errors && errors[0];
      console.log('Failure', errors);
    };

    CancelSubscriptionMutation({input}, onSuccess, onFailure);
    this.setState({isCancelPlanModalOpen: false});
  };

  handleActivatePlanClicked = (pricingPlanId) => {
    this.setState({pricingPlanId});

    ActivateSubscriptionMutation(
      {input: {pricingPlanId}},
      (response) => {
        const clientSecret = get(response, 'activateSubscription.clientSecret');

        this.setState({isPickPricingPlanModalOpen: false, clientSecret, intentType: 'payment'}, () =>
          this.setState({isCheckoutOpen: true}),
        );
      },
      () => console.log('error'),
    );
  };

  handleChangePaymentMethodClicked = () => {
    GetSubscriptionSetupIntent(
      {input: {subscriptionId: this.props.viewer.organization.subscription.id}},
      (response) => {
        const clientSecret = get(response, 'getSubscriptionSetupIntent.clientSecret');

        this.setState({clientSecret, intentType: 'setup'}, () => this.setState({isCheckoutOpen: true}));
      },
      () => console.log('error'),
    );
  };

  render() {
    const {t, viewer} = this.props;
    const {clients, services, organization} = viewer;
    const {subscription, providers, pricingPlans} = organization;
    const {isCancelPlanModalOpen, isPickPricingPlanModalOpen, isCheckoutOpen, clientSecret, intentType} = this.state;

    const isSubscriptionActive = subscription && subscription.status === 'active';
    const pricingPlan = get(subscription, 'pricingPlan');
    const paymentMethod = get(subscription, 'paymentMethod');
    const currentTier = get(subscription, 'currentTier');

    return (
      <div style={{marginTop: 28, marginLeft: 8}}>
        <Card.Group>
          <Grid style={{width: '100%'}}>
            <Grid.Row>
              <Grid.Column width={8}>
                <Card color="blue" fluid style={{height: '100%'}}>
                  <Card.Content>
                    <Header as="h2" style={{marginBottom: 5}}>
                      {t('common.plan_details')}
                      {!!pricingPlan && ` - ${pricingPlan.name}`}
                    </Header>
                    {subscription && (
                      <Status
                        style={{marginBottom: 20}}
                        color={getStatusColor(subscription.status)}
                        text={getStatusText(subscription.status, t)}
                      />
                    )}
                    <Card.Description>{t('common.staff_members')}</Card.Description>
                    <Card.Header>{providers.total}</Card.Header>
                    <Card.Description>{t('common.services_offered')}</Card.Description>
                    <Card.Header>{services.total}</Card.Header>
                    <Card.Description>{t('common.clients_number')}</Card.Description>
                    <Card.Header>{clients.total}</Card.Header>
                    {currentTier && (
                      <>
                        <Card.Description>{t('common.current_price_tier')}</Card.Description>
                        <Card.Header>
                          {currentTier.value} ({pricingPlan.currency.toUpperCase()})
                        </Card.Header>
                      </>
                    )}
                    {isSubscriptionActive && paymentMethod && (
                      <div style={{marginTop: 20}}>
                        <Card.Description>{t('common.payment_method')}</Card.Description>
                        <Card.Header style={{marginTop: 5, fontWeight: 'bold'}}>
                          {renderPaymentIcon(paymentMethod.brand)} ****{paymentMethod.last4}
                        </Card.Header>
                      </div>
                    )}
                    <div style={{marginTop: 30}}>
                      {subscription && subscription.isCanceled && (
                        <Header color="red">
                          {t('common.canceled_but_active_plan')} {moment(subscription.validTo).format('LLL')}
                        </Header>
                      )}
                      {!isSubscriptionActive && (
                        <Button positive onClick={() => this.setState({isPickPricingPlanModalOpen: true})}>
                          {t('common.activate_subscription')}
                        </Button>
                      )}
                      {isSubscriptionActive && (
                        <Button onClick={this.handleChangePaymentMethodClicked}>
                          {t('actions.change_payment_method')}
                        </Button>
                      )}
                      {isSubscriptionActive && (
                        <Button
                          disabled={subscription.isCanceled}
                          onClick={() => this.setState({isCancelPlanModalOpen: true})}
                        >
                          {t('common.cancel_plan')}
                        </Button>
                      )}
                      <Button style={{marginRight: 20}} href="mailto: support@cindyapp.com">
                        {t('common.contact_us')}
                      </Button>
                    </div>
                  </Card.Content>
                </Card>
                <Modal
                  onClose={() => this.setState({isPickPricingPlanModalOpen: false})}
                  open={isPickPricingPlanModalOpen}
                >
                  <Modal.Header content={t('actions.pick_plan')} />
                  <Modal.Content>
                    <PricingTable
                      pricingPlans={pricingPlans}
                      onPlanClick={this.handleActivatePlanClicked}
                      currentQuantity={providers.total}
                    />
                  </Modal.Content>
                </Modal>
                <Modal onClose={() => this.setState({isCancelPlanModalOpen: false})} open={isCancelPlanModalOpen}>
                  <Modal.Header content={t('common.cancel_plan')} />
                  <Modal.Content>{t('actions.are_you_sure_you_want_to_cancel_plan')}</Modal.Content>
                  <Modal.Actions>
                    <Button onClick={() => this.setState({isCancelPlanModalOpen: false})} color="red">
                      <Icon name="remove" /> {t('common.no')}
                    </Button>
                    <Button onClick={this.handleCancelPlanClicked} color="green">
                      <Icon name="checkmark" /> {t('common.yes')}
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Column>
              {isSubscriptionActive && subscription.lastPayment && (
                <Grid.Column width={4}>
                  <Card color="blue" fluid style={{height: '100%'}}>
                    <Card.Content>
                      <Header as="h2" style={{marginBottom: 20}}>
                        {t('common.last_payment')}
                      </Header>
                      <Card.Description>{t('common.date')}</Card.Description>
                      <Card.Header>{moment(subscription.lastPayment.createdAt).format('LL')}</Card.Header>
                      <Card.Description>{t('common.amount')}</Card.Description>
                      {/* TODO: Maybe use translations to handle this */}
                      <Card.Header>
                        {(subscription.lastPayment.amount / 100).toFixed(2)} (
                        {subscription.lastPayment.currency.toUpperCase()})
                      </Card.Header>
                      <Card.Description>{t('common.invoice_summary')}</Card.Description>
                      <Card.Header>
                        <a href={subscription.lastPayment.invoiceLink} download>
                          {t('common.invoice')} #{subscription.lastPayment.invoiceNumber}
                        </a>
                      </Card.Header>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Card.Group>
        {clientSecret && (
          <CheckoutModal
            intentType={intentType}
            clientSecret={clientSecret}
            open={isCheckoutOpen}
            onClose={() => this.setState({isCheckoutOpen: false})}
          />
        )}
      </div>
    );
  }
}

const GeneralContainer = createFragmentContainer(withTranslation()(General), {
  viewer: graphql`
    fragment General_viewer on User {
      ... on Provider {
        clients {
          total
        }
        services {
          total
        }
        organization {
          pricingPlans {
            id
            name
            period
            currency
            tiers {
              value
              minQuantity
              maxQuantity
            }
          }
          providers {
            total
          }
          subscription {
            id
            status
            validTo
            currentTier {
              value
            }
            pricingPlan {
              name
              period
              currency
            }
            paymentMethod {
              type
              brand
              last4
            }
            lastPayment {
              createdAt
              amount
              currency
              invoiceNumber
              invoiceLink
            }
            # eslint-disable-next-line relay/unused-fields
          }
        }
      }
    }
  `,
});

export default GeneralContainer;
