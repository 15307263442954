import moment from 'moment';
import {memoize} from 'lodash';

function formatTimes() {
  const times = [];
  const start = moment('00:00', 'HH:mm');
  const end = moment('23:30', 'HH:mm');

  times.push(start.format('HH:mm'));
  while (start.isBefore(end)) {
    times.push(start.add(30, 'm').format('HH:mm'));
  }

  return times;
}

const memoizedFunction = memoize(formatTimes);

export default function getFormattedTimes() {
  return memoizedFunction();
}
