import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import getTierForNUnits from '../helpers/getTierForNUnits';

const Container = styled.div`
  display: flex;
  padding: 30px;
  border: 1px solid lightgray;
  border-radius: 12px;
  gap: 12rem;
  align-items: center;
  flex-direction: column;
`;

const PricePlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`;

const PricePlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 20px;
  border: 1px solid lightgray;
  border-radius: 12px;
  align-items: center;

  :hover {
    border-color: #8070fd;
  }
`;

const PlanTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const PlanPrice = styled.div`
  font-size: 36px;
`;

const Discount = styled.div`
  font-size: 18px;
`;

const Description = styled.div`
  margin-top: 14px;
  font-size: 16px;
  color: gray;
  text-align: center;
`;

const CTAButton = styled.button`
  margin-top: 30px;
  width: 200px;
  height: 45px;
  text-align: center;
  background-color: #8070fd;
  border: 0px;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  font-size: 16px;

  :hover {
    background-color: purple;
  }
`;

function PricePlan({plan, currentQuantity, onClick}) {
  const {t} = useTranslation();

  const {tiers, name, period, currency} = plan;
  const tier = getTierForNUnits(tiers, currentQuantity);
  const {value, minQuantity, maxQuantity} = tier;

  const formattedPeriod =
    period === 12 ? t('common.year') : period === 1 ? t('common.month') : `${period} ${t('common.months')}`;

  let discount = null;
  if (period === 12) {
    discount = (value / 10) * 12 - value;
  }

  return (
    <PricePlanContainer key={plan.id}>
      <PlanTitle>{name}</PlanTitle>
      <PlanPrice>
        {value} {currency} / {formattedPeriod.toLowerCase()}
      </PlanPrice>
      <>
        {discount ? (
          <Discount>
            {t('common.save_money')} {discount} {currency}!
          </Discount>
        ) : (
          <div style={{height: 25}} />
        )}
        <Description>
          ({minQuantity} - {maxQuantity}) {t('common.staff_members')}*
          <br />
          {t('common.unlimited_reservations')}
          <br />
          {t('common.unlimited_services')}
          <br />
          {t('common.no_commission')}
        </Description>
      </>
      <CTAButton onClick={onClick}>{t('actions.choose_this_plan')}</CTAButton>
    </PricePlanContainer>
  );
}

export default function PricingTable({pricingPlans, currentQuantity, onPlanClick}) {
  return (
    <Container>
      <PricePlansContainer>
        {pricingPlans.map((plan) => (
          <PricePlan currentQuantity={currentQuantity} plan={plan} onClick={() => onPlanClick(plan.id)} />
        ))}
      </PricePlansContainer>
    </Container>
  );
}
