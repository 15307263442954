import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation DeleteRecurringTimeSlotMutation($connections: [ID!]!, $input: DeleteRecurringTimeSlotInput!) {
    deleteRecurringTimeSlot(input: $input) {
      clientMutationId
      id @deleteEdge(connections: $connections)
    }
  }
`;

const DeleteRecurringTimeSlotMutation = (payload, success, failure) => {
  const {input, connectionId} = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input,
      connections: [connectionId],
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default DeleteRecurringTimeSlotMutation;
