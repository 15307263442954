import graphql from 'babel-plugin-relay/macro';

const WorkHoursQuery = graphql`
  query WorkHoursQuery_Query {
    viewer {
      ...WorkHours_viewer
    }
  }
`;

export default WorkHoursQuery;
