/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Organization_organization$ref: FragmentReference;
declare export opaque type Organization_organization$fragmentType: Organization_organization$ref;
export type Organization_organization = {|
  +id: string,
  +name: ?string,
  +email: ?string,
  +phoneNumber: ?string,
  +coverPhotoUrl: ?string,
  +profilePhotoUrl: ?string,
  +categoryId: ?number,
  +categories: ?$ReadOnlyArray<?{|
    +rowId: number,
    +id: string,
    +name: ?string,
  |}>,
  +$refType: Organization_organization$ref,
|};
export type Organization_organization$data = Organization_organization;
export type Organization_organization$key = {
  +$data?: Organization_organization$data,
  +$fragmentRefs: Organization_organization$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Organization_organization",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverPhotoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePhotoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d85a0704d30fdef5e834800f5c73e51';

module.exports = node;
