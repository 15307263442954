/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NotificationsSidebar_viewer$ref = any;
export type NotificationFilterInput = {|
  type?: ?string
|};
export type NotificationsSidebarForwardQueryVariables = {|
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  filterBy?: ?NotificationFilterInput,
  cursor?: ?string,
  count?: ?number,
  shouldFetch: boolean,
|};
export type NotificationsSidebarForwardQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: NotificationsSidebar_viewer$ref
  |}
|};
export type NotificationsSidebarForwardQuery = {|
  variables: NotificationsSidebarForwardQueryVariables,
  response: NotificationsSidebarForwardQueryResponse,
|};
*/


/*
query NotificationsSidebarForwardQuery(
  $orderBy: [[String]]
  $filterBy: NotificationFilterInput
  $cursor: String
  $shouldFetch: Boolean!
) {
  viewer {
    __typename
    ...NotificationsSidebar_viewer_4cUli2
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment NotificationItem_notification on Notification {
  body
  objectType
  payload
  objectId
  createdAt
}

fragment NotificationsSidebar_viewer_4cUli2 on User {
  __isUser: __typename
  ... on Provider {
    id
    notifications(filterBy: $filterBy, orderBy: $orderBy, first: 15, after: $cursor) @include(if: $shouldFetch) {
      edges {
        node {
          id
          createdAt
          isRead
          ...NotificationItem_notification
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldFetch"
},
v5 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "cursor"
},
v6 = {
  "kind": "Variable",
  "name": "filterBy",
  "variableName": "filterBy"
},
v7 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  (v5/*: any*/),
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "first",
    "value": 15
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsSidebarForwardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "Variable",
                "name": "shouldFetch",
                "variableName": "shouldFetch"
              }
            ],
            "kind": "FragmentSpread",
            "name": "NotificationsSidebar_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotificationsSidebarForwardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v9/*: any*/),
              {
                "condition": "shouldFetch",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "NotificationConnection",
                    "kind": "LinkedField",
                    "name": "notifications",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NotificationEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Notification",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isRead",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "body",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "objectType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "payload",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "objectId",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UFPageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "NotificationSidebar_notifications",
                    "kind": "LinkedHandle",
                    "name": "notifications"
                  }
                ]
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v9/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78ade7c8b74dc127b61ad6f6d24dcbdc",
    "id": null,
    "metadata": {},
    "name": "NotificationsSidebarForwardQuery",
    "operationKind": "query",
    "text": "query NotificationsSidebarForwardQuery(\n  $orderBy: [[String]]\n  $filterBy: NotificationFilterInput\n  $cursor: String\n  $shouldFetch: Boolean!\n) {\n  viewer {\n    __typename\n    ...NotificationsSidebar_viewer_4cUli2\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment NotificationItem_notification on Notification {\n  body\n  objectType\n  payload\n  objectId\n  createdAt\n}\n\nfragment NotificationsSidebar_viewer_4cUli2 on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    notifications(filterBy: $filterBy, orderBy: $orderBy, first: 15, after: $cursor) @include(if: $shouldFetch) {\n      edges {\n        node {\n          id\n          createdAt\n          isRead\n          ...NotificationItem_notification\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7017b95b6078ff0794b1f3f60be16187';

module.exports = node;
