import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation ActivateSubscriptionMutation($input: ActivateSubscriptionInput!) {
    activateSubscription(input: $input) {
      clientSecret
      subscription {
        id
        status
        validTo
        pricingPlan {
          name
          period
          currency
        }
      }
    }
  }
`;

const ActivateSubscriptionMutation = (payload, success, failure) => {
  const {input} = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input,
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default ActivateSubscriptionMutation;
