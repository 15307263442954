/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Calendar_viewer$ref: FragmentReference;
declare export opaque type Calendar_viewer$fragmentType: Calendar_viewer$ref;
export type Calendar_viewer = {|
  +id?: string,
  +workHours?: ?$ReadOnlyArray<?{|
    +openTime: ?string,
    +closeTime: ?string,
  |}>,
  +appointment?: ?{|
    +id: string,
    +serviceId: ?number,
    +providerId: ?number,
    +service: ?{|
      +title: ?string,
      +capacity: ?number,
    |},
    +startAt: ?any,
    +status: ?string,
    +endAt: ?any,
    +isRecurring: ?boolean,
    +recurringTimeSlot: ?{|
      +capacity: ?number
    |},
    +client: ?{|
      +id: string,
      +name: ?string,
    |},
  |},
  +appointments?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +providerId: ?number,
        +serviceId: ?number,
        +service: ?{|
          +title: ?string,
          +capacity: ?number,
        |},
        +amountPaid: ?number,
        +startAt: ?any,
        +status: ?string,
        +endAt: ?any,
        +isPartOfRecurringSequence: ?boolean,
        +isRecurring: ?boolean,
        +recurringTimeSlot: ?{|
          +capacity: ?number
        |},
        +provider: ?{|
          +id: string,
          +name: ?string,
        |},
        +client: ?{|
          +id: string,
          +name: ?string,
        |},
      |}
    |}>
  |},
  +providers?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +$refType: Calendar_viewer$ref,
|};
export type Calendar_viewer$data = Calendar_viewer;
export type Calendar_viewer$key = {
  +$data?: Calendar_viewer$data,
  +$fragmentRefs: Calendar_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRecurring",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeSlot",
  "kind": "LinkedField",
  "name": "recurringTimeSlot",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v10 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "appointmentId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "appointments"
        ]
      }
    ]
  },
  "name": "Calendar_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkHour",
          "kind": "LinkedField",
          "name": "workHours",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "openTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "closeTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "appointmentId"
            }
          ],
          "concreteType": "Appointment",
          "kind": "LinkedField",
          "name": "appointment",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v11/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "appointments",
          "args": null,
          "concreteType": "AppointmentConnection",
          "kind": "LinkedField",
          "name": "__Calendar_appointments_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AppointmentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Appointment",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v1/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amountPaid",
                      "storageKey": null
                    },
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isPartOfRecurringSequence",
                      "storageKey": null
                    },
                    (v8/*: any*/),
                    (v9/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Provider",
                      "kind": "LinkedField",
                      "name": "provider",
                      "plural": false,
                      "selections": (v10/*: any*/),
                      "storageKey": null
                    },
                    (v11/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UFPageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10000
            },
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": [
                [
                  "createdAt",
                  "ASC"
                ]
              ]
            }
          ],
          "concreteType": "ProviderConnection",
          "kind": "LinkedField",
          "name": "providers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProviderEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Provider",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v10/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "providers(first:10000,orderBy:[[\"createdAt\",\"ASC\"]])"
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
})();
// prettier-ignore
(node/*: any*/).hash = '5cea73132f2ffa4d6bf8d2bd70db9001';

module.exports = node;
