import graphql from 'babel-plugin-relay/macro';
import {get, cloneDeep, set, pick, pickBy} from 'lodash';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Button, Form, Grid, Message, Modal, Icon} from 'semantic-ui-react';
import {UpdateProviderMutation} from '../../mutations';
import {withTranslation} from 'react-i18next';
import isEmpty from '../../helpers/isEmpty';
import isMatchWith from '../../helpers/isMatchWith';
import {ProfilePhotoButton, AvatarIcon} from './components';
import {subscribeUser} from '../../subscription';

const formFields = ['name', 'email', 'phoneNumber', 'organization', 'settings'];

class MyProfile extends React.Component {
  state = {
    name: null,
    email: null,
    phoneNumber: null,
    settings: {},
    password: '',
    confirmPassword: '',
    validatePassword: '',
    isLoading: false,
    isModalOpen: false,
    error: false,
    errorContent: null,
    isButtonDisabled: false,
    errorValidatePass: null,
  };

  constructor(props) {
    super(props);

    this.languageOptions = [
      {
        key: 'en',
        text: 'English',
        value: 'en',
      },
      {
        key: 'it',
        text: 'Italiano',
        value: 'it',
      },
      {
        key: 'bg',
        text: 'Български',
        value: 'bg',
      },
    ];
    this.profilePhotoInputRef = React.createRef();
  }

  componentDidMount() {
    const hasPushSubscription = localStorage.getItem('hasPushSubscription');
    const booleanResult = hasPushSubscription === 'true'; // TODO: Improve logic
    return this.setState({isButtonDisabled: booleanResult});
  }

  resetForm = () =>
    this.setState({
      name: null,
      email: null,
      phoneNumber: null,
      password: '',
      confirmPassword: '',
      validatePassword: '',
      isLoading: false,
      isModalOpen: false,
      error: false,
      errorContent: null,
      errorValidatePass: null,
    });

  handleSubscribeToPush = () => {
    const isUserSubscribbed = subscribeUser();
    if (isUserSubscribbed) {
      localStorage.setItem('hasPushSubscription', true);
    }
  };

  handleProfilePhotoUpload = (e) => {
    // TODO
    const fileButton = document.getElementById('profile-input');
    const profilePhoto = fileButton ? fileButton.files[0] : null;

    const onSuccess = (data) => {
      this.profilePhotoInputRef.current.value = '';
      this.setState({isLoading: false});
    };

    const onFailure = (errors) => {
      this.profilePhotoInputRef.current.value = '';
      this.setState({isLoading: false, error: 'Wrong password!'});
    };

    UpdateProviderMutation({input: {id: this.props.viewer.id}, uploadables: {profilePhoto}}, onSuccess, onFailure);
  };

  hasUnsavedChanges = () => {
    const propsValues = pick(this.props.viewer, formFields);
    const stateValues = pick(this.state, formFields);

    return !isMatchWith(propsValues, stateValues) || this.state.password;
  };

  handleFieldChange = (i, data) => {
    const {errorContent, errorValidatePass} = this.state;

    if (errorContent || errorValidatePass) {
      this.setState({errorContent: null, errorValidatePass: null, error: null});
    }

    // TODO: Rewrite and export to a separate file
    const isObjectDeep = data.name.indexOf('.') !== -1 || data.name.indexOf('[') !== -1;
    if (isObjectDeep) {
      const _state = cloneDeep(this.state);
      set(_state, data.name, data.value);
      this.setState(_state);
    } else {
      this.setState({[data.name]: data.value});
    }
  };

  getFieldValue = (fieldName) => {
    return isEmpty(get(this.state, fieldName)) && get(this.state, fieldName) !== ''
      ? get(this.props.viewer, fieldName)
      : get(this.state, fieldName);
  };

  handleSubmit = () => {
    const {viewer, t} = this.props;

    this.setState({isLoading: true});

    const onSuccess = (data) => {
      this.resetForm();
    };

    const onFailure = (errors) => {
      // TODO: Handle errors
      this.setState({
        isLoading: false,
        errorValidatePass: t('common.password_error'),
        validatePassword: '',
      });
    };

    const {password, validatePassword} = this.state;
    const stateValues = {
      ...pick(this.state, formFields),
      password,
      validatePassword,
    };

    const input = {
      ...pickBy(stateValues, (value) => !isEmpty(value)),
      id: viewer.id,
    };

    if (this.state.phoneNumber) {
      if (this.state.phoneNumber.match(/\d/g).length < 10) {
        return this.setState({error: true, errorContent: t('common.phone_number_error'), isLoading: false});
      }

      input.phoneNumber = this.state.phoneNumber.trim().replace(/[^+\d]+/g, '');
    }

    if (this.state.email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(this.state.email)) {
        return this.setState({error: true, errorContent: t('common.email_error'), isLoading: false});
      }
    }

    UpdateProviderMutation({input}, onSuccess, onFailure);
  };

  handleProfilePhotoInputRef = (e) => {
    this.profilePhotoInputRef.current.click();
  };

  handleSaveAction = () => {
    const {t} = this.props;
    const {password, confirmPassword} = this.state;
    this.setState({error: false});

    if (password) {
      if (password !== confirmPassword) {
        return this.setState({
          error: true,
          errorContent: `${t('common.passwords_do_not_match')}`,
          password: null,
          confirmPassword: null,
        });
      } else if (password.length < 5) {
        return this.setState({
          error: true,
          errorContent: `${t('common.password_length_error')}`,
          password: null,
          confirmPassword: null,
        });
      }
      this.setState({isModalOpen: true});
    } else {
      this.handleSubmit();
    }
  };

  render() {
    const {t} = this.props;
    const {profilePhotoUrl} = this.props.viewer;
    const {errorValidatePass, password, validatePassword, confirmPassword, isLoading, error, errorContent} = this.state;

    const unsavedChanges = this.hasUnsavedChanges();

    return (
      <div>
        <Modal open={this.state.isModalOpen} onClose={() => this.setState({isModalOpen: false})} closeIcon>
          <Modal.Header>{t('actions.enter_current_password_to_save')}</Modal.Header>
          <Modal.Content>
            {errorValidatePass && <Message error header={t('common.error')} content={errorValidatePass} />}
            <Form.Input
              fluid
              required
              icon="lock"
              name="validatePassword"
              placeholder={t('actions.enter_password')}
              value={validatePassword}
              onChange={this.handleFieldChange}
              type="password"
              style={{width: '100%'}}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              loading={isLoading}
              color="blue"
              disabled={!validatePassword}
              style={{marginTop: 5}}
              onClick={this.handleSubmit}
              size="small"
            >
              {t('actions.confirm')}
            </Button>
          </Modal.Actions>
        </Modal>
        <Grid columns="2" style={{marginTop: 40, marginBottom: 40}}>
          <Grid.Row>
            <Grid.Column width={4}>
              <ProfilePhotoButton
                src={profilePhotoUrl}
                id="avatar"
                type="button"
                style={{position: 'relative', top: 0, left: 0}}
                onClick={this.handleProfilePhotoInputRef}
                htmlFor="profile-input"
              >
                <AvatarIcon>
                  <Icon name="upload" style={{color: 'white'}} />
                </AvatarIcon>
              </ProfilePhotoButton>
              <input
                hidden
                id="profile-input"
                ref={this.profilePhotoInputRef}
                type="file"
                accept="image/*"
                onChange={this.handleProfilePhotoUpload}
              />
            </Grid.Column>
            <Grid.Column width={12}>
              <Form error={error}>
                <Message error header={t('common.error')} content={errorContent} />
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    name="name"
                    label={t('common.name')}
                    onChange={this.handleFieldChange}
                    value={this.getFieldValue('name')}
                  />
                  <Form.Input
                    required
                    fluid
                    name="email"
                    type="email"
                    label={t('common.email')}
                    onChange={this.handleFieldChange}
                    value={this.getFieldValue('email')}
                  />
                  <Form.Input
                    required
                    fluid
                    name="phoneNumber"
                    type="tel"
                    label={t('common.phone_number')}
                    onChange={this.handleFieldChange}
                    value={this.getFieldValue('phoneNumber')}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    value={password}
                    onChange={this.handleFieldChange}
                    name="password"
                    type="password"
                    label={t('common.new_password')}
                  />
                  <Form.Input
                    fluid
                    value={confirmPassword}
                    onChange={this.handleFieldChange}
                    name="confirmPassword"
                    type="password"
                    label={t('common.new_password_retype')}
                  />
                </Form.Group>
                {/* <Form.Group widths="equal">
                  <Form.Button
                    color="blue"
                    disabled={this.state.isButtonDisabled}
                    fluid
                    secondary
                    onClick={this.handleSubscribeToPush}
                  >
                    {t('actions.enable_web_notifications')}
                  </Form.Button>
                </Form.Group> */}
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Button fluid color="blue" loading={isLoading} disabled={!unsavedChanges} onClick={this.handleSaveAction}>
          {t('common.save')}
        </Button>
        <div style={{height: 30}} />
      </div>
    );
  }
}

export default createFragmentContainer(withTranslation()(MyProfile), {
  viewer: graphql`
    fragment Personal_viewer on User {
      ... on Provider {
        id
        name
        # eslint-disable-next-line relay/unused-fields
        email
        profilePhotoUrl
        # eslint-disable-next-line relay/unused-fields
        phoneNumber
        # eslint-disable-next-line relay/unused-fields
        settings {
          locale
        }
      }
    }
  `,
});
