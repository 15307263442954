import graphql from 'babel-plugin-relay/macro';

const AppQuery = graphql`
  query AppQuery_Query($appointmentId: ID) {
    viewer {
      ...App_viewer @arguments(appointmentId: $appointmentId)
    }
  }
`;

export default AppQuery;
