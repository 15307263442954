import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {createRefetchContainer} from 'react-relay';
import {Button, Table} from 'semantic-ui-react';
import ServiceRow from './ServiceRow';
import {withRelay} from '../../relay';
import Pagination from '../Pagination';
import {withTranslation} from 'react-i18next';

const refetchVariables = {
  filterBy: {includeNonActive: true},
  orderBy: [['title', 'ASC']],
  page: 1,
  first: 10000,
  limit: 10000,
};

class ServicesList extends React.Component {
  handleRowClick = (service) => this.props.router.push(`/service/${service.id}`);

  handlePageSelected = (page) => {
    refetchVariables.page = page;

    this.props.relay.refetch(refetchVariables);
  };

  render() {
    const {viewer, t, router} = this.props;
    const {services = []} = viewer;
    const {currentPage, start, end, total} = services;

    return (
      <div>
        <Button style={{marginBottom: 20}} color="blue" floated="right" onClick={() => router.push('/service')}>
          {t('actions.add_service')}
        </Button>
        <Table sortable padded selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common.service_name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.description')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.duration')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.capacity')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.active')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {services &&
              services.edges.map(({node: service}) => (
                <ServiceRow service={service} key={service.id} onRowClick={this.handleRowClick} />
              ))}
          </Table.Body>
          {total > Pagination.getLimit() && (
            <Pagination
              currentPage={currentPage}
              start={start}
              end={end}
              total={total}
              onPageSelected={this.handlePageSelected}
            />
          )}
        </Table>
      </div>
    );
  }
}

const ServicesListQuery = graphql`
  query ServicesListRefetchQuery(
    $filterBy: ServicesFilterInput
    $orderBy: [[String]]
    $page: Int
    $first: Int
    $limit: Int
  ) {
    viewer {
      ...ServicesList_viewer
        @arguments(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit)
    }
  }
`;

const ServicesContainer = createRefetchContainer(
  withTranslation()(ServicesList),
  {
    viewer: graphql`
      fragment ServicesList_viewer on User
      @argumentDefinitions(
        filterBy: {type: "ServicesFilterInput"}
        orderBy: {type: "[[String]]"}
        page: {type: "Int"}
        first: {type: "Int"}
        limit: {type: "Int"}
      ) {
        ... on Provider {
          id
          services(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit)
            @connection(key: "ServicesList_services", filters: []) {
            edges {
              node {
                id
                ...ServiceRow_service
              }
            }
          }
        }
      }
    `,
  },
  ServicesListQuery,
);

ServicesContainer.getVariables = (props) => refetchVariables;

export default withRelay(ServicesContainer, ServicesListQuery);
