import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';
import {deleteEdgeFromConnection} from '../helpers/SharedUpdater';
import {ConnectionHandler} from 'relay-runtime';

const mutation = graphql`
  mutation UpdateRecurringAppointmentWithTimeSlotsMutation(
    $connections: [ID!]!
    $input: UpdateRecurringAppointmentWithTimeSlotsInput!
  ) {
    updateRecurringAppointmentWithTimeSlots(input: $input) {
      updatedAppointment {
        id
        rowId
        serviceId
        isPartOfRecurringSequence
        providerId
        provider {
          id
          name
          profilePhotoUrl
        }
        service {
          title
        }
        startAt
        endAt
        timeSlots {
          id
          startAt
          endAt
          date
          duration
        }
        status
        clientId
        client {
          id
          name
        }
      }
      newAppointment @appendNode(connections: $connections, edgeTypeName: "Appointment") {
        rowId
        id
        startAt
        status
        isPartOfRecurringSequence
        providerId
        provider {
          id
          name
          profilePhotoUrl
        }
        service {
          title
        }
        startAt
        endAt
        timeSlots {
          id
          startAt
          endAt
          date
          duration
        }
        status
        clientId
        client {
          id
          name
        }
      }
    }
  }
`;

const UpdateRecurringAppointmentWithTimeSlotsMutation = (payload, success, failure) => {
  const {input, viewerId, connectionName} = payload;

  const filters = [];
  const connectionId = ConnectionHandler.getConnectionID(viewerId, connectionName, filters);

  commitMutation(environment, {
    mutation,
    variables: {input, connections: [connectionId]},
    updater: (store) => {
      const rootField = store.getRootField('updateRecurringAppointmentWithTimeSlots');

      if (rootField) {
        const filters = [];
        deleteEdgeFromConnection(store, viewerId, input.id, connectionName, filters);
      }
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default UpdateRecurringAppointmentWithTimeSlotsMutation;
