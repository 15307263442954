import graphql from 'babel-plugin-relay/macro';
import React, {useEffect, useState} from 'react';
import {useFragment} from 'relay-hooks';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import {Modal, Form, Button, Select} from 'semantic-ui-react';
import getCountryCodeByIp from '../helpers/getCountryCodeByIp';
import './AddStaffMemberModal.css';
import AddProviderMutation from '../mutations/AddProviderMutation';

const fragment = graphql`
  fragment AddStaffMemberModal_viewer on Provider {
    roles {
      name
      id
    }
  }
`;

export default function AddStaffMemberModal(props) {
  const {open, onClose, viewer} = props;

  const {roles} = useFragment(fragment, viewer);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [role, setRole] = useState(null);
  const [countryCode, setCountryCode] = useState();
  const [isNumberValid, setIsNumberValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCode = async () => {
      const code = await getCountryCodeByIp();

      setCountryCode(code);
    };

    fetchCode();
  }, []);

  const {t} = useTranslation();

  const handleNumberChange = (value, country) => {
    setPhoneNumber(value);

    const regex = /\+?\d{12,13}/g;
    setIsNumberValid(regex.test(value));
  };

  const handleAddClient = () => {
    setIsLoading(true);

    const formattedPhoneNumber = '+' + phoneNumber; // NOTE: library does not add + sign to phone number

    const connectionName = 'StaffMembers_providers';

    const input = {
      name,
      phoneNumber: formattedPhoneNumber,
      email,
      roleId: role,
    };

    const onSuccess = (data) => {
      setIsLoading(false);

      window._dcq.push([
        'track',
        'Added provider',
        {
          email,
          providerEmail: email,
          providerName: name,
          providerPhoneNumber: formattedPhoneNumber,
        },
      ]);

      props.onClose();
    };

    const onFailure = (e) => {
      setIsLoading(false);
    };

    AddProviderMutation({input, connectionName, viewerId: viewer.id, filters: []}, onSuccess, onFailure);
  };

  const isFormValid = isNumberValid && phoneNumber && role && name && email && !isLoading;
  const roleOptions = roles.map((role) => ({
    key: role.id,
    value: role.id,
    text: role.name,
  }));

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Modal.Header> {t('actions.add_staff_member')}</Modal.Header>
      {/* {showSuccess && <Message success content={t('common.client_add_success')} />}
      {showError && <Message error content={t('common.error')} />} */}
      <Modal.Content>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              type="text"
              name="name"
              value={name}
              label={t('common.name')}
              required
              onChange={(i, data) => setName(data.value)}
            />
            <Form.Input
              type="email"
              name="email"
              value={email}
              label={t('common.email')}
              required
              onChange={(i, data) => setEmail(data.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field required width={8}>
              <label>{t('common.phone_number')}</label>
              <PhoneInput
                inputClass="phone-input"
                country={countryCode}
                placeholder={t('common.enter_phone_number')}
                value={phoneNumber}
                countryCodeEditable={false}
                onChange={handleNumberChange}
              />
            </Form.Field>
            <Form.Field
              width={8}
              control={Select}
              label={t('common.role')}
              required
              value={role}
              onChange={(e, data) => setRole(data.value)}
              options={roleOptions}
              placeholder={t('common.role')}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary disabled={!isFormValid} loading={isLoading} color="blue" size="big" onClick={handleAddClient}>
          {t('actions.add')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
