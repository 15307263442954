import React from 'react';
import {Icon, Menu, Table} from 'semantic-ui-react';

const ROWS_PER_PAGE = 10;

class Pagination extends React.Component {
  goto = (page) => (e) => {
    e.preventDefault();

    this.props.onPageSelected(page);
  };

  render() {
    const {currentPage, start, end, total} = this.props;

    return (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="5">
            {start} - {end} from {total}
            <Menu floated="right" pagination>
              {start !== 1 && (
                <Menu.Item as="a" onClick={this.goto(currentPage - 1)} icon>
                  <Icon name="chevron left" />
                </Menu.Item>
              )}
              {end !== total && (
                <Menu.Item onClick={this.goto(currentPage + 1)} as="a" icon>
                  <Icon name="chevron right" />
                </Menu.Item>
              )}
            </Menu>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
  }
}

Pagination.getLimit = () => ROWS_PER_PAGE;

export default Pagination;
