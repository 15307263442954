/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MarkNotificationsReadInput = {|
  clientMutationId?: ?string,
  notificationIds?: ?$ReadOnlyArray<?string>,
  markAllAsRead?: ?boolean,
|};
export type MarkNotificationsReadMutationVariables = {|
  input: MarkNotificationsReadInput
|};
export type MarkNotificationsReadMutationResponse = {|
  +markNotificationsRead: ?{|
    +viewer: ?{|
      +unreadNotificationsCount?: ?number
    |},
    +notifications: ?$ReadOnlyArray<?{|
      +id: string,
      +isRead: ?boolean,
    |}>,
  |}
|};
export type MarkNotificationsReadMutation = {|
  variables: MarkNotificationsReadMutationVariables,
  response: MarkNotificationsReadMutationResponse,
|};
*/


/*
mutation MarkNotificationsReadMutation(
  $input: MarkNotificationsReadInput!
) {
  markNotificationsRead(input: $input) {
    viewer {
      __typename
      ... on Provider {
        unreadNotificationsCount
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
    notifications {
      id
      isRead
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unreadNotificationsCount",
      "storageKey": null
    }
  ],
  "type": "Provider",
  "abstractKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Notification",
  "kind": "LinkedField",
  "name": "notifications",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRead",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkNotificationsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkNotificationsReadPayload",
        "kind": "LinkedField",
        "name": "markNotificationsRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkNotificationsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MarkNotificationsReadPayload",
        "kind": "LinkedField",
        "name": "markNotificationsRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9352855b0275a0e3d99ca375e4d6f9b6",
    "id": null,
    "metadata": {},
    "name": "MarkNotificationsReadMutation",
    "operationKind": "mutation",
    "text": "mutation MarkNotificationsReadMutation(\n  $input: MarkNotificationsReadInput!\n) {\n  markNotificationsRead(input: $input) {\n    viewer {\n      __typename\n      ... on Provider {\n        unreadNotificationsCount\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    notifications {\n      id\n      isRead\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ceae4684fee287e39895630e221ccab2';

module.exports = node;
