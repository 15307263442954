import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';
import {addEdgeToConnection} from '../helpers/SharedUpdater';

const mutation = graphql`
  mutation AddAppointmentMutation($input: AddAppointmentInput!) {
    addAppointment(input: $input) {
      appointmentEdge {
        node {
          id
          rowId
          serviceId
          service {
            title
          }
          startAt
          isRecurring
          capacity
          endAt
          clientId
          providerId
          status
          provider {
            rowId
            id
            name
          }
          client {
            id
            name
          }
        }
      }
    }
  }
`;

const AddAppointmentMutation = (payload, success, failure) => {
  const {input, viewer, connectionName} = payload;

  commitMutation(environment, {
    mutation,
    variables: {
      input,
    },
    updater: (store) => {
      // Add to connection
      const payload = store.getRootField('addAppointment');
      if (!payload) {
        return;
      }
      const newEdge = payload.getLinkedRecord('appointmentEdge');
      addEdgeToConnection(store, viewer.id, newEdge, connectionName);
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default AddAppointmentMutation;
