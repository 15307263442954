/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VacationHourView_viewer$ref = any;
export type VacationHourViewQuery_QueryVariables = {|
  vacationHourId?: ?string
|};
export type VacationHourViewQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: VacationHourView_viewer$ref
  |}
|};
export type VacationHourViewQuery_Query = {|
  variables: VacationHourViewQuery_QueryVariables,
  response: VacationHourViewQuery_QueryResponse,
|};
*/


/*
query VacationHourViewQuery_Query(
  $vacationHourId: ID
) {
  viewer {
    __typename
    ...VacationHourView_viewer_2xUryY
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment VacationHourView_viewer_2xUryY on User {
  __isUser: __typename
  ... on Provider {
    id
    settings {
      locale
      id
    }
    vacationHour(id: $vacationHourId) {
      id
      startAt
      endAt
      provider {
        id
        name
      }
    }
    providers(first: 1000) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vacationHourId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VacationHourViewQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "vacationHourId",
                "variableName": "vacationHourId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "VacationHourView_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VacationHourViewQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProviderSettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locale",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "vacationHourId"
                  }
                ],
                "concreteType": "VacationHour",
                "kind": "LinkedField",
                "name": "vacationHour",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Provider",
                    "kind": "LinkedField",
                    "name": "provider",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000
                  }
                ],
                "concreteType": "ProviderConnection",
                "kind": "LinkedField",
                "name": "providers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProviderEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Provider",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v2/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "providers(first:1000)"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23b93009e19dfec0f590e4aba7e0dc82",
    "id": null,
    "metadata": {},
    "name": "VacationHourViewQuery_Query",
    "operationKind": "query",
    "text": "query VacationHourViewQuery_Query(\n  $vacationHourId: ID\n) {\n  viewer {\n    __typename\n    ...VacationHourView_viewer_2xUryY\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment VacationHourView_viewer_2xUryY on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    settings {\n      locale\n      id\n    }\n    vacationHour(id: $vacationHourId) {\n      id\n      startAt\n      endAt\n      provider {\n        id\n        name\n      }\n    }\n    providers(first: 1000) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '27b5a460f4ce404ee9143b7b2fd9e11e';

module.exports = node;
