import React from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import {get} from 'lodash';
import {useTheme} from '@mui/material/styles';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 85px;
`;

const CellContainer = styled.div`
  height: 60px;
  border: solid gray 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-width: 85px;
`;

const AppointmentContainer = styled.div`
  position: absolute;
  top: ${(props) => `${props.top}px`};
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.width}px`};
  cursor: pointer;
  border-radius: 8px;
  padding: 4px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${(props) => props.backgroundColor};
`;

const TimeText = styled.div`
  font-size: 12px;
  color: white;
`;

const ClientName = styled.div`
  font-size: 13px;
  color: white;
  line-height: 1;
`;

function getBackgroundColor(theme, appointment) {
  if (appointment.isPartOfRecurringSequence) {
    return theme.palette.success.light;
  }

  const isPaid = Number(appointment.amountPaid) > 0;
  if (isPaid) {
    return theme.palette.success.light;
  }

  return theme.palette.error.light;
}

const ProviderAppointments = ({earliestHour, latestHour, appointments, t, onAppointmentClick, width}) => {
  const time = moment(earliestHour, 'HH:mm:ss');
  const _latestHour = moment(latestHour, 'HH:mm:ss');

  const times = [];

  times.push(time.format('HH:mm'));
  while (time.isBefore(_latestHour)) {
    times.push(time.add(60, 'm').format('HH:mm'));
  }

  const AppointmentView = ({appointment}) => {
    const {startAt, endAt} = appointment;
    const startAtTime = moment(startAt).format('HH:mm:ss');
    // const endAtTime = moment(endAt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss');
    const diff = moment(startAtTime, 'HH:mm:ss').diff(moment(earliestHour, 'HH:mm:ss'), 'minutes');

    const duration = moment(endAt).diff(moment(startAt), 'minutes');

    // const isPaid = Number(appointment.amountPaid) > 0 || appointment.markedAsPaid;

    const theme = useTheme();

    return (
      <AppointmentContainer
        key={appointment.id}
        onClick={() => onAppointmentClick(appointment)}
        top={(diff / 60) * 60 + 275}
        height={(duration / 60) * 60}
        width={width}
        backgroundColor={getBackgroundColor(theme, appointment)}
      >
        <TimeText>
          {moment(startAt).format('HH:mm')} - {moment(endAt).format('HH:mm')}
        </TimeText>
        <ClientName>{get(appointment, 'client.name', t('common.guest'))}</ClientName>
      </AppointmentContainer>
    );
  };

  return (
    <Container>
      {times.map((time) => (
        <CellContainer key={time} />
      ))}
      {(appointments || []).map((appointment) => (
        <AppointmentView key={appointment.id} appointment={appointment} />
      ))}
    </Container>
  );
};

export default ProviderAppointments;
