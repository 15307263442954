/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ServiceRow_service$ref = any;
export type UpdateServiceInput = {|
  clientMutationId?: ?string,
  id: string,
  title?: ?string,
  description?: ?string,
  displayPrice?: ?boolean,
  shortDescription?: ?string,
  duration?: ?number,
  categoryId?: ?string,
  capacity?: ?number,
  providerIds?: ?$ReadOnlyArray<?string>,
  price?: ?number,
  visibleRangeMax?: ?number,
  isActive?: ?boolean,
  recurringTimeSlots?: ?$ReadOnlyArray<?any>,
|};
export type UpdateServiceMutationVariables = {|
  input: UpdateServiceInput
|};
export type UpdateServiceMutationResponse = {|
  +updateService: ?{|
    +service: ?{|
      +isActive: ?boolean,
      +id: string,
      +title: ?string,
      +description: ?string,
      +shortDescription: ?string,
      +duration: ?number,
      +coverPhotoUrl: ?string,
      +capacity: ?number,
      +price: ?number,
      +visibleRangeMax: ?number,
      +providerIds: ?$ReadOnlyArray<?number>,
      +providers: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +rowId: number,
            +id: string,
            +name: ?string,
            +phoneNumber: ?string,
          |}
        |}>
      |},
      +$fragmentRefs: ServiceRow_service$ref,
    |}
  |}
|};
export type UpdateServiceMutation = {|
  variables: UpdateServiceMutationVariables,
  response: UpdateServiceMutationResponse,
|};
*/


/*
mutation UpdateServiceMutation(
  $input: UpdateServiceInput!
) {
  updateService(input: $input) {
    service {
      isActive
      id
      title
      description
      shortDescription
      duration
      coverPhotoUrl
      capacity
      price
      visibleRangeMax
      providerIds
      providers(first: 1000) {
        edges {
          node {
            rowId
            id
            name
            phoneNumber
          }
        }
      }
      ...ServiceRow_service
    }
  }
}

fragment ServiceRow_service on Service {
  id
  title
  description
  duration
  capacity
  isActive
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverPhotoUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibleRangeMax",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerIds",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "ProviderConnection",
  "kind": "LinkedField",
  "name": "providers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProviderEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Provider",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowId",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "providers(first:1000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateServiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateServicePayload",
        "kind": "LinkedField",
        "name": "updateService",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Service",
            "kind": "LinkedField",
            "name": "service",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ServiceRow_service"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateServiceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateServicePayload",
        "kind": "LinkedField",
        "name": "updateService",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Service",
            "kind": "LinkedField",
            "name": "service",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "458d370cc32901d3088207701cdaafbf",
    "id": null,
    "metadata": {},
    "name": "UpdateServiceMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateServiceMutation(\n  $input: UpdateServiceInput!\n) {\n  updateService(input: $input) {\n    service {\n      isActive\n      id\n      title\n      description\n      shortDescription\n      duration\n      coverPhotoUrl\n      capacity\n      price\n      visibleRangeMax\n      providerIds\n      providers(first: 1000) {\n        edges {\n          node {\n            rowId\n            id\n            name\n            phoneNumber\n          }\n        }\n      }\n      ...ServiceRow_service\n    }\n  }\n}\n\nfragment ServiceRow_service on Service {\n  id\n  title\n  description\n  duration\n  capacity\n  isActive\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '62c29863f4d4f80b9d6207c6888bbcbd';

module.exports = node;
