/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AppBar_viewer$ref: FragmentReference;
declare export opaque type AppBar_viewer$fragmentType: AppBar_viewer$ref;
export type AppBar_viewer = {|
  +unreadNotificationsCount?: ?number,
  +role?: ?{|
    +sets: ?any
  |},
  +$refType: AppBar_viewer$ref,
|};
export type AppBar_viewer$data = AppBar_viewer;
export type AppBar_viewer$key = {
  +$data?: AppBar_viewer$data,
  +$fragmentRefs: AppBar_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppBar_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unreadNotificationsCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sets",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
// prettier-ignore
(node/*: any*/).hash = '8eed4d088abb20a9644f991af0ad9744';

module.exports = node;
