/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrganizationSettings_settings$ref: FragmentReference;
declare export opaque type OrganizationSettings_settings$fragmentType: OrganizationSettings_settings$ref;
export type OrganizationSettings_settings = {|
  +id: string,
  +locale: ?string,
  +country: ?string,
  +currency: ?string,
  +billingName: ?string,
  +billingVAT: ?string,
  +billingPOI: ?string,
  +billingAddress: ?{|
    +street: ?string,
    +city: ?string,
    +country: ?string,
    +zipCode: ?string,
  |},
  +$refType: OrganizationSettings_settings$ref,
|};
export type OrganizationSettings_settings$data = OrganizationSettings_settings;
export type OrganizationSettings_settings$key = {
  +$data?: OrganizationSettings_settings$data,
  +$fragmentRefs: OrganizationSettings_settings$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettings_settings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locale",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingVAT",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingPOI",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BillingAddress",
      "kind": "LinkedField",
      "name": "billingAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zipCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationSettings",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '81b4cca8077aa7241a2a4f49508b013c';

module.exports = node;
