import graphql from 'babel-plugin-relay/macro';
import moment from 'moment';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Table, Button} from 'semantic-ui-react';

class VacationHourRow extends React.Component {
  handleDeleteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onDeleteClick(this.props.vacationHour);
  };

  render() {
    const {id, provider, startAt, endAt} = this.props.vacationHour;

    return (
      <Table.Row key={id} onClick={() => this.props.onRowClick(this.props.vacationHour)}>
        <Table.Cell>{provider.name}</Table.Cell>
        <Table.Cell>{moment(startAt).format('MMM Do YYYY HH:mm')}</Table.Cell>
        <Table.Cell>{moment(endAt).format('MMM Do YYYY HH:mm')}</Table.Cell>
        <Table.Cell collapsing>
          <Button size="mini" circular icon="close" onClick={this.handleDeleteClick} />
        </Table.Cell>
        {/* <Modal open={this.state.isModalOpen}>
          <Header content="Delete Vacation Hour" />
          <Modal.Content>Are you sure you want to delete this vacation hour?</Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({isModalOpen: false})} color="red">
              <Icon name="remove" /> No
            </Button>
            <Button onClick={this.handle} color="green">
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal> */}
      </Table.Row>
    );
  }
}

export default createFragmentContainer(VacationHourRow, {
  vacationHour: graphql`
    fragment VacationHourRow_vacationHour on VacationHour {
      id
      provider {
        id
        name
      }
      startAt
      endAt
    }
  `,
});
