/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Personal_viewer$ref: FragmentReference;
declare export opaque type Personal_viewer$fragmentType: Personal_viewer$ref;
export type Personal_viewer = {|
  +id?: string,
  +name?: ?string,
  +email?: ?string,
  +profilePhotoUrl?: ?string,
  +phoneNumber?: ?string,
  +settings?: ?{|
    +locale: ?string
  |},
  +$refType: Personal_viewer$ref,
|};
export type Personal_viewer$data = Personal_viewer;
export type Personal_viewer$key = {
  +$data?: Personal_viewer$data,
  +$fragmentRefs: Personal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Personal_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhotoUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProviderSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locale",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
// prettier-ignore
(node/*: any*/).hash = '9cb9d0a088a3d4fa756a8d553172bdbc';

module.exports = node;
