/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SearchBar_viewer$ref = any;
export type SearchBarRefetchQueryVariables = {|
  text?: ?string,
  limit?: ?number,
  shouldSearch: boolean,
|};
export type SearchBarRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: SearchBar_viewer$ref
  |}
|};
export type SearchBarRefetchQuery = {|
  variables: SearchBarRefetchQueryVariables,
  response: SearchBarRefetchQueryResponse,
|};
*/


/*
query SearchBarRefetchQuery(
  $text: String
  $limit: Int
  $shouldSearch: Boolean!
) {
  viewer {
    __typename
    ...SearchBar_viewer_2qTilP
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment SearchBar_viewer_2qTilP on User {
  __isUser: __typename
  ... on Provider {
    search(text: $text, limit: $limit) @include(if: $shouldSearch) {
      __typename
      ... on Appointment {
        id
        startAt
        provider {
          id
          name
          profilePhotoUrl
        }
        service {
          id
          title
          profilePhotoUrl
        }
        client {
          id
          name
        }
      }
      ... on Client {
        id
        name
        phoneNumber
        lastActiveAt
        profilePhotoUrl
      }
      ... on Service {
        id
        title
        description
        profilePhotoUrl
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldSearch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v3 = {
  "kind": "Variable",
  "name": "limit",
  "variableName": "limit"
},
v4 = {
  "kind": "Variable",
  "name": "text",
  "variableName": "text"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhotoUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchBarRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/),
              {
                "kind": "Variable",
                "name": "shouldSearch",
                "variableName": "shouldSearch"
              },
              (v4/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "SearchBar_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchBarRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "condition": "shouldSearch",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "search",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Provider",
                            "kind": "LinkedField",
                            "name": "provider",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Service",
                            "kind": "LinkedField",
                            "name": "service",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v9/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Client",
                            "kind": "LinkedField",
                            "name": "client",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "Appointment",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "phoneNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastActiveAt",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "type": "Client",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "type": "Service",
                        "abstractKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c995f44243c7980c17446bf9a90b296",
    "id": null,
    "metadata": {},
    "name": "SearchBarRefetchQuery",
    "operationKind": "query",
    "text": "query SearchBarRefetchQuery(\n  $text: String\n  $limit: Int\n  $shouldSearch: Boolean!\n) {\n  viewer {\n    __typename\n    ...SearchBar_viewer_2qTilP\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment SearchBar_viewer_2qTilP on User {\n  __isUser: __typename\n  ... on Provider {\n    search(text: $text, limit: $limit) @include(if: $shouldSearch) {\n      __typename\n      ... on Appointment {\n        id\n        startAt\n        provider {\n          id\n          name\n          profilePhotoUrl\n        }\n        service {\n          id\n          title\n          profilePhotoUrl\n        }\n        client {\n          id\n          name\n        }\n      }\n      ... on Client {\n        id\n        name\n        phoneNumber\n        lastActiveAt\n        profilePhotoUrl\n      }\n      ... on Service {\n        id\n        title\n        description\n        profilePhotoUrl\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'db773b21b2452572590571fb8444d642';

module.exports = node;
