import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation UpdateWorkHoursMutation($input: UpdateWorkHoursInput!) {
    updateWorkHours(input: $input) {
      workHours {
        id
        dayOfTheWeek
        openTime
        closeTime
        isOpen
        providerId
      }
    }
  }
`;

const UpdateWorkHorusMutation = (payload, success, failure) => {
  const {input} = payload;

  commitMutation(environment, {
    mutation,
    variables: {input},
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default UpdateWorkHorusMutation;
