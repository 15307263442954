import Lozenge from '@atlaskit/lozenge';
import {debounce, get} from 'lodash';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import {
  Table,
  Button,
  Input as TextInput,
  Modal,
  Form,
  Divider,
  Label,
  Loader,
  Message,
  Checkbox,
  Grid,
} from 'semantic-ui-react';
import graphql from 'babel-plugin-relay/macro';
import papaparse from 'papaparse';
import {createRefetchContainer} from 'react-relay';
import Container from './Container';
import ClientRow from './ClientRow';
import Pagination from './Pagination';
import {ImportClientsMutation, AddClientMutation} from '../mutations';
import isEmpty from '../helpers/isEmpty';
import {QRCode} from 'react-qr-svg';
import API from '../helpers/api';
import getCountryCodeByIp from '../helpers/getCountryCodeByIp';
import {withTranslation} from 'react-i18next';

const refetchVariables = {
  orderBy: [
    ['createdAt', 'DESC'],
    ['name', 'ASC'],
  ],
  filterBy: {searchWord: null},
  page: 1,
  first: 10,
  limit: Pagination.getLimit(),
  isSingleUse: false,
};

class Clients extends React.Component {
  state = {
    direction: 'ascending',
    searchWord: '',
    selectedClientId: null,
    isImportModalOpen: false,
    data: null,
    dialCode: '',
    errors: false,
    clientName: '',
    clientEmail: '',
    countryCode: null,
    clientPhoneNumber: null,
    errorSendingSMS: false,
    errorAddingClient: false,
    isLoading: false,
    isImportButtonLoading: false,
    isMessageSent: false,
    imported: false,
    inviteClientViaSMS: true,
    importedClients: null,
    isClientAdded: false,
    isInviteLinkCopied: false,
    isInviteClientsModalOpen: false,
    isAddClientModalOpen: false,
    inviteClientsButtonLoading: false,
    addClientButtonLoading: false,
    isPhoneNumberInvalid: true,
    isGenerateQRModalOpen: false,
    isQRSingleUse: false,
  };

  constructor(props) {
    super(props);

    this.delayedSearch = debounce(this.handleSearch, 500);
    this.csvFileInputRef = React.createRef();
  }

  componentDidMount() {
    if (refetchVariables.filterBy.searchWord) {
      this.setState({searchWord: refetchVariables.filterBy.searchWord});
    }
  }

  handlePageSelected = (page) => {
    refetchVariables.page = page;

    this.props.relay.refetch(refetchVariables);
  };

  handleColumnSort = (column) => (e) => {
    e.preventDefault();

    const sortDirection = this.state.direction === 'ascending' ? 'DESC' : 'ASC';

    const orderByNew = [...[[column, sortDirection]], ...refetchVariables.orderBy.filter((v) => v[0] !== column)];

    refetchVariables.orderBy = orderByNew;
    this.props.relay.refetch(refetchVariables);
    this.setState({direction: this.state.direction === 'ascending' ? 'descending' : 'ascending', column: column});
  };

  handleSearch = (e) => {
    const searchWord = e.target.value;

    refetchVariables.filterBy = {searchWord};

    this.props.relay.refetch(refetchVariables);
  };

  onChange = (e) => {
    e.persist();
    this.setState({searchWord: e.target.value});
    this.delayedSearch(e);
  };

  handleInviteClientToggle = (e, data) => {
    this.setState({inviteClientViaSMS: data.checked});
  };

  handleInviteClient = (e) => {
    e.preventDefault();

    this.setState({isLoading: true});

    const {clientPhoneNumber} = this.state;
    const {inviteLink} = this.props.viewer;

    const formatted = '+' + clientPhoneNumber; // NOTE: library does not add + sign to phone number
    const body = JSON.stringify({clientPhoneNumber: formatted, inviteLink});
    API.post('invite/client', {body})
      .then(({json: data, response}) => {
        if (response.ok) {
          window._dcq.push(['track', 'Invited client', {clientPhoneNumber}]);

          this.setState({
            isLoading: false,
            isPhoneNumberInvalid: true,
            isMessageSent: true,
            clientPhoneNumber: this.state.dialCode,
          });
        } else {
          this.setState({
            clientPhoneNumber: this.state.dialCode,
            isPhoneNumberInvalid: true,
            errorSendingSMS: data.error,
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        this.setState({
          clientPhoneNumber: null,
          isPhoneNumberInvalid: true,
          errorSendingSMS: e.message,
          isLoading: false,
        });
      });
  };

  handleRowClick = (id) => this.props.router.push(`/clients/${id}`);

  handleCsvFileUpload = (e) => {
    const fileButton = document.getElementById('csv-input');

    const csvFile = fileButton ? fileButton.files[0] : null;
    this.csvFileInputRef.value = '';

    papaparse.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: ({data}) => {
        // TODO: Skip empty rows
        const missingRequiredData = data.some((value) => isEmpty(value.phoneNumber) || isEmpty(value.name));
        this.setState({data, isImportModalOpen: true, errors: missingRequiredData});
      },
    });
  };

  onNumberChange = (value, country) => {
    const {dialCode} = country;
    this.setState({clientPhoneNumber: value, dialCode, errorSendingSMS: false, isMessageSent: false});
    const regex = /\+?\d{12,13}/g;
    if (regex.test(value)) {
      this.setState({isPhoneNumberInvalid: false});
    } else {
      this.setState({isPhoneNumberInvalid: true});
    }
  };

  renderImportTable = (data) => {
    const {t} = this.props;
    return (
      <Table compact selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>No </Table.HeaderCell>
            <Table.HeaderCell>{t('common.status')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common.name')}</Table.HeaderCell>
            <Table.HeaderCell required>{t('common.phone_number')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common.email')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((value, index) => {
            const missingPhoneNumber = isEmpty(value.phoneNumber);
            const missingClientName = isEmpty(value.name);
            const appearance = missingPhoneNumber || missingClientName ? 'removed' : 'success';
            const statusText = missingPhoneNumber || missingClientName ? t('common.error') : t('common.ok');
            const clientName = get(value, 'name');

            return (
              <Table.Row error={missingPhoneNumber || missingClientName} key={index}>
                <Table.Cell collapsing>{++index}</Table.Cell>
                <Table.Cell collapsing>
                  <Lozenge appearance={appearance}>{statusText}</Lozenge>
                </Table.Cell>
                <Table.Cell>
                  {missingClientName ? (
                    <Lozenge appearance={'removed'}>{t('common.missing_client_name')}</Lozenge>
                  ) : (
                    clientName
                  )}
                </Table.Cell>
                <Table.Cell>
                  {missingPhoneNumber ? (
                    <Lozenge appearance={'removed'}>{t('common.missing_phone_number')}</Lozenge>
                  ) : (
                    value.phoneNumber
                  )}
                </Table.Cell>
                <Table.Cell>{value.email}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  handleInviteOrAddClientModalOpen = async (e, data) => {
    const {name} = data; // name of the button
    if (!this.state.countryCode) {
      // Fetch country code from server
      const loadingButton = `${name}Loading`; // Generate name of the loading button
      this.setState({[loadingButton]: true});
      const countryCode = await getCountryCodeByIp();
      this.setState({countryCode, [loadingButton]: false});
    }

    if (name === 'inviteClientsButton') {
      this.setState({isInviteClientsModalOpen: true});
    } else if (name === 'addClientButton') {
      this.setState({isAddClientModalOpen: true});
    }
  };

  handleCopyToClipboardClick = () => {
    navigator.clipboard.writeText(this.props.viewer.inviteLink).then(() => {
      this.setState({isInviteLinkCopied: true});
    });
  };

  handleImportButtonClicked = () => {
    this.setState({isImportButtonLoading: true});
    const {data} = this.state;

    const onSuccess = (data) => {
      this.setState({isImportButtonLoading: false});

      const {importClients: mutationData} = data;
      const numberOfImportedClients = mutationData.importedClients ? mutationData.importedClients.length : 0;

      window._dcq.push(['track', 'Imported clients', {numberOfImportedClients}]);

      this.setState({
        data: null,
        errors: false,
        imported: true,
        importedClients: numberOfImportedClients,
      });
    };

    const onFailure = (errors) => {
      this.setState({isImportButtonLoading: false});

      // TODO: Handle error message
      console.log('Failure', errors);
    };

    const input = {
      id: this.props.viewer.id,
      clients: data,
    };

    ImportClientsMutation({input}, onSuccess, onFailure);
  };

  handleAddClient = () => {
    this.setState({isLoading: true});
    const {clientName, clientPhoneNumber, dialCode, inviteClientViaSMS, clientEmail} = this.state;

    const formatted = '+' + clientPhoneNumber; // NOTE: library does not add + sign to phone number

    const connectionName = 'Clients_clients';
    const input = {
      name: clientName,
      phoneNumber: formatted,
      inviteClient: inviteClientViaSMS,
      email: clientEmail,
      // placeIds: this.props.viewer.placeIds,
    };

    const onFailure = (errors) => {
      // TODO: Handle error message
      console.log('Failure', errors);
      return this.setState({
        clientName: '',
        clientEmail: '',
        clientPhoneNumber: dialCode,
        errorAddingClient: true,
        isLoading: false,
        isPhoneNumberInvalid: true,
      });
    };

    const onSuccess = (data) => {
      window._dcq.push([
        'track',
        'Added client',
        {
          clientEmail,
          clientName,
          clientPhoneNumber,
          sentSMS: inviteClientViaSMS,
        },
      ]);

      return this.setState({
        isClientAdded: true,
        clientName: '',
        clientEmail: '',
        clientPhoneNumber: dialCode,
        isLoading: false,
        isPhoneNumberInvalid: true,
      });
    };

    const payload = {input, filters: [], connectionName, viewer: this.props.viewer};

    AddClientMutation(payload, onSuccess, onFailure);
  };

  // TODO: Refs might not be the best way to upload files
  handleCSVFileInputRef = (e) => this.csvFileInputeRef.current.click();

  handleModalClose = () => {
    this.csvFileInputRef.value = '';
    this.setState({
      isImportModalOpen: false,
      errors: false,
      data: null,
      imported: false,
      importedClients: null,
    });
  };

  handleQRChange = (e, data) => {
    this.setState({isQRSingleUse: data.checked, isInviteLinkCopied: false});

    refetchVariables.isSingleUse = data.checked;
    setTimeout(() => {
      this.props.relay.refetch(refetchVariables);
    }, 350);
  };

  render() {
    const {t} = this.props;
    const {clients = [], inviteLink} = this.props.viewer;
    const {currentPage, total, start, end} = clients;

    const {
      direction,
      column,
      clientPhoneNumber,
      isImportModalOpen,
      clientName,
      clientEmail,
      errors,
      errorSendingSMS,
      errorAddingClient,
      isClientAdded,
      isAddClientModalOpen,
      imported,
      inviteClientViaSMS,
      importedClients,
      isMessageSent,
      isInviteLinkCopied,
      isLoading,
      isInviteClientsModalOpen,
      isImportButtonLoading,
      isPhoneNumberInvalid,
      isGenerateQRModalOpen,
      data,
      searchWord,
      isQRSingleUse,
    } = this.state;

    return (
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <TextInput
                iconPosition="left"
                icon="users"
                value={searchWord}
                fluid
                placeholder={t('common.search')}
                onChange={this.onChange}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Button
                name="inviteClientsButton"
                loading={this.state.inviteClientsButtonLoading}
                onClick={this.handleInviteOrAddClientModalOpen}
              >
                {t('actions.invite_client')}
              </Button>

              <Modal
                open={isInviteClientsModalOpen}
                onClose={() =>
                  this.setState({isInviteClientsModalOpen: false, isMessageSent: false, errorSendingSMS: false})
                }
                closeIcon
              >
                <Modal.Header>{t('actions.invite_client')}</Modal.Header>
                {isMessageSent && <Message success content={t('common.client_invite_success')} />}
                {errorSendingSMS && <Message error content={t('common.error')} />}

                <Modal.Content>
                  <PhoneInput
                    country={this.state.countryCode}
                    placeholder={t('common.enter_phone_number')}
                    value={clientPhoneNumber}
                    countryCodeEditable={false}
                    onChange={this.onNumberChange}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    disabled={isPhoneNumberInvalid}
                    primary
                    loading={isLoading}
                    color="blue"
                    size="big"
                    onClick={this.handleInviteClient}
                  >
                    {t('actions.invite_client')}
                  </Button>
                </Modal.Actions>
              </Modal>
              <input
                hidden
                ref={(csvFileInputRef) => (this.csvFileInputRef = csvFileInputRef)}
                id="csv-input"
                type="file"
                accept=".csv"
                onChange={this.handleCsvFileUpload}
              />
              <Button htmlFor="csv-input" id="csv" onClick={() => this.csvFileInputRef.click()}>
                {t('actions.import_clients')}
              </Button>
              <Modal closeIcon open={isImportModalOpen} onClose={this.handleModalClose}>
                {/* <Modal.Header>List of clients that will be imported</Modal.Header> */}
                <Modal.Content>
                  {data ? this.renderImportTable(data) : <Loader size="huge">{t('common.loading')}.</Loader>}
                  {imported && (
                    <Message positive>
                      <Message.Header>{t('common.success')}</Message.Header>
                      <p>{t('common.successfully_imported', {number: importedClients})}</p>
                    </Message>
                  )}
                  {errors && (
                    <Message negative>
                      <Message.Header>{t('common.missing_information')}</Message.Header>
                      <p>{t('common.import_error')}</p>
                    </Message>
                  )}
                </Modal.Content>
                <Modal.Actions>
                  {data && (
                    <Button
                      color="green"
                      disabled={isImportButtonLoading}
                      loading={isImportButtonLoading}
                      onClick={this.handleImportButtonClicked}
                    >
                      {t('actions.import')}
                    </Button>
                  )}
                  {data && (
                    <Button
                      onClick={() =>
                        this.setState({
                          isImportModalOpen: false,
                          errors: false,
                          data: null,
                          imported: false,
                          importedClients: null,
                        })
                      }
                    >
                      {t('actions.cancel')}
                    </Button>
                  )}
                </Modal.Actions>
              </Modal>
              <Button onClick={() => this.setState({isGenerateQRModalOpen: true})}>
                {t('actions.generate_qr_and_link')}
              </Button>
              <Button
                name="addClientButton"
                primary
                loading={this.state.addClientButtonLoading}
                onClick={this.handleInviteOrAddClientModalOpen}
              >
                {t('actions.add_client')}
              </Button>
              <Modal
                open={isAddClientModalOpen}
                onClose={() =>
                  this.setState({isAddClientModalOpen: false, isClientAdded: false, errorAddingClient: false})
                }
                closeIcon
              >
                <Modal.Header> {t('actions.add_client')}</Modal.Header>
                {isClientAdded && <Message success content={t('common.client_add_success')} />}
                {errorAddingClient && <Message error content={t('common.error')} />}

                <Modal.Content>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Input
                        type="text"
                        name="name"
                        value={clientName}
                        label={t('common.name')}
                        required
                        onChange={(i, data) =>
                          this.setState({clientName: data.value, isClientAdded: false, errorAddingClient: false})
                        }
                      />
                      <Form.Input
                        type="email"
                        name="email"
                        value={clientEmail}
                        label={t('common.email')}
                        onChange={(i, data) =>
                          this.setState({clientEmail: data.value, isClientAdded: false, errorAddingClient: false})
                        }
                      />
                    </Form.Group>
                  </Form>
                  <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <PhoneInput
                      country={this.state.countryCode}
                      placeholder={t('common.enter_phone_number')}
                      value={clientPhoneNumber}
                      style={{width: '50%', minWidth: 'fit-content'}}
                      countryCodeEditable={false}
                      onChange={this.onNumberChange}
                    />
                    <Checkbox
                      toggle
                      label={t('actions.invite_client_sms')}
                      style={{marginTop: '1.2rem'}}
                      onChange={this.handleInviteClientToggle}
                      checked={inviteClientViaSMS}
                    />
                  </div>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    primary
                    disabled={isPhoneNumberInvalid || !clientName || isLoading}
                    loading={isLoading}
                    color="blue"
                    size="big"
                    onClick={this.handleAddClient}
                  >
                    {t('actions.add_client')}
                  </Button>
                </Modal.Actions>
              </Modal>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          open={isGenerateQRModalOpen}
          onClose={() => this.setState({isGenerateQRModalOpen: false, isQRSingleUse: false, isInviteLinkCopied: false})}
          closeIcon
          style={{width: 300, height: 'auto'}}
        >
          <Modal.Content>
            <Checkbox
              toggle
              label={t('common.qr_single_or_multi')}
              onChange={this.handleQRChange}
              checked={isQRSingleUse}
            />
            <Divider />
            <QRCode bgColor="#FFFFFF" fgColor="#000000" level="Q" style={{flex: 1}} value={inviteLink} />
          </Modal.Content>
          <Modal.Actions>
            <h5 style={{marginLeft: 0, display: 'flex'}}>{t('common.invite_link')}:</h5>
            <TextInput
              style={{width: '100%'}}
              action={{
                color: 'blue',
                icon: 'copy',
                onClick: () => this.handleCopyToClipboardClick(),
              }}
              defaultValue={inviteLink}
              readOnly={true}
            />
            {isInviteLinkCopied && (
              <Label pointing="above" prompt>
                Copied!
              </Label>
            )}
          </Modal.Actions>
        </Modal>
        <Table sortable padded selectable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={column === 'id' ? direction : null} onClick={this.handleColumnSort('id')}>
                {t('common.client_id')}
              </Table.HeaderCell>
              <Table.HeaderCell sorted={column === 'name' ? direction : null} onClick={this.handleColumnSort('name')}>
                {t('common.name')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'lastActiveAt' ? direction : null}
                onClick={this.handleColumnSort('lastActiveAt')}
              >
                {t('common.last_active')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'createdAt' ? direction : null}
                onClick={this.handleColumnSort('createdAt')}
              >
                {t('common.client_since')}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'totalAppointmentsCount' ? direction : null}
                onClick={this.handleColumnSort('totalAppointmentsCount')}
              >
                {t('common.total_appointments')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {clients.edges.map(({node: client}) => (
              <ClientRow client={client} key={client.id} onRowClick={this.handleRowClick} />
            ))}
          </Table.Body>
          {total > Pagination.getLimit() && (
            <Pagination
              currentPage={currentPage}
              start={start}
              end={end}
              total={total}
              onPageSelected={this.handlePageSelected}
            />
          )}
        </Table>
      </Container>
    );
  }
}

const ClientsQuery = graphql`
  query ClientsRefetchQuery(
    $filterBy: ClientsFilterInput
    $orderBy: [[String]]
    $page: Int
    $first: Int
    $limit: Int
    $isSingleUse: Boolean
  ) {
    viewer {
      ...Clients_viewer
        @arguments(
          filterBy: $filterBy
          orderBy: $orderBy
          page: $page
          first: $first
          limit: $limit
          isSingleUse: $isSingleUse
        )
    }
  }
`;

const ClientsContainer = createRefetchContainer(
  withTranslation()(Clients),
  {
    viewer: graphql`
      fragment Clients_viewer on User
      @argumentDefinitions(
        filterBy: {type: "ClientsFilterInput"}
        orderBy: {type: "[[String]]"}
        page: {type: "Int"}
        first: {type: "Int"}
        limit: {type: "Int"}
        isSingleUse: {type: "Boolean"}
      ) {
        ... on Provider {
          id
          rowId
          #placeIds
          inviteLink(isSingleUse: $isSingleUse)
          clients(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit)
            @connection(key: "Clients_clients", filters: []) {
            edges {
              node {
                id
                ...ClientRow_client
              }
            }
            currentPage
            start
            end
            total
          }
        }
      }
    `,
  },
  ClientsQuery,
);

ClientsContainer.getVariables = (props) => refetchVariables;

export default ClientsContainer;
