import {Header} from 'semantic-ui-react';
import {useTranslation} from 'react-i18next';

const AppointmentPaymentDetails = ({payment}) => {
  const {t} = useTranslation();

  if (payment.type !== 'refund') {
    return (
      <Header as="h4" style={{marginTop: 10}}>
        {t('common.payment_details')}
        <Header.Subheader>
          {t('common.status')}: {payment.status}
        </Header.Subheader>
        <Header.Subheader>
          {t('common.amount')}: {payment.amount} {payment.currency}
        </Header.Subheader>
        <Header.Subheader>
          {t('common.payment_method')}: {payment.paymentMethod}
        </Header.Subheader>
      </Header>
    );
  }
  return null;
};

export default AppointmentPaymentDetails;
