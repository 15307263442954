/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type General_viewer$ref = any;
export type CancelSubscriptionInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type CancelSubscriptionMutationVariables = {|
  input: CancelSubscriptionInput
|};
export type CancelSubscriptionMutationResponse = {|
  +cancelSubscription: ?{|
    +viewer: ?{|
      +$fragmentRefs: General_viewer$ref
    |}
  |}
|};
export type CancelSubscriptionMutation = {|
  variables: CancelSubscriptionMutationVariables,
  response: CancelSubscriptionMutationResponse,
|};
*/


/*
mutation CancelSubscriptionMutation(
  $input: CancelSubscriptionInput!
) {
  cancelSubscription(input: $input) {
    viewer {
      __typename
      ...General_viewer
      ... on Node {
        __isNode: __typename
        id
      }
    }
  }
}

fragment General_viewer on User {
  __isUser: __typename
  ... on Provider {
    clients {
      total
    }
    services {
      total
    }
    organization {
      pricingPlans {
        id
        name
        period
        currency
        tiers {
          value
          minQuantity
          maxQuantity
        }
      }
      providers {
        total
      }
      subscription {
        id
        status
        validTo
        currentTier {
          value
        }
        pricingPlan {
          name
          period
          currency
          id
        }
        paymentMethod {
          type
          brand
          last4
          id
        }
        lastPayment {
          createdAt
          amount
          currency
          invoiceNumber
          invoiceLink
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "period",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelSubscriptionPayload",
        "kind": "LinkedField",
        "name": "cancelSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "General_viewer"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelSubscriptionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelSubscriptionPayload",
        "kind": "LinkedField",
        "name": "cancelSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isUser"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientConnection",
                    "kind": "LinkedField",
                    "name": "clients",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceConnection",
                    "kind": "LinkedField",
                    "name": "services",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PricingPlan",
                        "kind": "LinkedField",
                        "name": "pricingPlans",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PricingPlanTier",
                            "kind": "LinkedField",
                            "name": "tiers",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "minQuantity",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maxQuantity",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProviderConnection",
                        "kind": "LinkedField",
                        "name": "providers",
                        "plural": false,
                        "selections": (v2/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Subscription",
                        "kind": "LinkedField",
                        "name": "subscription",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "validTo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PricingPlanTier",
                            "kind": "LinkedField",
                            "name": "currentTier",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PricingPlan",
                            "kind": "LinkedField",
                            "name": "pricingPlan",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "OrganizationPaymentMethod",
                            "kind": "LinkedField",
                            "name": "paymentMethod",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "brand",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "last4",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SubscriptionPayment",
                            "kind": "LinkedField",
                            "name": "lastPayment",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              },
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "invoiceNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "invoiceLink",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Provider",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eeb8c8e8d0a45f05f2075ad3f001a3f4",
    "id": null,
    "metadata": {},
    "name": "CancelSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation CancelSubscriptionMutation(\n  $input: CancelSubscriptionInput!\n) {\n  cancelSubscription(input: $input) {\n    viewer {\n      __typename\n      ...General_viewer\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment General_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    clients {\n      total\n    }\n    services {\n      total\n    }\n    organization {\n      pricingPlans {\n        id\n        name\n        period\n        currency\n        tiers {\n          value\n          minQuantity\n          maxQuantity\n        }\n      }\n      providers {\n        total\n      }\n      subscription {\n        id\n        status\n        validTo\n        currentTier {\n          value\n        }\n        pricingPlan {\n          name\n          period\n          currency\n          id\n        }\n        paymentMethod {\n          type\n          brand\n          last4\n          id\n        }\n        lastPayment {\n          createdAt\n          amount\n          currency\n          invoiceNumber\n          invoiceLink\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e5916af918bfe1c111c6f905ad29118';

module.exports = node;
