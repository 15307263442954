/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Services_viewer$ref: FragmentReference;
declare export opaque type Services_viewer$fragmentType: Services_viewer$ref;
export type Services_viewer = {|
  +id?: string,
  +$refType: Services_viewer$ref,
|};
export type Services_viewer$data = Services_viewer;
export type Services_viewer$key = {
  +$data?: Services_viewer$data,
  +$fragmentRefs: Services_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Services_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
// prettier-ignore
(node/*: any*/).hash = '658f6cb000edd16d786aacd73a47ddc3';

module.exports = node;
