import graphql from 'babel-plugin-relay/macro';

const ClientsQuery = graphql`
  query ClientsQuery_Query(
    $filterBy: ClientsFilterInput
    $orderBy: [[String]]
    $limit: Int
    $page: Int
    $first: Int
    $isSingleUse: Boolean
  ) {
    viewer {
      ...Clients_viewer
        @arguments(
          filterBy: $filterBy
          orderBy: $orderBy
          limit: $limit
          page: $page
          first: $first
          isSingleUse: $isSingleUse
        )
    }
  }
`;

export default ClientsQuery;
