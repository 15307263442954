import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {createRefetchContainer} from 'react-relay';
import {Table} from 'semantic-ui-react';
import {withRelay} from '../../../src/relay';
import HistoryRow from './HistoryRow';
import Pagination from '../Pagination';
import {withTranslation} from 'react-i18next';

const refetchVariables = {
  orderBy: [
    ['createdAt', 'DESC'],
    ['amount', 'ASC'],
  ],
  page: 1,
  first: 10,
  limit: Pagination.getLimit(),
};

class History extends React.Component {
  state = {
    direction: 'ascending',
  };

  handlePageSelected = (page) => {
    refetchVariables.page = page;

    this.props.relay.refetch(refetchVariables);
  };

  handleSort = (column) => (e) => {
    e.preventDefault();

    const sortDirection = this.state.direction === 'ascending' ? 'DESC' : 'ASC';

    const orderByNew = [...[[column, sortDirection]], ...refetchVariables.orderBy.filter((v) => v[0] !== column)];

    refetchVariables.orderBy = orderByNew;
    this.props.relay.refetch(refetchVariables);
    this.setState({direction: this.state.direction === 'ascending' ? 'descending' : 'ascending', column: column});
  };

  render() {
    const {direction, column} = this.state;
    const {t, viewer} = this.props;
    const {payments} = viewer.organization;
    const {currentPage, total, start, end} = payments;

    return (
      <div>
        <Table sortable padded celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'createdAt' ? direction : null}
                onClick={this.handleSort('createdAt')}
              >
                {t('common.date')}
              </Table.HeaderCell>
              <Table.HeaderCell>{t('common.payment_method')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.invoice_summary')}</Table.HeaderCell>
              <Table.HeaderCell sorted={column === 'amount' ? direction : null} onClick={this.handleSort('amount')}>
                {t('common.amount')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {!!payments?.edges?.length && (
            <Table.Body>
              {payments.edges.map(({node: payment}) => (
                <HistoryRow payment={payment} key={payment.id} />
              ))}
            </Table.Body>
          )}
          {total > Pagination.getLimit() && (
            <Pagination
              currentPage={currentPage}
              start={start}
              end={end}
              total={total}
              onPageSelected={this.handlePageSelected}
            />
          )}
        </Table>
      </div>
    );
  }
}

const HistoryQuery = graphql`
  query HistoryRefetchQuery($orderBy: [[String]], $page: Int, $first: Int, $limit: Int) {
    viewer {
      ...History_viewer @arguments(orderBy: $orderBy, page: $page, first: $first, limit: $limit)
    }
  }
`;

const HistoryContainer = createRefetchContainer(
  withTranslation()(History),
  {
    viewer: graphql`
      fragment History_viewer on User
      @argumentDefinitions(
        orderBy: {type: "[[String]]"}
        page: {type: "Int"}
        first: {type: "Int"}
        limit: {type: "Int"}
      ) {
        ... on Provider {
          organization {
            payments(orderBy: $orderBy, page: $page, first: $first, limit: $limit)
              @connection(key: "History_payments") {
              edges {
                node {
                  id
                  ...HistoryRow_payment
                }
              }
              currentPage
              start
              end
              total
            }
          }
        }
      }
    `,
  },
  HistoryQuery,
);

HistoryContainer.getVariables = (props) => refetchVariables;
export default withRelay(HistoryContainer, HistoryQuery);
