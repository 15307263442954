import graphql from 'babel-plugin-relay/macro';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/bg';
import 'moment/locale/it';
import React from 'react';
import * as Sentry from '@sentry/react';
import {createRefetchContainer} from 'react-relay';
import styled from 'styled-components';
import 'semantic-ui-css/semantic.min.css';
import '../../node_modules/react-vis/dist/style.css';
import CookieBanner from './CookieBanner';
import NotificationSidebar from './NotificationsSidebar';
import AppointmentModal from './Calendar/AppointmentModal';
import RecurringAppointmentModal from './Calendar/RecurringAppointmentModal';
import i18n from './../i18n';
import {getCookieDomain} from '../helpers';
import toGlobalId from '../helpers/toGlobalId';
import {ModalContext} from './ModalContext';
import {Sidebar, Button, Modal} from 'semantic-ui-react';
import {I18nextProvider, withTranslation} from 'react-i18next';
import {getExistingPushSubscription, subscribeUser, sendSubscription} from '../subscription';

import AppBar from './AppBar';

const Container = styled.div`
  height: 100vh;
  flex: 1;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  margin: 80px;
  height: calc(100vh - 160px);
  overflow: 'auto';
`;

class App extends React.Component {
  state = {
    isSidebarVisible: false,
    isAppointmentModalOpen: false,
    appointmentId: null,
    isWebPushModalOpen: false,
    isLoading: false,
    isRecurringAppointmentModalOpen: false,
  };

  // TODO: Handle opening recurring appointment from sidebar with all the attendants
  openModal = (appointmentId, isPartOfRecurringSequence) => {
    if (isPartOfRecurringSequence) {
      return this.setState({
        appointmentId: toGlobalId('Appointment', appointmentId), // Must generate relay ID from recurring appointment id
        isRecurringAppointmentModalOpen: true,
      });
    }
    this.setState({appointmentId, isAppointmentModalOpen: true});
  };

  handleAppointmentModalClose = () => {
    this.setState({isAppointmentModalOpen: false, appointmentId: null});
  };

  async componentDidMount() {
    const pushSubscription = await getExistingPushSubscription();
    if (!pushSubscription) {
      localStorage.setItem('hasPushSubscription', false);
      // this.setState({isWebPushModalOpen: true});
    } else {
      // send push subscription to server in order to keep in in sync always
      sendSubscription(pushSubscription);
    }
  }

  handleBellIconClick = () => {
    this.setState({isSidebarVisible: !this.state.isSidebarVisible});
  };

  handleWebPushModalClose = () => {
    this.setState({isWebPushModalOpen: false});
  };

  handleWebPushModalOpen = () => {
    // handle logic of opening modal
    this.setState({isWebPushModalOpen: true});
  };

  handleEnableWebPushNotificaitons = () => {
    const isUserSubscribbed = subscribeUser();
    this.setState({isWebPushModalOpen: false});

    if (isUserSubscribbed) {
      localStorage.setItem('hasPushSubscription', true);
    }
  };

  render() {
    const {children, viewer, t} = this.props;
    if (!viewer || viewer.__typename === 'Guest') {
      this.props.router.replace('/logout');

      return null;
    }

    const {settings} = viewer;
    const {locale} = settings;
    const {
      isSidebarVisible,
      appointmentId,
      isWebPushModalOpen,
      isRecurringAppointmentModalOpen,
      isAppointmentModalOpen,
    } = this.state;

    if (i18n.language !== locale) {
      i18n.changeLanguage(locale, (err, t) => {
        if (err) {
          Sentry.captureException(err);
          window.alert(t('common.something_went_wrong'));
        } else {
          const domain = getCookieDomain();
          Cookies.set('USER_LOCALE', locale, {expires: 365, domain});
        }
      });
    }

    if (moment.locale() !== locale) {
      moment.locale(locale);
    }

    return (
      <Container>
        <ModalContext.Provider value={{openModal: this.openModal}}>
          <AppBar {...this.props} onBellIconClick={this.handleBellIconClick} />
          <I18nextProvider i18n={i18n}>
            <Sidebar.Pushable>
              <NotificationSidebar
                router={this.props.router}
                isSidebarVisible={isSidebarVisible}
                onClose={() => this.setState({isSidebarVisible: false})}
              />
              <Sidebar.Pusher style={{overflow: 'auto'}}>
                {appointmentId && (
                  <AppointmentModal
                    isOpen={isAppointmentModalOpen}
                    appointmentIds={[appointmentId]}
                    onClose={this.handleAppointmentModalClose}
                  />
                )}
                <Modal
                  open={isWebPushModalOpen}
                  onClose={this.handleWebPushModalClose}
                  onOpen={this.handleWebPushModalOpen}
                >
                  <Modal.Header>{t('actions.enable_web_notifications')} </Modal.Header>
                  <Modal.Content>{t('common.web_push_description')}</Modal.Content>
                  <Modal.Actions>
                    <Button color="blue" onClick={this.handleEnableWebPushNotificaitons}>
                      {t('actions.enable')}
                    </Button>
                    <Button onClick={this.handleWebPushModalClose}>{t('actions.not_now')}</Button>
                  </Modal.Actions>
                </Modal>
                {appointmentId && isRecurringAppointmentModalOpen && (
                  <RecurringAppointmentModal
                    isOpen={isRecurringAppointmentModalOpen}
                    appointmentId={appointmentId}
                    onClose={this.handleAppointmentModalClose}
                  />
                )}

                <ContentContainer>
                  {React.Children.map(children, (child) => React.cloneElement(child, {user: viewer}))}
                </ContentContainer>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
            <CookieBanner />
          </I18nextProvider>
        </ModalContext.Provider>
      </Container>
    );
  }
}

const AppQuery = graphql`
  query AppRefetchQuery($appointmentId: ID) {
    viewer {
      ... on Provider {
        ...App_viewer @arguments(appointmentId: $appointmentId)
      }
    }
  }
`;

const AppContainer = createRefetchContainer(
  withTranslation()(App),
  {
    viewer: graphql`
      fragment App_viewer on User @argumentDefinitions(appointmentId: {type: "ID"}) {
        __typename
        ... on Provider {
          ...AppBar_viewer
          settings {
            locale
          }
        }
      }
    `,
  },
  AppQuery,
);

export default AppContainer;
