import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {get, isEmpty, cloneDeep, set} from 'lodash';
import {withTranslation} from 'react-i18next';
import {createFragmentContainer} from 'react-relay';
import {Form, Icon, Message, Button} from 'semantic-ui-react';
import AddressSearchInput from '../AddressSearchInput';
import {CoverPhotoButton, CoverIcon, ProfilePhotoButton, AvatarIcon} from './components';
import isMatchWith from '../../helpers/isMatchWith';
import {UpdatePlaceMutation, UpdatePlacesMutation} from '../../mutations';

class Places extends React.Component {
  state = {
    places: [],
    error: false,
    errorContent: null,
    isLoading: false,
  };

  constructor(props) {
    super(props);

    const {places} = this.props.organization;

    this.state.places = (places || []).map((place) => ({id: place.id}));

    this.coverPhotoInputRef = [];
    this.profilePhotoInputRef = [];
  }

  resetForm = () => {
    this.setState({
      places: (this.props.organization.places || []).map((place) => ({
        id: place.id,
      })),
      error: null,
    });
  };

  hasUnsavedChanges = () => {
    const {places: propsValues} = this.props.organization;
    const {places: stateValues} = this.state;

    return !isMatchWith(propsValues, stateValues);
  };

  handleAddressChange = (e, data, fieldName) => {
    if (data.place) {
      const newValue = {
        latitude: Number(data.place.latitude),
        longitude: Number(data.place.longitude),
        placeId: data.place.placeId,
        formatted: data.value,
      };
      this.handleFieldChange(fieldName, {name: data.name, value: newValue});
    } else {
      this.handleFieldChange(fieldName, {name: data.name, value: {formatted: data.value}});
    }
  };

  handleCoverPhotoInputRef = (e, placeId) => {
    this.coverPhotoInputRef[placeId].click();
  };

  handleProfilePhotoInputRef = (e, placeId) => {
    this.profilePhotoInputRef[placeId].click();
  };

  getFieldValue = (fieldName) =>
    isEmpty(get(this.state, fieldName)) && isEmpty(get(this.state, fieldName)) && get(this.state, fieldName) !== ''
      ? get(this.props.organization, fieldName)
      : get(this.state, fieldName);

  handleFieldChange = (i, data) => {
    const {error} = this.state;

    if (error) {
      this.setState({errorContent: null, error: false});
    }

    // TODO: Rewrite and export to a separate file
    const isObjectDeep = data.name.indexOf('.') !== -1 || data.name.indexOf('[') !== -1;
    if (isObjectDeep) {
      const _state = cloneDeep(this.state);
      set(_state, data.name, data.value);
      this.setState(_state);
    } else {
      this.setState({[data.name]: data.value});
    }
  };

  handleSubmit = () => {
    this.setState({isLoading: true});
    const {t} = this.props;

    const onSuccess = (data) => {
      this.setState({isLoading: false});
      this.resetForm();
    };

    const onFailure = (errors) => {
      // TODO: Handle errors
      this.setState({
        isLoading: false,
        error: true,
        errorContent: `${t('common.something_went_wrong')} ${t('common.try_again')}`,
      });
    };

    const {places} = this.state;

    let error = null;
    let errorContent = null;
    const mappedPlaces = places.map((place) => {
      // Sanitize data
      if (place.email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!regex.test(place.email)) {
          error = true;
          errorContent = t('common.email_error');
        }
      }

      if (place.phoneNumber) {
        if (place.phoneNumber.match(/\d/g).length < 10) {
          error = true;
          errorContent = t('common.phone_number_error');
        }
        place.phoneNumber = place.phoneNumber.trim().replace(/[^+\d]+/g, '');
      }
      return place;
    });

    if (error) {
      return this.setState({error: true, errorContent, isLoading: false});
    }

    const input = {
      places: mappedPlaces,
    };

    UpdatePlacesMutation({input}, onSuccess, onFailure);
  };

  handleCoverPhotoUpload = (placeId) => {
    const fileButton = document.getElementById(`cover-input-${placeId}`);
    const coverPhoto = fileButton ? fileButton.files[0] : null;

    const onSuccess = (data) => {
      this.coverPhotoInputRef[placeId].value = '';
      this.setState({isLoading: false, errorContent: null, error: false});
    };

    const onFailure = (errors) => {
      const {t} = this.props;
      this.coverPhotoInputRef[placeId].value = '';

      this.setState({
        isLoading: false,
        error: true,
        errorContent: `${t('common.something_went_wrong')} ${t('common.try_again')}`,
      });
    };

    UpdatePlaceMutation({input: {id: placeId}, uploadables: {coverPhoto}}, onSuccess, onFailure);
  };

  handleProfilePhotoUpload = (placeId) => {
    const {t} = this.props;

    const fileButton = document.getElementById(`avatar-input-${placeId}`);
    const profilePhoto = fileButton ? fileButton.files[0] : null;

    const onSuccess = (data) => {
      this.profilePhotoInputRef[placeId].value = '';
      this.setState({isLoading: false, error: false, errorContent: null});
    };

    const onFailure = (errors) => {
      this.profilePhotoInputRef[placeId].value = '';
      this.setState({
        isLoading: false,
        error: true,
        errorContent: `${t('common.something_went_wrong')} ${t('common.try_again')}`,
      });
    };

    UpdatePlaceMutation({input: {id: placeId}, uploadables: {profilePhoto}}, onSuccess, onFailure);
  };

  render() {
    const {error, isLoading, errorContent} = this.state;
    const {t, organization} = this.props;
    const {places} = organization;

    const unsavedChanges = this.hasUnsavedChanges();

    return (
      <div style={{paddingBottom: 20}}>
        {places.map((place, index) => {
          return (
            <div key={index} style={{position: 'relative'}}>
              <CoverPhotoButton
                src={place.coverPhotoUrl}
                id={`cover-${place.id}`}
                type="button"
                onClick={(e) => this.handleCoverPhotoInputRef(e, place.id)}
                htmlFor={`cover-input-${place.id}`}
              >
                <CoverIcon>
                  <Icon style={{color: 'white'}} name="upload" />
                </CoverIcon>
              </CoverPhotoButton>
              <input
                hidden
                ref={(ref) => (this.coverPhotoInputRef[place.id] = ref)}
                id={`cover-input-${place.id}`}
                type="file"
                accept="image/*"
                onChange={() => this.handleCoverPhotoUpload(place.id)}
              />
              <ProfilePhotoButton
                src={place.profilePhotoUrl}
                id={`avatar-${place.id}`}
                type="button"
                onClick={(e) => this.handleProfilePhotoInputRef(e, place.id)}
                htmlFor={`avatar-input-${place.id}`}
              >
                <AvatarIcon>
                  <Icon name="upload" style={{color: 'white'}} />
                </AvatarIcon>
              </ProfilePhotoButton>
              <input
                hidden
                id={`avatar-input-${place.id}`}
                ref={(ref) => (this.profilePhotoInputRef[place.id] = ref)}
                type="file"
                accept="image/*"
                onChange={() => this.handleProfilePhotoUpload(place.id)}
              />
              <Form key={index} error={error} style={{marginTop: 40}}>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    fluid
                    name={`places[${index}].name`}
                    label={t('common.name')}
                    onChange={this.handleFieldChange}
                    value={this.getFieldValue(`places[${index}].name`)}
                  />
                  <Form.Input
                    required
                    fluid
                    name={`places[${index}].email`}
                    type="email"
                    label={t('common.email')}
                    onChange={this.handleFieldChange}
                    value={this.getFieldValue(`places[${index}].email`)}
                  />
                  <Form.Input
                    required
                    fluid
                    name={`places[${index}].phoneNumber`}
                    type="tel"
                    label={t('common.phone_number')}
                    onChange={this.handleFieldChange}
                    value={this.getFieldValue(`places[${index}].phoneNumber`)}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>{t('common.location')}</label>
                    <AddressSearchInput
                      name={`places[${index}].address`}
                      onChange={(e, data) => this.handleAddressChange(e, data, `places[${index}].address`)}
                      details={true}
                      value={this.getFieldValue(`places[${index}].address.formatted`)}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </div>
          );
        })}
        {error && <Message error header={t('common.error')} content={errorContent} />}
        <Button loading={isLoading} fluid color="blue" disabled={!unsavedChanges} onClick={this.handleSubmit}>
          {t('common.save')}
        </Button>
      </div>
    );
  }
}

export default createFragmentContainer(withTranslation()(Places), {
  organization: graphql`
    fragment Places_organization on Organization {
      places {
        id
        name
        # eslint-disable-next-line relay/unused-fields
        phoneNumber
        # eslint-disable-next-line relay/unused-fields
        email
        profilePhotoUrl
        coverPhotoUrl
        # eslint-disable-next-line relay/unused-fields
        address {
          placeId
          formatted
        }
      }
    }
  `,
});
