import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Table, Checkbox} from 'semantic-ui-react';
import {UpdateServiceMutation} from '../../mutations';
import {withTranslation} from 'react-i18next';

class ServiceRow extends React.Component {
  handleChange = (e, {checked}) => {
    e.preventDefault();
    e.stopPropagation();

    const onSuccess = () => null;
    const onFailure = () => null;

    UpdateServiceMutation({input: {id: this.props.service.id, isActive: checked}}, onSuccess, onFailure);
  };

  render() {
    const {t} = this.props;
    const {title, description, duration, capacity, isActive} = this.props.service;

    return (
      <Table.Row onClick={() => this.props.onRowClick(this.props.service)}>
        <Table.Cell>{title}</Table.Cell>
        <Table.Cell>{description}</Table.Cell>
        <Table.Cell>
          {duration} {t('common.mins')}
        </Table.Cell>
        <Table.Cell>{capacity}</Table.Cell>
        <Table.Cell collapsing>
          <Checkbox color="green" checked={isActive} toggle onChange={this.handleChange} />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default createFragmentContainer(withTranslation()(ServiceRow), {
  service: graphql`
    fragment ServiceRow_service on Service {
      id
      title
      description
      duration
      capacity
      isActive
    }
  `,
});
