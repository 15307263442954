import React from 'react';
import ReactCookieBanner from 'react-cookie-banner';
import {withTranslation} from 'react-i18next';
import i18n from '../i18n';

const styles = {
  banner: {
    fontFamily: 'Source Sans Pro',
    // minHeight: 70,
    background: 'rgba(52, 64, 81, 0.88) url(/cookie.png) 20px 50% no-repeat',
    backgroundSize: '30px 30px',
    fontSize: '15px',
    fontWeight: 600,
    height: 50,
    position: 'absolute',
    bottom: 0,
  },
  button: {
    border: '1px solid white',
    borderRadius: 4,
    width: 74,
    lineHeight: '32px',
    background: 'transparent',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -16,
  },
  message: {
    paddingLeft: 10,
    paddingRight: 15,
    width: 'calc(100vw - 100px)',
    display: 'block',
    lineHeight: '50px',
    textAlign: 'left',
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
  },
};

class CookieBanner extends React.Component {
  render() {
    const {t} = this.props;
    const {language} = i18n;
    const message = t('common.cookies_message');

    const privacyLink = `https://cindyapp.com/${language}/privacy_policy.html`;

    return (
      <ReactCookieBanner
        styles={styles}
        message={message}
        link={<a href={privacyLink}>{t('common.read_more')}</a>}
        buttonMessage="OK"
        dismissOnScroll={false}
        dismissOnClick={true}
      />
    );
  }
}

export default withTranslation()(CookieBanner);
