/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddServiceInput = {|
  clientMutationId?: ?string,
  title: string,
  providerIds?: ?$ReadOnlyArray<?string>,
  description?: ?string,
  displayPrice?: ?boolean,
  duration: number,
  categoryId?: ?string,
  capacity: number,
  price?: ?number,
  visibleRangeMax?: ?number,
  recurringTimeSlots?: ?$ReadOnlyArray<?any>,
|};
export type AddServiceMutationVariables = {|
  input: AddServiceInput
|};
export type AddServiceMutationResponse = {|
  +addService: ?{|
    +serviceEdge: ?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +description: ?string,
        +duration: ?number,
        +capacity: ?number,
        +price: ?number,
        +visibleRangeMax: ?number,
      |}
    |}
  |}
|};
export type AddServiceMutation = {|
  variables: AddServiceMutationVariables,
  response: AddServiceMutationResponse,
|};
*/


/*
mutation AddServiceMutation(
  $input: AddServiceInput!
) {
  addService(input: $input) {
    serviceEdge {
      node {
        id
        title
        description
        duration
        capacity
        price
        visibleRangeMax
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddServicePayload",
    "kind": "LinkedField",
    "name": "addService",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceEdge",
        "kind": "LinkedField",
        "name": "serviceEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Service",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "duration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "capacity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "visibleRangeMax",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddServiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddServiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9ee6289aa0bd03ded4e184b8404ca9f4",
    "id": null,
    "metadata": {},
    "name": "AddServiceMutation",
    "operationKind": "mutation",
    "text": "mutation AddServiceMutation(\n  $input: AddServiceInput!\n) {\n  addService(input: $input) {\n    serviceEdge {\n      node {\n        id\n        title\n        description\n        duration\n        capacity\n        price\n        visibleRangeMax\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e63a710d5a6f3ae4e071cd192d33d84';

module.exports = node;
