/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdatePlacesSettingsInput = {|
  clientMutationId?: ?string,
  placesSettings?: ?$ReadOnlyArray<?PlaceSettingsInput>,
|};
export type PlaceSettingsInput = {|
  id?: ?string,
  viewType?: ?string,
  currency?: ?string,
  showServiceCategories?: ?boolean,
  timezone?: ?string,
  locale?: ?string,
|};
export type UpdatePlacesSettingsMutationVariables = {|
  input: UpdatePlacesSettingsInput
|};
export type UpdatePlacesSettingsMutationResponse = {|
  +updatePlacesSettings: ?{|
    +places: ?$ReadOnlyArray<?{|
      +id: string,
      +name: ?string,
      +settings: ?{|
        +currency: ?string,
        +locale: ?string,
        +timezone: ?string,
        +viewType: ?string,
        +showServiceCategories: ?boolean,
      |},
    |}>
  |}
|};
export type UpdatePlacesSettingsMutation = {|
  variables: UpdatePlacesSettingsMutationVariables,
  response: UpdatePlacesSettingsMutationResponse,
|};
*/


/*
mutation UpdatePlacesSettingsMutation(
  $input: UpdatePlacesSettingsInput!
) {
  updatePlacesSettings(input: $input) {
    places {
      id
      name
      settings {
        currency
        locale
        timezone
        viewType
        showServiceCategories
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showServiceCategories",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePlacesSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePlacesSettingsPayload",
        "kind": "LinkedField",
        "name": "updatePlacesSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "places",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaceSettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePlacesSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePlacesSettingsPayload",
        "kind": "LinkedField",
        "name": "updatePlacesSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Place",
            "kind": "LinkedField",
            "name": "places",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlaceSettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5a7c4a49723e4a2ac79581e9f9c82711",
    "id": null,
    "metadata": {},
    "name": "UpdatePlacesSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePlacesSettingsMutation(\n  $input: UpdatePlacesSettingsInput!\n) {\n  updatePlacesSettings(input: $input) {\n    places {\n      id\n      name\n      settings {\n        currency\n        locale\n        timezone\n        viewType\n        showServiceCategories\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e349152ada6b8700524810c9f06c48c8';

module.exports = node;
