import styled from 'styled-components';

const Container = styled.div`
  height: 30px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 8px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export default function Status({style, color, text}) {
  return (
    <Container style={{borderColor: color, ...style}}>
      <Text style={{color}}>{text}</Text>
    </Container>
  );
}
