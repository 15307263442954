import React, {useState} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useFragment} from 'relay-hooks';
import {withRelay} from '../../relay';
import {currencyOptions, languageOptions} from './constants';
import Dropdown from './Dropdown';
import {pickBy, pick, get} from 'lodash';
import isEmpty from '../../helpers/isEmpty';
import {useTranslation} from 'react-i18next';
import isMatchWith from '../../helpers/isMatchWith';
import {UpdateOrganizationSettingsMutation} from '../../mutations';
import BottomBar from './BottomBar';
import styled from 'styled-components';
import {Form} from 'semantic-ui-react';
import {CountryDropdown} from 'react-country-region-selector';

const formFields = ['locale', 'country', 'currency', 'billingName', 'billingVAT', 'billingPOI', 'billingAddress'];

const SectionTitle = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 1.29rem;
  color: grey;
  font-weight: bold;
`;

const SectionSubtitle = styled.div`
  margin-top: 20px;
  margin-bottom: 5px;
  color: grey;
`;

function OrganizationSettings(props) {
  const settings = useFragment(fragment, props.viewer.organization.settings);
  const {currency, country, locale, billingName, billingVAT, billingPOI} = settings;

  const [_locale, setLocale] = useState(null);
  const [_country, setCountry] = useState(null);
  const [_currency, setCurrency] = useState(null);
  const [_billingName, setBillingName] = useState(null);
  const [_billingVAT, setBillingVAT] = useState(null);
  const [_billingPOI, setBillingPOI] = useState(null);
  const [_billingAddress, setBillingAddress] = useState({});

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  function hasUnsavedChanges() {
    const propsValues = pick(settings, formFields);

    const stateObject = {
      locale: _locale,
      currency: _currency,
      country: _country,
      billingName: _billingName,
      billingVAT: _billingVAT,
      billingPOI: _billingPOI,
      billingAddress: _billingAddress,
    };

    return !isMatchWith(propsValues, stateObject);
  }

  function resetState() {
    setLocale(null);
    setCountry(null);
    setCurrency(null);
    setBillingVAT(null);
    setBillingPOI(null);
    setBillingAddress({});
  }

  function handleSubmit() {
    setIsSubmitButtonLoading(true);

    const rawInput = {
      locale: _locale,
      currency: _currency,
      country: _country,
      billingName: _billingName,
      billingVAT: _billingVAT,
      billingPOI: _billingPOI,
      billingAddress: _billingAddress,
    };

    const input = {
      ...pickBy(rawInput, (value) => !isEmpty(value)),
      id: settings.id,
    };

    const onSuccess = (data) => {
      setIsSubmitButtonLoading(false);

      resetState();
    };

    const onFailure = (errors) => {
      setIsSubmitButtonLoading(false);
    };

    UpdateOrganizationSettingsMutation({input}, onSuccess, onFailure);
  }

  const {t} = useTranslation();

  const billingAddress = get(settings, 'billingAddress') || {};

  return (
    <div className="container">
      <BottomBar isVisible={hasUnsavedChanges()} isLoading={isSubmitButtonLoading} handleSubmit={handleSubmit} />
      <Form>
        <Form.Group>
          <Dropdown
            defaultValue={_locale || locale}
            selection
            label={t('common.language')}
            placeholder={t('common.language')}
            options={languageOptions}
            onChange={(e, {value}) => setLocale(value)}
          />
          <Dropdown
            defaultValue={_currency || currency}
            selection
            label={t('common.currency')}
            placeholder={t('common.currency')}
            options={currencyOptions}
            onChange={(e, {value}) => setCurrency(value)}
          />
          <Form.Field>
            <label>{t('common.country')}</label>
            <CountryDropdown
              // style={{border: 0, outline: 0, color: !country ? '#CCCCCC' : 'black'}}
              value={_country || country}
              valueType="short"
              priorityOptions={['BG']}
              onChange={(value) => setCountry(value)}
            />
          </Form.Field>
        </Form.Group>
        <SectionTitle>{t('common.billing_info')}</SectionTitle>
        <Form.Group>
          <Form.Field width={4}>
            <label>{t('common.name')}</label>
            <Form.Input value={_billingName || billingName} onChange={(e, {value}) => setBillingName(value)} />
          </Form.Field>
          <Form.Field>
            <label>{t('common.vat_number')}</label>
            <Form.Input value={_billingVAT || billingVAT} onChange={(e, {value}) => setBillingVAT(value)} />
          </Form.Field>
          <Form.Field width={4}>
            <label>{t('common.poi')}</label>
            <Form.Input value={_billingPOI || billingPOI} onChange={(e, {value}) => setBillingPOI(value)} />
          </Form.Field>
        </Form.Group>
        <SectionSubtitle>{t('common.address')}</SectionSubtitle>
        <Form.Group>
          <Form.Field width={4}>
            <label>{t('common.street')}</label>
            <Form.Input
              value={_billingAddress.street || billingAddress.street}
              onChange={(e, {value}) =>
                setBillingAddress({
                  ..._billingAddress,
                  street: value,
                })
              }
            />
          </Form.Field>
          <Form.Field width={3}>
            <label>{t('common.city')}</label>
            <Form.Input
              value={_billingAddress.city || billingAddress.city}
              onChange={(e, {value}) =>
                setBillingAddress({
                  ..._billingAddress,
                  city: value,
                })
              }
            />
          </Form.Field>
          <Form.Field width={2}>
            <label>{t('common.zip_code')}</label>
            <Form.Input
              value={_billingAddress.zipCode || billingAddress.zipCode}
              onChange={(e, {value}) =>
                setBillingAddress({
                  ..._billingAddress,
                  zipCode: value,
                })
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Field width={4}>
          <label>{t('common.country')}</label>
          <CountryDropdown
            value={_billingAddress.country || billingAddress.country}
            valueType="short"
            priorityOptions={['BG']}
            onChange={(value) =>
              setBillingAddress({
                ..._billingAddress,
                country: value,
              })
            }
          />
        </Form.Field>
      </Form>
    </div>
  );
}

const fragment = graphql`
  fragment OrganizationSettings_settings on OrganizationSettings {
    id
    locale
    country
    currency
    billingName
    billingVAT
    billingPOI
    # eslint-disable-next-line relay/unused-fields
    billingAddress {
      street
      city
      country
      zipCode
    }
  }
`;

const OrganizationSettingsQuery = graphql`
  query OrganizationSettingsQuery {
    viewer {
      ... on Provider {
        organization {
          settings {
            ...OrganizationSettings_settings
          }
        }
      }
    }
  }
`;

OrganizationSettings.getVariables = (props) => {};

export default withRelay(OrganizationSettings, OrganizationSettingsQuery);
