import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation AddServiceMutation($input: AddServiceInput!) {
    addService(input: $input) {
      serviceEdge {
        node {
          id
          title
          description
          duration
          capacity
          price
          visibleRangeMax
        }
      }
    }
  }
`;

const AddServiceMutation = (payload, success, failure) => {
  const {input} = payload;

  commitMutation(environment, {
    mutation,
    variables: {input},
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default AddServiceMutation;
