import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {createFragmentContainer} from 'react-relay';
import {Tab} from 'semantic-ui-react';
import Container from '../Container';
import Personal from './Personal';
import Organization from './Organization';
import Places from './Places';
import canAccess, {
  USER_SETS_CONDITION_ASSIGNED_TO_ORGANIZATION,
  USER_SETS_CONDITION_ASSIGNED_TO_PLACE,
} from '../../helpers/canAccess';

class MyProfile extends React.Component {
  state = {activeIndex: 0};

  handleTabChange = (e, {activeIndex}) => this.setState({activeIndex});

  render() {
    const {activeIndex} = this.state;
    const {t, viewer} = this.props;
    const {organization, role} = viewer;
    const {sets} = role;

    const panes = [
      {menuItem: `${t('titles.personal_profile')}`, render: () => <Personal viewer={viewer} />},
      canAccess(sets, 'places', [USER_SETS_CONDITION_ASSIGNED_TO_ORGANIZATION])
        ? {menuItem: `${t('titles.organization')}`, render: () => <Organization organization={organization} />}
        : {},
      canAccess(sets, 'places', [USER_SETS_CONDITION_ASSIGNED_TO_PLACE, USER_SETS_CONDITION_ASSIGNED_TO_ORGANIZATION])
        ? {menuItem: `${t('titles.places')}`, render: () => <Places organization={organization} />}
        : {},
    ];

    return (
      <Container>
        <Tab
          menu={{secondary: true, pointing: true}}
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}
          panes={panes}
        />
      </Container>
    );
  }
}

const MyProfileContainer = createFragmentContainer(withTranslation()(MyProfile), {
  viewer: graphql`
    fragment MyProfile_viewer on User {
      ... on Provider {
        ...Personal_viewer
        organization {
          ...Organization_organization
          ...Places_organization
        }
        role {
          sets
        }
      }
    }
  `,
});

export default MyProfileContainer;
