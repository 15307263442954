/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffMemberRow_provider$ref = any;
export type AddProviderInput = {|
  clientMutationId?: ?string,
  name: string,
  email: string,
  phoneNumber: string,
  roleId: string,
  placeId?: ?string,
|};
export type AddProviderMutationVariables = {|
  input: AddProviderInput,
  connections: $ReadOnlyArray<string>,
|};
export type AddProviderMutationResponse = {|
  +addProvider: ?{|
    +provider: ?{|
      +id: string,
      +$fragmentRefs: StaffMemberRow_provider$ref,
    |}
  |}
|};
export type AddProviderMutation = {|
  variables: AddProviderMutationVariables,
  response: AddProviderMutationResponse,
|};
*/


/*
mutation AddProviderMutation(
  $input: AddProviderInput!
) {
  addProvider(input: $input) {
    provider {
      id
      ...StaffMemberRow_provider
    }
  }
}

fragment StaffMemberRow_provider on Provider {
  id
  rowId
  name
  email
  phoneNumber
  profilePhotoUrl
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddProviderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AddProviderPayload",
        "kind": "LinkedField",
        "name": "addProvider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Provider",
            "kind": "LinkedField",
            "name": "provider",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StaffMemberRow_provider"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddProviderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AddProviderPayload",
        "kind": "LinkedField",
        "name": "addProvider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Provider",
            "kind": "LinkedField",
            "name": "provider",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rowId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phoneNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "profilePhotoUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "provider",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "Provider"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac22a077b8ef11b38346adf582312c3c",
    "id": null,
    "metadata": {},
    "name": "AddProviderMutation",
    "operationKind": "mutation",
    "text": "mutation AddProviderMutation(\n  $input: AddProviderInput!\n) {\n  addProvider(input: $input) {\n    provider {\n      id\n      ...StaffMemberRow_provider\n    }\n  }\n}\n\nfragment StaffMemberRow_provider on Provider {\n  id\n  rowId\n  name\n  email\n  phoneNumber\n  profilePhotoUrl\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aef891a52f895dcf94cf2ed90eb83dc8';

module.exports = node;
