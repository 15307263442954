import {isEmpty} from 'lodash';

export default function groupAppointmentsTimeSlots(timeSlots) {
  if (isEmpty(timeSlots)) {
    return [];
  }

  const timeSlotsByProviderId = timeSlots.reduce((p, c) => {
    const {providerId} = c;
    if (!p[providerId]) {
      p[providerId] = [];
    }

    p[providerId].push(c);

    return p;
  }, {});

  const timeSlotsByAppointment = [];
  let currentIndex = 0;

  Object.keys(timeSlotsByProviderId).forEach((providerId) => {
    const _timeSlots = timeSlotsByProviderId[providerId];
    const timeSlotsByDate = _timeSlots.reduce((p, c) => {
      const {date} = c;
      if (!p[date]) {
        p[date] = [];
      }

      p[date].push(c);

      return p;
    }, {});

    Object.values(timeSlotsByDate).forEach((slots) =>
      slots.sort((a, b) => {
        return a.startAt < b.startAt ? -1 : a.startAt > b.startAt ? 1 : 0;
      }),
    );

    Object.values(timeSlotsByDate).forEach((slots) => {
      for (let i = 0; i < slots.length; i++) {
        const slot = slots[i];

        if (i === 0) {
          timeSlotsByAppointment.push([slot]);

          continue;
        }

        const previousSlot = slots[i - 1];

        if (slot.startAt === previousSlot.endAt) {
          timeSlotsByAppointment[currentIndex].push(slot);
        } else {
          timeSlotsByAppointment.push([slot]);

          currentIndex++;
        }
      }

      currentIndex++;
    });
  });

  return timeSlotsByAppointment;
}
