import {Select} from 'semantic-ui-react';

const ServicesDropdown = (props) => {
  const {services, t} = props;

  const servicesOptions = services.edges.map(({node: service}) => ({
    key: service.rowId,
    text: service.title,
    value: service.rowId,
    id: service.rowId,
    // image: {avatar: true, src: service.profilePhotoUrl},
  }));

  return (
    <Select
      placeholder={t('titles.select_service')}
      fluid
      search
      onChange={props.onServiceChange}
      options={servicesOptions}
    />
  );
};

export default ServicesDropdown;
