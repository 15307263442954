import React from 'react';
import {useFragment} from 'relay-hooks';
import {Image, Table, Button} from 'semantic-ui-react';
import graphql from 'babel-plugin-relay/macro';

const fragment = graphql`
  fragment StaffMemberRow_provider on Provider {
    id
    rowId
    name
    email
    phoneNumber
    profilePhotoUrl
  }
`;

export default function StaffMemberRow(props) {
  const provider = useFragment(fragment, props.provider);
  const {rowId, profilePhotoUrl, name, email, phoneNumber} = provider;

  return (
    <Table.Row onClick={props.onClick}>
      <Table.Cell>{rowId}</Table.Cell>
      <Table.Cell>
        <Image style={{backgroundColor: 'lightgray'}} src={profilePhotoUrl} avatar />
        {name}
      </Table.Cell>
      <Table.Cell>{email}</Table.Cell>
      <Table.Cell>{phoneNumber}</Table.Cell>
      <Table.Cell textAlign="center">
        <div style={{display: 'flex'}}>
          {/* <Button icon="edit" onClick={props.onEditClick} /> */}
          <Button icon="remove" color="red" onClick={props.onDeleteClick} />
        </div>
      </Table.Cell>
    </Table.Row>
  );
}
