import React from 'react';
import {List, Message, Sidebar} from 'semantic-ui-react';
import {createPaginationContainer} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {withRelay} from '../relay';
import {throttle} from 'lodash';
import NotificationItem from './NotificationItem';
import {MarkNotificationsReadMutation} from '../mutations';
import isEmpty from '../helpers/isEmpty';
import {CreateNotificationSubscription} from '../subscriptions';
import {withTranslation} from 'react-i18next';
import styled from 'styled-components';
import CustomLoader from '../helpers/CustomLoader';

const LIMIT = 5;
const LIST_ITEM_HEIGHT = 75;

const MarkAsReadButton = styled.p`
  margin-left: 0.5rem;
  text-decoration: underline;
  color: #3f51b5;
  &:hover {
    cursor: pointer;
  }
`;

class NotificationsSidebar extends React.Component {
  state = {
    justReadNotificationIds: [],
  };

  constructor(props) {
    super(props);

    this.throttleScroll = throttle(this.handleScroll, 900);
    this.subscriptions = [];
  }

  componentDidMount() {
    const filters = [];
    const connectionName = 'NotificationSidebar_notifications';

    const subcription = CreateNotificationSubscription(this.props.viewer.id, connectionName, filters);
    this.subscriptions.push(subcription);
  }

  componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
  }

  componentDidUpdate(prevProps, prevState) {
    const {viewer} = this.props;

    if (prevProps.isSidebarVisible && !this.props.isSidebarVisible) {
      return this.setState({justReadNotificationIds: []});
    }

    if (!prevProps.isSidebarVisible && this.props.isSidebarVisible && viewer && !viewer.notifications) {
      // Fetch initial data
      return this.fetchInitialQuery();
    }

    if (this.props.isSidebarVisible && viewer.notifications) {
      return this.markVisibleNotificationsAsRead();
    }
  }

  markVisibleNotificationsAsRead = () => {
    const initialVisibleElements = document.documentElement.clientHeight / LIST_ITEM_HEIGHT;
    const roundedVisibleElements = Math.round(initialVisibleElements);
    const {notifications} = this.props.viewer;

    if (isEmpty(notifications)) {
      return;
    }

    // Note: We need to sort notifications like in the render method
    const sortedNotificationsByDate = [...notifications.edges].sort(
      ({node: a}, {node: b}) => new Date(b.createdAt) - new Date(a.createdAt),
    );

    const newNonReadNotificationIds = sortedNotificationsByDate
      .slice(0, roundedVisibleElements + 1)
      .reduce((acc, {node}) => {
        if (!node.isRead) {
          acc.push(node.id);
        }

        return acc;
      }, []);

    if (newNonReadNotificationIds.length === 0) {
      return;
    }

    this.markNotificationsRead(newNonReadNotificationIds);
  };

  onScroll = (e) => {
    e.persist();

    this.throttleScroll(e);
  };

  handleScroll = (e) => {
    const {notifications} = this.props.viewer;
    const initialVisibleElements = document.documentElement.clientHeight / LIST_ITEM_HEIGHT;
    const numberOfInitialVisibleElements = Math.round(initialVisibleElements);

    const clientHeight = e.target.clientHeight;
    const scrolledTo = e.target.scrollTop;
    const scrollHeight = e.target.scrollHeight;
    const nearBottom = scrollHeight - scrolledTo - clientHeight < 250;
    const isAtBottom = scrolledTo + clientHeight >= scrollHeight;

    const visibleElements = scrolledTo / LIST_ITEM_HEIGHT;
    const roundedElements = Math.round(visibleElements);

    if (!this.props.relay.isLoading() && nearBottom) {
      if (this.props.relay.hasMore()) {
        this.props.relay.loadMore(LIMIT, (error) => {
          if (error) console.log('error', error);
        });
      }
    }

    const notificationIds = notifications.edges
      .slice(numberOfInitialVisibleElements - 1, numberOfInitialVisibleElements + roundedElements)
      .reduce((acc, {node}) => {
        if (!node.isRead) {
          acc.push(node.id);
        }
        return acc;
      }, []);

    if (isEmpty(notificationIds)) {
      return;
    }

    // Note: Handle bottom notifications
    if (isAtBottom && !notifications.pageInfo.hasNextPage) {
      const bottomNotificationIds = notifications.edges
        .slice(numberOfInitialVisibleElements - 1)
        .reduce((acc, {node}) => {
          if (!node.isRead) {
            acc.push(node.id);
          }
          return acc;
        }, []);

      this.markNotificationsRead(bottomNotificationIds);
    } else {
      this.markNotificationsRead(notificationIds);
    }
  };

  markNotificationsRead = (notificationIds) => {
    if (isEmpty(notificationIds)) {
      return;
    }

    const input = {notificationIds};

    const onSuccess = () => {
      this.setState((prevState) => ({
        justReadNotificationIds: [...prevState.justReadNotificationIds, ...notificationIds],
      }));
    };

    const onFailure = (errors) => {
      console.log('error', errors);
    };

    MarkNotificationsReadMutation({input}, onSuccess, onFailure);
  };

  fetchInitialQuery = () => {
    const refetchVariables = {shouldFetch: true};

    const count = 15;

    this.props.relay.refetchConnection(
      count,
      (error) => {
        if (error) {
          console.log('Error refetching connection', error);
        }
      },
      refetchVariables,
    );
  };

  isNotificationRead = (notification) => {
    if (!notification.isRead) {
      return false;
    } else if (this.state.justReadNotificationIds.indexOf(notification.id) !== -1) {
      return false;
    }

    return true;
  };

  markAllNotificationsAsRead = () => {
    const input = {markAllAsRead: true};

    const onSuccess = (data) => {};

    const onFailure = (errors) => {};

    MarkNotificationsReadMutation({input}, onSuccess, onFailure);
  };

  render() {
    const {viewer} = this.props;
    if (!viewer) {
      return null;
    }

    const {notifications} = viewer;
    const {isSidebarVisible, t} = this.props;
    if (!isSidebarVisible) {
      return null;
    }

    const sortedNotificationsByDate =
      notifications && notifications.edges
        ? [...notifications.edges].sort(({node: a}, {node: b}) => new Date(b.createdAt) - new Date(a.createdAt))
        : [];

    return (
      <div>
        <Sidebar
          onScroll={this.onScroll}
          style={{
            backgroundColor: 'white',
            overflow: 'hidden',
            borderWidth: 0,
          }}
          animation="overlay"
          onHide={() => this.props.onClose()}
          visible={isSidebarVisible}
          width="wide"
          direction="right"
        >
          {sortedNotificationsByDate.length > 0 && (
            <div>
              <MarkAsReadButton style={{marginTop: '5.5rem'}} onClick={this.markAllNotificationsAsRead}>
                {t('actions.mark_all_as_read')}
              </MarkAsReadButton>
              <List divided selection relaxed style={{marginTop: '10px', backgroundColor: 'white'}}>
                {sortedNotificationsByDate.map(({node: notification}) => {
                  const isNotificationRead = this.isNotificationRead(notification);

                  return (
                    <NotificationItem key={notification.id} notification={notification} isRead={isNotificationRead} />
                  );
                })}
              </List>
            </div>
          )}
          {sortedNotificationsByDate.length === 0 && notifications && (
            <Message style={{marginTop: '75px'}}>{t('titles.no_notifications')}</Message>
          )}
          {!notifications && <CustomLoader t={t} />}
        </Sidebar>
      </div>
    );
  }
}

const NotificationsSidebarQuery = graphql`
  query NotificationsSidebarQuery(
    $orderBy: [[String]]
    $filterBy: NotificationFilterInput
    $cursor: String
    $count: Int
    $shouldFetch: Boolean!
  ) {
    viewer {
      ...NotificationsSidebar_viewer
        @arguments(orderBy: $orderBy, filterBy: $filterBy, after: $cursor, count: $count, shouldFetch: $shouldFetch)
    }
  }
`;

const NotificationsSidebarContainer = createPaginationContainer(
  withTranslation()(NotificationsSidebar),
  {
    viewer: graphql`
      fragment NotificationsSidebar_viewer on User
      @argumentDefinitions(
        orderBy: {type: "[[String]]"}
        filterBy: {type: "NotificationFilterInput"}
        count: {type: "Int"}
        after: {type: "String"}
        shouldFetch: {type: "Boolean!", defaultValue: false}
      ) {
        ... on Provider {
          id
          notifications(filterBy: $filterBy, orderBy: $orderBy, first: 15, after: $after)
            @include(if: $shouldFetch)
            @connection(key: "NotificationSidebar_notifications", filters: []) {
            edges {
              node {
                id
                createdAt
                isRead
                ...NotificationItem_notification
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    query: graphql`
      query NotificationsSidebarForwardQuery(
        $orderBy: [[String]]
        $filterBy: NotificationFilterInput
        $cursor: String
        $count: Int
        $shouldFetch: Boolean!
      ) {
        viewer {
          ...NotificationsSidebar_viewer
            @arguments(orderBy: $orderBy, filterBy: $filterBy, after: $cursor, count: $count, shouldFetch: $shouldFetch)
        }
      }
    `,
    getConnectionFromProps(props) {
      return props.viewer && props.viewer.notifications;
    },
    getFragmentVariables(previousVariables, totalCount) {
      return {
        ...previousVariables,
        count: totalCount,
      };
    },
    getVariables(props, {count, cursor}, fragmentVariables) {
      return {
        count,
        cursor,
        orderBy: fragmentVariables.orderBy,
        filterBy: fragmentVariables.filterBy,
        shouldFetch: fragmentVariables.shouldFetch,
      };
    },
  },
);

NotificationsSidebarContainer.getVariables = (props) => {
  return {
    orderBy: [['createdAt', 'DESC']],
    filterBy: {},
    count: 15,
    shouldFetch: false,
  };
};

export default withRelay(NotificationsSidebarContainer, NotificationsSidebarQuery);
