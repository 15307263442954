import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 60px;
  border: solid gray 1px;
  width: 80px;
  background-color: white;
`;

const HourText = styled.div`
  color: black;
  font-size: 17px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HourCell = ({hour, yOffset, style}) => {
  return (
    <Container style={{...style}}>
      <HourText>{hour}</HourText>
    </Container>
  );
};

export default HourCell;
