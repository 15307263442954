export const languageOptions = [
  {
    key: 'en',
    text: 'English',
    value: 'en',
  },
  {
    key: 'it',
    text: 'Italiano',
    value: 'it',
  },
  {
    key: 'bg',
    text: 'Български',
    value: 'bg',
  },
];

export const getTextForMinutes = (t, minutes) => {
  switch (minutes) {
    case 0:
      return t('common.at_the_time_of_the_event');
    case 5:
      return `5 ${t('common.minutes_before')}`;
    case 15:
      return `15 ${t('common.minutes_before')}`;
    case 30:
      return `30 ${t('common.minutes_before')}`;
    case 60:
      return `1 ${t('common.hour_before')}`;
    case 120:
      return `2 ${t('common.hours_before')}`;
    case 1440:
      return `1 ${t('common.day_before')}`;
    case 2880:
      return `2 ${t('common.days_before')}`;
    case 10080:
      return `1 ${t('common.week_before')}`;
    default:
      return t('common.none');
  }
};

export const currencyOptions = [
  {
    value: 'eur',
    key: 'eur',
    text: 'EUR',
  },
  {
    value: 'gbp',
    key: 'gbp',
    text: 'GBP',
  },
  {
    value: 'bgn',
    key: 'bgn',
    text: 'BGN',
  },
];

export const timezoneOptions = [
  {value: 'America/Adak', key: 'America/Adak', text: 'America/Adak'},
  {
    value: 'America/Anchorage',
    key: 'America/Anchorage',
    text: 'America/Anchorage',
  },
  {
    value: 'America/Anguilla',
    key: 'America/Anguilla',
    text: 'America/Anguilla',
  },
  {
    value: 'America/Antigua',
    key: 'America/Antigua',
    text: 'America/Antigua',
  },
  {
    value: 'America/Araguaina',
    key: 'America/Araguaina',
    text: 'America/Araguaina',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    key: 'America/Argentina/Buenos_Aires',
    text: 'America/Argentina/Buenos_Aires',
  },
  {
    value: 'America/Argentina/Catamarca',
    key: 'America/Argentina/Catamarca',
    text: 'America/Argentina/Catamarca',
  },
  {
    value: 'America/Argentina/ComodRivadavia',
    key: 'America/Argentina/ComodRivadavia',
    text: 'America/Argentina/ComodRivadavia',
  },
  {
    value: 'America/Argentina/Cordoba',
    key: 'America/Argentina/Cordoba',
    text: 'America/Argentina/Cordoba',
  },
  {
    value: 'America/Argentina/Jujuy',
    key: 'America/Argentina/Jujuy',
    text: 'America/Argentina/Jujuy',
  },
  {
    value: 'America/Argentina/La_Rioja',
    key: 'America/Argentina/La_Rioja',
    text: 'America/Argentina/La_Rioja',
  },
  {
    value: 'America/Argentina/Mendoza',
    key: 'America/Argentina/Mendoza',
    text: 'America/Argentina/Mendoza',
  },
  {
    value: 'America/Argentina/Rio_Gallegos',
    key: 'America/Argentina/Rio_Gallegos',
    text: 'America/Argentina/Rio_Gallegos',
  },
  {
    value: 'America/Argentina/Salta',
    key: 'America/Argentina/Salta',
    text: 'America/Argentina/Salta',
  },
  {
    value: 'America/Argentina/San_Juan',
    key: 'America/Argentina/San_Juan',
    text: 'America/Argentina/San_Juan',
  },
  {
    value: 'America/Argentina/San_Luis',
    key: 'America/Argentina/San_Luis',
    text: 'America/Argentina/San_Luis',
  },
  {
    value: 'America/Argentina/Tucuman',
    key: 'America/Argentina/Tucuman',
    text: 'America/Argentina/Tucuman',
  },
  {
    value: 'America/Argentina/Ushuaia',
    key: 'America/Argentina/Ushuaia',
    text: 'America/Argentina/Ushuaia',
  },
  {
    value: 'America/Aruba',
    key: 'America/Aruba',
    text: 'America/Aruba',
  },
  {
    value: 'America/Asuncion',
    key: 'America/Asuncion',
    text: 'America/Asuncion',
  },
  {
    value: 'America/Atikokan',
    key: 'America/Atikokan',
    text: 'America/Atikokan',
  },
  {value: 'America/Atka', key: 'America/Atka', text: 'America/Atka'},
  {
    value: 'America/Bahia',
    key: 'America/Bahia',
    text: 'America/Bahia',
  },
  {
    value: 'America/Barbados',
    key: 'America/Barbados',
    text: 'America/Barbados',
  },
  {
    value: 'America/Belem',
    key: 'America/Belem',
    text: 'America/Belem',
  },
  {
    value: 'America/Belize',
    key: 'America/Belize',
    text: 'America/Belize',
  },
  {
    value: 'America/Blanc-Sablon',
    key: 'America/Blanc-Sablon',
    text: 'America/Blanc-Sablon',
  },
  {
    value: 'America/Boa_Vista',
    key: 'America/Boa_Vista',
    text: 'America/Boa_Vista',
  },
  {
    value: 'America/Bogota',
    key: 'America/Bogota',
    text: 'America/Bogota',
  },
  {
    value: 'America/Boise',
    key: 'America/Boise',
    text: 'America/Boise',
  },
  {
    value: 'America/Buenos_Aires',
    key: 'America/Buenos_Aires',
    text: 'America/Buenos_Aires',
  },
  {
    value: 'America/Cambridge_Bay',
    key: 'America/Cambridge_Bay',
    text: 'America/Cambridge_Bay',
  },
  {
    value: 'America/Campo_Grande',
    key: 'America/Campo_Grande',
    text: 'America/Campo_Grande',
  },
  {
    value: 'America/Cancun',
    key: 'America/Cancun',
    text: 'America/Cancun',
  },
  {
    value: 'America/Caracas',
    key: 'America/Caracas',
    text: 'America/Caracas',
  },
  {
    value: 'America/Catamarca',
    key: 'America/Catamarca',
    text: 'America/Catamarca',
  },
  {
    value: 'America/Cayenne',
    key: 'America/Cayenne',
    text: 'America/Cayenne',
  },
  {
    value: 'America/Cayman',
    key: 'America/Cayman',
    text: 'America/Cayman',
  },
  {
    value: 'America/Chicago',
    key: 'America/Chicago',
    text: 'America/Chicago',
  },
  {
    value: 'America/Chihuahua',
    key: 'America/Chihuahua',
    text: 'America/Chihuahua',
  },
  {
    value: 'America/Coral_Harbour',
    key: 'America/Coral_Harbour',
    text: 'America/Coral_Harbour',
  },
  {
    value: 'America/Cordoba',
    key: 'America/Cordoba',
    text: 'America/Cordoba',
  },
  {
    value: 'America/Costa_Rica',
    key: 'America/Costa_Rica',
    text: 'America/Costa_Rica',
  },
  {
    value: 'America/Cuiaba',
    key: 'America/Cuiaba',
    text: 'America/Cuiaba',
  },
  {
    value: 'America/Curacao',
    key: 'America/Curacao',
    text: 'America/Curacao',
  },
  {
    value: 'America/Danmarkshavn',
    key: 'America/Danmarkshavn',
    text: 'America/Danmarkshavn',
  },
  {
    value: 'America/Dawson',
    key: 'America/Dawson',
    text: 'America/Dawson',
  },
  {
    value: 'America/Dawson_Creek',
    key: 'America/Dawson_Creek',
    text: 'America/Dawson_Creek',
  },
  {
    value: 'America/Denver',
    key: 'America/Denver',
    text: 'America/Denver',
  },
  {
    value: 'America/Detroit',
    key: 'America/Detroit',
    text: 'America/Detroit',
  },
  {
    value: 'America/Dominica',
    key: 'America/Dominica',
    text: 'America/Dominica',
  },
  {
    value: 'America/Edmonton',
    key: 'America/Edmonton',
    text: 'America/Edmonton',
  },
  {
    value: 'America/Eirunepe',
    key: 'America/Eirunepe',
    text: 'America/Eirunepe',
  },
  {
    value: 'America/El_Salvador',
    key: 'America/El_Salvador',
    text: 'America/El_Salvador',
  },
  {
    value: 'America/Ensenada',
    key: 'America/Ensenada',
    text: 'America/Ensenada',
  },
  {
    value: 'America/Fortaleza',
    key: 'America/Fortaleza',
    text: 'America/Fortaleza',
  },
  {
    value: 'America/Fort_Wayne',
    key: 'America/Fort_Wayne',
    text: 'America/Fort_Wayne',
  },
  {
    value: 'America/Glace_Bay',
    key: 'America/Glace_Bay',
    text: 'America/Glace_Bay',
  },
  {
    value: 'America/Godthab',
    key: 'America/Godthab',
    text: 'America/Godthab',
  },
  {
    value: 'America/Goose_Bay',
    key: 'America/Goose_Bay',
    text: 'America/Goose_Bay',
  },
  {
    value: 'America/Grand_Turk',
    key: 'America/Grand_Turk',
    text: 'America/Grand_Turk',
  },
  {
    value: 'America/Grenada',
    key: 'America/Grenada',
    text: 'America/Grenada',
  },
  {
    value: 'America/Guadeloupe',
    key: 'America/Guadeloupe',
    text: 'America/Guadeloupe',
  },
  {
    value: 'America/Guatemala',
    key: 'America/Guatemala',
    text: 'America/Guatemala',
  },
  {
    value: 'America/Guayaquil',
    key: 'America/Guayaquil',
    text: 'America/Guayaquil',
  },
  {
    value: 'America/Guyana',
    key: 'America/Guyana',
    text: 'America/Guyana',
  },
  {
    value: 'America/Halifax',
    key: 'America/Halifax',
    text: 'America/Halifax',
  },
  {
    value: 'America/Havana',
    key: 'America/Havana',
    text: 'America/Havana',
  },
  {
    value: 'America/Hermosillo',
    key: 'America/Hermosillo',
    text: 'America/Hermosillo',
  },
  {
    value: 'America/Indiana/Indianapolis',
    key: 'America/Indiana/Indianapolis',
    text: 'America/Indiana/Indianapolis',
  },
  {
    value: 'America/Indiana/Knox',
    key: 'America/Indiana/Knox',
    text: 'America/Indiana/Knox',
  },
  {
    value: 'America/Indiana/Marengo',
    key: 'America/Indiana/Marengo',
    text: 'America/Indiana/Marengo',
  },
  {
    value: 'America/Indiana/Petersburg',
    key: 'America/Indiana/Petersburg',
    text: 'America/Indiana/Petersburg',
  },
  {
    value: 'America/Indiana/Tell_City',
    key: 'America/Indiana/Tell_City',
    text: 'America/Indiana/Tell_City',
  },
  {
    value: 'America/Indiana/Vevay',
    key: 'America/Indiana/Vevay',
    text: 'America/Indiana/Vevay',
  },
  {
    value: 'America/Indiana/Vincennes',
    key: 'America/Indiana/Vincennes',
    text: 'America/Indiana/Vincennes',
  },
  {
    value: 'America/Indiana/Winamac',
    key: 'America/Indiana/Winamac',
    text: 'America/Indiana/Winamac',
  },
  {
    value: 'America/Indianapolis',
    key: 'America/Indianapolis',
    text: 'America/Indianapolis',
  },
  {
    value: 'America/Inuvik',
    key: 'America/Inuvik',
    text: 'America/Inuvik',
  },
  {
    value: 'America/Iqaluit',
    key: 'America/Iqaluit',
    text: 'America/Iqaluit',
  },
  {
    value: 'America/Jamaica',
    key: 'America/Jamaica',
    text: 'America/Jamaica',
  },
  {
    value: 'America/Jujuy',
    key: 'America/Jujuy',
    text: 'America/Jujuy',
  },
  {
    value: 'America/Juneau',
    key: 'America/Juneau',
    text: 'America/Juneau',
  },
  {
    value: 'America/Kentucky/Louisville',
    key: 'America/Kentucky/Louisville',
    text: 'America/Kentucky/Louisville',
  },
  {
    value: 'America/Kentucky/Monticello',
    key: 'America/Kentucky/Monticello',
    text: 'America/Kentucky/Monticello',
  },
  {
    value: 'America/Knox_IN',
    key: 'America/Knox_IN',
    text: 'America/Knox_IN',
  },
  {
    value: 'America/La_Paz',
    key: 'America/La_Paz',
    text: 'America/La_Paz',
  },
  {value: 'America/Lima', key: 'America/Lima', text: 'America/Lima'},
  {
    value: 'America/Louisville',
    key: 'America/Louisville',
    text: 'America/Louisville',
  },
  {
    value: 'America/Maceio',
    key: 'America/Maceio',
    text: 'America/Maceio',
  },
  {
    value: 'America/Managua',
    key: 'America/Managua',
    text: 'America/Managua',
  },
  {
    value: 'America/Manaus',
    key: 'America/Manaus',
    text: 'America/Manaus',
  },
  {
    value: 'America/Marigot',
    key: 'America/Marigot',
    text: 'America/Marigot',
  },
  {
    value: 'America/Martinique',
    key: 'America/Martinique',
    text: 'America/Martinique',
  },
  {
    value: 'America/Matamoros',
    key: 'America/Matamoros',
    text: 'America/Matamoros',
  },
  {
    value: 'America/Mazatlan',
    key: 'America/Mazatlan',
    text: 'America/Mazatlan',
  },
  {
    value: 'America/Mendoza',
    key: 'America/Mendoza',
    text: 'America/Mendoza',
  },
  {
    value: 'America/Menominee',
    key: 'America/Menominee',
    text: 'America/Menominee',
  },
  {
    value: 'America/Merida',
    key: 'America/Merida',
    text: 'America/Merida',
  },
  {
    value: 'America/Mexico_City',
    key: 'America/Mexico_City',
    text: 'America/Mexico_City',
  },
  {
    value: 'America/Miquelon',
    key: 'America/Miquelon',
    text: 'America/Miquelon',
  },
  {
    value: 'America/Moncton',
    key: 'America/Moncton',
    text: 'America/Moncton',
  },
  {
    value: 'America/Monterrey',
    key: 'America/Monterrey',
    text: 'America/Monterrey',
  },
  {
    value: 'America/Montevideo',
    key: 'America/Montevideo',
    text: 'America/Montevideo',
  },
  {
    value: 'America/Montreal',
    key: 'America/Montreal',
    text: 'America/Montreal',
  },
  {
    value: 'America/Montserrat',
    key: 'America/Montserrat',
    text: 'America/Montserrat',
  },
  {
    value: 'America/Nassau',
    key: 'America/Nassau',
    text: 'America/Nassau',
  },
  {
    value: 'America/Nipigon',
    key: 'America/Nipigon',
    text: 'America/Nipigon',
  },
  {value: 'America/Nome', key: 'America/Nome', text: 'America/Nome'},
  {
    value: 'America/Noronha',
    key: 'America/Noronha',
    text: 'America/Noronha',
  },
  {
    value: 'America/North_Dakota/Center',
    key: 'America/North_Dakota/Center',
    text: 'America/North_Dakota/Center',
  },
  {
    value: 'America/North_Dakota/New_Salem',
    key: 'America/North_Dakota/New_Salem',
    text: 'America/North_Dakota/New_Salem',
  },
  {
    value: 'America/Ojinaga',
    key: 'America/Ojinaga',
    text: 'America/Ojinaga',
  },
  {
    value: 'America/Panama',
    key: 'America/Panama',
    text: 'America/Panama',
  },
  {
    value: 'America/Pangnirtung',
    key: 'America/Pangnirtung',
    text: 'America/Pangnirtung',
  },
  {
    value: 'America/Paramaribo',
    key: 'America/Paramaribo',
    text: 'America/Paramaribo',
  },
  {
    value: 'America/Port-au-Prince',
    key: 'America/Port-au-Prince',
    text: 'America/Port-au-Prince',
  },
  {
    value: 'America/Porto_Acre',
    key: 'America/Porto_Acre',
    text: 'America/Porto_Acre',
  },
  {
    value: 'America/Port_of_Spain',
    key: 'America/Port_of_Spain',
    text: 'America/Port_of_Spain',
  },
  {
    value: 'America/Porto_Velho',
    key: 'America/Porto_Velho',
    text: 'America/Porto_Velho',
  },
  {
    value: 'America/Rainy_River',
    key: 'America/Rainy_River',
    text: 'America/Rainy_River',
  },
  {
    value: 'America/Rankin_Inlet',
    key: 'America/Rankin_Inlet',
    text: 'America/Rankin_Inlet',
  },
  {
    value: 'America/Recife',
    key: 'America/Recife',
    text: 'America/Recife',
  },
  {
    value: 'America/Regina',
    key: 'America/Regina',
    text: 'America/Regina',
  },
  {
    value: 'America/Resolute',
    key: 'America/Resolute',
    text: 'America/Resolute',
  },
  {
    value: 'America/Rio_Branco',
    key: 'America/Rio_Branco',
    text: 'America/Rio_Branco',
  },
  {
    value: 'America/Rosario',
    key: 'America/Rosario',
    text: 'America/Rosario',
  },
  {
    value: 'America/Santa_Isabel',
    key: 'America/Santa_Isabel',
    text: 'America/Santa_Isabel',
  },
  {
    value: 'America/Santarem',
    key: 'America/Santarem',
    text: 'America/Santarem',
  },
  {
    value: 'America/Santiago',
    key: 'America/Santiago',
    text: 'America/Santiago',
  },
  {
    value: 'America/Santo_Domingo',
    key: 'America/Santo_Domingo',
    text: 'America/Santo_Domingo',
  },
  {
    value: 'America/Sao_Paulo',
    key: 'America/Sao_Paulo',
    text: 'America/Sao_Paulo',
  },
  {
    value: 'America/Scoresbysund',
    key: 'America/Scoresbysund',
    text: 'America/Scoresbysund',
  },
  {
    value: 'America/Shiprock',
    key: 'America/Shiprock',
    text: 'America/Shiprock',
  },
  {
    value: 'America/St_Barthelemy',
    key: 'America/St_Barthelemy',
    text: 'America/St_Barthelemy',
  },
  {
    value: 'America/St_Johns',
    key: 'America/St_Johns',
    text: 'America/St_Johns',
  },
  {
    value: 'America/St_Kitts',
    key: 'America/St_Kitts',
    text: 'America/St_Kitts',
  },
  {
    value: 'America/St_Lucia',
    key: 'America/St_Lucia',
    text: 'America/St_Lucia',
  },
  {
    value: 'America/St_Thomas',
    key: 'America/St_Thomas',
    text: 'America/St_Thomas',
  },
  {
    value: 'America/St_Vincent',
    key: 'America/St_Vincent',
    text: 'America/St_Vincent',
  },
  {
    value: 'America/Swift_Current',
    key: 'America/Swift_Current',
    text: 'America/Swift_Current',
  },
  {
    value: 'America/Tegucigalpa',
    key: 'America/Tegucigalpa',
    text: 'America/Tegucigalpa',
  },
  {
    value: 'America/Thule',
    key: 'America/Thule',
    text: 'America/Thule',
  },
  {
    value: 'America/Thunder_Bay',
    key: 'America/Thunder_Bay',
    text: 'America/Thunder_Bay',
  },
  {
    value: 'America/Tijuana',
    key: 'America/Tijuana',
    text: 'America/Tijuana',
  },
  {
    value: 'America/Toronto',
    key: 'America/Toronto',
    text: 'America/Toronto',
  },
  {
    value: 'America/Tortola',
    key: 'America/Tortola',
    text: 'America/Tortola',
  },
  {
    value: 'America/Vancouver',
    key: 'America/Vancouver',
    text: 'America/Vancouver',
  },
  {
    value: 'America/Virgin',
    key: 'America/Virgin',
    text: 'America/Virgin',
  },
  {
    value: 'America/Whitehorse',
    key: 'America/Whitehorse',
    text: 'America/Whitehorse',
  },
  {
    value: 'America/Winnipeg',
    key: 'America/Winnipeg',
    text: 'America/Winnipeg',
  },
  {
    value: 'America/Yakutat',
    key: 'America/Yakutat',
    text: 'America/Yakutat',
  },
  {
    value: 'America/Yellowknife',
    key: 'America/Yellowknife',
    text: 'America/Yellowknife',
  },
  {
    value: 'America/Puerto_Rico',
    key: 'America/Puerto_Rico',
    text: 'America/Puerto_Rico',
  },
  {
    value: 'America/New_York',
    key: 'America/New_York',
    text: 'America/New_York',
  },

  {
    value: 'America/Phoenix',
    key: 'America/Phoenix',
    text: 'America/Phoenix',
  },
  {
    value: 'America/Los_Angeles',
    key: 'America/Los_Angeles',
    text: 'America/Los_Angeles',
  },
  {
    value: 'Pacific/Honolulu',
    key: 'Pacific/Honolulu',
    text: 'Pacific/Honolulu',
  },
  {
    value: 'Europe/Amsterdam',
    key: 'Europe/Amsterdam',
    text: 'Europe/Amsterdam',
  },
  {
    value: 'Europe/Andorra',
    key: 'Europe/Andorra',
    text: 'Europe/Andorra',
  },
  {
    value: 'Europe/Athens',
    key: 'Europe/Athens',
    text: 'Europe/Athens',
  },
  {
    value: 'Europe/Belfast',
    key: 'Europe/Belfast',
    text: 'Europe/Belfast',
  },
  {
    value: 'Europe/Belgrade',
    key: 'Europe/Belgrade',
    text: 'Europe/Belgrade',
  },
  {
    value: 'Europe/Berlin',
    key: 'Europe/Berlin',
    text: 'Europe/Berlin',
  },
  {
    value: 'Europe/Bratislava',
    key: 'Europe/Bratislava',
    text: 'Europe/Bratislava',
  },
  {
    value: 'Europe/Brussels',
    key: 'Europe/Brussels',
    text: 'Europe/Brussels',
  },
  {
    value: 'Europe/Bucharest',
    key: 'Europe/Bucharest',
    text: 'Europe/Bucharest',
  },
  {
    value: 'Europe/Budapest',
    key: 'Europe/Budapest',
    text: 'Europe/Budapest',
  },
  {
    value: 'Europe/Chisinau',
    key: 'Europe/Chisinau',
    text: 'Europe/Chisinau',
  },
  {
    value: 'Europe/Copenhagen',
    key: 'Europe/Copenhagen',
    text: 'Europe/Copenhagen',
  },
  {
    value: 'Europe/Dublin',
    key: 'Europe/Dublin',
    text: 'Europe/Dublin',
  },
  {
    value: 'Europe/Gibraltar',
    key: 'Europe/Gibraltar',
    text: 'Europe/Gibraltar',
  },
  {
    value: 'Europe/Guernsey',
    key: 'Europe/Guernsey',
    text: 'Europe/Guernsey',
  },
  {
    value: 'Europe/Helsinki',
    key: 'Europe/Helsinki',
    text: 'Europe/Helsinki',
  },
  {
    value: 'Europe/Isle_of_Man',
    key: 'Europe/Isle_of_Man',
    text: 'Europe/Isle_of_Man',
  },
  {
    value: 'Europe/Istanbul',
    key: 'Europe/Istanbul',
    text: 'Europe/Istanbul',
  },
  {
    value: 'Europe/Jersey',
    key: 'Europe/Jersey',
    text: 'Europe/Jersey',
  },
  {
    value: 'Europe/Kaliningrad',
    key: 'Europe/Kaliningrad',
    text: 'Europe/Kaliningrad',
  },
  {value: 'Europe/Kiev', key: 'Europe/Kiev', text: 'Europe/Kiev'},
  {
    value: 'Europe/Lisbon',
    key: 'Europe/Lisbon',
    text: 'Europe/Lisbon',
  },
  {
    value: 'Europe/Ljubljana',
    key: 'Europe/Ljubljana',
    text: 'Europe/Ljubljana',
  },
  {
    value: 'Europe/London',
    key: 'Europe/London',
    text: 'Europe/London',
  },
  {
    value: 'Europe/Luxembourg',
    key: 'Europe/Luxembourg',
    text: 'Europe/Luxembourg',
  },
  {
    value: 'Europe/Madrid',
    key: 'Europe/Madrid',
    text: 'Europe/Madrid',
  },
  {value: 'Europe/Malta', key: 'Europe/Malta', text: 'Europe/Malta'},
  {
    value: 'Europe/Mariehamn',
    key: 'Europe/Mariehamn',
    text: 'Europe/Mariehamn',
  },
  {value: 'Europe/Minsk', key: 'Europe/Minsk', text: 'Europe/Minsk'},
  {
    value: 'Europe/Monaco',
    key: 'Europe/Monaco',
    text: 'Europe/Monaco',
  },
  {
    value: 'Europe/Moscow',
    key: 'Europe/Moscow',
    text: 'Europe/Moscow',
  },
  {
    value: 'Europe/Nicosia',
    key: 'Europe/Nicosia',
    text: 'Europe/Nicosia',
  },
  {value: 'Europe/Oslo', key: 'Europe/Oslo', text: 'Europe/Oslo'},
  {value: 'Europe/Paris', key: 'Europe/Paris', text: 'Europe/Paris'},
  {
    value: 'Europe/Podgorica',
    key: 'Europe/Podgorica',
    text: 'Europe/Podgorica',
  },
  {
    value: 'Europe/Prague',
    key: 'Europe/Prague',
    text: 'Europe/Prague',
  },
  {value: 'Europe/Riga', key: 'Europe/Riga', text: 'Europe/Riga'},
  {value: 'Europe/Rome', key: 'Europe/Rome', text: 'Europe/Rome'},
  {
    value: 'Europe/Samara',
    key: 'Europe/Samara',
    text: 'Europe/Samara',
  },
  {
    value: 'Europe/San_Marino',
    key: 'Europe/San_Marino',
    text: 'Europe/San_Marino',
  },
  {
    value: 'Europe/Sarajevo',
    key: 'Europe/Sarajevo',
    text: 'Europe/Sarajevo',
  },
  {
    value: 'Europe/Simferopol',
    key: 'Europe/Simferopol',
    text: 'Europe/Simferopol',
  },
  {
    value: 'Europe/Skopje',
    key: 'Europe/Skopje',
    text: 'Europe/Skopje',
  },
  {value: 'Europe/Sofia', key: 'Europe/Sofia', text: 'Europe/Sofia'},
  {
    value: 'Europe/Stockholm',
    key: 'Europe/Stockholm',
    text: 'Europe/Stockholm',
  },
  {
    value: 'Europe/Tallinn',
    key: 'Europe/Tallinn',
    text: 'Europe/Tallinn',
  },
  {
    value: 'Europe/Tirane',
    key: 'Europe/Tirane',
    text: 'Europe/Tirane',
  },
  {
    value: 'Europe/Tiraspol',
    key: 'Europe/Tiraspol',
    text: 'Europe/Tiraspol',
  },
  {
    value: 'Europe/Uzhgorod',
    key: 'Europe/Uzhgorod',
    text: 'Europe/Uzhgorod',
  },
  {value: 'Europe/Vaduz', key: 'Europe/Vaduz', text: 'Europe/Vaduz'},
  {
    value: 'Europe/Vatican',
    key: 'Europe/Vatican',
    text: 'Europe/Vatican',
  },
  {
    value: 'Europe/Vienna',
    key: 'Europe/Vienna',
    text: 'Europe/Vienna',
  },
  {
    value: 'Europe/Vilnius',
    key: 'Europe/Vilnius',
    text: 'Europe/Vilnius',
  },
  {
    value: 'Europe/Volgograd',
    key: 'Europe/Volgograd',
    text: 'Europe/Volgograd',
  },
  {
    value: 'Europe/Warsaw',
    key: 'Europe/Warsaw',
    text: 'Europe/Warsaw',
  },
  {
    value: 'Europe/Zagreb',
    key: 'Europe/Zagreb',
    text: 'Europe/Zagreb',
  },
  {
    value: 'Europe/Zaporozhye',
    key: 'Europe/Zaporozhye',
    text: 'Europe/Zaporozhye',
  },
  {
    value: 'Europe/Zurich',
    key: 'Europe/Zurich',
    text: 'Europe/Zurich',
  },
  {value: 'Asia/Aden', key: 'Asia/Aden', text: 'Asia/Aden'},
  {value: 'Asia/Almaty', key: 'Asia/Almaty', text: 'Asia/Almaty'},
  {value: 'Asia/Amman', key: 'Asia/Amman', text: 'Asia/Amman'},
  {value: 'Asia/Anadyr', key: 'Asia/Anadyr', text: 'Asia/Anadyr'},
  {value: 'Asia/Aqtau', key: 'Asia/Aqtau', text: 'Asia/Aqtau'},
  {value: 'Asia/Aqtobe', key: 'Asia/Aqtobe', text: 'Asia/Aqtobe'},
  {
    value: 'Asia/Ashgabat',
    key: 'Asia/Ashgabat',
    text: 'Asia/Ashgabat',
  },
  {
    value: 'Asia/Ashkhabad',
    key: 'Asia/Ashkhabad',
    text: 'Asia/Ashkhabad',
  },
  {value: 'Asia/Baghdad', key: 'Asia/Baghdad', text: 'Asia/Baghdad'},
  {value: 'Asia/Bahrain', key: 'Asia/Bahrain', text: 'Asia/Bahrain'},
  {value: 'Asia/Baku', key: 'Asia/Baku', text: 'Asia/Baku'},
  {value: 'Asia/Bangkok', key: 'Asia/Bangkok', text: 'Asia/Bangkok'},
  {value: 'Asia/Beirut', key: 'Asia/Beirut', text: 'Asia/Beirut'},
  {value: 'Asia/Bishkek', key: 'Asia/Bishkek', text: 'Asia/Bishkek'},
  {value: 'Asia/Brunei', key: 'Asia/Brunei', text: 'Asia/Brunei'},
  {
    value: 'Asia/Calcutta',
    key: 'Asia/Calcutta',
    text: 'Asia/Calcutta',
  },
  {
    value: 'Asia/Choibalsan',
    key: 'Asia/Choibalsan',
    text: 'Asia/Choibalsan',
  },
  {
    value: 'Asia/Chongqing',
    key: 'Asia/Chongqing',
    text: 'Asia/Chongqing',
  },
  {
    value: 'Asia/Chungking',
    key: 'Asia/Chungking',
    text: 'Asia/Chungking',
  },
  {value: 'Asia/Colombo', key: 'Asia/Colombo', text: 'Asia/Colombo'},
  {value: 'Asia/Dacca', key: 'Asia/Dacca', text: 'Asia/Dacca'},
  {
    value: 'Asia/Damascus',
    key: 'Asia/Damascus',
    text: 'Asia/Damascus',
  },
  {value: 'Asia/Dhaka', key: 'Asia/Dhaka', text: 'Asia/Dhaka'},
  {value: 'Asia/Dili', key: 'Asia/Dili', text: 'Asia/Dili'},
  {value: 'Asia/Dubai', key: 'Asia/Dubai', text: 'Asia/Dubai'},
  {
    value: 'Asia/Dushanbe',
    key: 'Asia/Dushanbe',
    text: 'Asia/Dushanbe',
  },
  {value: 'Asia/Gaza', key: 'Asia/Gaza', text: 'Asia/Gaza'},
  {value: 'Asia/Harbin', key: 'Asia/Harbin', text: 'Asia/Harbin'},
  {
    value: 'Asia/Ho_Chi_Minh',
    key: 'Asia/Ho_Chi_Minh',
    text: 'Asia/Ho_Chi_Minh',
  },
  {
    value: 'Asia/Hong_Kong',
    key: 'Asia/Hong_Kong',
    text: 'Asia/Hong_Kong',
  },
  {value: 'Asia/Hovd', key: 'Asia/Hovd', text: 'Asia/Hovd'},
  {value: 'Asia/Irkutsk', key: 'Asia/Irkutsk', text: 'Asia/Irkutsk'},
  {
    value: 'Asia/Istanbul',
    key: 'Asia/Istanbul',
    text: 'Asia/Istanbul',
  },
  {value: 'Asia/Jakarta', key: 'Asia/Jakarta', text: 'Asia/Jakarta'},
  {
    value: 'Asia/Jayapura',
    key: 'Asia/Jayapura',
    text: 'Asia/Jayapura',
  },
  {
    value: 'Asia/Jerusalem',
    key: 'Asia/Jerusalem',
    text: 'Asia/Jerusalem',
  },
  {value: 'Asia/Kabul', key: 'Asia/Kabul', text: 'Asia/Kabul'},
  {
    value: 'Asia/Kamchatka',
    key: 'Asia/Kamchatka',
    text: 'Asia/Kamchatka',
  },
  {value: 'Asia/Karachi', key: 'Asia/Karachi', text: 'Asia/Karachi'},
  {value: 'Asia/Kashgar', key: 'Asia/Kashgar', text: 'Asia/Kashgar'},
  {
    value: 'Asia/Kathmandu',
    key: 'Asia/Kathmandu',
    text: 'Asia/Kathmandu',
  },
  {
    value: 'Asia/Katmandu',
    key: 'Asia/Katmandu',
    text: 'Asia/Katmandu',
  },
  {value: 'Asia/Kolkata', key: 'Asia/Kolkata', text: 'Asia/Kolkata'},
  {
    value: 'Asia/Krasnoyarsk',
    key: 'Asia/Krasnoyarsk',
    text: 'Asia/Krasnoyarsk',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    key: 'Asia/Kuala_Lumpur',
    text: 'Asia/Kuala_Lumpur',
  },
  {value: 'Asia/Kuching', key: 'Asia/Kuching', text: 'Asia/Kuching'},
  {value: 'Asia/Kuwait', key: 'Asia/Kuwait', text: 'Asia/Kuwait'},
  {value: 'Asia/Macao', key: 'Asia/Macao', text: 'Asia/Macao'},
  {value: 'Asia/Macau', key: 'Asia/Macau', text: 'Asia/Macau'},
  {value: 'Asia/Magadan', key: 'Asia/Magadan', text: 'Asia/Magadan'},
  {
    value: 'Asia/Makassar',
    key: 'Asia/Makassar',
    text: 'Asia/Makassar',
  },
  {value: 'Asia/Manila', key: 'Asia/Manila', text: 'Asia/Manila'},
  {value: 'Asia/Muscat', key: 'Asia/Muscat', text: 'Asia/Muscat'},
  {value: 'Asia/Nicosia', key: 'Asia/Nicosia', text: 'Asia/Nicosia'},
  {
    value: 'Asia/Novokuznetsk',
    key: 'Asia/Novokuznetsk',
    text: 'Asia/Novokuznetsk',
  },
  {
    value: 'Asia/Novosibirsk',
    key: 'Asia/Novosibirsk',
    text: 'Asia/Novosibirsk',
  },
  {value: 'Asia/Omsk', key: 'Asia/Omsk', text: 'Asia/Omsk'},
  {value: 'Asia/Oral', key: 'Asia/Oral', text: 'Asia/Oral'},
  {
    value: 'Asia/Phnom_Penh',
    key: 'Asia/Phnom_Penh',
    text: 'Asia/Phnom_Penh',
  },
  {
    value: 'Asia/Pontianak',
    key: 'Asia/Pontianak',
    text: 'Asia/Pontianak',
  },
  {
    value: 'Asia/Pyongyang',
    key: 'Asia/Pyongyang',
    text: 'Asia/Pyongyang',
  },
  {value: 'Asia/Qatar', key: 'Asia/Qatar', text: 'Asia/Qatar'},
  {
    value: 'Asia/Qyzylorda',
    key: 'Asia/Qyzylorda',
    text: 'Asia/Qyzylorda',
  },
  {value: 'Asia/Rangoon', key: 'Asia/Rangoon', text: 'Asia/Rangoon'},
  {value: 'Asia/Riyadh', key: 'Asia/Riyadh', text: 'Asia/Riyadh'},
  {value: 'Asia/Saigon', key: 'Asia/Saigon', text: 'Asia/Saigon'},
  {
    value: 'Asia/Sakhalin',
    key: 'Asia/Sakhalin',
    text: 'Asia/Sakhalin',
  },
  {
    value: 'Asia/Samarkand',
    key: 'Asia/Samarkand',
    text: 'Asia/Samarkand',
  },
  {value: 'Asia/Seoul', key: 'Asia/Seoul', text: 'Asia/Seoul'},
  {
    value: 'Asia/Shanghai',
    key: 'Asia/Shanghai',
    text: 'Asia/Shanghai',
  },
  {
    value: 'Asia/Singapore',
    key: 'Asia/Singapore',
    text: 'Asia/Singapore',
  },
  {value: 'Asia/Taipei', key: 'Asia/Taipei', text: 'Asia/Taipei'},
  {
    value: 'Asia/Tashkent',
    key: 'Asia/Tashkent',
    text: 'Asia/Tashkent',
  },
  {value: 'Asia/Tbilisi', key: 'Asia/Tbilisi', text: 'Asia/Tbilisi'},
  {value: 'Asia/Tehran', key: 'Asia/Tehran', text: 'Asia/Tehran'},
  {
    value: 'Asia/Tel_Aviv',
    key: 'Asia/Tel_Aviv',
    text: 'Asia/Tel_Aviv',
  },
  {value: 'Asia/Thimbu', key: 'Asia/Thimbu', text: 'Asia/Thimbu'},
  {value: 'Asia/Thimphu', key: 'Asia/Thimphu', text: 'Asia/Thimphu'},
  {value: 'Asia/Tokyo', key: 'Asia/Tokyo', text: 'Asia/Tokyo'},
  {
    value: 'Asia/Ujung_Pandang',
    key: 'Asia/Ujung_Pandang',
    text: 'Asia/Ujung_Pandang',
  },
  {
    value: 'Asia/Ulaanbaatar',
    key: 'Asia/Ulaanbaatar',
    text: 'Asia/Ulaanbaatar',
  },
  {
    value: 'Asia/Ulan_Bator',
    key: 'Asia/Ulan_Bator',
    text: 'Asia/Ulan_Bator',
  },
  {value: 'Asia/Urumqi', key: 'Asia/Urumqi', text: 'Asia/Urumqi'},
  {
    value: 'Asia/Vientiane',
    key: 'Asia/Vientiane',
    text: 'Asia/Vientiane',
  },
  {
    value: 'Asia/Vladivostok',
    key: 'Asia/Vladivostok',
    text: 'Asia/Vladivostok',
  },
  {value: 'Asia/Yakutsk', key: 'Asia/Yakutsk', text: 'Asia/Yakutsk'},
  {
    value: 'Asia/Yekaterinburg',
    key: 'Asia/Yekaterinburg',
    text: 'Asia/Yekaterinburg',
  },
  {value: 'Asia/Yerevan', key: 'Asia/Yerevan', text: 'Asia/Yerevan'},
  {
    value: 'Africa/Abidjan',
    key: 'Africa/Abidjan',
    text: 'Africa/Abidjan',
  },
  {value: 'Africa/Accra', key: 'Africa/Accra', text: 'Africa/Accra'},
  {
    value: 'Africa/Addis_Ababa',
    key: 'Africa/Addis_Ababa',
    text: 'Africa/Addis_Ababa',
  },
  {
    value: 'Africa/Algiers',
    key: 'Africa/Algiers',
    text: 'Africa/Algiers',
  },
  {
    value: 'Africa/Asmara',
    key: 'Africa/Asmara',
    text: 'Africa/Asmara',
  },
  {
    value: 'Africa/Asmera',
    key: 'Africa/Asmera',
    text: 'Africa/Asmera',
  },
  {
    value: 'Africa/Bamako',
    key: 'Africa/Bamako',
    text: 'Africa/Bamako',
  },
  {
    value: 'Africa/Bangui',
    key: 'Africa/Bangui',
    text: 'Africa/Bangui',
  },
  {
    value: 'Africa/Banjul',
    key: 'Africa/Banjul',
    text: 'Africa/Banjul',
  },
  {
    value: 'Africa/Bissau',
    key: 'Africa/Bissau',
    text: 'Africa/Bissau',
  },
  {
    value: 'Africa/Blantyre',
    key: 'Africa/Blantyre',
    text: 'Africa/Blantyre',
  },
  {
    value: 'Africa/Brazzaville',
    key: 'Africa/Brazzaville',
    text: 'Africa/Brazzaville',
  },
  {
    value: 'Africa/Bujumbura',
    key: 'Africa/Bujumbura',
    text: 'Africa/Bujumbura',
  },
  {value: 'Africa/Cairo', key: 'Africa/Cairo', text: 'Africa/Cairo'},
  {
    value: 'Africa/Casablanca',
    key: 'Africa/Casablanca',
    text: 'Africa/Casablanca',
  },
  {value: 'Africa/Ceuta', key: 'Africa/Ceuta', text: 'Africa/Ceuta'},
  {
    value: 'Africa/Conakry',
    key: 'Africa/Conakry',
    text: 'Africa/Conakry',
  },
  {value: 'Africa/Dakar', key: 'Africa/Dakar', text: 'Africa/Dakar'},
  {
    value: 'Africa/Dar_es_Salaam',
    key: 'Africa/Dar_es_Salaam',
    text: 'Africa/Dar_es_Salaam',
  },
  {
    value: 'Africa/Djibouti',
    key: 'Africa/Djibouti',
    text: 'Africa/Djibouti',
  },
  {
    value: 'Africa/Douala',
    key: 'Africa/Douala',
    text: 'Africa/Douala',
  },
  {
    value: 'Africa/El_Aaiun',
    key: 'Africa/El_Aaiun',
    text: 'Africa/El_Aaiun',
  },
  {
    value: 'Africa/Freetown',
    key: 'Africa/Freetown',
    text: 'Africa/Freetown',
  },
  {
    value: 'Africa/Gaborone',
    key: 'Africa/Gaborone',
    text: 'Africa/Gaborone',
  },
  {
    value: 'Africa/Harare',
    key: 'Africa/Harare',
    text: 'Africa/Harare',
  },
  {
    value: 'Africa/Johannesburg',
    key: 'Africa/Johannesburg',
    text: 'Africa/Johannesburg',
  },
  {
    value: 'Africa/Kampala',
    key: 'Africa/Kampala',
    text: 'Africa/Kampala',
  },
  {
    value: 'Africa/Khartoum',
    key: 'Africa/Khartoum',
    text: 'Africa/Khartoum',
  },
  {
    value: 'Africa/Kigali',
    key: 'Africa/Kigali',
    text: 'Africa/Kigali',
  },
  {
    value: 'Africa/Kinshasa',
    key: 'Africa/Kinshasa',
    text: 'Africa/Kinshasa',
  },
  {value: 'Africa/Lagos', key: 'Africa/Lagos', text: 'Africa/Lagos'},
  {
    value: 'Africa/Libreville',
    key: 'Africa/Libreville',
    text: 'Africa/Libreville',
  },
  {value: 'Africa/Lome', key: 'Africa/Lome', text: 'Africa/Lome'},
  {
    value: 'Africa/Luanda',
    key: 'Africa/Luanda',
    text: 'Africa/Luanda',
  },
  {
    value: 'Africa/Lubumbashi',
    key: 'Africa/Lubumbashi',
    text: 'Africa/Lubumbashi',
  },
  {
    value: 'Africa/Lusaka',
    key: 'Africa/Lusaka',
    text: 'Africa/Lusaka',
  },
  {
    value: 'Africa/Malabo',
    key: 'Africa/Malabo',
    text: 'Africa/Malabo',
  },
  {
    value: 'Africa/Maputo',
    key: 'Africa/Maputo',
    text: 'Africa/Maputo',
  },
  {
    value: 'Africa/Maseru',
    key: 'Africa/Maseru',
    text: 'Africa/Maseru',
  },
  {
    value: 'Africa/Mbabane',
    key: 'Africa/Mbabane',
    text: 'Africa/Mbabane',
  },
  {
    value: 'Africa/Mogadishu',
    key: 'Africa/Mogadishu',
    text: 'Africa/Mogadishu',
  },
  {
    value: 'Africa/Monrovia',
    key: 'Africa/Monrovia',
    text: 'Africa/Monrovia',
  },
  {
    value: 'Africa/Nairobi',
    key: 'Africa/Nairobi',
    text: 'Africa/Nairobi',
  },
  {
    value: 'Africa/Ndjamena',
    key: 'Africa/Ndjamena',
    text: 'Africa/Ndjamena',
  },
  {
    value: 'Africa/Niamey',
    key: 'Africa/Niamey',
    text: 'Africa/Niamey',
  },
  {
    value: 'Africa/Nouakchott',
    key: 'Africa/Nouakchott',
    text: 'Africa/Nouakchott',
  },
  {
    value: 'Africa/Ouagadougou',
    key: 'Africa/Ouagadougou',
    text: 'Africa/Ouagadougou',
  },
  {
    value: 'Africa/Porto-Novo',
    key: 'Africa/Porto-Novo',
    text: 'Africa/Porto-Novo',
  },
  {
    value: 'Africa/Sao_Tome',
    key: 'Africa/Sao_Tome',
    text: 'Africa/Sao_Tome',
  },
  {
    value: 'Africa/Timbuktu',
    key: 'Africa/Timbuktu',
    text: 'Africa/Timbuktu',
  },
  {
    value: 'Africa/Tripoli',
    key: 'Africa/Tripoli',
    text: 'Africa/Tripoli',
  },
  {value: 'Africa/Tunis', key: 'Africa/Tunis', text: 'Africa/Tunis'},
  {
    value: 'Africa/Windhoek',
    key: 'Africa/Windhoek',
    text: 'Africa/Windhoek',
  },
  {
    value: 'Australia/ACT',
    key: 'Australia/ACT',
    text: 'Australia/ACT',
  },
  {
    value: 'Australia/Adelaide',
    key: 'Australia/Adelaide',
    text: 'Australia/Adelaide',
  },
  {
    value: 'Australia/Brisbane',
    key: 'Australia/Brisbane',
    text: 'Australia/Brisbane',
  },
  {
    value: 'Australia/Broken_Hill',
    key: 'Australia/Broken_Hill',
    text: 'Australia/Broken_Hill',
  },
  {
    value: 'Australia/Canberra',
    key: 'Australia/Canberra',
    text: 'Australia/Canberra',
  },
  {
    value: 'Australia/Currie',
    key: 'Australia/Currie',
    text: 'Australia/Currie',
  },
  {
    value: 'Australia/Darwin',
    key: 'Australia/Darwin',
    text: 'Australia/Darwin',
  },
  {
    value: 'Australia/Eucla',
    key: 'Australia/Eucla',
    text: 'Australia/Eucla',
  },
  {
    value: 'Australia/Hobart',
    key: 'Australia/Hobart',
    text: 'Australia/Hobart',
  },
  {
    value: 'Australia/LHI',
    key: 'Australia/LHI',
    text: 'Australia/LHI',
  },
  {
    value: 'Australia/Lindeman',
    key: 'Australia/Lindeman',
    text: 'Australia/Lindeman',
  },
  {
    value: 'Australia/Lord_Howe',
    key: 'Australia/Lord_Howe',
    text: 'Australia/Lord_Howe',
  },
  {
    value: 'Australia/Melbourne',
    key: 'Australia/Melbourne',
    text: 'Australia/Melbourne',
  },
  {
    value: 'Australia/North',
    key: 'Australia/North',
    text: 'Australia/North',
  },
  {
    value: 'Australia/NSW',
    key: 'Australia/NSW',
    text: 'Australia/NSW',
  },
  {
    value: 'Australia/Perth',
    key: 'Australia/Perth',
    text: 'Australia/Perth',
  },
  {
    value: 'Australia/Queensland',
    key: 'Australia/Queensland',
    text: 'Australia/Queensland',
  },
  {
    value: 'Australia/South',
    key: 'Australia/South',
    text: 'Australia/South',
  },
  {
    value: 'Australia/Sydney',
    key: 'Australia/Sydney',
    text: 'Australia/Sydney',
  },
  {
    value: 'Australia/Tasmania',
    key: 'Australia/Tasmania',
    text: 'Australia/Tasmania',
  },
  {
    value: 'Australia/Victoria',
    key: 'Australia/Victoria',
    text: 'Australia/Victoria',
  },
  {
    value: 'Australia/West',
    key: 'Australia/West',
    text: 'Australia/West',
  },
  {
    value: 'Australia/Yancowinna',
    key: 'Australia/Yancowinna',
    text: 'Australia/Yancowinna',
  },
  {
    value: 'Indian/Antananarivo',
    key: 'Indian/Antananarivo',
    text: 'Indian/Antananarivo',
  },
  {
    value: 'Indian/Chagos',
    key: 'Indian/Chagos',
    text: 'Indian/Chagos',
  },
  {
    value: 'Indian/Christmas',
    key: 'Indian/Christmas',
    text: 'Indian/Christmas',
  },
  {value: 'Indian/Cocos', key: 'Indian/Cocos', text: 'Indian/Cocos'},
  {
    value: 'Indian/Comoro',
    key: 'Indian/Comoro',
    text: 'Indian/Comoro',
  },
  {
    value: 'Indian/Kerguelen',
    key: 'Indian/Kerguelen',
    text: 'Indian/Kerguelen',
  },
  {value: 'Indian/Mahe', key: 'Indian/Mahe', text: 'Indian/Mahe'},
  {
    value: 'Indian/Maldives',
    key: 'Indian/Maldives',
    text: 'Indian/Maldives',
  },
  {
    value: 'Indian/Mauritius',
    key: 'Indian/Mauritius',
    text: 'Indian/Mauritius',
  },
  {
    value: 'Indian/Mayotte',
    key: 'Indian/Mayotte',
    text: 'Indian/Mayotte',
  },
  {
    value: 'Indian/Reunion',
    key: 'Indian/Reunion',
    text: 'Indian/Reunion',
  },
  {
    value: 'Atlantic/Azores',
    key: 'Atlantic/Azores',
    text: 'Atlantic/Azores',
  },
  {
    value: 'Atlantic/Bermuda',
    key: 'Atlantic/Bermuda',
    text: 'Atlantic/Bermuda',
  },
  {
    value: 'Atlantic/Canary',
    key: 'Atlantic/Canary',
    text: 'Atlantic/Canary',
  },
  {
    value: 'Atlantic/Cape_Verde',
    key: 'Atlantic/Cape_Verde',
    text: 'Atlantic/Cape_Verde',
  },
  {
    value: 'Atlantic/Faeroe',
    key: 'Atlantic/Faeroe',
    text: 'Atlantic/Faeroe',
  },
  {
    value: 'Atlantic/Faroe',
    key: 'Atlantic/Faroe',
    text: 'Atlantic/Faroe',
  },
  {
    value: 'Atlantic/Jan_Mayen',
    key: 'Atlantic/Jan_Mayen',
    text: 'Atlantic/Jan_Mayen',
  },
  {
    value: 'Atlantic/Madeira',
    key: 'Atlantic/Madeira',
    text: 'Atlantic/Madeira',
  },
  {
    value: 'Atlantic/Reykjavik',
    key: 'Atlantic/Reykjavik',
    text: 'Atlantic/Reykjavik',
  },
  {
    value: 'Atlantic/South_Georgia',
    key: 'Atlantic/South_Georgia',
    text: 'Atlantic/South_Georgia',
  },
  {
    value: 'Atlantic/Stanley',
    key: 'Atlantic/Stanley',
    text: 'Atlantic/Stanley',
  },
  {
    value: 'Atlantic/St_Helena',
    key: 'Atlantic/St_Helena',
    text: 'Atlantic/St_Helena',
  },
  {value: 'Pacific/Apia', key: 'Pacific/Apia', text: 'Pacific/Apia'},
  {
    value: 'Pacific/Auckland',
    key: 'Pacific/Auckland',
    text: 'Pacific/Auckland',
  },
  {
    value: 'Pacific/Chatham',
    key: 'Pacific/Chatham',
    text: 'Pacific/Chatham',
  },
  {
    value: 'Pacific/Easter',
    key: 'Pacific/Easter',
    text: 'Pacific/Easter',
  },
  {
    value: 'Pacific/Efate',
    key: 'Pacific/Efate',
    text: 'Pacific/Efate',
  },
  {
    value: 'Pacific/Enderbury',
    key: 'Pacific/Enderbury',
    text: 'Pacific/Enderbury',
  },
  {
    value: 'Pacific/Fakaofo',
    key: 'Pacific/Fakaofo',
    text: 'Pacific/Fakaofo',
  },
  {value: 'Pacific/Fiji', key: 'Pacific/Fiji', text: 'Pacific/Fiji'},
  {
    value: 'Pacific/Funafuti',
    key: 'Pacific/Funafuti',
    text: 'Pacific/Funafuti',
  },
  {
    value: 'Pacific/Galapagos',
    key: 'Pacific/Galapagos',
    text: 'Pacific/Galapagos',
  },
  {
    value: 'Pacific/Gambier',
    key: 'Pacific/Gambier',
    text: 'Pacific/Gambier',
  },
  {
    value: 'Pacific/Guadalcanal',
    key: 'Pacific/Guadalcanal',
    text: 'Pacific/Guadalcanal',
  },
  {value: 'Pacific/Guam', key: 'Pacific/Guam', text: 'Pacific/Guam'},
  {
    value: 'Pacific/Johnston',
    key: 'Pacific/Johnston',
    text: 'Pacific/Johnston',
  },
  {
    value: 'Pacific/Kiritimati',
    key: 'Pacific/Kiritimati',
    text: 'Pacific/Kiritimati',
  },
  {
    value: 'Pacific/Kosrae',
    key: 'Pacific/Kosrae',
    text: 'Pacific/Kosrae',
  },
  {
    value: 'Pacific/Kwajalein',
    key: 'Pacific/Kwajalein',
    text: 'Pacific/Kwajalein',
  },
  {
    value: 'Pacific/Majuro',
    key: 'Pacific/Majuro',
    text: 'Pacific/Majuro',
  },
  {
    value: 'Pacific/Marquesas',
    key: 'Pacific/Marquesas',
    text: 'Pacific/Marquesas',
  },
  {
    value: 'Pacific/Midway',
    key: 'Pacific/Midway',
    text: 'Pacific/Midway',
  },
  {
    value: 'Pacific/Nauru',
    key: 'Pacific/Nauru',
    text: 'Pacific/Nauru',
  },
  {value: 'Pacific/Niue', key: 'Pacific/Niue', text: 'Pacific/Niue'},
  {
    value: 'Pacific/Norfolk',
    key: 'Pacific/Norfolk',
    text: 'Pacific/Norfolk',
  },
  {
    value: 'Pacific/Noumea',
    key: 'Pacific/Noumea',
    text: 'Pacific/Noumea',
  },
  {
    value: 'Pacific/Pago_Pago',
    key: 'Pacific/Pago_Pago',
    text: 'Pacific/Pago_Pago',
  },
  {
    value: 'Pacific/Palau',
    key: 'Pacific/Palau',
    text: 'Pacific/Palau',
  },
  {
    value: 'Pacific/Pitcairn',
    key: 'Pacific/Pitcairn',
    text: 'Pacific/Pitcairn',
  },
  {
    value: 'Pacific/Ponape',
    key: 'Pacific/Ponape',
    text: 'Pacific/Ponape',
  },
  {
    value: 'Pacific/Port_Moresby',
    key: 'Pacific/Port_Moresby',
    text: 'Pacific/Port_Moresby',
  },
  {
    value: 'Pacific/Rarotonga',
    key: 'Pacific/Rarotonga',
    text: 'Pacific/Rarotonga',
  },
  {
    value: 'Pacific/Saipan',
    key: 'Pacific/Saipan',
    text: 'Pacific/Saipan',
  },
  {
    value: 'Pacific/Samoa',
    key: 'Pacific/Samoa',
    text: 'Pacific/Samoa',
  },
  {
    value: 'Pacific/Tahiti',
    key: 'Pacific/Tahiti',
    text: 'Pacific/Tahiti',
  },
  {
    value: 'Pacific/Tarawa',
    key: 'Pacific/Tarawa',
    text: 'Pacific/Tarawa',
  },
  {
    value: 'Pacific/Tongatapu',
    key: 'Pacific/Tongatapu',
    text: 'Pacific/Tongatapu',
  },
  {value: 'Pacific/Truk', key: 'Pacific/Truk', text: 'Pacific/Truk'},
  {value: 'Pacific/Wake', key: 'Pacific/Wake', text: 'Pacific/Wake'},
  {
    value: 'Pacific/Wallis',
    key: 'Pacific/Wallis',
    text: 'Pacific/Wallis',
  },
  {value: 'Pacific/Yap', key: 'Pacific/Yap', text: 'Pacific/Yap'},
  {
    value: 'Antarctica/Casey',
    key: 'Antarctica/Casey',
    text: 'Antarctica/Casey',
  },
  {
    value: 'Antarctica/Davis',
    key: 'Antarctica/Davis',
    text: 'Antarctica/Davis',
  },
  {
    value: 'Antarctica/DumontDUrville',
    key: 'Antarctica/DumontDUrville',
    text: 'Antarctica/DumontDUrville',
  },
  {
    value: 'Antarctica/Macquarie',
    key: 'Antarctica/Macquarie',
    text: 'Antarctica/Macquarie',
  },
  {
    value: 'Antarctica/Mawson',
    key: 'Antarctica/Mawson',
    text: 'Antarctica/Mawson',
  },
  {
    value: 'Antarctica/McMurdo',
    key: 'Antarctica/McMurdo',
    text: 'Antarctica/McMurdo',
  },
  {
    value: 'Antarctica/Palmer',
    key: 'Antarctica/Palmer',
    text: 'Antarctica/Palmer',
  },
  {
    value: 'Antarctica/Rothera',
    key: 'Antarctica/Rothera',
    text: 'Antarctica/Rothera',
  },
  {
    value: 'Antarctica/South_Pole',
    key: 'Antarctica/South_Pole',
    text: 'Antarctica/South_Pole',
  },
  {
    value: 'Antarctica/Syowa',
    key: 'Antarctica/Syowa',
    text: 'Antarctica/Syowa',
  },
  {
    value: 'Antarctica/Vostok',
    key: 'Antarctica/Vostok',
    text: 'Antarctica/Vostok',
  },
  {
    value: 'Arctic/Longyearbyen',
    key: 'Arctic/Longyearbyen',
    text: 'Arctic/Longyearbyen',
  },
];
