import graphql from 'babel-plugin-relay/macro';

const SearchBarQuery = graphql`
  query SearchBarQuery_Query($text: String, $limit: Int, $shouldSearch: Boolean!) {
    viewer {
      ...SearchBar_viewer @arguments(text: $text, limit: $limit, shouldSearch: $shouldSearch)
    }
  }
`;
export default SearchBarQuery;
