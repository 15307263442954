import React from 'react';
import Cookies from 'js-cookie';

function Logout(props) {
  const getCookieDomain = () => {
    const domain = window.location.hostname;

    const parts = domain.split('.');
    if (parts.length > 2) {
      parts.shift();
    }

    return parts.join('.');
  };

  // Clear local and session storage
  localStorage.clear();
  sessionStorage.clear();

  // Remove all Cookies
  const domain = getCookieDomain();
  Object.keys(Cookies.get()).forEach((key) => key !== 'USER_LOCALE' && Cookies.remove(key, {domain}));

  React.useEffect(() => {
    if (props && props.router) {
      props.router.replace('/login');
    }
  }, [props]);

  return <h1>Logging out...</h1>;
}

export default Logout;
