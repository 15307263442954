import './BottomBar.css';
import {Button} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

function BottomBar({handleSubmit, isVisible, isLoading}) {
  const {t} = useTranslation();
  return (
    <div className={isVisible ? 'bottom-save-bar-active' : 'bottom-save-bar'}>
      <Button
        primary
        loading={isLoading}
        disabled={isLoading}
        style={{marginTop: '1rem', marginLeft: '7.5rem', width: '9.5rem', height: '2.5rem'}}
        onClick={handleSubmit}
      >
        {t('common.save')}
      </Button>
      <p className="bottom-save-items">{t('common.unsaved_changes')}</p>
    </div>
  );
}

BottomBar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

export default BottomBar;
