import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';
import {ConnectionHandler} from 'relay-runtime';

const mutation = graphql`
  mutation DeleteAppointmentMutation($connections: [ID!]!, $input: DeleteAppointmentInput!) {
    deleteAppointment(input: $input) {
      clientMutationId
      deletedAppointmentId @deleteEdge(connections: $connections)
    }
  }
`;

const DeleteAppointmentMutation = (payload, success, failure) => {
  const {input, connectionNames, viewerId, filters} = payload;

  const connectionIds = connectionNames.map((connectionName) =>
    ConnectionHandler.getConnectionID(viewerId, connectionName, filters),
  );

  commitMutation(environment, {
    mutation,
    variables: {
      input,
      connections: connectionIds,
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default DeleteAppointmentMutation;
