import moment from 'moment';
import {Select, Checkbox} from 'semantic-ui-react';
import {DatePicker} from '@atlaskit/datetime-picker';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

const Heading = styled.div`
  margin-top: 10px;
  font-size: 18px;
  color: gray;
`;

const Container = styled.div`
  border-left: 1px solid lightgray;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CheckboxComponent = styled(Checkbox)`
  margin-top: 20px;
`;

const SelectFrequency = styled(Select)`
  margin-top: 14px;
`;

const DatePickerContainer = styled.div`
  margin-top: 14px;
  width: 200px;
`;

const RecurrenceOptionsView = ({
  isAppointmentRepeating,
  onOngoingCheckboxClick,
  isRecurringAppointmentOngoing,
  onRecurringFrequencyChange,
  onUntilDateSelected,
  selectedRecurrenceFrequency,
  onRepeatingCheckboxToggle,
}) => {
  const {t} = useTranslation();

  return (
    <Container>
      <Heading>{t('common.recurrence')}</Heading>
      <CheckboxComponent
        checked={isAppointmentRepeating}
        onChange={onRepeatingCheckboxToggle}
        label={t('common.repeating')}
      />
      <SelectFrequency
        placeholder={t('common.frequency')}
        disabled={!isAppointmentRepeating}
        value={selectedRecurrenceFrequency}
        onChange={onRecurringFrequencyChange}
        style={{width: '25%'}}
        options={[
          {key: 1, text: t('common.daily'), value: 'DAILY'},
          {key: 2, text: t('common.weekly'), value: 'WEEKLY'},
          {key: 3, text: t('common.monthly'), value: 'MONTHLY'},
          {key: 4, text: t('common.yearly'), value: 'YEARLY'},
        ]}
      />
      <DatePickerContainer>
        <DatePicker
          placeholder={t('common.until')}
          minDate={moment().format('YYYY-MM-DD')}
          timeIsEditable={false}
          selectProps={{
            menuPortalTarget: document.body,
            styles: {
              menuPortal: (base) => {
                return {
                  ...base,
                  zIndex: 9999,
                };
              },
            },
          }}
          isDisabled={!isAppointmentRepeating}
          onChange={onUntilDateSelected}
        />
      </DatePickerContainer>
      {/* <Checkbox
          checked={isRecurringAppointmentOngoing}
          onChange={onOngoingCheckboxClick}
          label={t('common.ongoing')}
          disabled={!isAppointmentRepeating}
          style={{marginTop: '10px'}}
        /> */}
    </Container>
  );
};

export default RecurrenceOptionsView;
