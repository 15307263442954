/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddRecurringTimeSlotInput = {|
  clientMutationId?: ?string,
  serviceId: string,
  day: number,
  startAt: string,
  endAt: string,
  capacity?: ?number,
|};
export type AddRecurringTimeSlotMutationVariables = {|
  connections: $ReadOnlyArray<string>,
  input: AddRecurringTimeSlotInput,
|};
export type AddRecurringTimeSlotMutationResponse = {|
  +addRecurringTimeSlot: ?{|
    +timeSlotEdge: ?{|
      +node: ?{|
        +id: string,
        +day: ?number,
        +startAt: ?any,
        +endAt: ?any,
        +capacity: ?number,
      |}
    |}
  |}
|};
export type AddRecurringTimeSlotMutation = {|
  variables: AddRecurringTimeSlotMutationVariables,
  response: AddRecurringTimeSlotMutationResponse,
|};
*/


/*
mutation AddRecurringTimeSlotMutation(
  $input: AddRecurringTimeSlotInput!
) {
  addRecurringTimeSlot(input: $input) {
    timeSlotEdge {
      node {
        id
        day
        startAt
        endAt
        capacity
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeSlotEdge",
  "kind": "LinkedField",
  "name": "timeSlotEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TimeSlot",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "day",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "capacity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddRecurringTimeSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddRecurringTimeSlotPayload",
        "kind": "LinkedField",
        "name": "addRecurringTimeSlot",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddRecurringTimeSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddRecurringTimeSlotPayload",
        "kind": "LinkedField",
        "name": "addRecurringTimeSlot",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "timeSlotEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9dd647a2a8350df0c9bc78e57217dfbe",
    "id": null,
    "metadata": {},
    "name": "AddRecurringTimeSlotMutation",
    "operationKind": "mutation",
    "text": "mutation AddRecurringTimeSlotMutation(\n  $input: AddRecurringTimeSlotInput!\n) {\n  addRecurringTimeSlot(input: $input) {\n    timeSlotEdge {\n      node {\n        id\n        day\n        startAt\n        endAt\n        capacity\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3e1d5d443e258abea103d56674346182';

module.exports = node;
