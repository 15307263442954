/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AppointmentModal_viewer$ref = any;
export type ProviderTimeSlotFilterInput = {|
  date?: ?RangeDateFilter,
  serviceId?: ?string,
  providerId?: ?string,
  clientId?: ?string,
  includeAll?: ?boolean,
|};
export type RangeDateFilter = {|
  from?: ?string,
  to?: ?string,
|};
export type AppointmentModalQuery_QueryVariables = {|
  timeSlotsFilterBy?: ?ProviderTimeSlotFilterInput,
  first?: ?number,
|};
export type AppointmentModalQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: AppointmentModal_viewer$ref
  |}
|};
export type AppointmentModalQuery_Query = {|
  variables: AppointmentModalQuery_QueryVariables,
  response: AppointmentModalQuery_QueryResponse,
|};
*/


/*
query AppointmentModalQuery_Query {
  viewer {
    __typename
    ...AppointmentModal_viewer_1ApL4k
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment AppointmentModal_viewer_1ApL4k on User {
  __isUser: __typename
  __typename
  ... on Provider {
    id
    places(first: 1, limit: 1) {
      edges {
        node {
          id
          settings {
            showServiceCategories
            id
          }
          paymentMethods {
            id
            rowId
            name
          }
        }
      }
    }
    clients(first: 1000, limit: 1000) {
      edges {
        node {
          id
          rowId
          name
          lastActiveAt
          phoneNumber
          profilePhotoUrl
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    services(first: 1000, limit: 1000) {
      edges {
        node {
          id
          rowId
          description
          title
          category {
            id
            name
          }
          appointmentFields {
            rowId
            name
            required
            type
            prompt
            id
          }
          duration
          profilePhotoUrl
          isRecurring
          providers(first: 1000) {
            edges {
              node {
                id
                rowId
                name
                profilePhotoUrl
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timeSlotsFilterBy"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000
},
v7 = [
  (v6/*: any*/),
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1000
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhotoUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppointmentModalQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "first"
              },
              {
                "kind": "Variable",
                "name": "timeSlotsFilterBy",
                "variableName": "timeSlotsFilterBy"
              }
            ],
            "kind": "FragmentSpread",
            "name": "AppointmentModal_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppointmentModalQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  {
                    "kind": "Literal",
                    "name": "limit",
                    "value": 1
                  }
                ],
                "concreteType": "PlaceConnection",
                "kind": "LinkedField",
                "name": "places",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlaceEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Place",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PlaceSettings",
                            "kind": "LinkedField",
                            "name": "settings",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "showServiceCategories",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PlacePaymentMethod",
                            "kind": "LinkedField",
                            "name": "paymentMethods",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "places(first:1,limit:1)"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ClientConnection",
                "kind": "LinkedField",
                "name": "clients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Client",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastActiveAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "phoneNumber",
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UFPageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "clients(first:1000,limit:1000)"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": [
                  "limit"
                ],
                "handle": "connection",
                "key": "AppointmentModal_clients",
                "kind": "LinkedHandle",
                "name": "clients"
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "ServiceConnection",
                "kind": "LinkedField",
                "name": "services",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Service",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ServiceCategory",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AppointmentField",
                            "kind": "LinkedField",
                            "name": "appointmentFields",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "required",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "prompt",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "duration",
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isRecurring",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              (v6/*: any*/)
                            ],
                            "concreteType": "ProviderConnection",
                            "kind": "LinkedField",
                            "name": "providers",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProviderEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Provider",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v4/*: any*/),
                                      (v5/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "providers(first:1000)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "services(first:1000,limit:1000)"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2fd1a630f775020e3983d2757050dd1d",
    "id": null,
    "metadata": {},
    "name": "AppointmentModalQuery_Query",
    "operationKind": "query",
    "text": "query AppointmentModalQuery_Query {\n  viewer {\n    __typename\n    ...AppointmentModal_viewer_1ApL4k\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment AppointmentModal_viewer_1ApL4k on User {\n  __isUser: __typename\n  __typename\n  ... on Provider {\n    id\n    places(first: 1, limit: 1) {\n      edges {\n        node {\n          id\n          settings {\n            showServiceCategories\n            id\n          }\n          paymentMethods {\n            id\n            rowId\n            name\n          }\n        }\n      }\n    }\n    clients(first: 1000, limit: 1000) {\n      edges {\n        node {\n          id\n          rowId\n          name\n          lastActiveAt\n          phoneNumber\n          profilePhotoUrl\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    services(first: 1000, limit: 1000) {\n      edges {\n        node {\n          id\n          rowId\n          description\n          title\n          category {\n            id\n            name\n          }\n          appointmentFields {\n            rowId\n            name\n            required\n            type\n            prompt\n            id\n          }\n          duration\n          profilePhotoUrl\n          isRecurring\n          providers(first: 1000) {\n            edges {\n              node {\n                id\n                rowId\n                name\n                profilePhotoUrl\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0511405d988bb1e358b1acb967b20d9';

module.exports = node;
