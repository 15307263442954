import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Container from './Container';
import {Table, Button, Input as TextInput, Modal, Grid, Icon} from 'semantic-ui-react';
import StaffMemberRow from './StaffMemberRow';
import Pagination from './Pagination';
import {useRefetchable} from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import {RemoveProviderMutation} from '../mutations';
import AddStaffMemberModal from './AddStaffMemberModal';
import useDebounce from '../hooks/useDebounce';

const fragment = graphql`
  fragment StaffMembers_viewer on User
  @refetchable(queryName: "StaffMembersRefetchQuery")
  @argumentDefinitions(
    filterBy: {type: "ProviderFilterInput"}
    orderBy: {type: "[[String]]", defaultValue: [["createdAt", "ASC"], ["name", "ASC"]]}
    page: {type: "Int", defaultValue: 1}
    first: {type: "Int", defaultValue: 50}
    after: {type: "String"}
    limit: {type: "Int"}
  ) {
    ... on Provider {
      id
      providers(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, after: $after, limit: $limit)
        @connection(key: "StaffMembers_providers", filters: []) {
        edges {
          node {
            id
            name
            placeIds
            ...StaffMemberRow_provider
          }
        }
        total
        currentPage
        start
        end
      }
      ...AddStaffMemberModal_viewer
    }
  }
`;

const refetchVariables = {
  orderBy: [
    ['createdAt', 'ASC'],
    ['name', 'ASC'],
  ],
  filterBy: {searchWord: null},
  page: 1,
  first: 30,
  limit: Pagination.getLimit(),
};

export default function StaffMembers(props) {
  const {t} = useTranslation();
  const [searchWord, setSearchWord] = useState(null);
  const [column, setColumn] = useState();
  const [direction, setDirection] = useState('ascending');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const {data: viewer, refetch} = useRefetchable(fragment, props.viewer);

  const {providers, total, currentPage, start, end} = viewer;

  const handleSeachBarChange = (e) => {
    e.persist();
    setSearchWord(e.target.value);
  };

  const debouncedSearchTerm = useDebounce(searchWord, 500);
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== refetchVariables.filterBy.searchWord) {
        refetchVariables.filterBy = {searchWord: debouncedSearchTerm};
        refetch(refetchVariables);
      }
    },
    [debouncedSearchTerm, refetch], // Only call effect if debounced search term changes
  );

  const handleColumnSort = (column) => (e) => {
    e.preventDefault();

    const sortDirection = direction === 'ascending' ? 'DESC' : 'ASC';

    const orderByNew = [...[[column, sortDirection]], ...refetchVariables.orderBy.filter((v) => v[0] !== column)];

    refetchVariables.orderBy = orderByNew;
    refetch(refetchVariables);
    setDirection(direction === 'ascending' ? 'descending' : 'ascending');
    setColumn(column);
  };

  const handleRowClick = (provider) => (e) => {};

  const handleEditClick = (provider) => (e) => {
    e.stopPropagation();
  };

  const handleDeleteClick = (provider) => (e) => {
    setSelectedProvider(provider);

    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };

  const handleDeleteAction = (provider) => (e) => {
    const input = {
      id: provider.id,
      placeId: provider.placeIds[0], // TODO: Fix this
    };

    const onSuccess = (e) => {
      refetch(refetchVariables); // TODO: Fix this
      setIsDeleteModalOpen(false);
    };

    const onFailure = (e) => console.log('error', e);

    RemoveProviderMutation(
      {input, proxyId: viewer.id, connectionName: 'StaffMembers_providers', filters: []},
      onSuccess,
      onFailure,
    );
  };

  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <TextInput
              iconPosition="left"
              icon="users"
              value={searchWord}
              fluid
              placeholder={t('common.search')}
              onChange={handleSeachBarChange}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Button
              name="addStaffMemberButton"
              primary
              // loading={this.state.addClientButtonLoading}
              onClick={() => setIsAddModalOpen(true)}
            >
              {t('actions.add_staff_member')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table sortable padded selectable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell sorted={column === 'id' ? direction : null} onClick={handleColumnSort('id')}>
              {t('common.id')}
            </Table.HeaderCell>
            <Table.HeaderCell sorted={column === 'name' ? direction : null} onClick={handleColumnSort('name')}>
              {t('common.name')}
            </Table.HeaderCell>
            <Table.HeaderCell>{t('common.email')}</Table.HeaderCell>
            <Table.HeaderCell>{t('common.phone_number')}</Table.HeaderCell>
            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {providers.edges.map(({node: provider}) => (
            <StaffMemberRow
              onEditClick={handleEditClick(provider)}
              onDeleteClick={handleDeleteClick(provider)}
              provider={provider}
              key={provider.id}
              onClick={handleRowClick(provider)}
            />
          ))}
        </Table.Body>
        {total > Pagination.getLimit() && (
          <Pagination
            currentPage={currentPage}
            start={start}
            end={end}
            total={total}
            onPageSelected={this.handlePageSelected}
          />
        )}
      </Table>
      <Modal open={isDeleteModalOpen}>
        <Modal.Content>
          Are you sure you want to revoke access to {selectedProvider ? selectedProvider.name : 'staff member'}?
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setIsDeleteModalOpen(false)} color="red">
            <Icon name="remove" /> No
          </Button>
          <Button onClick={handleDeleteAction(selectedProvider)} color="green">
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
      <AddStaffMemberModal open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} viewer={viewer} />
    </Container>
  );
}
