import graphql from 'babel-plugin-relay/macro';
import {requestSubscription} from 'react-relay';
import {environment} from '../relay';
import {addEdgeToConnection} from '../helpers/SharedUpdater';

const createNotificationSubscription = graphql`
  subscription CreateNotificationSubscription($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      viewer {
        ... on Provider {
          unreadNotificationsCount
        }
      }
      notificationEdge {
        node {
          id
          createdAt
          isRead
          ...NotificationItem_notification
        }
      }
    }
  }
`;
const CreateNotificationSubscription = (viewerId, connectionName, filters, callback) => {
  const requestSubscriptionResult = requestSubscription(environment, {
    subscription: createNotificationSubscription,
    variables: {
      input: {},
    },
    updater: (store) => {
      const rootField = store.getRootField('createNotification');
      const newEdge = rootField.getLinkedRecord('notificationEdge');
      addEdgeToConnection(store, viewerId, newEdge, connectionName);
    },
    onNext: (...rest) => {
      if (callback && callback.onSuccess) {
        callback.onSuccess();
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return {
    dispose: requestSubscriptionResult.dispose,
  };
};

export default CreateNotificationSubscription;
