/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type OrganizationSettings_settings$ref = any;
export type UpdateOrganizationSettingsInput = {|
  clientMutationId?: ?string,
  id: string,
  locale?: ?string,
  currency?: ?string,
  country?: ?string,
  billingName?: ?string,
  billingVAT?: ?string,
  billingPOI?: ?string,
  billingAddress?: ?any,
|};
export type UpdateOrganizationSettingsMutationVariables = {|
  input: UpdateOrganizationSettingsInput
|};
export type UpdateOrganizationSettingsMutationResponse = {|
  +updateOrganizationSettings: ?{|
    +viewer: ?{|
      +organization?: ?{|
        +settings: ?{|
          +$fragmentRefs: OrganizationSettings_settings$ref
        |}
      |}
    |}
  |}
|};
export type UpdateOrganizationSettingsMutation = {|
  variables: UpdateOrganizationSettingsMutationVariables,
  response: UpdateOrganizationSettingsMutationResponse,
|};
*/


/*
mutation UpdateOrganizationSettingsMutation(
  $input: UpdateOrganizationSettingsInput!
) {
  updateOrganizationSettings(input: $input) {
    viewer {
      __typename
      ... on Provider {
        organization {
          settings {
            ...OrganizationSettings_settings
            id
          }
          id
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
  }
}

fragment OrganizationSettings_settings on OrganizationSettings {
  id
  locale
  country
  currency
  billingName
  billingVAT
  billingPOI
  billingAddress {
    street
    city
    country
    zipCode
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateOrganizationSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSettingsPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationSettings",
                        "kind": "LinkedField",
                        "name": "settings",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "OrganizationSettings_settings"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Provider",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateOrganizationSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSettingsPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrganizationSettings",
                        "kind": "LinkedField",
                        "name": "settings",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locale",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billingName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billingVAT",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "billingPOI",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BillingAddress",
                            "kind": "LinkedField",
                            "name": "billingAddress",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "street",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "city",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "zipCode",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Provider",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f665123f630b4d4871f89786ea4c4d2a",
    "id": null,
    "metadata": {},
    "name": "UpdateOrganizationSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateOrganizationSettingsMutation(\n  $input: UpdateOrganizationSettingsInput!\n) {\n  updateOrganizationSettings(input: $input) {\n    viewer {\n      __typename\n      ... on Provider {\n        organization {\n          settings {\n            ...OrganizationSettings_settings\n            id\n          }\n          id\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment OrganizationSettings_settings on OrganizationSettings {\n  id\n  locale\n  country\n  currency\n  billingName\n  billingVAT\n  billingPOI\n  billingAddress {\n    street\n    city\n    country\n    zipCode\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e7808a9c01f3eff1244aa2b1f104d3d';

module.exports = node;
