/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MyProfile_viewer$ref = any;
export type MyProfileQuery_QueryVariables = {||};
export type MyProfileQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: MyProfile_viewer$ref
  |}
|};
export type MyProfileQuery_Query = {|
  variables: MyProfileQuery_QueryVariables,
  response: MyProfileQuery_QueryResponse,
|};
*/


/*
query MyProfileQuery_Query {
  viewer {
    __typename
    ...MyProfile_viewer
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment MyProfile_viewer on User {
  __isUser: __typename
  ... on Provider {
    ...Personal_viewer
    organization {
      ...Organization_organization
      ...Places_organization
      id
    }
    role {
      sets
      id
    }
  }
}

fragment Organization_organization on Organization {
  id
  name
  email
  phoneNumber
  coverPhotoUrl
  profilePhotoUrl
  categoryId
  categories {
    rowId
    id
    name
  }
}

fragment Personal_viewer on User {
  __isUser: __typename
  ... on Provider {
    id
    name
    email
    profilePhotoUrl
    phoneNumber
    settings {
      locale
      id
    }
  }
}

fragment Places_organization on Organization {
  places {
    id
    name
    phoneNumber
    email
    profilePhotoUrl
    coverPhotoUrl
    address {
      placeId
      formatted
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverPhotoUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhotoUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyProfileQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MyProfile_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MyProfileQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "categoryId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "categories",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rowId",
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Place",
                    "kind": "LinkedField",
                    "name": "places",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v3/*: any*/),
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "placeId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "formatted",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "role",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sets",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProviderSettings",
                        "kind": "LinkedField",
                        "name": "settings",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locale",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Provider",
                    "abstractKey": null
                  }
                ],
                "type": "User",
                "abstractKey": "__isUser"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f48d41d3d4255361d89e1369cfe3bd38",
    "id": null,
    "metadata": {},
    "name": "MyProfileQuery_Query",
    "operationKind": "query",
    "text": "query MyProfileQuery_Query {\n  viewer {\n    __typename\n    ...MyProfile_viewer\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment MyProfile_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    ...Personal_viewer\n    organization {\n      ...Organization_organization\n      ...Places_organization\n      id\n    }\n    role {\n      sets\n      id\n    }\n  }\n}\n\nfragment Organization_organization on Organization {\n  id\n  name\n  email\n  phoneNumber\n  coverPhotoUrl\n  profilePhotoUrl\n  categoryId\n  categories {\n    rowId\n    id\n    name\n  }\n}\n\nfragment Personal_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    name\n    email\n    profilePhotoUrl\n    phoneNumber\n    settings {\n      locale\n      id\n    }\n  }\n}\n\nfragment Places_organization on Organization {\n  places {\n    id\n    name\n    phoneNumber\n    email\n    profilePhotoUrl\n    coverPhotoUrl\n    address {\n      placeId\n      formatted\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb716f5cacc15895a91c89cddf5a023c';

module.exports = node;
