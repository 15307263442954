export default function canAccess(sets, name, conditions) {
  for (const condition of conditions) {
    if (sets[name][0].indexOf(condition) !== -1) {
      return true;
    }
  }

  return false;
}

export const USER_SETS_CONDITION_ALL = 'all';
export const USER_SETS_CONDITION_ASSIGNED_TO_USER = 'assigned_to_user';
export const USER_SETS_CONDITION_ASSIGNED_TO_PLACE = 'assigned_to_place';
export const USER_SETS_CONDITION_ASSIGNED_TO_ORGANIZATION = 'assigned_to_organization';
