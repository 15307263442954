import graphql from 'babel-plugin-relay/macro';

const HistoryQuery = graphql`
  query HistoryQuery_Query($orderBy: [[String]], $limit: Int, $page: Int, $first: Int) {
    viewer {
      ...History_viewer @arguments(orderBy: $orderBy, limit: $limit, page: $page, first: $first)
    }
  }
`;

export default HistoryQuery;
