import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';
import {ConnectionHandler} from 'relay-runtime';

const mutation = graphql`
  mutation AddVacationHourMutation($connections: [ID!]!, $input: AddVacationHourInput!) {
    addVacationHour(input: $input) {
      vacationHourEdge @prependEdge(connections: $connections) {
        node {
          ...VacationHourRow_vacationHour
        }
      }
    }
  }
`;

const AddVacationHourMutation = (payload, success, failure) => {
  const {input, connectionName, viewerId} = payload;
  const connectionId = ConnectionHandler.getConnectionID(viewerId, connectionName);

  commitMutation(environment, {
    mutation,
    variables: {input, connections: [connectionId]},
    updater: (store) => {
      const payload = store.getRootField('addVacationHour');
      if (!payload) {
        return;
      }
    },
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default AddVacationHourMutation;
