import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {createRefetchContainer} from 'react-relay';
import {Calendar as ReactCalendar, Views, momentLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import randomColor from 'randomcolor';
import AppointmentModal from './AppointmentModal';
import RecurringAppointmentModal from './RecurringAppointmentModal';
import Container from '../Container';
import Toolbar from './Toolbar';
import Agenda from './Agenda';
import {
  AddAppointmentSubscription,
  DeleteAppointmentSubscription,
  UpdateAppointmentSubscription,
} from '../../subscriptions';
import './Calendar.css';
import CustomDayView from './CustomDayView';

// TODO: Fix 24h format
// moment.locale('en-GB');
const localizer = momentLocalizer(moment);

let refetchVariables = {
  filterBy: {
    date: {
      from: moment().subtract(2, 'week').format('YYYY-MM-DD'),
      to: moment().add(2, 'week').format('YYYY-MM-DD'),
    },
  },
  orderBy: [['startAt', 'DESC']],
};

class Calendar extends React.Component {
  state = {
    isModalShown: false,
    width: window.innerWidth,
    height: window.innerHeight,
    selectedDate: moment().format('YYYY-MM-DD'),
    appointmentIds: null,
    viewType: 'week',
    isRecurringAppointmentModalOpen: false,
    urlAppointmentId: this.props.match.params.appointmentId,
  };

  constructor(props) {
    super(props);

    this.subscriptions = [];
    this.calendar = React.createRef();
    this.profilePhotoInputRef = React.createRef();
  }

  componentDidMount() {
    const filters = [];
    const connectionName = 'Calendar_appointments';

    this.subscriptions.push(
      AddAppointmentSubscription(this.props.viewer.id, connectionName, filters),
      DeleteAppointmentSubscription(this.props.viewer.id, connectionName, filters),
      UpdateAppointmentSubscription(this.props.viewer.id, connectionName, filters),
    );

    window.addEventListener('resize', this.updateWindowDimensions);
    const {appointment} = this.props.viewer;

    if (appointment) {
      this.setState({appointmentIds: [appointment.id], isModalShown: true});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {appointmentId} = this.props.match.params;
    const {urlAppointmentId} = this.state;

    if (appointmentId && urlAppointmentId !== appointmentId) {
      refetchVariables.appointmentId = appointmentId;
      this.props.relay.refetch(refetchVariables, null, () => {
        this.setState({
          appointmentIds: [this.props.viewer.appointment.id],
        });
      });

      this.setState({
        urlAppointmentId: appointmentId,
        isModalShown: true,
      });
    }
  }

  componentWillUnmount() {
    this.subscriptions.forEach((subscription) => subscription.dispose());

    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => this.setState({width: window.innerWidth, height: window.innerHeight});

  handleAddAppointment = () => this.setState({isModalShown: true, appointmentIds: null});

  handleAppointmentModalClose = () => this.setState({isModalShown: false, appointmentIds: null});

  handleRecurringAppointmentModalClose = () => {
    return this.setState({isRecurringAppointmentModalOpen: false, isModalShown: false, recurringAppointmentId: null});
  };

  handleClientChange = (i, data) => {
    this.setState({[data.name]: data.value});
  };

  handleNextClick = () => this.calendar.handleNavigate('NEXT');

  handleTodayClick = () => this.calendar.handleNavigate('TODAY');

  handleBackClick = () => this.calendar.handleNavigate('PREV');

  handleChangeViewClick = (event, viewType) =>
    this.setState({viewType}, () => this.calendar.handleViewChange(viewType));

  handleMonthViewClick = () => {
    this.setState({viewType: 'month'}, () => this.calendar.handleViewChange('month'));
  };

  handleWeekViewClick = () => {
    this.setState({viewType: 'week'}, () => this.calendar.handleViewChange('week'));
  };

  handleDayViewClick = () => {
    this.setState({viewType: 'day'}, () => this.calendar.handleViewChange('day'));
  };

  handleAgendaViewClick = () => {
    this.setState({viewType: 'agenda'}, () => this.calendar.handleViewChange('agenda'));
  };

  handleDateChange = (date) => {
    this.calendar.handleNavigate('DATE', moment(date).toDate());
  };

  handleNavigate = (date) => {
    // TODO: Improve fetching
    //       Do not fetch on every date change
    const from = moment(date).subtract(2, 'week').format('YYYY-MM-DD');
    const to = moment(date).add(4, 'week').format('YYYY-MM-DD');

    refetchVariables = {
      ...refetchVariables,
      filterBy: {
        date: {
          from,
          to,
        },
      },
      orderBy: [['startAt', 'DESC']],
    };

    this.props.relay.refetch(refetchVariables);

    this.setState({selectedDate: moment(date).format('YYYY-MM-DD')});
  };

  handleSelectEvent = (event) => {
    const {appointmentIds} = event;
    if (event.isPartOfRecurringSequence) {
      // appointmentIds[0] is valid since it can be a single appointment only
      return this.setState({recurringAppointmentId: appointmentIds[0], isRecurringAppointmentModalOpen: true});
    }

    this.setState({appointmentIds, isModalShown: true});
  };

  handleAppointmentFromDayViewClick = (appointment) => {
    const {id, isPartOfRecurringSequence} = appointment;

    if (isPartOfRecurringSequence) {
      return this.setState({recurringAppointmentId: id, isRecurringAppointmentModalOpen: true});
    } else {
      return this.setState({appointmentIds: [id], isModalShown: true});
    }
  };

  getEventStyle = (event, start, end, isSelected) => {
    const {appointments} = event;

    // TODO: Refactor this
    const appointment = appointments[0];

    const providerColor = randomColor({hue: 'purple', luminosity: 'light', seed: appointment.providerId});

    const isPending = appointment.status === 'Pending';
    const style = isPending
      ? {
          backgroundColor: providerColor,
          borderRadius: '5px',
          color: 'black',
          border: '2px',
          borderColor: 'red',
          borderStyle: 'solid',
          display: 'block',
        }
      : {
          backgroundColor: providerColor,
          borderRadius: '5px',
          color: 'black',
          border: '0px',
          display: 'block',
        };

    return {style};
  };

  getCalendarEvents = () => {
    const appointmentsByStartTime = this.props.viewer.appointments.edges.reduce((p, {node}) => {
      const key = `${node.providerId}:${node.serviceId}:${node.startAt}`;
      if (!p[key]) {
        p[key] = [];
      }

      p[key].push(node);

      return p;
    }, {});

    const events = Object.values(appointmentsByStartTime).reduce((p, appointments) => {
      const appointment = appointments[0];
      const {
        service = {},
        client = {},
        startAt,
        endAt,
        isPartOfRecurringSequence,
        recurrencePattern,
        isRecurring,
        provider,
        recurringTimeSlot,
      } = appointment;
      const capacity = recurringTimeSlot ? recurringTimeSlot.capacity : service.capacity;

      if (appointments && appointments.length > 1) {
        // Needed to prevent assigning twice same client to the appointment
        // TODO: See if there is a faster way to do it.
        const filteredAppointments = appointments.filter(
          (appointment, index, self) => index === self.findIndex((t) => t.client.id === appointment.client.id),
        );

        p.push({
          title: `${service.title} (${filteredAppointments.length}/${capacity})`,
          start: new Date(startAt),
          end: new Date(endAt),
          appointments: filteredAppointments,
          appointmentIds: filteredAppointments.map((appointment) => appointment.id),
          isRecurring: true,
        });
      } else {
        p.push({
          id: appointment.id,
          title: isRecurring
            ? service
              ? `${service.title} (${appointments.length}/${capacity})`
              : '--'
            : client
            ? `${client.name} \n (${provider.name})`
            : '--',
          start: new Date(startAt),
          end: new Date(endAt),
          appointments,
          appointmentIds: [appointment.id],
          isRecurring,
          recurrencePattern,
          isPartOfRecurringSequence,
        });
      }

      return p;
    }, []);

    return events;
  };

  render() {
    const {viewer} = this.props;
    const {providers: _providers, workHours = []} = viewer;

    const {
      height,
      selectedDate,
      appointmentIds,
      viewType,
      isRecurringAppointmentModalOpen,
      recurringAppointmentId,
      isModalShown,
    } = this.state;

    const providers = _providers.edges.map(({node}) => node);

    const calendarEvents = this.getCalendarEvents();

    // TODO: Set different opening/closing hours for each day
    const minOpenTime = workHours.length > 0 ? workHours.map((hours) => hours.openTime).sort()[0] : '08:00:00';
    const maxCloseTime =
      workHours.length > 0 ? workHours.map((hours) => hours.closeTime).sort()[workHours.length - 1] : '20:00:00';

    // TODO: Improve event views
    const today = moment().toDate();
    const startOfDay = moment(selectedDate, 'YYYY-MM-DD').startOf('day');
    const endOfDay = moment(selectedDate, 'YYYY-MM-DD').endOf('day');

    const eventsForTheSelectedDate = calendarEvents.filter((event) => {
      const eventStartAt = moment(event.start);
      return eventStartAt.isAfter(startOfDay) && eventStartAt.isBefore(endOfDay);
    });

    const components = {
      toolbar: () => (
        <Toolbar
          viewType={viewType}
          date={selectedDate}
          onTodayClick={this.handleTodayClick}
          onNextClick={this.handleNextClick}
          onBackClick={this.handleBackClick}
          onChangeView={this.handleChangeViewClick}
          onAddAppointmentClick={this.handleAddAppointment}
          onDateChange={this.handleDateChange}
        />
      ),
    };

    return (
      <Container style={{paddingRight: 0}}>
        {/* HACK: margin-top doesn't work on the container div */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: 15,
          }}
        ></div>
        {isModalShown && (
          <AppointmentModal
            appointmentIds={appointmentIds}
            isOpen={isModalShown}
            onClose={this.handleAppointmentModalClose}
          />
        )}
        {isRecurringAppointmentModalOpen && (
          <RecurringAppointmentModal
            appointmentId={recurringAppointmentId}
            isOpen={isRecurringAppointmentModalOpen}
            onClose={this.handleRecurringAppointmentModalClose}
          />
        )}

        <div>
          <div style={viewType === 'agenda' || viewType === 'day' ? {display: 'none'} : {height: height - 100}}>
            <ReactCalendar
              // views={{month: true, week: true, day: CustomView, agenda: true}}
              ref={(ref) => (this.calendar = ref)}
              components={components}
              events={calendarEvents}
              min={moment(minOpenTime, 'HH:mm:ss').toDate()}
              max={moment(maxCloseTime, 'HH:mm:ss').toDate()}
              defaultView={Views.WEEK}
              defaultDate={today}
              localizer={localizer}
              onNavigate={this.handleNavigate}
              onSelectEvent={this.handleSelectEvent}
              eventPropGetter={this.getEventStyle}
            />
          </div>
          {viewType === 'agenda' && (
            <div>
              <Toolbar
                viewType={viewType}
                date={selectedDate}
                onTodayClick={this.handleTodayClick}
                onNextClick={this.handleNextClick}
                onBackClick={this.handleBackClick}
                onChangeView={this.handleChangeViewClick}
                onAddAppointmentClick={this.handleAddAppointment}
                onDateChange={this.handleDateChange}
              />
              <Agenda events={calendarEvents} onAgendaRowClick={this.handleSelectEvent} />
            </div>
          )}
          {viewType === 'day' && (
            <div>
              <Toolbar
                viewType={viewType}
                date={selectedDate}
                onTodayClick={this.handleTodayClick}
                onNextClick={this.handleNextClick}
                onBackClick={this.handleBackClick}
                onChangeView={this.handleChangeViewClick}
                onAddAppointmentClick={this.handleAddAppointment}
                onDateChange={this.handleDateChange}
              />
              <CustomDayView
                earliestHour={minOpenTime}
                latestHour={maxCloseTime}
                providers={providers}
                events={eventsForTheSelectedDate}
                onAppointmentClick={this.handleAppointmentFromDayViewClick}
              />
            </div>
          )}
        </div>
      </Container>
    );
  }
}

const CalendarQuery = graphql`
  query CalendarRefetchQuery($appointmentId: ID, $filterBy: ProviderAppointmentFilterInput, $orderBy: [[String]]) {
    viewer {
      ...Calendar_viewer @arguments(appointmentId: $appointmentId, filterBy: $filterBy, orderBy: $orderBy)
    }
  }
`;

const CalendarContainer = createRefetchContainer(
  Calendar,
  {
    viewer: graphql`
      fragment Calendar_viewer on User
      @argumentDefinitions(
        filterBy: {type: "ProviderAppointmentFilterInput"}
        orderBy: {type: "[[String]]"}
        appointmentId: {type: "ID"}
      ) {
        ... on Provider {
          id
          workHours {
            openTime
            closeTime
          }
          appointment(id: $appointmentId) {
            id
            serviceId
            providerId
            service {
              title
              capacity
            }
            startAt
            status
            endAt
            isRecurring
            recurringTimeSlot {
              capacity
            }
            client {
              id
              name
            }
          }
          appointments(filterBy: $filterBy, orderBy: $orderBy, first: 10000, limit: 5000)
            @connection(key: "Calendar_appointments", filters: []) {
            edges {
              node {
                id
                providerId
                serviceId
                service {
                  title
                  capacity
                }
                # eslint-disable-next-line relay/unused-fields
                amountPaid
                startAt
                status
                endAt
                isPartOfRecurringSequence
                isRecurring
                recurringTimeSlot {
                  capacity
                }
                provider {
                  id
                  name
                }
                client {
                  id
                  name
                }
              }
            }
          }
          providers(orderBy: [["createdAt", "ASC"]], first: 10000) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `,
  },
  CalendarQuery,
);

CalendarContainer.getVariables = (props) => {
  refetchVariables.appointmentId = props.appointmentId;
  return refetchVariables;
};

export default CalendarContainer;
