/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Analytics_viewer$ref: FragmentReference;
declare export opaque type Analytics_viewer$fragmentType: Analytics_viewer$ref;
export type Analytics_viewer = {|
  +analytics?: ?{|
    +appointments: ?any,
    +totalNumberClients: ?number,
    +newClients: ?any,
    +hours: ?any,
  |},
  +$refType: Analytics_viewer$ref,
|};
export type Analytics_viewer$data = Analytics_viewer;
export type Analytics_viewer$key = {
  +$data?: Analytics_viewer$data,
  +$fragmentRefs: Analytics_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterBy"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Analytics_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "filterBy",
              "variableName": "filterBy"
            }
          ],
          "concreteType": "Analytics",
          "kind": "LinkedField",
          "name": "analytics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appointments",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalNumberClients",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "newClients",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hours",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
// prettier-ignore
(node/*: any*/).hash = '22dd6bf15ac9fd21660ee6515427907e';

module.exports = node;
