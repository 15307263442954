import './AppointmentModal.css';
import {Button} from 'semantic-ui-react';
import moment from 'moment';
import {get} from 'lodash';

const SelectedClientView = ({client, t, onDeselectClient}) => {
  const formattedLastActiveAt = client && client.lastActiveAt ? moment(client.lastActiveAt).format('LLL') : '';

  return (
    <div style={{position: 'relative'}}>
      <div className="details-subcontainer">
        {/* <img alt="client avatar" className="client-photo" src={client.profilePhotoUrl} /> */}
        <div className="details-info">
          <div className="details-header">{get(client, 'name', t('common.guest'))}</div>
          <p>{client ? `${t('common.phone_number')} : ${client.phoneNumber}` : ''}</p>
          <p>{client ? `${t('common.last_active')}: ${formattedLastActiveAt}` : ''}</p>
        </div>
      </div>
      <Button size="mini" className="close-button" circular icon="close" onClick={onDeselectClient} />
    </div>
  );
};

export default SelectedClientView;
