import React from 'react';
import _styled from 'styled-components';
import {DatePicker} from '@atlaskit/datetime-picker';
import './Toolbar.css';
import {withTranslation} from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import {styled} from '@mui/material/styles';

// TODO: Style
const Button = _styled.button`
  background-color: ${(props) => (props.selected ? '#a7a7a9' : '#f4f5f7')};
  padding: 0.78571429em 1.5em 0.78571429em;
  margin-right: 2px;
  border: 0px solid #f4f5f7;
  border-radius: 4px;
  cursor: pointer;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  :hover {
    background-color: ${(props) => (props.selected ? '#a7a7a9' : '#ebecf0')};
  }
  :focus {
    outline: 0;
  }
`;

const AddButton = styled(IconButton)(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRadius: 4,
  width: 60,
  marginLeft: 20,
}));

const Container = _styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  height: 50px;
`;

const ButtonsContainer = _styled.div`
  display: flex;
`;

class Toolbar extends React.Component {
  handleDateChange = (date) => this.props.onDateChange(date);

  render() {
    const {t, viewType} = this.props;

    return (
      <Container>
        <ButtonsContainer>
          <Button onClick={this.props.onBackClick}>{'<'}</Button>
          <Button onClick={this.props.onNextClick}>{'>'}</Button>
          <Button onClick={this.props.onTodayClick}>{t('common.today')}</Button>
        </ButtonsContainer>
        <DatePicker
          hideIcon={true}
          icon={false}
          innerProps={{
            style: {
              width: 160,
            },
          }}
          value={this.props.date}
          onChange={this.handleDateChange}
          dateFormat="Do MMMM YYYY"
        />
        <ButtonsContainer>
          <ToggleButtonGroup color="primary" value={viewType} exclusive onChange={this.props.onChangeView}>
            <ToggleButton value={'month'}>{t('common.month')}</ToggleButton>
            <ToggleButton value={'week'}>{t('common.week')}</ToggleButton>
            <ToggleButton value={'day'}>{t('common.day')}</ToggleButton>
            <ToggleButton value={'agenda'}>{t('common.agenda')}</ToggleButton>
          </ToggleButtonGroup>
          <AddButton variant="outlined" onClick={this.props.onAddAppointmentClick}>
            <AddIcon />
          </AddButton>
        </ButtonsContainer>
      </Container>
    );
  }
}

export default withTranslation()(Toolbar);
