import graphql from 'babel-plugin-relay/macro';

const PaymentsQuery = graphql`
  query PaymentsQuery_Query {
    viewer {
      ...Payments_viewer
    }
  }
`;

export default PaymentsQuery;
