import {commitMutation, ConnectionHandler} from 'react-relay';
import {environment} from '../relay';
import graphql from 'babel-plugin-relay/macro';

const mutation = graphql`
  mutation RemoveProviderMutation($connections: [ID!]!, $input: RemoveProviderInput!) {
    removeProvider(input: $input) {
      provider @deleteEdge(connections: $connections) {
        id
      }
    }
  }
`;

const RemoveProviderMutation = (payload, success, failure) => {
  const {connectionName, proxyId, filters} = payload;
  const connectionId = ConnectionHandler.getConnectionID(proxyId, connectionName, filters);

  commitMutation(environment, {
    mutation,
    variables: {input: payload.input, conections: [connectionId]},
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default RemoveProviderMutation;
