/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Clients_viewer$ref = any;
export type ClientsFilterInput = {|
  searchWord?: ?string
|};
export type ClientsQuery_QueryVariables = {|
  filterBy?: ?ClientsFilterInput,
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  limit?: ?number,
  page?: ?number,
  first?: ?number,
  isSingleUse?: ?boolean,
|};
export type ClientsQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: Clients_viewer$ref
  |}
|};
export type ClientsQuery_Query = {|
  variables: ClientsQuery_QueryVariables,
  response: ClientsQuery_QueryResponse,
|};
*/


/*
query ClientsQuery_Query(
  $filterBy: ClientsFilterInput
  $orderBy: [[String]]
  $limit: Int
  $page: Int
  $first: Int
  $isSingleUse: Boolean
) {
  viewer {
    __typename
    ...Clients_viewer_3Wa5l4
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment ClientRow_client on Client {
  id
  rowId
  name
  profilePhotoUrl
  lastActiveAt
  clientSince
  totalAppointmentsCount
}

fragment Clients_viewer_3Wa5l4 on User {
  __isUser: __typename
  ... on Provider {
    id
    rowId
    inviteLink(isSingleUse: $isSingleUse)
    clients(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit) {
      edges {
        node {
          id
          ...ClientRow_client
          __typename
        }
        cursor
      }
      currentPage
      start
      end
      total
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSingleUse"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v6 = {
  "kind": "Variable",
  "name": "filterBy",
  "variableName": "filterBy"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = {
  "kind": "Variable",
  "name": "isSingleUse",
  "variableName": "isSingleUse"
},
v9 = {
  "kind": "Variable",
  "name": "limit",
  "variableName": "limit"
},
v10 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v11 = {
  "kind": "Variable",
  "name": "page",
  "variableName": "page"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v15 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "Clients_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClientsQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": [
                  (v8/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "inviteLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "concreteType": "ClientConnection",
                "kind": "LinkedField",
                "name": "clients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Client",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePhotoUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastActiveAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "clientSince",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalAppointmentsCount",
                            "storageKey": null
                          },
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "start",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "end",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UFPageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v15/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "Clients_clients",
                "kind": "LinkedHandle",
                "name": "clients"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v13/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1140320e72dc2e1d51f95f07138e53b5",
    "id": null,
    "metadata": {},
    "name": "ClientsQuery_Query",
    "operationKind": "query",
    "text": "query ClientsQuery_Query(\n  $filterBy: ClientsFilterInput\n  $orderBy: [[String]]\n  $limit: Int\n  $page: Int\n  $first: Int\n  $isSingleUse: Boolean\n) {\n  viewer {\n    __typename\n    ...Clients_viewer_3Wa5l4\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment ClientRow_client on Client {\n  id\n  rowId\n  name\n  profilePhotoUrl\n  lastActiveAt\n  clientSince\n  totalAppointmentsCount\n}\n\nfragment Clients_viewer_3Wa5l4 on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    rowId\n    inviteLink(isSingleUse: $isSingleUse)\n    clients(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit) {\n      edges {\n        node {\n          id\n          ...ClientRow_client\n          __typename\n        }\n        cursor\n      }\n      currentPage\n      start\n      end\n      total\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2016c261626259a6227997b0aa6a25c4';

module.exports = node;
