import React from 'react';
import {Table} from 'semantic-ui-react';
import moment from 'moment';
import {reverse, get} from 'lodash';
import {withTranslation} from 'react-i18next';

class Agenda extends React.Component {
  render() {
    const {events, t} = this.props;

    const orderedEvents = reverse(events);

    return (
      <div>
        <Table sortable padded selectable compact celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('common.date')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.time')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.name')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.provider')}</Table.HeaderCell>
              <Table.HeaderCell>{t('common.status')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orderedEvents.map((event) => {
              const formattedDay = moment(event.start).format('LL');
              const formattedStartAt = moment(event.start).format('LT');
              const formattedEndAt = moment(event.end).format('LT');

              const appointment = event.appointments[0];
              const clientName = get(appointment, 'client.name');
              const providerName = get(appointment, 'provider.name');
              const status = get(appointment, 'status');

              return (
                <Table.Row key={event.appointments[0].id} onClick={() => this.props.onAgendaRowClick(event)}>
                  <Table.Cell>{formattedDay}</Table.Cell>
                  <Table.Cell>
                    {formattedStartAt} - {formattedEndAt}
                  </Table.Cell>
                  <Table.Cell>{clientName}</Table.Cell>
                  <Table.Cell>{providerName}</Table.Cell>
                  <Table.Cell>{status ? t(`common.${status.split(' ').join('_').toLowerCase()}`) : '-'}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default withTranslation()(Agenda);
