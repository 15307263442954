import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {get} from 'lodash';
import PlaceHolderImage from '../images/person-placeholder.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
`;

const InnerContainer = styled.div`
  border-radius: 14px;
  border: 1px solid lightgray;
  padding-bottom: 10px;
`;

const ProfilePicture = styled.img`
  height: 180px;
  width: 180px;
  border-radius: 14px;
  border: 0px;
  object-fit: cover;
`;

const Name = styled.div`
  font-size: 17px;
  font-weight: bold;
  margin-top: 8px;
  word-wrap: break-word;
`;

const Detail = styled.div`
  font-size: 14px;
  color: darkgray;
`;

const DetailsContainer = styled.div`
  padding: 0px 10px 0px 10px;
`;

function ClientCard({client}) {
  const {t} = useTranslation();

  const name = get(client, 'name') || t('common.guest');
  const phoneNumber = get(client, 'phoneNumber');

  return (
    <Container>
      <InnerContainer>
        <ProfilePicture src={client.profilePhotoUrl || PlaceHolderImage} />
        <DetailsContainer>
          <Name>{name}</Name>
          {phoneNumber && <Detail>{phoneNumber}</Detail>}
        </DetailsContainer>
      </InnerContainer>
      <div style={{display: 'flex', flexShring: 0}} />
    </Container>
  );
}

export default ClientCard;
