/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferAlternativeHoursInput = {|
  clientMutationId?: ?string,
  id: string,
  alternativeTimes?: ?$ReadOnlyArray<?any>,
  fieldsData?: ?any,
|};
export type OfferAlternativeHoursMutationVariables = {|
  input: OfferAlternativeHoursInput
|};
export type OfferAlternativeHoursMutationResponse = {|
  +offerAlternativeHours: ?{|
    +appointment: ?{|
      +id: string,
      +rowId: string,
      +status: ?string,
      +startAt: ?any,
      +updatedAt: ?any,
      +service: ?{|
        +id: string
      |},
      +endAt: ?any,
      +client: ?{|
        +id: string
      |},
      +providerId: ?number,
      +provider: ?{|
        +id: string,
        +name: ?string,
      |},
    |}
  |}
|};
export type OfferAlternativeHoursMutation = {|
  variables: OfferAlternativeHoursMutationVariables,
  response: OfferAlternativeHoursMutationResponse,
|};
*/


/*
mutation OfferAlternativeHoursMutation(
  $input: OfferAlternativeHoursInput!
) {
  offerAlternativeHours(input: $input) {
    appointment {
      id
      rowId
      status
      startAt
      updatedAt
      service {
        id
      }
      endAt
      client {
        id
      }
      providerId
      provider {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OfferAlternativeHoursPayload",
    "kind": "LinkedField",
    "name": "offerAlternativeHours",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Appointment",
        "kind": "LinkedField",
        "name": "appointment",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Service",
            "kind": "LinkedField",
            "name": "service",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "providerId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Provider",
            "kind": "LinkedField",
            "name": "provider",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OfferAlternativeHoursMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OfferAlternativeHoursMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "365d9ec8d2662ad43555aa07771ed1f3",
    "id": null,
    "metadata": {},
    "name": "OfferAlternativeHoursMutation",
    "operationKind": "mutation",
    "text": "mutation OfferAlternativeHoursMutation(\n  $input: OfferAlternativeHoursInput!\n) {\n  offerAlternativeHours(input: $input) {\n    appointment {\n      id\n      rowId\n      status\n      startAt\n      updatedAt\n      service {\n        id\n      }\n      endAt\n      client {\n        id\n      }\n      providerId\n      provider {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '66b8edae134a3a50c294f78aa5fb4b1b';

module.exports = node;
