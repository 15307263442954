/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AppointmentRow_appointment$ref: FragmentReference;
declare export opaque type AppointmentRow_appointment$fragmentType: AppointmentRow_appointment$ref;
export type AppointmentRow_appointment = {|
  +startAt: ?any,
  +endAt: ?any,
  +duration: ?number,
  +service: ?{|
    +title: ?string
  |},
  +$refType: AppointmentRow_appointment$ref,
|};
export type AppointmentRow_appointment$data = AppointmentRow_appointment;
export type AppointmentRow_appointment$key = {
  +$data?: AppointmentRow_appointment$data,
  +$fragmentRefs: AppointmentRow_appointment$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppointmentRow_appointment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Service",
      "kind": "LinkedField",
      "name": "service",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Appointment",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd69f2a9f3f90994566124bd0113c23a7';

module.exports = node;
