import graphql from 'babel-plugin-relay/macro';
import {requestSubscription} from 'react-relay';
import {environment} from '../relay';
import {addEdgeToConnection} from '../helpers/SharedUpdater';

const addAppointmentSubscription = graphql`
  subscription AddAppointmentSubscription($input: AddAppointmentSubscriptionInput!) {
    addAppointment(input: $input) {
      id
      appointmentEdge {
        node {
          id
          providerId
          serviceId
          service {
            profilePhotoUrl
            title
            duration
            capacity
          }
          capacity
          startAt
          status
          endAt
          clientId
          isRecurring
          provider {
            id
            rowId
            name
          }
          client {
            id
            name
            profilePhotoUrl
          }
        }
      }
    }
  }
`;

const AddAppointmentSubscription = (viewerId, connectionName, filters, callback) => {
  const requestSubscriptionResult = requestSubscription(environment, {
    subscription: addAppointmentSubscription,
    variables: {
      input: {},
    },
    updater: (store) => {
      const rootField = store.getRootField('addAppointment');
      const newEdge = rootField.getLinkedRecord('appointmentEdge');
      addEdgeToConnection(store, viewerId, newEdge, connectionName);
    },
    onNext: (...rest) => {
      if (callback && callback.onSuccess) {
        callback.onSuccess();
      }
    },
    onError: (error) => {
      console.log('error', error);
    },
  });

  return {
    dispose: requestSubscriptionResult.dispose,
  };
};

export default AddAppointmentSubscription;
