/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteServiceInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type DeleteServiceMutationVariables = {|
  input: DeleteServiceInput
|};
export type DeleteServiceMutationResponse = {|
  +deleteService: ?{|
    +clientMutationId: ?string,
    +deletedServiceId: ?string,
  |}
|};
export type DeleteServiceMutation = {|
  variables: DeleteServiceMutationVariables,
  response: DeleteServiceMutationResponse,
|};
*/


/*
mutation DeleteServiceMutation(
  $input: DeleteServiceInput!
) {
  deleteService(input: $input) {
    clientMutationId
    deletedServiceId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteServicePayload",
    "kind": "LinkedField",
    "name": "deleteService",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedServiceId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteServiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteServiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0c97d925dacbda3f1c1b9dc78a4a32d2",
    "id": null,
    "metadata": {},
    "name": "DeleteServiceMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteServiceMutation(\n  $input: DeleteServiceInput!\n) {\n  deleteService(input: $input) {\n    clientMutationId\n    deletedServiceId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2848a489dc7e3a46ad06027c5ea3781d';

module.exports = node;
