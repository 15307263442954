/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VacationHourRow_vacationHour$ref = any;
export type AddVacationHourInput = {|
  clientMutationId?: ?string,
  providerIds: $ReadOnlyArray<?string>,
  startAt: string,
  endAt: string,
|};
export type AddVacationHourMutationVariables = {|
  connections: $ReadOnlyArray<string>,
  input: AddVacationHourInput,
|};
export type AddVacationHourMutationResponse = {|
  +addVacationHour: ?{|
    +vacationHourEdge: ?{|
      +node: ?{|
        +$fragmentRefs: VacationHourRow_vacationHour$ref
      |}
    |}
  |}
|};
export type AddVacationHourMutation = {|
  variables: AddVacationHourMutationVariables,
  response: AddVacationHourMutationResponse,
|};
*/


/*
mutation AddVacationHourMutation(
  $input: AddVacationHourInput!
) {
  addVacationHour(input: $input) {
    vacationHourEdge {
      node {
        ...VacationHourRow_vacationHour
        id
      }
    }
  }
}

fragment VacationHourRow_vacationHour on VacationHour {
  id
  provider {
    id
    name
  }
  startAt
  endAt
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddVacationHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddVacationHourPayload",
        "kind": "LinkedField",
        "name": "addVacationHour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VacationHourEdge",
            "kind": "LinkedField",
            "name": "vacationHourEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VacationHour",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "VacationHourRow_vacationHour"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddVacationHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddVacationHourPayload",
        "kind": "LinkedField",
        "name": "addVacationHour",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VacationHourEdge",
            "kind": "LinkedField",
            "name": "vacationHourEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VacationHour",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Provider",
                    "kind": "LinkedField",
                    "name": "provider",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "vacationHourEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "329a69fd315768aeee0e7cc6d0968fb9",
    "id": null,
    "metadata": {},
    "name": "AddVacationHourMutation",
    "operationKind": "mutation",
    "text": "mutation AddVacationHourMutation(\n  $input: AddVacationHourInput!\n) {\n  addVacationHour(input: $input) {\n    vacationHourEdge {\n      node {\n        ...VacationHourRow_vacationHour\n        id\n      }\n    }\n  }\n}\n\nfragment VacationHourRow_vacationHour on VacationHour {\n  id\n  provider {\n    id\n    name\n  }\n  startAt\n  endAt\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8ec6554c378f14d2a9575e47f7e38ddb';

module.exports = node;
