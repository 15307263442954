/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type History_viewer$ref = any;
export type HistoryQuery_QueryVariables = {|
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  limit?: ?number,
  page?: ?number,
  first?: ?number,
|};
export type HistoryQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: History_viewer$ref
  |}
|};
export type HistoryQuery_Query = {|
  variables: HistoryQuery_QueryVariables,
  response: HistoryQuery_QueryResponse,
|};
*/


/*
query HistoryQuery_Query(
  $orderBy: [[String]]
  $limit: Int
  $page: Int
  $first: Int
) {
  viewer {
    __typename
    ...History_viewer_4GxLBr
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment HistoryRow_payment on SubscriptionPayment {
  id
  rowId
  createdAt
  currency
  amount
  paymentMethod {
    brand
    last4
    id
  }
}

fragment History_viewer_4GxLBr on User {
  __isUser: __typename
  ... on Provider {
    organization {
      payments(orderBy: $orderBy, page: $page, first: $first, limit: $limit) {
        edges {
          node {
            id
            ...HistoryRow_payment
            __typename
          }
          cursor
        }
        currentPage
        start
        end
        total
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v4 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HistoryQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "FragmentSpread",
            "name": "History_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HistoryQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "SubscriptionPaymentConnection",
                    "kind": "LinkedField",
                    "name": "payments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SubscriptionPaymentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SubscriptionPayment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rowId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currency",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "OrganizationPaymentMethod",
                                "kind": "LinkedField",
                                "name": "paymentMethod",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "brand",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "last4",
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "start",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "end",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "total",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UFPageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "filters": [
                      "orderBy",
                      "page",
                      "limit"
                    ],
                    "handle": "connection",
                    "key": "History_payments",
                    "kind": "LinkedHandle",
                    "name": "payments"
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "688a182ee3297b0a53a9470efd68d991",
    "id": null,
    "metadata": {},
    "name": "HistoryQuery_Query",
    "operationKind": "query",
    "text": "query HistoryQuery_Query(\n  $orderBy: [[String]]\n  $limit: Int\n  $page: Int\n  $first: Int\n) {\n  viewer {\n    __typename\n    ...History_viewer_4GxLBr\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment HistoryRow_payment on SubscriptionPayment {\n  id\n  rowId\n  createdAt\n  currency\n  amount\n  paymentMethod {\n    brand\n    last4\n    id\n  }\n}\n\nfragment History_viewer_4GxLBr on User {\n  __isUser: __typename\n  ... on Provider {\n    organization {\n      payments(orderBy: $orderBy, page: $page, first: $first, limit: $limit) {\n        edges {\n          node {\n            id\n            ...HistoryRow_payment\n            __typename\n          }\n          cursor\n        }\n        currentPage\n        start\n        end\n        total\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cfc59220e0339bf99bff704097898835';

module.exports = node;
