/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AppBar_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type App_viewer$ref: FragmentReference;
declare export opaque type App_viewer$fragmentType: App_viewer$ref;
export type App_viewer = {|
  +__typename: "Provider",
  +settings: ?{|
    +locale: ?string
  |},
  +$fragmentRefs: AppBar_viewer$ref,
  +$refType: App_viewer$ref,
|} | {|
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  +__typename: "%other",
  +$refType: App_viewer$ref,
|};
export type App_viewer$data = App_viewer;
export type App_viewer$key = {
  +$data?: App_viewer$data,
  +$fragmentRefs: App_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "appointmentId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "App_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProviderSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locale",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AppBar_viewer"
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
// prettier-ignore
(node/*: any*/).hash = '33b567e3da3ddb0db2d3e803f11d0d6f';

module.exports = node;
