import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {createRefetchContainer} from 'react-relay';
import {Input, List, Segment, Menu, Header} from 'semantic-ui-react';
import {withRelay} from '../relay';
import {debounce, get} from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'found';
import {ModalContext} from './ModalContext';

import './SearchBar.css';

const AvatarContainer = styled.div`
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;
  margin-right: 10px;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;

const refetchVariables = {
  text: '',
  limit: 30,
  shouldSearch: false,
};

class SearchBar extends React.Component {
  state = {
    searchInput: '',
    isLoading: false,
    isOpen: false,
  };

  constructor(props) {
    super(props);

    this.delayedSearch = debounce(this.search, 350);
  }

  componentDidMount() {
    window.addEventListener('click', this.closeMenuOnOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeMenuOnOutsideClick);
  }

  closeMenuOnOutsideClick = (e) => {
    const element = this.menuRef;
    let targetElement = e.target;

    do {
      if (element === targetElement) {
        return;
      }
      targetElement = targetElement.parentNode;
    } while (targetElement);

    if (this.state.isOpen) {
      return this.setState({isOpen: false, searchInput: ''});
    }
  };

  search = (e) => {
    if (!e.target.value || e.target.value.trim().length < 3) {
      if (refetchVariables.shouldSearch) {
        refetchVariables.shouldSearch = false;
        refetchVariables.text = '';
        return this.props.relay.refetch(refetchVariables, null, () => this.setState({isLoading: false, isOpen: false}));
      } else {
        return this.setState({isLoading: false});
      }
    }

    refetchVariables.text = e.target.value;
    refetchVariables.shouldSearch = true;

    this.props.relay.refetch(refetchVariables, null, () => this.setState({isLoading: false, isOpen: true}));
  };

  handleInputChange = (e, value) => {
    this.setState({searchInput: e.target.value, isLoading: true});
    e.persist();

    this.delayedSearch(e);
  };

  renderOptionContent = (option) => {
    if (!option) {
      return;
    }

    const {t} = this.props;
    if (option.__typename === 'Appointment') {
      const client = get(option, 'client') || {};
      const service = get(option, 'service') || {};

      return (
        <List.Content>
          <List.Header>
            {t('titles.appointment_with')} {client.name}
          </List.Header>
          <List.Description>
            {service.title} {t('common.on')} {option.startAt && moment(option.startAt).format('lll')}
          </List.Description>
        </List.Content>
      );
    }
    if (option.__typename === 'Client') {
      return (
        <List.Content>
          <List.Header>{option.name}</List.Header>
          <List.Description>{option.phoneNumber}</List.Description>
          <List.Description>
            {t('common.last_active')} {moment(option.lastActiveAt).fromNow()}
          </List.Description>
        </List.Content>
      );
    }
    if (option.__typename === 'Service') {
      return (
        <List.Content>
          <List.Header>{option.title}</List.Header>
          <List.Description>{option.description}</List.Description>
        </List.Content>
      );
    }
  };

  handleResultSelect = (result, openModal) => {
    if (!result) {
      return;
    }
    this.setState({isOpen: false});

    if (result.__typename === 'Client') {
      this.props.router.push(`/clients/${result.id}`);
    }
    if (result.__typename === 'Service') {
      this.props.router.push(`/service/${result.id}`);
    }
    if (result.__typename === 'Appointment') {
      openModal(result.id);
    }
  };

  renderOption = (option) => {
    const {__typename} = option;

    const profileUrl =
      __typename === 'Appointment' ? get(option, 'service.profilePhotoUrl') : get(option, 'profilePhotoUrl');

    return (
      <div>
        <List>
          <List.Item style={{height: 65, display: 'flex', flexDirection: 'row'}} key={option.id}>
            <AvatarContainer>
              <Avatar src={profileUrl} />
            </AvatarContainer>
            {this.renderOptionContent(option)}
          </List.Item>
        </List>
      </div>
    );
  };

  render() {
    const {viewer} = this.props;
    if (!viewer) {
      return null;
    }

    const {search = []} = viewer;
    const options = search || [];
    const {searchInput, isOpen, isLoading} = this.state;
    const {t} = this.props;

    const formattedGroupedOptions = options.reduce((acc, curr) => {
      const key = `${curr['__typename']}s`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr);
      return acc;
    }, {});

    return (
      <div ref={(ref) => (this.menuRef = ref)}>
        <Input
          onChange={this.handleInputChange}
          className="custom-input"
          iconPosition="left"
          value={searchInput}
          placeholder={t('common.search')}
          icon="search"
          loading={isLoading}
        />

        {!isLoading && searchInput && searchInput.length >= 3 && search && search.length === 0 && (
          <Segment style={{position: 'absolute', marginTop: '0px', width: '350px', marginLeft: '-50px'}}>
            <Header as="h4">{t('titles.no_results')}</Header>
          </Segment>
        )}
        {search.length > 0 && isOpen && !isLoading && (
          <ModalContext.Consumer>
            {({appointmentId, openModal}) => (
              <Menu
                vertical
                style={{
                  position: 'absolute',
                  width: '400px',
                  marginLeft: '-90px',
                  overflowY: 'auto',
                  maxHeight: '500px',
                }}
              >
                {Object.keys(formattedGroupedOptions).map((optionType) => {
                  let translatedOption = '';
                  switch (optionType) {
                    case 'Clients':
                      translatedOption = t('titles.clients');
                      break;
                    case 'Appointments':
                      translatedOption = t('titles.appointments');
                      break;
                    case 'Services':
                      translatedOption = t('titles.services');
                      break;
                    default:
                      return null;
                  }

                  return (
                    <div key={optionType}>
                      <Menu.Item header>{translatedOption}</Menu.Item>
                      {formattedGroupedOptions[optionType].map((option) => (
                        <Menu.Item
                          onClick={() => this.handleResultSelect(option, openModal)}
                          key={`${optionType}.${option.id}`}
                        >
                          {this.renderOption(option)}
                        </Menu.Item>
                      ))}
                    </div>
                  );
                })}
              </Menu>
            )}
          </ModalContext.Consumer>
        )}
      </div>
    );
  }
}

const SearchBarQuery = graphql`
  query SearchBarRefetchQuery($text: String, $limit: Int, $shouldSearch: Boolean!) {
    viewer {
      ...SearchBar_viewer @arguments(text: $text, limit: $limit, shouldSearch: $shouldSearch)
    }
  }
`;

const SearchBarRefetchContainer = createRefetchContainer(
  withTranslation()(SearchBar),
  {
    viewer: graphql`
      fragment SearchBar_viewer on User
      @argumentDefinitions(
        text: {type: "String"}
        limit: {type: "Int"}
        shouldSearch: {type: "Boolean!", defaultValue: false}
      ) {
        ... on Provider {
          search(text: $text, limit: $limit) @include(if: $shouldSearch) {
            __typename
            ... on Appointment {
              id
              startAt
              # eslint-disable-next-line relay/unused-fields
              provider {
                id
                name
                profilePhotoUrl
              }
              # eslint-disable-next-line relay/unused-fields
              service {
                id
                title
                profilePhotoUrl
              }
              # eslint-disable-next-line relay/unused-fields
              client {
                id
                name
              }
            }
            ... on Client {
              id
              name
              phoneNumber
              lastActiveAt
              # eslint-disable-next-line relay/unused-fields
              profilePhotoUrl
            }

            ... on Service {
              id
              title
              description
              # eslint-disable-next-line relay/unused-fields
              profilePhotoUrl
            }
          }
        }
      }
    `,
  },
  SearchBarQuery,
);

SearchBarRefetchContainer.getVariables = (props) => refetchVariables;

export default withRouter(withRelay(SearchBarRefetchContainer, SearchBarQuery));
