/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffMembers_viewer$ref = any;
export type ProviderFilterInput = {|
  type?: ?string,
  searchWord?: ?string,
  serviceId?: ?string,
|};
export type StaffMembersRefetchQueryVariables = {|
  after?: ?string,
  filterBy?: ?ProviderFilterInput,
  first?: ?number,
  limit?: ?number,
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  page?: ?number,
  id: string,
|};
export type StaffMembersRefetchQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: StaffMembers_viewer$ref
  |}
|};
export type StaffMembersRefetchQuery = {|
  variables: StaffMembersRefetchQueryVariables,
  response: StaffMembersRefetchQueryResponse,
|};
*/


/*
query StaffMembersRefetchQuery(
  $after: String
  $filterBy: ProviderFilterInput
  $first: Int = 50
  $limit: Int
  $orderBy: [[String]] = [["createdAt", "ASC"], ["name", "ASC"]]
  $page: Int = 1
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...StaffMembers_viewer_1LrRyD
    id
  }
}

fragment AddStaffMemberModal_viewer on Provider {
  roles {
    name
    id
  }
}

fragment StaffMemberRow_provider on Provider {
  id
  rowId
  name
  email
  phoneNumber
  profilePhotoUrl
}

fragment StaffMembers_viewer_1LrRyD on User {
  __isUser: __typename
  ... on Provider {
    id
    providers(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, after: $after, limit: $limit) {
      edges {
        node {
          id
          name
          placeIds
          ...StaffMemberRow_provider
          __typename
        }
        cursor
      }
      total
      currentPage
      start
      end
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    ...AddStaffMemberModal_viewer
  }
  ... on Node {
    __isNode: __typename
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v2 = {
  "defaultValue": 50,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v5 = {
  "defaultValue": [
    [
      "createdAt",
      "ASC"
    ],
    [
      "name",
      "ASC"
    ]
  ],
  "kind": "LocalArgument",
  "name": "orderBy"
},
v6 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filterBy"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StaffMembersRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v8/*: any*/),
            "kind": "FragmentSpread",
            "name": "StaffMembers_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "StaffMembersRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "ProviderConnection",
                    "kind": "LinkedField",
                    "name": "providers",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProviderEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Provider",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "placeIds",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rowId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "phoneNumber",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "profilePhotoUrl",
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "total",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currentPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "start",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "end",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UFPageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "StaffMembers_providers",
                    "kind": "LinkedHandle",
                    "name": "providers"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "roles",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Provider",
                "abstractKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isNode"
              }
            ],
            "type": "User",
            "abstractKey": "__isUser"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0488c18e49dfbbe1137b5a641ea46ac",
    "id": null,
    "metadata": {},
    "name": "StaffMembersRefetchQuery",
    "operationKind": "query",
    "text": "query StaffMembersRefetchQuery(\n  $after: String\n  $filterBy: ProviderFilterInput\n  $first: Int = 50\n  $limit: Int\n  $orderBy: [[String]] = [[\"createdAt\", \"ASC\"], [\"name\", \"ASC\"]]\n  $page: Int = 1\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...StaffMembers_viewer_1LrRyD\n    id\n  }\n}\n\nfragment AddStaffMemberModal_viewer on Provider {\n  roles {\n    name\n    id\n  }\n}\n\nfragment StaffMemberRow_provider on Provider {\n  id\n  rowId\n  name\n  email\n  phoneNumber\n  profilePhotoUrl\n}\n\nfragment StaffMembers_viewer_1LrRyD on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    providers(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, after: $after, limit: $limit) {\n      edges {\n        node {\n          id\n          name\n          placeIds\n          ...StaffMemberRow_provider\n          __typename\n        }\n        cursor\n      }\n      total\n      currentPage\n      start\n      end\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    ...AddStaffMemberModal_viewer\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '800dadad7a9206562a3bf254f802aeba';

module.exports = node;
