import React from 'react';
import styled from 'styled-components';
import GridTimeLines from './GridTimeLines';
import ProviderAppointments from './ProviderAppointments';
import {withTranslation} from 'react-i18next';

const ProviderNameCell = styled.div`
  height: 100px;
  border: solid white 1px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #167ac2;
  color: white;
  font-size: 16px;
  /* position: sticky; */
  top: 64px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: 'row';
  flex: 1;
  width: 100%;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

class CustomView extends React.Component {
  state = {
    cellWidth: null,
  };

  constructor(props) {
    super(props);
    this.cellRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    this.setState({cellWidth: this.cellRef.current.offsetWidth});
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({cellWidth: this.cellRef.current.offsetWidth});
  };

  handleAppointmentClick = (appointment) => {
    this.props.onAppointmentClick(appointment);
  };

  render() {
    const {events, providers, earliestHour = '07:00:00', latestHour = '20:00:00'} = this.props;

    // TODO: Fix this

    const appointmentsByProviderIds = events.reduce((p, c) => {
      const {appointments} = c;
      const appointment = appointments[0];

      if (!p[appointment.provider.id]) {
        p[appointment.provider.id] = [];
      }

      p[appointment.provider.id].push(appointment);

      return p;
    }, {});
    // const providerIds = Object.keys(appointmentsByProviders);

    return (
      <div>
        <div style={{display: 'flex', flex: 1, position: 'sticky', top: 64, zIndex: 3}}>
          <ProviderNameCell style={{maxWidth: 80, minWidth: 80, height: 60}} />
          {providers.map((provider) => (
            <ProviderNameCell ref={this.cellRef} key={provider.id} style={{minWidth: 85, height: 60}}>
              {provider.name}
            </ProviderNameCell>
          ))}
        </div>
        <Container>
          <GridContainer>
            <GridTimeLines earliestHour={earliestHour} latestHour={latestHour} />
            {providers.map((provider) => (
              <ProviderAppointments
                key={provider.id}
                width={this.state.cellWidth}
                t={this.props.t}
                onAppointmentClick={this.handleAppointmentClick}
                earliestHour={earliestHour}
                latestHour={latestHour}
                appointments={appointmentsByProviderIds[provider.id]}
              />
            ))}
          </GridContainer>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(CustomView);
