import styled from 'styled-components';
import {Elements, useElements, useStripe, PaymentElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Modal} from 'semantic-ui-react';
import CircularProgress from '@mui/material/CircularProgress';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';

const CTAButton = styled.button`
  width: 200px;
  height: 45px;
  text-align: center;
  background-color: #8070fd;
  border: 0px;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  width: 100%;

  :hover {
    background-color: purple;
  }
`;

const ErrorMessage = styled.div`
  color: #ef5350;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function CheckoutModal({onClose, open, onCompletePayment, intentType}) {
  const {t} = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const confirmPayload = {
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payments`,
      },
      // redirect: 'if_required',
    };

    setIsLoading(true);

    let result = null;
    if (intentType === 'payment') {
      result = await stripe.confirmPayment(confirmPayload);
    } else if (intentType === 'setup') {
      result = await stripe.confirmSetup(confirmPayload);
    } else {
      // TODO: Handle errors
      setIsLoading(false);
      return;
    }

    setIsLoading(false);

    if (result.error) {
      console.log('result.error', result.error);
      // Show error to your customer (for example, payment details incomplete)
      setErrorMessage(`${result.error.message} (${result.error.decline_code})`);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Modal onClose={onClose} open={open}>
      <Modal.Header content={t('common.payment_method')} />
      <Modal.Content>
        <form>
          <PaymentElement />
          <div style={{marginTop: 20}}>
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <CTAButton disabled={isLoading} onClick={handleSubmit}>
              {isLoading ? <CircularProgress style={{padding: 5}} color="inherit" /> : t('actions.submit')}
            </CTAButton>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
}

function StripeWrapper({children, clientSecret}) {
  const options = {
    clientSecret,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({clientSecret, ...props}) => (
  <StripeWrapper clientSecret={clientSecret}>
    <CheckoutModal {...props} />
  </StripeWrapper>
);
