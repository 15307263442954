import graphql from 'babel-plugin-relay/macro';
import {defaultsDeep, get} from 'lodash';
import moment from 'moment';
import {Card, Icon, Form, /*Select/*/ Button} from 'semantic-ui-react';
import {DatePicker} from '@atlaskit/datetime-picker';
import React from 'react';
import {createRefetchContainer} from 'react-relay';
import {
  XAxis,
  YAxis,
  FlexibleWidthXYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  LineMarkSeries,
  AreaSeries,
  Crosshair,
} from 'react-vis';
import Container from './Container';
import $ from 'jquery';
import {withTranslation} from 'react-i18next';

const defaultFilter = {
  date: {
    from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
  },
};

const formatHoursToMinutes = (minutes) => `${Math.floor(minutes / 60)}h ${minutes % 60 ? `${minutes % 60}mins` : ''}`;

class Analytics extends React.Component {
  state = {
    hoveredNode: null,
    selectedCard: 'hours',
    selectedDate: defaultFilter.date,
    crosshairValues: [],
    dateFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
  };

  refetchVaribles = {filterBy: defaultFilter};

  handleDateChange = (event, {value, name}) => {
    this.setState({[name]: value});

    this.refetchVaribles.filterBy = defaultsDeep({date: value}, this.refetchVaribles.filterBy);
    this.props.relay.refetch(this.refetchVaribles);
  };

  handleCardClick = (e, {name}) => this.setState({selectedCard: name});

  getGraphData = () => {
    const {selectedCard} = this.state;

    const rawData = get(this.props, `viewer.analytics[${selectedCard}]`) || {};
    const keys = Object.keys(rawData);
    keys.sort((a, b) => new Date(a) - new Date(b));

    const data = keys.length
      ? keys.map((key) => ({x: key, y: selectedCard === 'hours' ? rawData[key] / 60 : rawData[key]}))
      : null;

    return data;
  };

  handleExportCsv = () => {
    const {dateFrom, dateTo} = this.state;

    if (!dateFrom || !dateTo) {
      return;
    }

    const date = {
      from: moment(dateFrom).format('YYYY-MM-DD'),
      to: moment(dateTo).format('YYYY-MM-DD'),
    };

    const filterBy = {date};

    const params = $.param({filterBy});
    window.location.href = `//${window.SERVER_URL}/export/appointments?${params}`;
  };

  handleFromDateSelected = (dateFrom) => {
    let {dateTo} = this.state;

    if (dateTo && dateFrom >= dateTo) {
      dateTo = '';
    }

    this.setState({dateFrom, dateTo});

    if (dateTo !== '') {
      // refetch
      const date = {
        from: moment(dateFrom).format('YYYY-MM-DD'),
        to: moment(dateTo).format('YYYY-MM-DD'),
      };

      this.refetchVaribles.filterBy = defaultsDeep({date}, this.refetchVaribles.filterBy);
      this.props.relay.refetch(this.refetchVaribles);
    }
  };

  handleUntilDateSelected = (dateTo) => {
    let {dateFrom} = this.state;

    if (dateFrom && dateTo <= dateFrom) {
      dateFrom = '';
    }

    this.setState({dateTo, dateFrom});

    if (dateFrom !== '') {
      // refetch
      const date = {
        from: moment(dateFrom).format('YYYY-MM-DD'),
        to: moment(dateTo).format('YYYY-MM-DD'),
      };

      this.refetchVaribles.filterBy = defaultsDeep({date}, this.refetchVaribles.filterBy);
      this.props.relay.refetch(this.refetchVaribles);
    }
  };

  render() {
    const {/*selectedDate/*/ selectedCard, dateFrom, dateTo, crosshairValues} = this.state;
    const {t} = this.props;
    const {analytics = {}} = this.props.viewer;
    const {hours, appointments, totalNumberClients, newClients} = analytics;

    const totalHoursSpent = hours ? formatHoursToMinutes(Object.values(hours).reduce((p, c) => p + c, 0)) : 'N/A';
    const totalNumberNewClients = newClients ? Object.values(newClients).reduce((p, c) => p + c, 0) : 'N/A';
    const totalAppointments = appointments ? Object.values(appointments).reduce((p, c) => p + c, 0) : 'N/A';

    const data = this.getGraphData();

    // const dateOptions = [
    //   {
    //     key: 0,
    //     text: `${t('common.today')}`,
    //     value: {
    //       from: moment().format('YYYY-MM-DD'),
    //       to: moment().format('YYYY-MM-DD'),
    //     },
    //   },
    //   {
    //     key: -1,
    //     text: `${t('common.yesterday')}`,
    //     value: {
    //       from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    //       to: moment().format('YYYY-MM-DD'),
    //     },
    //   },
    //   {
    //     key: 7,
    //     text: `${t('common.last_7_days')}`,
    //     value: {
    //       from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    //       to: moment().format('YYYY-MM-DD'),
    //     },
    //   },
    //   {
    //     key: 30,
    //     text: `${t('common.last_30_days')}`,
    //     value: {
    //       from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    //       to: moment().format('YYYY-MM-DD'),
    //     },
    //   },
    //   {
    //     key: 90,
    //     text: `${t('common.last_90_days')}`,
    //     value: {
    //       from: moment().subtract(90, 'days').format('YYYY-MM-DD'),
    //       to: moment().format('YYYY-MM-DD'),
    //     },
    //   },
    //   {
    //     key: 365,
    //     text: `${t('common.last_year')}`,
    //     value: {
    //       from: moment().subtract(1, 'years').format('YYYY-MM-DD'),
    //       to: moment().format('YYYY-MM-DD'),
    //     },
    //   },
    // ];

    return (
      <Container>
        {/* <Select
          style={{marginBottom: 15, width: 557}}
          value={selectedDate}
          fluid
          placeholder="Select date range"
          name="selectedDate"
          options={dateOptions}
          onChange={this.handleDateChange}
        /> */}
        <Form>
          <Form.Group widths="2">
            <Form.Field>
              {t('common.from')}
              <DatePicker
                placeholder={t('actions.pick_date')}
                timeIsEditable={false}
                value={dateFrom}
                onChange={this.handleFromDateSelected}
              />
            </Form.Field>
            <Form.Field>
              {t('common.until')}
              <DatePicker
                placeholder={t('actions.pick_date')}
                timeIsEditable={false}
                value={dateTo}
                onChange={this.handleUntilDateSelected}
              />
            </Form.Field>
            <Form.Field>
              <Button
                style={{position: 'absolute', right: 0, marginTop: '20px'}}
                color="blue"
                onClick={this.handleExportCsv}
              >
                {t('actions.export_csv')}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <Card.Group itemsPerRow={4}>
          <Card name="hours" onClick={this.handleCardClick} raised={selectedCard === 'hours'}>
            <Card.Content textAlign="center">
              <Icon name="stopwatch" style={{color: '#3f51b5'}} size="huge" />
              <Card.Header style={{marginTop: 20}}>{totalHoursSpent}</Card.Header>
              <Card.Meta>{t('common.total_hours_spent')}</Card.Meta>
            </Card.Content>
          </Card>
          <Card name="clients" onClick={this.handleCardClick} raised={selectedCard === 'clients'}>
            <Card.Content textAlign="center">
              <Icon name="users" style={{color: '#3f51b5'}} size="huge" />
              <Card.Header style={{marginTop: 20}}>{totalNumberClients}</Card.Header>
              <Card.Meta>{t('common.total_number_clients')}</Card.Meta>
            </Card.Content>
          </Card>
          <Card name="newClients" onClick={this.handleCardClick} raised={selectedCard === 'newClients'}>
            <Card.Content textAlign="center">
              <Icon name="user plus" style={{color: '#3f51b5'}} size="huge" />
              <Card.Header style={{marginTop: 20}}>{totalNumberNewClients}</Card.Header>
              <Card.Meta>{t('common.new_clients')}</Card.Meta>
            </Card.Content>
          </Card>
          <Card name="appointments" onClick={this.handleCardClick} raised={selectedCard === 'appointments'}>
            <Card.Content textAlign="center">
              <Icon name="calendar alternate outline" style={{color: '#3f51b5'}} size="huge" />
              <Card.Header style={{marginTop: 20}}>{totalAppointments}</Card.Header>
              <Card.Meta>{t('common.total_appointments')}</Card.Meta>
            </Card.Content>
          </Card>
        </Card.Group>
        {data && (
          <FlexibleWidthXYPlot
            xType="ordinal"
            style={{marginTop: 50}}
            height={340}
            color="#3f51b5"
            onMouseLeave={() => this.setState({crosshairValues: []})}
          >
            {/* stackBy */}
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis
              tickValues={
                data.length > 15
                  ? data
                      .filter((item, idx) => {
                        if (idx % Math.floor(data.length / 15) === 0) {
                          return item.x;
                        }
                        return null;
                      })
                      .map((item) => item.x)
                  : data.map((item) => item.x)
              }
              tickFormat={(d) => {
                const diff = moment(dateTo).diff(dateFrom, 'days');

                //format dates according to period
                if (diff <= 7) {
                  return moment(d).format('ddd');
                } else if (diff > 7 && diff <= 90) {
                  return d;
                } else {
                  return moment(d).format('MMM');
                }
              }}
            />
            <YAxis />
            <AreaSeries
              curve={'curveMonotoneX'}
              animation={'noWobble'}
              // xOffset={offset}
              // yOffset={offset}
              data={data}
              onNearestX={(value, {index}) => this.setState({crosshairValues: [data[index]]})}
            />
            <LineMarkSeries
              size={3}
              curve={'curveMonotoneX'}
              onValueMouseOver={(d) => this.setState({hoveredNode: d})}
              animation={'noWobble'}
              // xOffset={offset}
              // yOffset={offset}
              data={data}
            />
            <Crosshair
              values={crosshairValues}
              titleFormat={(value) => ({title: 'Date', value: value[0].x})}
              itemsFormat={(data) => data.map(({x, y}) => ({title: 'Value', value: Math.floor(y)}))}
            />
          </FlexibleWidthXYPlot>
        )}
      </Container>
    );
  }
}
const AnalyticsContainer = createRefetchContainer(
  withTranslation()(Analytics),
  {
    viewer: graphql`
      fragment Analytics_viewer on User @argumentDefinitions(filterBy: {type: "AnalyticsFilterInput"}) {
        ... on Provider {
          analytics(filterBy: $filterBy) {
            appointments
            totalNumberClients
            newClients
            hours
          }
        }
      }
    `,
  },
  graphql`
    query AnalyticsRefetchQuery($filterBy: AnalyticsFilterInput) {
      viewer {
        ...Analytics_viewer @arguments(filterBy: $filterBy)
      }
    }
  `,
);

AnalyticsContainer.getInitialVariables = () => ({filterBy: defaultFilter});

export default AnalyticsContainer;
