/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddClientInput = {|
  clientMutationId?: ?string,
  name?: ?string,
  email?: ?string,
  placeIds?: ?$ReadOnlyArray<?string>,
  phoneNumber?: ?string,
  inviteClient?: ?boolean,
|};
export type AddClientMutationVariables = {|
  connections: $ReadOnlyArray<string>,
  input: AddClientInput,
|};
export type AddClientMutationResponse = {|
  +addClient: ?{|
    +clientEdge: ?{|
      +node: ?{|
        +id: string,
        +rowId: number,
        +name: ?string,
        +profilePhotoUrl: ?string,
        +phoneNumber: ?string,
      |}
    |}
  |}
|};
export type AddClientMutation = {|
  variables: AddClientMutationVariables,
  response: AddClientMutationResponse,
|};
*/


/*
mutation AddClientMutation(
  $input: AddClientInput!
) {
  addClient(input: $input) {
    clientEdge {
      node {
        id
        rowId
        name
        profilePhotoUrl
        phoneNumber
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ClientEdge",
  "kind": "LinkedField",
  "name": "clientEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhotoUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phoneNumber",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddClientMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddClientPayload",
        "kind": "LinkedField",
        "name": "addClient",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddClientMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddClientPayload",
        "kind": "LinkedField",
        "name": "addClient",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "clientEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44d9d889109573bd8dc36bac1481dcfe",
    "id": null,
    "metadata": {},
    "name": "AddClientMutation",
    "operationKind": "mutation",
    "text": "mutation AddClientMutation(\n  $input: AddClientInput!\n) {\n  addClient(input: $input) {\n    clientEdge {\n      node {\n        id\n        rowId\n        name\n        profilePhotoUrl\n        phoneNumber\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3b2671ad42723267253a24dbf0d389f7';

module.exports = node;
