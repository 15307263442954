/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ServiceRow_service$ref: FragmentReference;
declare export opaque type ServiceRow_service$fragmentType: ServiceRow_service$ref;
export type ServiceRow_service = {|
  +id: string,
  +title: ?string,
  +description: ?string,
  +duration: ?number,
  +capacity: ?number,
  +isActive: ?boolean,
  +$refType: ServiceRow_service$ref,
|};
export type ServiceRow_service$data = ServiceRow_service;
export type ServiceRow_service$key = {
  +$data?: ServiceRow_service$data,
  +$fragmentRefs: ServiceRow_service$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServiceRow_service",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "capacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    }
  ],
  "type": "Service",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'fe0d71ae49dec5ef987debeef39254dc';

module.exports = node;
