import graphql from 'babel-plugin-relay/macro';

const MyProfileQuery = graphql`
  query MyProfileQuery_Query {
    viewer {
      ...MyProfile_viewer
    }
  }
`;

export default MyProfileQuery;
