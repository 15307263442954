/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteWorkHourInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type DeleteWorkHourMutationVariables = {|
  connections: $ReadOnlyArray<string>,
  input: DeleteWorkHourInput,
|};
export type DeleteWorkHourMutationResponse = {|
  +deleteWorkHour: ?{|
    +clientMutationId: ?string,
    +deletedId: ?string,
  |}
|};
export type DeleteWorkHourMutation = {|
  variables: DeleteWorkHourMutationVariables,
  response: DeleteWorkHourMutationResponse,
|};
*/


/*
mutation DeleteWorkHourMutation(
  $input: DeleteWorkHourInput!
) {
  deleteWorkHour(input: $input) {
    clientMutationId
    deletedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteWorkHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteWorkHourPayload",
        "kind": "LinkedField",
        "name": "deleteWorkHour",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteWorkHourMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteWorkHourPayload",
        "kind": "LinkedField",
        "name": "deleteWorkHour",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedId",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "805d92e62a7b79b25b09e2d6546c78b4",
    "id": null,
    "metadata": {},
    "name": "DeleteWorkHourMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteWorkHourMutation(\n  $input: DeleteWorkHourInput!\n) {\n  deleteWorkHour(input: $input) {\n    clientMutationId\n    deletedId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '99d69ff7f1cae396c44a313756bed19c';

module.exports = node;
