import {Dimmer, Loader} from 'semantic-ui-react';

const CustomLoader = ({t}) => {
  return (
    <Dimmer active inverted>
      <Loader size="huge">{t && t('common.loading')}</Loader>
    </Dimmer>
  );
};

export default CustomLoader;
