/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteAppointmentSubscriptionInput = {|
  clientSubscriptionId?: ?string
|};
export type DeleteAppointmentSubscriptionVariables = {|
  input: DeleteAppointmentSubscriptionInput
|};
export type DeleteAppointmentSubscriptionResponse = {|
  +deleteAppointment: ?{|
    +id: ?string,
    +deletedAppointments: ?$ReadOnlyArray<?{|
      +id: string
    |}>,
  |}
|};
export type DeleteAppointmentSubscription = {|
  variables: DeleteAppointmentSubscriptionVariables,
  response: DeleteAppointmentSubscriptionResponse,
|};
*/


/*
subscription DeleteAppointmentSubscription(
  $input: DeleteAppointmentSubscriptionInput!
) {
  deleteAppointment(input: $input) {
    id
    deletedAppointments {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteAppointmentSubscriptionPayload",
    "kind": "LinkedField",
    "name": "deleteAppointment",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Appointment",
        "kind": "LinkedField",
        "name": "deletedAppointments",
        "plural": true,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAppointmentSubscription",
    "selections": (v2/*: any*/),
    "type": "SocketSubscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteAppointmentSubscription",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fd840d31ef51d766c68c21080b6e9670",
    "id": null,
    "metadata": {},
    "name": "DeleteAppointmentSubscription",
    "operationKind": "subscription",
    "text": "subscription DeleteAppointmentSubscription(\n  $input: DeleteAppointmentSubscriptionInput!\n) {\n  deleteAppointment(input: $input) {\n    id\n    deletedAppointments {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e1b0bdd94900fd360557377f31e80bb7';

module.exports = node;
