/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type General_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Payments_viewer$ref: FragmentReference;
declare export opaque type Payments_viewer$fragmentType: Payments_viewer$ref;
export type Payments_viewer = {|
  +organization?: ?{|
    +subscription: ?{|
      +status: ?string
    |}
  |},
  +$fragmentRefs: General_viewer$ref,
  +$refType: Payments_viewer$ref,
|};
export type Payments_viewer$data = Payments_viewer;
export type Payments_viewer$key = {
  +$data?: Payments_viewer$data,
  +$fragmentRefs: Payments_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "includeGeneral"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Payments_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Subscription",
              "kind": "LinkedField",
              "name": "subscription",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "condition": "includeGeneral",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "General_viewer"
            }
          ]
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
// prettier-ignore
(node/*: any*/).hash = 'dd9e33ab25b7bc58cfbbf7b3a1888803';

module.exports = node;
