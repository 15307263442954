import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Tab} from 'semantic-ui-react';
import Container from '../Container';
import canAccess, {
  USER_SETS_CONDITION_ASSIGNED_TO_ORGANIZATION,
  USER_SETS_CONDITION_ASSIGNED_TO_PLACE,
} from '../../helpers/canAccess';
import PersonalSettings from './PersonalSettings';
import OrganizationSettings from './OrganizationSettings';
import PlaceSettings from './PlaceSettings';
import {useFragment} from 'relay-hooks';

function Settings(props) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const {t} = useTranslation();

  function handleTabChange(e, {activeIndex}) {
    setActiveIndex(activeIndex);
  }

  const viewer = useFragment(
    graphql`
      fragment Settings_viewer on User {
        ... on Provider {
          ...PersonalSettings_viewer
          role {
            sets
          }
        }
      }
    `,
    props.viewer,
  );

  const {role} = viewer;
  const {sets} = role;

  const panes = [
    {menuItem: `${t('titles.personal_settings')}`, render: () => <PersonalSettings />},
    canAccess(sets, 'places', [USER_SETS_CONDITION_ASSIGNED_TO_PLACE, USER_SETS_CONDITION_ASSIGNED_TO_ORGANIZATION])
      ? {menuItem: `${t('titles.places')}`, render: () => <PlaceSettings />}
      : {},
    canAccess(sets, 'places', [USER_SETS_CONDITION_ASSIGNED_TO_ORGANIZATION])
      ? {
          menuItem: `${t('titles.organization')}`,
          render: () => <OrganizationSettings />,
        }
      : {},
  ];

  return (
    <Container>
      <Tab
        menu={{secondary: true, pointing: true}}
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
        panes={panes}
      />
    </Container>
  );
}

export default Settings;
