/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BookRecurringTimeSlotsInput = {|
  clientMutationId?: ?string,
  timeSlots: $ReadOnlyArray<?TimeSlotInput>,
  paymentId?: ?string,
  recurrenceOptions?: ?any,
  status?: ?string,
  fieldsData?: ?any,
|};
export type TimeSlotInput = {|
  id: string,
  date: string,
  startAt: string,
  endAt: string,
  providerId: string,
  serviceId: string,
  price?: ?number,
  clientId?: ?string,
  recurringTimeSlotId?: ?string,
  timestamp?: ?number,
  capacity?: ?number,
  duration?: ?number,
|};
export type BookRecurringTimeSlotsMutationVariables = {|
  connections: $ReadOnlyArray<string>,
  input: BookRecurringTimeSlotsInput,
|};
export type BookRecurringTimeSlotsMutationResponse = {|
  +bookRecurringTimeSlots: ?{|
    +appointments: ?$ReadOnlyArray<?{|
      +id: string,
      +rowId: string,
      +status: ?string,
      +startAt: ?any,
      +endAt: ?any,
      +isPartOfRecurringSequence: ?boolean,
      +formattedRecurrencePattern: ?string,
      +providerId: ?number,
      +serviceId: ?number,
      +amountPaid: ?number,
      +clientId: ?number,
      +timeSlots: ?$ReadOnlyArray<?{|
        +id: string,
        +startAt: ?any,
        +endAt: ?any,
        +date: ?any,
        +duration: ?number,
      |}>,
      +service: ?{|
        +id: string,
        +title: ?string,
        +duration: ?number,
      |},
      +client: ?{|
        +name: ?string,
        +phoneNumber: ?string,
        +profilePhotoUrl: ?string,
      |},
      +provider: ?{|
        +id: string,
        +rowId: number,
        +name: ?string,
        +profilePhotoUrl: ?string,
      |},
      +updatedAt: ?any,
    |}>
  |}
|};
export type BookRecurringTimeSlotsMutation = {|
  variables: BookRecurringTimeSlotsMutationVariables,
  response: BookRecurringTimeSlotsMutationResponse,
|};
*/


/*
mutation BookRecurringTimeSlotsMutation(
  $input: BookRecurringTimeSlotsInput!
) {
  bookRecurringTimeSlots(input: $input) {
    appointments {
      id
      rowId
      status
      startAt
      endAt
      isPartOfRecurringSequence
      formattedRecurrencePattern
      providerId
      serviceId
      amountPaid
      clientId
      timeSlots {
        id
        startAt
        endAt
        date
        duration
      }
      service {
        id
        title
        duration
      }
      client {
        name
        phoneNumber
        profilePhotoUrl
        id
      }
      provider {
        id
        rowId
        name
        profilePhotoUrl
      }
      updatedAt
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPartOfRecurringSequence",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "formattedRecurrencePattern",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "providerId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountPaid",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "TimeSlot",
  "kind": "LinkedField",
  "name": "timeSlots",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    (v13/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    (v13/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phoneNumber",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhotoUrl",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Provider",
  "kind": "LinkedField",
  "name": "provider",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v16/*: any*/),
    (v18/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookRecurringTimeSlotsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BookRecurringTimeSlotsPayload",
        "kind": "LinkedField",
        "name": "bookRecurringTimeSlots",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "appointments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookRecurringTimeSlotsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BookRecurringTimeSlotsPayload",
        "kind": "LinkedField",
        "name": "bookRecurringTimeSlots",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "appointments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "appointments",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "Appointment"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a2dc2d0270f7aedcc5120f0016d3b29",
    "id": null,
    "metadata": {},
    "name": "BookRecurringTimeSlotsMutation",
    "operationKind": "mutation",
    "text": "mutation BookRecurringTimeSlotsMutation(\n  $input: BookRecurringTimeSlotsInput!\n) {\n  bookRecurringTimeSlots(input: $input) {\n    appointments {\n      id\n      rowId\n      status\n      startAt\n      endAt\n      isPartOfRecurringSequence\n      formattedRecurrencePattern\n      providerId\n      serviceId\n      amountPaid\n      clientId\n      timeSlots {\n        id\n        startAt\n        endAt\n        date\n        duration\n      }\n      service {\n        id\n        title\n        duration\n      }\n      client {\n        name\n        phoneNumber\n        profilePhotoUrl\n        id\n      }\n      provider {\n        id\n        rowId\n        name\n        profilePhotoUrl\n      }\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '195f6af52fa6515eb345a19a995a8edd';

module.exports = node;
