import {TimePicker} from '@atlaskit/datetime-picker';
import graphql from 'babel-plugin-relay/macro';
import {get, isEqual, isEmpty} from 'lodash';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Header, Checkbox, Form, Button, Icon, Modal, Table, Message, Divider, Select} from 'semantic-ui-react';
import styled from 'styled-components';

import {
  UpdateServiceMutation,
  DeleteRecurringTimeSlotMutation,
  DeleteServiceMutation,
  AddServiceMutation,
  UpdateRecurringTimeSlotMutation,
  AddRecurringTimeSlotMutation,
} from '../../mutations';
import Container from '../Container';
import preventWheelAction from '../../helpers/preventWheelAction';

const CoverPhotoButton = styled.button`
  background-color: lightgray;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  width: 100%;

  &:hover {
    opacity: 0.6;
  }
`;

const CoverIcon = styled.div`
  transition: 0.4s;
  opacity: 0;
  font-size: 80px;
  position: centered;

  ${CoverPhotoButton}:hover & {
    opacity: 1;
  }
`;

const inputFields = ['title', 'description', 'displayPrice', 'duration', 'price', 'capacity', 'visibleRangeMax'];
const timeSlotFields = ['day', 'startAt', 'endAt', 'capacity'];

const mergeTimeSlots = (source, defaults) => {
  const merged = defaults.map((slot) => {
    const sourceSlot = source.find((el) => el.id === slot.id);
    if (sourceSlot) {
      return {
        ...slot,
        ...sourceSlot,
      };
    }
    return slot;
  });

  const newSlots = source.filter((slot) => !defaults.find((el) => el.id === slot.id));

  return [...newSlots, ...merged];
};

class ServiceView extends React.Component {
  state = {
    isLoading: false,
    localRecurringTimeSlots: [],
    title: null,
    description: null,
    duration: null,
    capacity: null,
    categoryId: null,
    visibleRangeMax: null,
    providerIds: [],
    error: null,
    price: null,
    errorTimeSlot: null,
    isConfirmModalOpen: false,
  };

  constructor(props) {
    super(props);
    const {t} = this.props;
    this.dayOptions = [
      {
        key: 0,
        value: t('common.monday'),
        text: t('common.monday'),
      },
      {
        key: 1,
        value: t('common.tuesday'),
        text: t('common.tuesday'),
      },
      {
        key: 2,
        value: t('common.wednesday'),
        text: t('common.wednesday'),
      },
      {
        key: 3,
        value: t('common.thursday'),
        text: t('common.thursday'),
      },
      {
        key: 4,
        value: t('common.friday'),
        text: t('common.friday'),
      },
      {
        key: 5,
        value: t('common.saturday'),
        text: t('common.saturday'),
      },
      {
        key: 6,
        value: t('common.sunday'),
        text: t('common.sunday'),
      },
    ];

    this.coverPhotoInputRef = React.createRef();

    const {service} = this.props.viewer;

    if (service) {
      this.state = {
        ...this.state,
        categoryId: service.category ? service.category.id : null,
        ...service,
      };
    }
  }

  handleFieldChange = (i, data) => {
    const {name, value} = data;

    const _value = ['duration', 'capacity', 'price', 'visibleRangeMax'].indexOf(name) !== -1 ? Number(value) : value;
    this.setState({[name]: _value === 0 ? null : _value});
  };

  handleFormSubmit = () => {
    if (!this.hasUnsavedChanges()) {
      return;
    }
    const {t} = this.props;

    const {providerIds} = this.state;

    const input = inputFields.reduce((_input, field) => {
      _input[field] = this.state[field];
      return _input;
    }, {});

    input.providerIds = providerIds;

    if (this.state.categoryId) {
      input.categoryId = this.state.categoryId;
    }

    const missingTextField = ['title'].find((field) => {
      const value = input[field] && input[field].trim();
      if (value === '') {
        return true;
      }
      return false;
    });

    const missingNumberField = ['capacity', 'duration'].find((field) => {
      const value = input[field];
      if (!value || value <= 0) {
        return true;
      }

      return false;
    });

    const missingVisibleRangeMax = ['visibleRangeMax'].find((field) => {
      const value = input[field];
      if (value > 0 || value === null) {
        return false;
      }
      return true;
    });

    if (missingTextField || missingNumberField || missingVisibleRangeMax) {
      return this.setState({error: t('common.fill_required_fields')});
    }

    this.setState({isLoading: true});

    const {service} = this.props.viewer;

    const onSuccess = (data) => {
      this.setState({isLoading: false, error: false});
      if (service) {
        this.props.router.go(-1);
      } else {
        window._dcq.push([
          'track',
          'Added service',
          {
            serviceTitle: get(data, 'addService.serviceEdge.node.title'),
          },
        ]);

        const serviceId = get(data, 'addService.serviceEdge.node.id');
        serviceId ? this.props.router.push(`/service/${serviceId}`) : this.props.router.go(-1);
      }
    };

    const onFailure = (errors) => {
      console.log('Errors', errors);
      this.setState({
        isLoading: false,
        error: t('common.something_went_wrong'),
      });
    };

    if (service) {
      input.id = service.id;

      UpdateServiceMutation({input}, onSuccess, onFailure);
    } else {
      AddServiceMutation({input}, onSuccess, onFailure);
    }
  };

  handleDeleteTimeSlot = (id) => {
    if (id === null) {
      return this.setState({localRecurringTimeSlots: []});
    }

    const onSuccess = (data) => null;

    const onFailure = (errors) => console.log('Failure', errors);

    const payload = {
      input: {id},
      connectionId: this.props.viewer.service.recurringTimeSlots.__id,
    };

    DeleteRecurringTimeSlotMutation(payload, onSuccess, onFailure);
  };

  isTimeSlotDirty = (timeSlotId) => this.state.localRecurringTimeSlots.find((slot) => slot.id === timeSlotId);

  hasUnsavedChanges = () => {
    const {service} = this.props.viewer;
    const {providerIds} = this.state;

    if (service) {
      const providerIdsProps = service.providerIds;

      let hasCategoryChanged = false;
      if (service.cateogry) {
        hasCategoryChanged = this.state.categoryId !== service.category.id;
      }

      return (
        inputFields.some((field) => this.state[field] !== service[field]) ||
        !isEqual(providerIds, providerIdsProps) ||
        hasCategoryChanged
      );
    } else {
      return true;
    }
  };

  handleSaveTimeSlot = (timeSlotId) => {
    const {t} = this.props;
    const onFailure = (e) => console.log('Failure', e);

    const {service} = this.props.viewer;

    const {localRecurringTimeSlots} = this.state;
    const timeSlot = localRecurringTimeSlots.find((slot) => slot.id === timeSlotId);

    const input = timeSlotFields.reduce((p, c) => {
      const value = timeSlot[c];
      if (typeof value !== 'undefined') {
        p[c] = value;
      }

      return p;
    }, {});

    // Validate times
    // TODO: Refactor this logic
    let existingTimeSlot = {};
    if (timeSlotId) {
      const {node} = service.recurringTimeSlots.edges.find(({node}) => node.id === timeSlotId);
      existingTimeSlot = node;
    }

    const startAt = input.startAt ? input.startAt : this.formatTime(existingTimeSlot.startAt);
    const endAt = input.endAt ? input.endAt : this.formatTime(existingTimeSlot.endAt);
    const startAtTime = moment(startAt, 'hh:mm');
    const endAtTime = moment(endAt, 'hh:mm');

    if (endAtTime.isSameOrBefore(startAtTime)) {
      return this.setState({errorTimeSlot: t('common.error_time_slot')});
    }

    if (timeSlotId) {
      input.id = timeSlotId;

      const onSuccess = () => {
        this.setState({localRecurringTimeSlots: []});
      };

      UpdateRecurringTimeSlotMutation({input}, onSuccess, onFailure);
    } else {
      input.serviceId = service.id;

      const payload = {
        input,
        connectionName: 'ServiceView_recurringTimeSlots',
        serviceId: service.id,
      };

      // Handle adding multiple timeslots at once
      const onSuccess = () => this.setState({localRecurringTimeSlots: []});
      // this.setState({localRecurringTimeSlots: localRecurringTimeSlots.filter(slot => slot.id !== timeSlotId)});

      AddRecurringTimeSlotMutation(payload, onSuccess, onFailure);
    }
  };

  formatTimes = (dayOfTheWeek) => {
    const {workHours = []} = this.props.viewer;
    const times = [];

    const dayWorkHours = workHours.find((_workHours) => _workHours.dayOfTheWeek === dayOfTheWeek);
    const time = moment(dayWorkHours ? dayWorkHours.openTime : '00:00', 'HH:mm');
    const closeTime = moment(dayWorkHours ? dayWorkHours.closeTime : '24:00', 'HH:mm');

    times.push(time.format('HH:mm'));
    while (time.isBefore(closeTime)) {
      times.push(time.add(15, 'm').format('HH:mm'));
    }

    return times;
  };

  formatTime = (time) => time && (time.length === 5 ? time : time.substring(0, 5));

  handleAddNewTimeSlot = () => {
    // TODO: Handle adding multiple new slots as once
    const existingUnsavedTimeSlot = this.state.localRecurringTimeSlots.find((slot) => slot.id === null);
    if (existingUnsavedTimeSlot) {
      return;
    }

    this.setState((prevState) => ({
      localRecurringTimeSlots: [
        ...prevState.localRecurringTimeSlots,
        {
          id: null,
          day: 0,
          startAt: null,
          endAt: null,
          capacity: null,
        },
      ],
    }));
  };

  handleTimeSlotDayChange = (value, timeSlotId) => {
    const {key: day} = this.dayOptions.find(({value: _value}) => _value === value);

    this.handleTimeSlotFieldChange('day', day, timeSlotId);
  };

  handleTimeSlotFieldChange = (field, value, timeSlotId) => {
    const {localRecurringTimeSlots} = this.state;
    this.setState({errorTimeSlot: null});

    const existingSlot = localRecurringTimeSlots.find((slot) => slot.id === timeSlotId);
    if (existingSlot) {
      existingSlot[field] = value;
    } else {
      localRecurringTimeSlots.push({id: timeSlotId, [field]: value});
    }

    this.setState({localRecurringTimeSlots});
  };

  handleDeleteService = () => {
    this.setState({isLoading: true});
    const {viewer} = this.props;
    const {service} = viewer;

    const payload = {
      input: {id: service.id},
    };

    const onSuccess = () => {
      this.props.router.go(-1);
    };

    const onFailure = (err) => {
      const {t} = this.props;

      this.setState({
        isLoading: false,
        error: t('actions.could_not_delete_service'),
      });
    };

    DeleteServiceMutation(payload, onSuccess, onFailure);
  };

  handleCoverPhotoInputRef = (e) => {
    this.coverPhotoInputRef.current.click();
  };

  handleCoverPhotoUpload = (e) => {
    const fileButton = document.getElementById('cover-input');
    const coverPhoto = fileButton ? fileButton.files[0] : null;

    const onSuccess = (data) => {
      this.coverPhotoInputRef.current.value = '';
      this.setState({isLoading: false});
    };

    const onFailure = (errors) => {
      this.coverPhotoInputRef.current.value = '';
      this.setState({isLoading: false});
    };

    UpdateServiceMutation({input: {id: this.props.viewer.service.id}, uploadables: {coverPhoto}}, onSuccess, onFailure);
  };

  handleDisplayPriceToggle = (e, {checked}) => {
    this.setState({displayPrice: checked});
  };

  handleProviderChange = (event, data) => {
    const {value} = data;
    if (!value) {
      return this.setState((prevState) => ({
        providerIds: [],
      }));
    }

    return this.setState({
      providerIds: value,
    });
  };

  render() {
    const {
      localRecurringTimeSlots,
      title,
      description,
      displayPrice,
      duration,
      capacity,
      visibleRangeMax,
      error,
      price,
      errorTimeSlot,
      isConfirmModalOpen,
      providerIds,
    } = this.state;

    const {
      service,
      providers,
      places,
      settings: {locale},
    } = this.props.viewer;
    const {t} = this.props;

    const coverPhotoUrl = service && service.coverPhotoUrl;

    const hasCategories = get(places, 'edges[0].node.settings.showServiceCategories') || false;

    const categoriesOptions = [];

    hasCategories &&
      places.edges.forEach(({node: place}) => {
        place.categories.edges.map(({node: category}) => {
          return categoriesOptions.push({key: category.rowId, text: category.name, value: category.id});
        });
      });

    const providerOptions =
      providers &&
      providers.edges.map(({node: provider}) => ({
        key: provider.rowId,
        text: provider.name,
        value: provider.rowId,
      }));

    const selectedCategoryValue = service && service.category && service.category.id;
    const selectedProviderValues =
      service && service.providers && service.providers.edges.map(({node: provider}) => provider.rowId);

    const _recurringTimeSlots =
      service && service.recurringTimeSlots ? service.recurringTimeSlots.edges.map(({node}) => node) : [];

    const mergedRecurringTimeSlots = mergeTimeSlots(localRecurringTimeSlots, _recurringTimeSlots);
    // mergedRecurringTimeSlots.sort((slotA, slotB) => {
    //   if (slotA.day !== slotB.day) {
    //     return Number(slotA.day) - Number(slotB.day);
    //   }

    //   if (!slotA.startAt || !slotA.id) {
    //     return slotA;
    //   }

    //   if (!slotB.startAt || !slotB.id) {
    //     return slotB;
    //   }

    //   const dateValuesA = (slotA.startAt.split(':') || []).map(Number);
    //   const dateValuesB = (slotB.startAt.split(':') || []).map(Number);

    //   return new Date().setHours(...dateValuesA).valueOf() - new Date().setHours(...dateValuesB).valueOf();
    // });

    const hasUnsavedChanges = this.hasUnsavedChanges();

    return (
      // TODO: Fix container top
      <Container>
        {service && (
          <Button color="red" floated="right" onClick={() => this.setState({isConfirmModalOpen: true, error: false})}>
            {t('actions.delete_service')}
          </Button>
        )}
        <Modal open={isConfirmModalOpen} onClose={() => this.setState({isConfirmModalOpen: false, error: false})}>
          <Modal.Header>{t('actions.are_you_sure_you_want_to_delete_service')}</Modal.Header>
          {error && <Message error header={t('common.error')} content={error} />}
          <Modal.Actions>
            <Button
              onClick={() => this.setState({isConfirmModalOpen: false})}
              disabled={this.state.isLoading}
              color="red"
            >
              <Icon name="remove" /> {t('common.no')}
            </Button>
            <Button
              onClick={this.handleDeleteService}
              disabled={this.state.isLoading}
              loading={this.state.isLoading}
              color="green"
            >
              <Icon name="checkmark" /> {t('common.yes')}
            </Button>
          </Modal.Actions>
        </Modal>
        <CoverPhotoButton
          src={coverPhotoUrl}
          id="cover"
          type="button"
          onClick={this.handleCoverPhotoInputRef}
          htmlFor="cover-input"
        >
          <CoverIcon>
            <Icon style={{color: 'white'}} name="upload" />
          </CoverIcon>
        </CoverPhotoButton>
        <input
          hidden
          ref={this.coverPhotoInputRef}
          id="cover-input"
          type="file"
          accept="image/*"
          onChange={this.handleCoverPhotoUpload}
        />
        {/* <TransparentLoader isActive={isLoading}/> */}
        <Header style={{marginTop: 100, marginBottom: 20}}>{t('common.general')}</Header>
        <Form error={!!error}>
          <Message error header={t('common.error')} content={error} />
          <Form.Group widths="equal">
            <Form.Input
              fluid
              required
              name="title"
              label={t('common.title')}
              value={title}
              onChange={this.handleFieldChange}
            />
            <Form.Input
              fluid
              required
              type="number"
              name="duration"
              label={t('common.duration')}
              defaultValue={duration}
              onChange={this.handleFieldChange}
              onWheel={preventWheelAction}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="description"
              label={t('common.description')}
              value={description}
              onChange={this.handleFieldChange}
            />
            {hasCategories && (
              <Form.Select
                fluid
                name="categoryId"
                defaultValue={selectedCategoryValue}
                options={categoriesOptions}
                onChange={this.handleFieldChange}
                label={t('common.service_category')}
              />
            )}
          </Form.Group>
          <Form.Group widths={16}>
            <Form.Input
              width={4}
              fluid
              required
              type="number"
              name="capacity"
              label={t('common.capacity')}
              value={capacity}
              onChange={this.handleFieldChange}
              onWheel={preventWheelAction}
            />
            <Form.Input
              width={4}
              fluid
              type="number"
              name="visibleRangeMax"
              label={t('common.visible_range')}
              value={!visibleRangeMax ? '' : visibleRangeMax}
              placeholder={t('common.unlimited')}
              onChange={this.handleFieldChange}
              onWheel={preventWheelAction}
            />
            <Form.Select
              width={8}
              fluid
              search
              label={t('common.provider')}
              options={providerOptions}
              defaultValue={selectedProviderValues}
              onChange={this.handleProviderChange}
              multiple
              placeholder={t('titles.select_provider')}
            />
          </Form.Group>
          <Form.Group widths={4}>
            <Form.Input
              type="number"
              name="price"
              style={{width: '100%'}}
              label={t('common.price')}
              value={price}
              onChange={this.handleFieldChange}
              onWheel={preventWheelAction}
            />
            <Checkbox
              toggle
              style={{marginTop: '30px'}}
              label={t('common.display_price')}
              onChange={this.handleDisplayPriceToggle}
              checked={displayPrice}
            />
          </Form.Group>
          <Button
            loading={this.state.isLoading}
            disabled={!hasUnsavedChanges || isEmpty(providerIds)}
            fluid
            style={{marginTop: '35px'}}
            color="blue"
            onClick={this.handleFormSubmit}
          >
            {t('common.save')}
          </Button>
        </Form>
        {service && (
          <div style={{paddingBottom: 50}}>
            <Divider style={{marginTop: 40}} />
            {errorTimeSlot && <Message error header={t('common.error')} content={errorTimeSlot} />}
            <Header>
              {t('common.recurring_time_slots')}
              <Button style={{marginLeft: 20}} onClick={this.handleAddNewTimeSlot}>
                {t('actions.add')}
              </Button>
            </Header>
            <Table sortable padded selectable celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('common.day')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.start_at')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.end_at')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('common.capacity')}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {mergedRecurringTimeSlots.map((timeSlot) => {
                  const dayOption = this.dayOptions.find(({key}) => key === timeSlot.day);
                  // TODO: Format startAt times
                  const {id, day, startAt, endAt, capacity} = timeSlot;
                  const times = this.formatTimes(day);
                  const isDirty = this.isTimeSlotDirty(id);

                  // Handle both HH:mm and HH:mm:ss since the former comes from input while the latter from relay
                  const formattedStartAt = this.formatTime(startAt);
                  const formattedEndAt = this.formatTime(endAt);

                  return (
                    <Table.Row key={id}>
                      <Table.Cell>
                        <Select
                          fluid
                          value={dayOption.text}
                          selection
                          placeholder="select day"
                          options={this.dayOptions}
                          onChange={(e, {value}) => this.handleTimeSlotDayChange(value, id)}
                        />
                      </Table.Cell>
                      <Table.Cell width="2">
                        <TimePicker
                          locale={locale}
                          hideIcon={true}
                          placeholder={t('actions.select_time')}
                          timeIsEditable={true}
                          value={formattedStartAt}
                          times={times}
                          onChange={(value) => this.handleTimeSlotFieldChange('startAt', value, id)}
                        />
                      </Table.Cell>
                      <Table.Cell width="2">
                        <TimePicker
                          locale={locale}
                          hideIcon={true}
                          selectProps={{classNamePrefix: 'timepicker-select'}}
                          placeholder={t('actions.select_time')}
                          timeIsEditable={true}
                          value={formattedEndAt}
                          times={times}
                          onChange={(value) => this.handleTimeSlotFieldChange('endAt', value, id)}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          type="number"
                          fluid
                          defaultValue={capacity}
                          onChange={(e, {value}) => this.handleTimeSlotFieldChange('capacity', Number(value), id)}
                        />
                      </Table.Cell>
                      <Table.Cell collapsing>
                        {isDirty && <Button color="green" icon="check" onClick={() => this.handleSaveTimeSlot(id)} />}
                        <Button icon="remove" onClick={() => this.handleDeleteTimeSlot(id)} />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )}
      </Container>
    );
  }
}

const ServiceViewContainer = createFragmentContainer(withTranslation()(ServiceView), {
  viewer: graphql`
    fragment ServiceView_viewer on User
    @argumentDefinitions(serviceId: {type: "ID"}, shouldFetchService: {type: "Boolean"}, first: {type: "Int"}) {
      ... on Provider {
        id
        workHours {
          dayOfTheWeek
          openTime
          closeTime
        }
        places {
          edges {
            node {
              settings {
                # eslint-disable-next-line relay/unused-fields
                showServiceCategories
              }
              categories {
                edges {
                  node {
                    rowId
                    id
                    name
                  }
                }
              }
            }
          }
        }
        providers(first: 1000) {
          edges {
            node {
              rowId
              id
              name
            }
          }
        }
        service(id: $serviceId) @include(if: $shouldFetchService) {
          id
          title
          description
          coverPhotoUrl
          displayPrice
          price
          duration
          capacity
          visibleRangeMax
          providerIds
          category {
            id
            rowId
            name
          }
          providers(first: 1000) {
            edges {
              node {
                rowId
                id
                name
              }
            }
          }
          recurringTimeSlots(first: $first) @connection(key: "ServiceView_recurringTimeSlots", filters: []) {
            __id
            edges {
              node {
                id
                day
                startAt
                endAt
                capacity
              }
            }
          }
        }
        settings {
          locale
        }
      }
    }
  `,
});

ServiceViewContainer.getVariables = (props) => {
  return {
    serviceId: props.serviceId,
    shouldFetchService: !!props.serviceId,
    first: 50,
  };
};

export default ServiceViewContainer;
