/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Organization_organization$ref = any;
type Personal_viewer$ref = any;
type Places_organization$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyProfile_viewer$ref: FragmentReference;
declare export opaque type MyProfile_viewer$fragmentType: MyProfile_viewer$ref;
export type MyProfile_viewer = {|
  +organization?: ?{|
    +$fragmentRefs: Organization_organization$ref & Places_organization$ref
  |},
  +role?: ?{|
    +sets: ?any
  |},
  +$fragmentRefs: Personal_viewer$ref,
  +$refType: MyProfile_viewer$ref,
|};
export type MyProfile_viewer$data = MyProfile_viewer;
export type MyProfile_viewer$key = {
  +$data?: MyProfile_viewer$data,
  +$fragmentRefs: MyProfile_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyProfile_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Organization_organization"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Places_organization"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sets",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Personal_viewer"
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
// prettier-ignore
(node/*: any*/).hash = '2b5510637ed4977f0d5ab41a63f9eba7';

module.exports = node;
