import API from './helpers/api';

const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY);

function urlBase64ToUint8Array(base64String) {
  if (!base64String) {
    return null;
  }

  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export async function sendSubscription(subscription) {
  const body = JSON.stringify({subscription});
  API.post('webpush/saveSubscription', {body})
    .then(({json: data, response}) => {
      if (!response.ok) {
        // Sentry.captureException(response);
      } else {
        return true;
      }
    })
    .catch((e) => {
      // Sentry.captureException(e);
    });
}

export async function getExistingPushSubscription() {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.ready;
      if (!registration.pushManager) {
        console.log('Push manager unavailable.');
        return;
      }
      if (!('Notification' in window)) {
        console.log('Notifications not supported on current browser');
        return;
      }

      const existingSubscription = await registration.pushManager.getSubscription();
      return existingSubscription; // returns null if there is not such subscription
    } catch (e) {
      console.log('Error', e);
      return;
    }
  }
}

export async function subscribeUser() {
  if (!convertedVapidKey) {
    return false;
  }

  if ('serviceWorker' in navigator && 'PushManager' in window) {
    try {
      const registration = await navigator.serviceWorker.ready;
      if (!registration.pushManager) {
        console.log('Push manager unavailable.');
        return false;
      }
      if (!('Notification' in window)) {
        console.log('notifications not supported');
        return false;
      }

      if (Notification.permission !== 'denied') {
        const permissionResult = await Notification.requestPermission();
        if (permissionResult === 'granted') {
          const newSubscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey,
          });

          sendSubscription(newSubscription);
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      if (Notification.permission !== 'granted') {
        console.log('Permission was not granted.', e);
        return false;
      } else {
        console.error('An error ocurred during the subscription process.', e);
        return false;
      }
    }
  }
}
