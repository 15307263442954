/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffMembers_viewer$ref = any;
export type StaffMembersQuery_QueryVariables = {||};
export type StaffMembersQuery_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: StaffMembers_viewer$ref
  |}
|};
export type StaffMembersQuery_Query = {|
  variables: StaffMembersQuery_QueryVariables,
  response: StaffMembersQuery_QueryResponse,
|};
*/


/*
query StaffMembersQuery_Query {
  viewer {
    __typename
    ...StaffMembers_viewer
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment AddStaffMemberModal_viewer on Provider {
  roles {
    name
    id
  }
}

fragment StaffMemberRow_provider on Provider {
  id
  rowId
  name
  email
  phoneNumber
  profilePhotoUrl
}

fragment StaffMembers_viewer on User {
  __isUser: __typename
  ... on Provider {
    id
    providers(orderBy: [["createdAt", "ASC"], ["name", "ASC"]], page: 1, first: 50) {
      edges {
        node {
          id
          name
          placeIds
          ...StaffMemberRow_provider
          __typename
        }
        cursor
      }
      total
      currentPage
      start
      end
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    ...AddStaffMemberModal_viewer
  }
  ... on Node {
    __isNode: __typename
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      [
        "createdAt",
        "ASC"
      ],
      [
        "name",
        "ASC"
      ]
    ]
  },
  {
    "kind": "Literal",
    "name": "page",
    "value": 1
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StaffMembersQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StaffMembers_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StaffMembersQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "ProviderConnection",
                "kind": "LinkedField",
                "name": "providers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProviderEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Provider",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "placeIds",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rowId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "phoneNumber",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePhotoUrl",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "start",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "end",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UFPageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "providers(first:50,orderBy:[[\"createdAt\",\"ASC\"],[\"name\",\"ASC\"]],page:1)"
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "StaffMembers_providers",
                "kind": "LinkedHandle",
                "name": "providers"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "roles",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "101b4aadd78e64c3f0194b82e9a0a89b",
    "id": null,
    "metadata": {},
    "name": "StaffMembersQuery_Query",
    "operationKind": "query",
    "text": "query StaffMembersQuery_Query {\n  viewer {\n    __typename\n    ...StaffMembers_viewer\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment AddStaffMemberModal_viewer on Provider {\n  roles {\n    name\n    id\n  }\n}\n\nfragment StaffMemberRow_provider on Provider {\n  id\n  rowId\n  name\n  email\n  phoneNumber\n  profilePhotoUrl\n}\n\nfragment StaffMembers_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    providers(orderBy: [[\"createdAt\", \"ASC\"], [\"name\", \"ASC\"]], page: 1, first: 50) {\n      edges {\n        node {\n          id\n          name\n          placeIds\n          ...StaffMemberRow_provider\n          __typename\n        }\n        cursor\n      }\n      total\n      currentPage\n      start\n      end\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    ...AddStaffMemberModal_viewer\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5154551d8cd0768a581264254380e25d';

module.exports = node;
