import {ConnectionHandler} from 'relay-runtime';
import {isArray} from 'lodash';

const createEdgeAndAddToConnection = (store, id, node, type, rawConnectioNames, filters) => {
  if (!node) {
    console.error('Cannot create edge! Missing node.');
    return;
  }

  const connectionNames = isArray(rawConnectioNames) ? rawConnectioNames : [rawConnectioNames];

  const proxy = store.get(id);

  connectionNames.forEach((connectionName) => {
    const connection = ConnectionHandler.getConnection(proxy, connectionName, filters);

    if (connection) {
      const newEdge = ConnectionHandler.createEdge(store, connection, node, type);
      addEdgeToConnection(store, id, newEdge, connectionName, filters);
    } else {
      console.error('Cannot get connection with name', connectionName, '(createEdgeAndAddToConnection)');
    }
  });
};

const addEdgeToConnection = (store, id, newEdge, rawConnectioNames, filters) => {
  if (!newEdge) {
    console.error('Cannot add edge! Missing edge.');
    return;
  }

  const connectionNames = isArray(rawConnectioNames) ? rawConnectioNames : [rawConnectioNames];

  const proxy = store.get(id);
  const newNode = newEdge.getLinkedRecord('node');
  const newDataID = newNode.getDataID();

  connectionNames.forEach((connectionName) => {
    const connection = ConnectionHandler.getConnection(proxy, connectionName, filters);

    if (connection) {
      const edges = connection.getLinkedRecords('edges');
      let add = true;
      for (let i = 0; i < edges.length; i++) {
        const edge = edges[i];
        const node = edge.getLinkedRecord('node');
        const dataID = node.getDataID();
        if (dataID === newDataID) {
          add = false;
          edges[i] = newEdge;
          break;
        }
      }

      if (add) {
        ConnectionHandler.insertEdgeAfter(connection, newEdge);
      } else {
        connection.setLinkedRecords(edges, connection);
      }
    } else {
      console.error('Cannot get connection with name', connectionName, '(addEdgeToConnection)');
    }
  });
};

const deleteEdgeFromConnection = (store, id, rawDeletedId, rawConnectioNames, filters) => {
  if (!rawDeletedId) {
    console.error('Cannot delete edge! Missing ID.');
    return;
  }

  const connectionNames = isArray(rawConnectioNames) ? rawConnectioNames : [rawConnectioNames];
  const proxy = store.get(id);

  connectionNames.forEach((connectionName) => {
    const connection = ConnectionHandler.getConnection(proxy, connectionName, filters);

    if (connection) {
      const deletedIds = isArray(rawDeletedId) ? rawDeletedId : [rawDeletedId];
      deletedIds.forEach((deletedId) => ConnectionHandler.deleteNode(connection, deletedId));
    } else {
      console.error('Cannot get connection with name', connectionName, '(deleteEdgeFromConnection)');
    }
  });
};

export {createEdgeAndAddToConnection, addEdgeToConnection, deleteEdgeFromConnection};
