/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ServiceView_viewer$ref: FragmentReference;
declare export opaque type ServiceView_viewer$fragmentType: ServiceView_viewer$ref;
export type ServiceView_viewer = {|
  +id?: string,
  +workHours?: ?$ReadOnlyArray<?{|
    +dayOfTheWeek: ?number,
    +openTime: ?string,
    +closeTime: ?string,
  |}>,
  +places?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +settings: ?{|
          +showServiceCategories: ?boolean
        |},
        +categories: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +rowId: number,
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
      |}
    |}>
  |},
  +providers?: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +rowId: number,
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +service?: ?{|
    +id: string,
    +title: ?string,
    +description: ?string,
    +coverPhotoUrl: ?string,
    +displayPrice: ?boolean,
    +price: ?number,
    +duration: ?number,
    +capacity: ?number,
    +visibleRangeMax: ?number,
    +providerIds: ?$ReadOnlyArray<?number>,
    +category: ?{|
      +id: string,
      +rowId: number,
      +name: ?string,
    |},
    +providers: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +rowId: number,
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +recurringTimeSlots: ?{|
      +__id: string,
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +day: ?number,
          +startAt: ?any,
          +endAt: ?any,
          +capacity: ?number,
        |}
      |}>,
    |},
  |},
  +settings?: ?{|
    +locale: ?string
  |},
  +$refType: ServiceView_viewer$ref,
|};
export type ServiceView_viewer$data = ServiceView_viewer;
export type ServiceView_viewer$key = {
  +$data?: ServiceView_viewer$data,
  +$fragmentRefs: ServiceView_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "ProviderConnection",
  "kind": "LinkedField",
  "name": "providers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProviderEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Provider",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "providers(first:1000)"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "capacity",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "serviceId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shouldFetchService"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": null,
        "direction": "forward",
        "path": [
          "service",
          "recurringTimeSlots"
        ]
      }
    ]
  },
  "name": "ServiceView_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkHour",
          "kind": "LinkedField",
          "name": "workHours",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dayOfTheWeek",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "openTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "closeTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PlaceConnection",
          "kind": "LinkedField",
          "name": "places",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PlaceEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Place",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PlaceSettings",
                      "kind": "LinkedField",
                      "name": "settings",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "showServiceCategories",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ServiceCategoryConnection",
                      "kind": "LinkedField",
                      "name": "categories",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ServiceCategoryEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ServiceCategory",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProviderSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locale",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "condition": "shouldFetchService",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "id",
                  "variableName": "serviceId"
                }
              ],
              "concreteType": "Service",
              "kind": "LinkedField",
              "name": "service",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "coverPhotoUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "duration",
                  "storageKey": null
                },
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "visibleRangeMax",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "providerIds",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ServiceCategory",
                  "kind": "LinkedField",
                  "name": "category",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                (v4/*: any*/),
                {
                  "alias": "recurringTimeSlots",
                  "args": null,
                  "concreteType": "TimeSlotConnection",
                  "kind": "LinkedField",
                  "name": "__ServiceView_recurringTimeSlots_connection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TimeSlotEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TimeSlot",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "day",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "startAt",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "endAt",
                              "storageKey": null
                            },
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "cursor",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "UFPageInfo",
                      "kind": "LinkedField",
                      "name": "pageInfo",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endCursor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasNextPage",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "kind": "ClientExtension",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__id",
                          "storageKey": null
                        }
                      ]
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ebd9d54caea432b3e39533979f423fe8';

module.exports = node;
