import './PersonalSettings.css';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {Checkbox, Select} from 'semantic-ui-react';
import {useFragment} from 'relay-hooks';
import {withRelay} from '../../relay';
import {languageOptions, timezoneOptions, getTextForMinutes} from './constants';
import Dropdown from './Dropdown';
import {pickBy, pick} from 'lodash';
import isEmpty from '../../helpers/isEmpty';
import {useTranslation} from 'react-i18next';
import isMatchWith from '../../helpers/isMatchWith';
import {UpdatePrviderSettingsMutation} from '../../mutations';
import BottomBar from './BottomBar';

const values = [0, 5, 15, 30, 60, 120, 1440, 2880, 10080];
const formFields = ['locale', 'timezone', 'viewType', 'appointmentRequiresApproval', 'emails', 'notifications'];

function PersonalSettings(props) {
  const viewer = useFragment(
    graphql`
      fragment PersonalSettings_viewer on User {
        ... on Provider {
          id
          settings {
            locale
            timezone
            viewType
            appointmentRequiresApproval
            emails
            notifications {
              reminder
              changeOfAppointment
            }
          }
        }
      }
    `,
    props.viewer,
  );

  const {locale, emails, viewType, appointmentRequiresApproval, notifications, timezone} = viewer.settings;

  const [selectedLocale, setSelectedLocale] = React.useState(null);
  const [selectedTimezone, setSelectedTimezone] = React.useState(null);
  const [emailSettings, setEmailSettings] = React.useState({});
  const [notificationsSettings, setNotificationsSettings] = React.useState({});
  const [requiresApprovalValue, setRequiresApprovalValue] = React.useState(null);
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = React.useState(false);
  const [selectedViewType, setSelectedViewType] = React.useState(null);

  const {t} = useTranslation();

  const viewTypeOptions = [
    {
      value: 'grid',
      key: 'grid',
      text: t('common.view_type_grid'),
    },
    {
      value: 'regular',
      key: 'regular',
      text: t('common.view_type_regular'),
    },
  ];

  const reminderOptions = values.map((value) => {
    return {
      value: value,
      key: value,
      text: getTextForMinutes(t, value),
    };
  });

  function handleLocaleChange(e, {value}) {
    setSelectedLocale(value);
  }

  function handleTimezoneChange(e, {value}) {
    setSelectedTimezone(value);
  }

  function handleEmailSettingsChange(e, data) {
    if (!data.name) {
      return null;
    }

    const [emailType, typeValue] = data.name.split('.');

    setEmailSettings((prevState) => {
      return {
        [emailType]: {...prevState[emailType], [typeValue]: data.checked},
      };
    });
  }

  function handleViewTypeChange(e, {value}) {
    setSelectedViewType(value);
  }

  function handleApprovalChange(e, {checked}) {
    setRequiresApprovalValue(checked);
  }

  function hasUnsavedChanges() {
    const propsValues = pick(viewer.settings, formFields);

    const stateObject = {
      locale: selectedLocale,
      timezone: selectedTimezone,
      notifications: notificationsSettings,
      emails: emailSettings,
      viewType: selectedViewType,
      appointmentRequiresApproval: requiresApprovalValue,
    };

    const stateValues = pick(stateObject, formFields);

    return !isMatchWith(propsValues, stateValues);
  }

  function handleNotificationsSettingsChange(e, data) {
    const value = data.toggle ? data.checked : data.value;
    setNotificationsSettings((prevState) => {
      return {
        ...prevState,
        [data.name]: value,
      };
    });
  }

  function resetState() {
    setSelectedLocale(null);
    setSelectedTimezone(null);
    setSelectedViewType(null);
    setNotificationsSettings({});
    setRequiresApprovalValue(null);
    setEmailSettings({});
  }

  function handleSubmit() {
    setIsSubmitButtonLoading(true);
    const stateObject = {
      locale: selectedLocale,
      timezone: selectedTimezone,
      notifications: notificationsSettings,
      emails: emailSettings,
      viewType: selectedViewType,
      appointmentRequiresApproval: requiresApprovalValue,
    };

    const input = {
      ...pickBy(stateObject, (value) => !isEmpty(value)),
      id: viewer.id,
    };

    const onSuccess = (data) => {
      setIsSubmitButtonLoading(false);
      resetState();
    };

    const onFailure = (errors) => {
      setIsSubmitButtonLoading(false);
      console.log('errors', errors);
    };

    UpdatePrviderSettingsMutation({input}, onSuccess, onFailure);
  }

  return (
    <div className="container">
      <BottomBar isVisible={hasUnsavedChanges()} isLoading={isSubmitButtonLoading} handleSubmit={handleSubmit} />
      <h3 className="settings-category-header">{t('common.general')}</h3>
      <div className="general-container">
        <Dropdown
          defaultValue={locale}
          selection
          label={t('common.language')}
          placeholder={t('common.language')}
          options={languageOptions}
          onChange={handleLocaleChange}
        />
        <Dropdown
          defaultValue={timezone}
          selection
          onChange={handleTimezoneChange}
          placeholder={t('common.timezone')}
          label={t('common.timezone')}
          options={timezoneOptions}
        />
      </div>
      <h3 className="settings-category-header">{t('titles.notifications')}</h3>
      <h4 style={{fontWeight: 700}}>{t('common.mobile')}</h4>
      <div className="notifications-appointments-container">
        <p>{t('titles.appointments')}</p>
        <Checkbox
          name="changeOfAppointment"
          toggle
          onChange={handleNotificationsSettingsChange}
          defaultChecked={notifications.changeOfAppointment}
        />
      </div>
      <div className="notifications-container">
        <p>{t('titles.reminders')}</p>
        <Select
          defaultValue={notifications.reminder}
          onChange={handleNotificationsSettingsChange}
          name="reminder"
          options={reminderOptions}
          style={{marginLeft: '1.5rem'}}
        />
      </div>
      <h4 style={{fontWeight: 700}}>{t('common.email')}</h4>
      <div className="emails-container">
        <p>{t('common.on_new_appointment')}</p>
        <Checkbox
          toggle
          name="appointment.created"
          onChange={handleEmailSettingsChange}
          defaultChecked={emails.appointment.created}
        />
      </div>
      <div className="emails-container">
        <p>{t('common.on_change')}</p>
        <Checkbox
          toggle
          name="appointment.changed"
          onChange={handleEmailSettingsChange}
          defaultChecked={emails.appointment.changed}
        />
      </div>
      <div className="emails-container">
        <p>{t('common.on_confirm')}</p>
        <Checkbox
          toggle
          onChange={handleEmailSettingsChange}
          name="appointment.confirmed"
          defaultChecked={emails.appointment.confirmed}
        />
      </div>
      <div className="emails-container">
        <p>{t('common.on_alternative')}</p>
        <Checkbox
          toggle
          onChange={handleEmailSettingsChange}
          name="appointment.offered_alternative"
          defaultChecked={emails.appointment.offered_alternative}
        />
      </div>
      <h3 className="settings-category-header">{t('titles.other')}</h3>
      <div className="emails-container">
        <p>{t('common.manual_approval')}</p>
        <Checkbox toggle onChange={handleApprovalChange} defaultChecked={appointmentRequiresApproval} />
      </div>
      <div className="general-container">
        <p>{t('common.main_view')}</p>
        <Select defaultValue={viewType} onChange={handleViewTypeChange} options={viewTypeOptions} />
      </div>
    </div>
  );
}

const PersonalSettingsQuery = graphql`
  query PersonalSettingsQuery {
    viewer {
      ...PersonalSettings_viewer
    }
  }
`;

PersonalSettings.getVariables = (props) => {};

export default withRelay(PersonalSettings, PersonalSettingsQuery);
