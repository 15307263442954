/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AddStaffMemberModal_viewer$ref: FragmentReference;
declare export opaque type AddStaffMemberModal_viewer$fragmentType: AddStaffMemberModal_viewer$ref;
export type AddStaffMemberModal_viewer = {|
  +roles: ?$ReadOnlyArray<?{|
    +name: ?string,
    +id: string,
  |}>,
  +$refType: AddStaffMemberModal_viewer$ref,
|};
export type AddStaffMemberModal_viewer$data = AddStaffMemberModal_viewer;
export type AddStaffMemberModal_viewer$key = {
  +$data?: AddStaffMemberModal_viewer$data,
  +$fragmentRefs: AddStaffMemberModal_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddStaffMemberModal_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "roles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Provider",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'a73ebba475eed7217b4c9e3f5bafb0d4';

module.exports = node;
