/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchBar_viewer$ref: FragmentReference;
declare export opaque type SearchBar_viewer$fragmentType: SearchBar_viewer$ref;
export type SearchBar_viewer = {|
  +search?: ?$ReadOnlyArray<?({|
    +__typename: "Appointment",
    +id: string,
    +startAt: ?any,
    +provider: ?{|
      +id: string,
      +name: ?string,
      +profilePhotoUrl: ?string,
    |},
    +service: ?{|
      +id: string,
      +title: ?string,
      +profilePhotoUrl: ?string,
    |},
    +client: ?{|
      +id: string,
      +name: ?string,
    |},
  |} | {|
    +__typename: "Client",
    +id: string,
    +name: ?string,
    +phoneNumber: ?string,
    +lastActiveAt: ?any,
    +profilePhotoUrl: ?string,
  |} | {|
    +__typename: "Service",
    +id: string,
    +title: ?string,
    +description: ?string,
    +profilePhotoUrl: ?string,
  |} | {|
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    +__typename: "%other"
  |})>,
  +$refType: SearchBar_viewer$ref,
|};
export type SearchBar_viewer$data = SearchBar_viewer;
export type SearchBar_viewer$key = {
  +$data?: SearchBar_viewer$data,
  +$fragmentRefs: SearchBar_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePhotoUrl",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "limit"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "text"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchBar_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "condition": "shouldSearch",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "limit",
                  "variableName": "limit"
                },
                {
                  "kind": "Variable",
                  "name": "text",
                  "variableName": "text"
                }
              ],
              "concreteType": null,
              "kind": "LinkedField",
              "name": "search",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Provider",
                      "kind": "LinkedField",
                      "name": "provider",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Service",
                      "kind": "LinkedField",
                      "name": "service",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Client",
                      "kind": "LinkedField",
                      "name": "client",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Appointment",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "phoneNumber",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastActiveAt",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "type": "Client",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "type": "Service",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e9a261ad722b5abea3cd48b0acb6f071';

module.exports = node;
