/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PersonalSettings_viewer$ref = any;
export type PersonalSettingsQueryVariables = {||};
export type PersonalSettingsQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: PersonalSettings_viewer$ref
  |}
|};
export type PersonalSettingsQuery = {|
  variables: PersonalSettingsQueryVariables,
  response: PersonalSettingsQueryResponse,
|};
*/


/*
query PersonalSettingsQuery {
  viewer {
    __typename
    ...PersonalSettings_viewer
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment PersonalSettings_viewer on User {
  __isUser: __typename
  ... on Provider {
    id
    settings {
      locale
      timezone
      viewType
      appointmentRequiresApproval
      emails
      notifications {
        reminder
        changeOfAppointment
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PersonalSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PersonalSettings_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PersonalSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProviderSettings",
                "kind": "LinkedField",
                "name": "settings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "locale",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timezone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "appointmentRequiresApproval",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emails",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProviderSettingsNotifications",
                    "kind": "LinkedField",
                    "name": "notifications",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reminder",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "changeOfAppointment",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v0/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a69881ab818093286954de5dc24df6c",
    "id": null,
    "metadata": {},
    "name": "PersonalSettingsQuery",
    "operationKind": "query",
    "text": "query PersonalSettingsQuery {\n  viewer {\n    __typename\n    ...PersonalSettings_viewer\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment PersonalSettings_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    settings {\n      locale\n      timezone\n      viewType\n      appointmentRequiresApproval\n      emails\n      notifications {\n        reminder\n        changeOfAppointment\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f3a79c30a2d1c103501b8cb22b6ae3f';

module.exports = node;
