import {Button, Divider, Form, Icon, TextArea, Modal} from 'semantic-ui-react';
import {defaultsDeep, get, isEmpty} from 'lodash';
import styled from 'styled-components';
import graphql from 'babel-plugin-relay/macro';
import {createRefetchContainer} from 'react-relay';
import React from 'react';
import moment from 'moment';
import {withRelay} from '../../relay';
import {DeleteRecurringAppointmentMutation, UpdateRecurringAppointmentWithTimeSlotsMutation} from '../../mutations';
import ServicesDropdown from './ServicesDropdown';
import ClientsDropdown from './ClientsDropdown';
import ProvidersDropdown from './ProvidersDropdown';
import MonthsDropdown from './MonthsDropdown';
import SelectedClientView from './SelectedClientView';
import SelectedServiceView from './SelectedServiceView';
import SelectedProviderView from './SelectedProviderView';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './AppointmentModal.css';
import TimeSlotsCalendar from './TimeSlotsCalendar';
import './TimeSlotsCalendar.css';
import {withTranslation} from 'react-i18next';
import CustomLoader from '../../helpers/CustomLoader';
import AppointmentInfo from './AppointmentInfo';

const ConfirmationContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

// TODO: Copied from AppointmentModal. Refactor
const AppointmentFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-top: 1px solid lightgray;
  flex: 1;
`;

const AppointmentField = styled.div`
  margin-top: 10px;
`;

const AppointmentFieldName = styled.div`
  font-weight: 400;
  font-size: 15px;
`;

const AppointmentFieldValue = styled.div`
  font-size: 14px;
`;

let refetchVariables = {
  fetchTimeSlots: false,
  orderBy: [['startAt', 'DESC']],
  timeSlotsFilterBy: {
    date: {
      from: moment().format('YYYY-MM-DD'),
      to: moment().add(14, 'days').format('YYYY-MM-DD'),
    },
    serviceId: null,
  },
  first: 10000,
};

let isFetching = false;

class RecurringAppointmentModal extends React.Component {
  state = {
    selectedMonth: moment().month(),
    clientId: null,
    serviceId: null,
    providerId: null,
    cancellationReason: '',
    selectedTimeSlots: [],
    isConfirmModalOpen: false,
    formattedTimeSlots: null,
    isLoading: true,
    step: 0,
  };

  constructor(props) {
    super(props);

    this.monthOptions = [];
    const numberOfMonhtsAhead = 12;
    for (let i = 0; i < numberOfMonhtsAhead; i++) {
      const date = moment().date(1).add(i, 'month');

      this.monthOptions.push({
        key: date.month(),
        text: date.format('MMMM').toLowerCase(),
        value: date.month(),
        fulldate: date.toISOString(),
      });
    }

    this.timeSlots = React.createRef();
  }

  isModalActionClicked = (prevState) => this.props.viewer.appointment && prevState.step === 1 && this.state.step === 0;

  componentDidMount() {
    const {appointment} = this.props.viewer;

    if (appointment) {
      this.setState({
        step: 1,
        isLoading: false,
      });
    }
  }

  handleDidMountRefetchCallback = (appointment) => {
    const {serviceId, providerId, timeSlots, clientId} = appointment;
    const startAt = moment(appointment.startAt);

    const decoratedTimeSlots = timeSlots.map((t) => ({
      ...t,
      providerId,
      clientId,
      serviceId,
    }));

    this.setState({
      selectedTimeSlots: decoratedTimeSlots,
      selectedMonth: startAt.month(),
      serviceId,
      providerId,
      clientId,
      isLoading: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // Clicked action
    if (this.isModalActionClicked(prevState)) {
      this.setState({isLoading: true});
      const {appointment} = this.props.viewer;

      const {providerId, serviceId, clientId} = appointment;

      refetchVariables.timeSlotsFilterBy = {
        date: {
          from: moment(appointment.startAt).subtract(7, 'days').format('YYYY-MM-DD'),
          to: moment(appointment.startAt).endOf('month').format('YYYY-MM-DD'),
        },
        serviceId: serviceId,
        providerId,
        clientId,
        includeAll: false,
      };
      refetchVariables.fetchTimeSlots = true;

      this.props.relay.refetch(refetchVariables, null, () => {
        this.handleClickedModalActionRefetchCallback(appointment);
      });
    }
  }

  getFormattedTimeSlots = (isFetchingFromMonthsDropdown) => {
    const {timeSlots, appointment} = this.props.viewer;
    const formattedTimeSlots =
      timeSlots &&
      timeSlots.edges.reduce((p, {node}) => {
        if (!p[node.date]) {
          p[node.date] = [];
        }

        p[node.date].push(node);
        return p;
      }, {});

    if (appointment) {
      const {timeSlots} = appointment;

      // timeslots of appointment can be only from one month;
      const timeSlotsMonth = moment(timeSlots[0].date).month();

      // add appointment timeslots only if they are in the selected month
      if (isFetchingFromMonthsDropdown && timeSlotsMonth !== this.state.selectedMonth) {
        return formattedTimeSlots;
      }

      const date = moment(appointment.startAt).format('YYYY-MM-DD');
      if (formattedTimeSlots && !formattedTimeSlots[date]) {
        formattedTimeSlots[date] = [];
      }

      formattedTimeSlots[date] = [...formattedTimeSlots[date], ...timeSlots];
      formattedTimeSlots[date].sort((a, b) => moment(a.startAt, 'HH:mm').unix() - moment(b.startAt, 'HH:mm').unix());
    }
    return formattedTimeSlots;
  };

  handleClickedModalActionRefetchCallback = (appointment) => {
    const formattedTimeSlots = this.getFormattedTimeSlots();
    const startAt = moment(appointment.startAt);
    const {serviceId, providerId, timeSlots, clientId} = appointment;

    const decoratedTimeSlots = timeSlots.map((t) => ({
      ...t,
      providerId,
      clientId,
      serviceId,
    }));

    this.setState(
      {
        selectedTimeSlots: decoratedTimeSlots,
        selectedMonth: startAt.month(),
        serviceId,
        formattedTimeSlots,
        clientId,
        providerId,
        isLoading: false,
      },
      () => {
        this.timeSlots.current &&
          this.timeSlots.current.scrollToItem(
            this.timeSlots.current.getItemElementById(this.state.selectedTimeSlots[0].date, 'smooth', 'center'),
          );
      },
    );
  };

  handleClose = () => {
    this.setState(
      {
        step: 0,
        appointmentId: null,
        isConfirmModalOpen: false,
        selectedTimeSlots: [],
        formattedTimeSlots: null,
        providerId: null,
        serviceId: null,
        clientId: null,
        isLoading: false,
      },
      this.props.onClose(),
    );
  };

  handleServiceChange = (e, data) => {
    const {value: serviceId} = data;

    this.setState({serviceId});

    const {providerId, clientId} = this.state;

    const now = moment();
    refetchVariables.timeSlotsFilterBy = defaultsDeep(
      {
        serviceId,
        clientId,
        providerId,
        date: {
          from: now.format('YYYY-MM-DD'),
          to: now.add(14, 'days'),
        },
      },
      refetchVariables.timeSlotsFilterBy,
    );

    refetchVariables.fetchTimeSlots = true;

    this.props.relay.refetch(refetchVariables);
  };

  handleClientChange = (i, data) => {
    const {value: clientId} = data;

    this.setState({clientId});

    const {serviceId, providerId} = this.state;
    if (!serviceId || !providerId) {
      return;
    }

    refetchVariables.timeSlotsFilterBy = defaultsDeep({clientId}, refetchVariables.timeSlotsFilterBy);

    refetchVariables.fetchTimeSlots = true;

    this.props.relay.refetch(refetchVariables);
  };

  handleProviderChange = (e, data) => {
    const {value: providerId} = data;

    this.setState({providerId, isLoading: true});

    const {serviceId, clientId} = this.state;

    const now = moment();
    refetchVariables.timeSlotsFilterBy = defaultsDeep(
      {
        clientId,
        serviceId,
        providerId,
        date: {
          from: now.format('YYYY-MM-DD'),
          to: now.add(14, 'days'),
        },
      },
      refetchVariables.timeSlotsFilterBy,
    );

    refetchVariables.fetchTimeSlots = true;

    this.props.relay.refetch(refetchVariables, null, () => {
      const formattedTimeSlots = this.getFormattedTimeSlots();
      return this.setState({formattedTimeSlots, isLoading: false});
    });
  };

  getFirstDateWithTimeSlot = (fullDate) => {
    const [selectedYear, selectedMonth] = fullDate.split('-', 2);
    const firstTimeSlot = this.props.viewer.timeSlots.edges.find(({node}) => {
      const [year, month] = node.date.split('-', 2);
      return selectedYear === year && selectedMonth === month;
    });

    return firstTimeSlot && firstTimeSlot.node.date;
  };

  handleMonthChange = (e, data) => {
    const {value, options} = data;
    this.setState({selectedMonth: value});

    const monthOption = options.find((option) => option.key === value);

    this.setState({isLoading: true});

    const from = moment(monthOption.fulldate).isBefore(moment())
      ? moment().format('YYYY-MM-DD')
      : moment(monthOption.fulldate).format('YYYY-MM-DD');
    const to = moment(from).endOf('month');

    refetchVariables.timeSlotsFilterBy = defaultsDeep({date: {from, to}}, refetchVariables.timeSlotsFilterBy);

    this.props.relay.refetch(refetchVariables, null, (error) => {
      const isFetchingFromMonthsDropdown = true;
      const formattedTimeSlots = this.getFormattedTimeSlots(isFetchingFromMonthsDropdown);
      this.setState({formattedTimeSlots, isLoading: false});
    });
  };

  handleDeselectClient = () =>
    this.setState({clientId: null, selectedTimeSlot: null, selectedMonth: moment().month(), fetchTimeSlots: false});

  handleDeselectService = () =>
    this.setState({
      serviceId: null,
      providerId: null,
      selectedMonth: moment().month(),
      selectedTimeSlot: null,
      fetchTimeSlots: false,
    });

  handleDeselectProvider = () => {
    refetchVariables.fetchTimeSlots = false;

    this.setState(
      {
        providerId: null,
        selectedMonth: moment().month(),
        formattedTimeSlots: null,
        selectedTimeSlot: null,
        selectedTimeSlots: [],
        fetchTimeSlots: false,
      },
      () => this.props.relay.refetch(refetchVariables),
    );
  };

  renderConfirmation = () => {
    const {appointment} = this.props.viewer;

    const payments = get(appointment, 'payments');
    const fieldsData = get(appointment, 'fieldsData');
    const createdBy = appointment && appointment.createdBy;

    const formattedDate = moment(appointment.startAt).format('YYYY-MM-DD');
    const formattedStartAt = moment(appointment.startAt).format('k:mm');
    const formattedEndAt = moment(appointment.endAt).format('k:mm');

    const timeSlots = [{date: formattedDate, startAt: formattedStartAt, endAt: formattedEndAt}];

    const client = appointment.client || {};
    const service = appointment.service || {};
    const provider = appointment.provider || {};

    const {formattedRecurrencePattern} = appointment;

    return (
      <ConfirmationContainer>
        <Row>
          <AppointmentInfo
            formattedRecurrencePattern={formattedRecurrencePattern}
            client={client}
            service={service}
            provider={provider}
            payments={payments}
            fieldsData={fieldsData}
            createdBy={createdBy}
            timeSlots={timeSlots}
          />
        </Row>
        <Row>
          {fieldsData && (
            <AppointmentFieldsContainer>
              {fieldsData.map((fieldData) => (
                <AppointmentField key={fieldData.id}>
                  <AppointmentFieldName>{fieldData.name}</AppointmentFieldName>
                  <AppointmentFieldValue>{fieldData.value || '-'}</AppointmentFieldValue>
                </AppointmentField>
              ))}
            </AppointmentFieldsContainer>
          )}
        </Row>
      </ConfirmationContainer>
    );
  };

  handleUpdate = (date) => {
    const {selectedMonth} = this.state;

    const selectedMonthDate = moment().month(selectedMonth);
    const newScrollDate = moment(date);

    if (selectedMonthDate.month() !== newScrollDate.month()) {
      this.setState({selectedMonth: newScrollDate.month()});
    }
  };

  handleRightArrowClick = (lastVisibleItemDate) => {
    if (isFetching) {
      return;
    }

    isFetching = true;

    // TODO: Fix this hack
    refetchVariables.timeSlotsFilterBy = {
      ...refetchVariables.timeSlotsFilterBy,
      date: {
        to: moment(lastVisibleItemDate).add(14, 'days').format('YYYY-MM-DD'),
        from: refetchVariables.timeSlotsFilterBy.date.from,
      },
    };

    // TODO
    // renderVariables = {...}

    this.props.relay.refetch(
      refetchVariables,
      null,
      (error) => {
        if (error) {
          console.log('Error', error);
        }
        isFetching = false;
        const formattedTimeSlots = this.getFormattedTimeSlots();
        this.setState({formattedTimeSlots});
      },
      {
        force: false,
      },
    );
  };

  handleLeftArrowClick = (firstVisibleDate) => {
    if (isFetching) {
      return;
    }

    const formattedLeftMostDate = moment(firstVisibleDate);
    const difference = formattedLeftMostDate.diff(moment(), 'days');

    if (difference > 0) {
      isFetching = true;

      const _refetchVariables = defaultsDeep(
        {
          timeSlotsFilterBy: {
            date: {
              from: moment(firstVisibleDate).subtract(8, 'days').format('YYYY-MM-DD'),
            },
          },
        },
        refetchVariables,
      );

      this.props.relay.refetch(
        _refetchVariables,
        null,
        (error) => {
          isFetching = false;

          const formattedTimeSlots = this.getFormattedTimeSlots();
          this.setState({formattedTimeSlots});

          if (error) {
            console.log('Error', error);
          }
        },
        {
          force: false,
        },
      );
    }
  };

  handleTimeSlotClick = (timeSlot) => {
    const {alternativeTimes, isAlternativeTimesClicked} = this.state;

    if (isAlternativeTimesClicked) {
      const date = moment(timeSlot.date);
      const formattedAlternativeTime = {
        id: timeSlot.id,
        startAt: moment(`${date.format('YYYY-MM-DD')} ${timeSlot.startAt}`).toISOString(),
        endAt: moment(`${date.format('YYYY-MM-DD')} ${timeSlot.endAt}`).toISOString(),
      };

      // if we click again time slot - deactivate it.
      const {id} = formattedAlternativeTime;
      if (alternativeTimes.some((slot) => slot.id === id)) {
        this.setState((previousState) => {
          previousState.alternativeTimes.splice(previousState.alternativeTimes.indexOf(formattedAlternativeTime), 1);
          return {
            alternativeTimes: [...previousState.alternativeTimes],
          };
        });
      } else {
        this.setState((previousState) => ({
          alternativeTimes: [...previousState.alternativeTimes, formattedAlternativeTime],
        }));
      }
    } else {
      this.setState((prevState) => {
        if (prevState.selectedTimeSlots.some((_timeSlot) => _timeSlot.id === timeSlot.id)) {
          const filteredTimeSlots = prevState.selectedTimeSlots.filter((_timeSlot) => _timeSlot.id !== timeSlot.id);
          return {
            selectedTimeSlots: [...filteredTimeSlots],
          };
        } else {
          return {
            selectedTimeSlots: [...this.state.selectedTimeSlots, timeSlot],
          };
        }
      });
    }
  };

  renderAppointmentContent = () => {
    const {clientId, isLoading, selectedTimeSlots, formattedTimeSlots, serviceId, providerId, step} = this.state;

    const {viewer, t} = this.props;

    const {clients, services} = viewer;

    const selectedClient = clientId && clients.edges.find(({node}) => node.rowId === clientId).node;
    const selectedService = serviceId && services.edges.find(({node}) => node.rowId === serviceId).node;
    const selectedProvider =
      providerId && selectedService && selectedService.providers.edges.find(({node}) => node.rowId === providerId).node;

    return (
      <div>
        {isLoading ? (
          <CustomLoader t={t} />
        ) : (
          <div>
            {(step === 1 || step === 2) && this.renderConfirmation()}
            {step === 0 && (
              <div>
                <div className="details-container">
                  <div className="client-container">
                    {clientId ? (
                      <SelectedClientView client={selectedClient} t={t} onDeselectClient={this.handleDeselectClient} />
                    ) : (
                      <ClientsDropdown clients={clients} t={t} onClientChange={this.handleClientChange} />
                    )}
                  </div>
                  <div className="service-container">
                    {serviceId ? (
                      <SelectedServiceView
                        service={selectedService}
                        t={t}
                        onDeselectService={this.handleDeselectService}
                      />
                    ) : (
                      <ServicesDropdown t={t} services={services} onServiceChange={this.handleServiceChange} />
                    )}
                  </div>
                  <div className="service-container">
                    {providerId ? (
                      <SelectedProviderView
                        provider={selectedProvider}
                        onDeselectProvider={this.handleDeselectProvider}
                      />
                    ) : (
                      <ProvidersDropdown
                        services={services}
                        t={t}
                        serviceId={this.state.serviceId}
                        onProviderChange={this.handleProviderChange}
                      />
                    )}
                  </div>
                </div>

                {formattedTimeSlots && serviceId && clientId && providerId && (
                  <div className="timeslots-container">
                    <Divider />
                    <MonthsDropdown
                      t={t}
                      selectedMonth={this.state.selectedMonth}
                      options={this.monthOptions}
                      onMonthChange={this.handleMonthChange}
                    />
                    <TimeSlotsCalendar
                      forwaredRef={this.timeSlots}
                      isFetching={isFetching}
                      timeSlots={formattedTimeSlots}
                      onFetchTimeSlotsForward={this.handleRightArrowClick}
                      onFetchTimeSlotsBackward={this.handleLeftArrowClick}
                      onUpdate={this.handleUpdate}
                      onTimeSlotClick={this.handleTimeSlotClick}
                      selectedTimeSlots={selectedTimeSlots}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  hasUnsavedChanges = () => {
    const {appointment} = this.props.viewer;
    const {selectedTimeSlots} = this.state;

    if (appointment) {
      return !isEmpty(
        selectedTimeSlots.filter((timeSlot) =>
          appointment.timeSlots.every((_timeSlot) => _timeSlot.id !== timeSlot.id),
        ),
      );
    }
    return true;
  };

  handleDeleteRecurringAppointment = ({deleteAll}) => {
    if (deleteAll) {
      this.setState({isDeleteAllButtonLoading: true});
    } else {
      this.setState({isDeleteSingleButtonLoading: true});
    }

    const input = {
      id: this.props.viewer.appointment.id,
      deleteAll,
    };

    if (this.state.cancellationReason && this.state.cancellationReason.trim() !== '') {
      input.cancellationReason = this.state.cancellationReason;
    }

    const onFailure = (errors) => {
      const errorMessage = get(errors, '[0].message') || JSON.stringify(errors);
      window.alert(errorMessage);

      this.setState({
        isLoading: false,
        isDeleteAllButtonLoading: false,
        isDeleteSingleButtonLoading: false,
      });
    };

    const onSuccess = (data) => {
      this.handleClose();
    };

    DeleteRecurringAppointmentMutation(
      {input, connectionNames: ['Calendar_appointments'], viewerId: this.props.viewer.id},
      onSuccess,
      onFailure,
    );
  };

  handleUpdateAppointment = ({updateAll}) => {
    this.setState({isLoading: true});

    const {appointment} = this.props.viewer;

    const {selectedTimeSlots} = this.state;

    const onSuccess = (data) => {
      this.setState({
        isLoading: false,
        serviceId: null,
        step: 0,
        clientId: null,
        providerId: null,
      });

      this.props.onClose();
    };

    const onFailure = (errors) => {
      const errorMessage = get(errors, '[0].message') || JSON.stringify(errors);
      window.alert(errorMessage);

      this.setState({isLoading: false});
    };

    const input = {
      id: appointment.id,
      timeSlots: selectedTimeSlots,
      updateAll: false,
    };

    return UpdateRecurringAppointmentWithTimeSlotsMutation(
      {input, viewerId: this.props.viewer.id, connectionName: 'Calendar_appointments'},
      onSuccess,
      onFailure,
    );
  };

  handlePrevStep = () => {
    this.setState({step: 0});
  };

  handleCancellationReasonInput = (e, {value}) => {
    this.setState({cancellationReason: value});
  };

  renderModalActions = () => {
    const {t} = this.props;

    const {appointment} = this.props.viewer;

    const {
      step,
      isConfirmModalOpen,
      isLoading,
      isDeleteAllButtonLoading,
      isDeleteSingleButtonLoading,
      selectedTimeSlots,
    } = this.state;

    const isAppointmentInThePast = moment(appointment.startAt).isBefore(moment());
    const isAppointmentCanceled = appointment.status === 'Canceled';

    if (appointment && step === 1) {
      return (
        <div style={{flexDirection: 'row'}}>
          <div className="header-buttons">
            {!isAppointmentInThePast && (
              <Button primary onClick={() => this.setState({step: 0})}>
                {t('actions.modify')}
              </Button>
            )}
            {!isAppointmentCanceled && (
              <Button color="red" onClick={() => this.setState({isConfirmModalOpen: true})}>
                {t('actions.delete')}
              </Button>
            )}
            <Modal closeIcon open={isConfirmModalOpen} onClose={() => this.setState({isConfirmModalOpen: false})}>
              <Modal.Header>{t('common.deleting_recurring_appointment')}</Modal.Header>
              <Modal.Content>
                <p>{t('actions.are_you_sure_you_want_to_cancel')}</p>
                <Form>
                  <TextArea
                    maxLength="120"
                    style={{minHeight: 50, maxHeight: 80}}
                    placeholder={t('common.give_reason')}
                    value={this.state.cancellationReason}
                    onChange={this.handleCancellationReasonInput}
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  loading={isDeleteSingleButtonLoading}
                  disabled={isDeleteAllButtonLoading || isDeleteSingleButtonLoading}
                  onClick={() => this.handleDeleteRecurringAppointment({deleteAll: false})}
                >
                  <Icon name="remove" /> {t('actions.delete_current_recurring')}
                </Button>
                <Button
                  loading={isDeleteAllButtonLoading}
                  disabled={isDeleteAllButtonLoading || isDeleteSingleButtonLoading}
                  color="red"
                  onClick={() => this.handleDeleteRecurringAppointment({deleteAll: true})}
                >
                  <Icon name="remove" /> {t('actions.delete_all_recurring')}
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        </div>
      );
    }

    if (appointment && step === 0) {
      return (
        <div>
          {!isLoading && selectedTimeSlots.length && (
            <Button
              className="next-button"
              fluid
              color="blue"
              // disabled={!hasUnsavedChanges}
              onClick={() => this.setState({step: 2})}
            >
              {t('common.next')}
            </Button>
          )}
        </div>
      );
    }

    if (appointment && step === 2) {
      return (
        <div style={{flexDirection: 'row'}}>
          {!isLoading && selectedTimeSlots.length > 0 && (
            <Button.Group widths="2">
              {(step === 1 || step === 2) && (
                <Button className="prev-button" onClick={this.handlePrevStep}>
                  {t('common.back')}
                </Button>
              )}
              <Button
                className="next-button"
                fluid
                color={step === 0 ? 'blue' : 'green'}
                disabled={step === 1 && this.isConfirmButtonDisabled()}
                onClick={this.handleUpdateAppointment}
              >
                {t('actions.confirm')}
              </Button>
            </Button.Group>
          )}
        </div>
      );
    }
  };

  renderHeader = () => {
    const {t} = this.props;
    const {step} = this.state;
    const {appointment} = this.props.viewer;
    if (!appointment) {
      return null;
    }

    const isAppointmentInThePast = appointment && moment(appointment.startAt).isBefore(moment());

    const title =
      isAppointmentInThePast || step === 1
        ? `${t('common.appointment')} - ${t(
            `common.${appointment.status.split(' ').join('_').toLowerCase()}`,
          ).toUpperCase()}`
        : `${t('actions.edit_appointment')}`;

    return <Modal.Header>{title}</Modal.Header>;
  };

  render() {
    const {t} = this.props;
    const {appointment} = this.props.viewer;
    if (!appointment) {
      return null;
    }

    const {step, clientId, isLoading, serviceId} = this.state;

    return (
      <Modal className="modal-container" onClose={this.handleClose} open={true}>
        {this.renderHeader()}
        <Modal.Content className={step === 0 && 'full-height'} scrolling={!!(step === 0 && serviceId && clientId)}>
          {!this.props.viewer && <CustomLoader t={t} />}
          {isLoading ? <CustomLoader t={t} /> : this.renderAppointmentContent()}
        </Modal.Content>
        {<Modal.Actions>{this.renderModalActions()}</Modal.Actions>}
      </Modal>
    );
  }
}

const RecurringAppointmentModalQuery = graphql`
  query RecurringAppointmentModalRefetchQuery(
    $fetchTimeSlots: Boolean!
    $timeSlotsFilterBy: ProviderTimeSlotFilterInput
    $first: Int
    $appointmentId: ID
    $orderBy: [[String]]
    $isPartOfRecurringSequence: Boolean
    $filterBy: ProviderAppointmentFilterInput
  ) {
    viewer {
      ...RecurringAppointmentModal_viewer
        @arguments(
          fetchTimeSlots: $fetchTimeSlots
          timeSlotsFilterBy: $timeSlotsFilterBy
          first: $first
          filterBy: $filterBy
          isPartOfRecurringSequence: $isPartOfRecurringSequence
          appointmentId: $appointmentId
          orderBy: $orderBy
        )
    }
  }
`;

const RecurringAppointmentModalContainer = createRefetchContainer(
  withTranslation()(RecurringAppointmentModal),
  {
    viewer: graphql`
      fragment RecurringAppointmentModal_viewer on User
      @argumentDefinitions(
        fetchTimeSlots: {type: "Boolean!", defaultValue: false}
        timeSlotsFilterBy: {type: "ProviderTimeSlotFilterInput"}
        first: {type: "Int"}
        orderBy: {type: "[[String]]"}
        appointmentId: {type: "ID"}
        isPartOfRecurringSequence: {type: "Boolean"}
        filterBy: {type: "ProviderAppointmentFilterInput"}
      ) {
        __typename
        ... on Provider {
          id
          appointment(id: $appointmentId, isPartOfRecurringSequence: $isPartOfRecurringSequence) {
            id
            rowId
            status
            startAt
            endAt
            providerId
            clientId
            cancellationReason
            # eslint-disable-next-line relay/unused-fields
            fieldsData {
              id
              rowId
              name
              type
              value
            }
            timeSlots {
              id
              startAt
              endAt
              date
              # eslint-disable-next-line relay/unused-fields
              duration
            }
            serviceId
            isPartOfRecurringSequence
            formattedRecurrencePattern
            createdBy
            service {
              id
              # eslint-disable-next-line relay/unused-fields
              title
              # eslint-disable-next-line relay/unused-fields
              appointmentFields {
                rowId
                name
                required
                type
                prompt
              }
              # eslint-disable-next-line relay/unused-fields
              description
            }
            client {
              id
              name
              # eslint-disable-next-line relay/unused-fields
              phoneNumber
              # eslint-disable-next-line relay/unused-fields
              profilePhotoUrl
            }
            provider {
              id
              name
            }
          }
          clients(first: 1000, limit: 1000) @connection(key: "RecurringAppointmentModal_clients") {
            edges {
              node {
                id
                rowId
                name
                # eslint-disable-next-line relay/unused-fields
                phoneNumber
                # eslint-disable-next-line relay/unused-fields
                profilePhotoUrl
              }
            }
          }
          timeSlots(filterBy: $timeSlotsFilterBy, first: $first) @include(if: $fetchTimeSlots) {
            edges {
              node {
                id
                date
                # eslint-disable-next-line relay/unused-fields
                duration
                startAt
                endAt
                # provider {
                #   id
                #   name
                # }
                providerId
                serviceId
                clientId
              }
            }
          }
          services(first: 1000, limit: 1000) {
            edges {
              node {
                id
                rowId
                # eslint-disable-next-line relay/unused-fields
                description
                # eslint-disable-next-line relay/unused-fields
                title
                # eslint-disable-next-line relay/unused-fields
                category {
                  id
                  name
                }
                # eslint-disable-next-line relay/unused-fields
                duration
                # eslint-disable-next-line relay/unused-fields
                profilePhotoUrl
                providers(first: 1000) {
                  edges {
                    node {
                      id
                      rowId
                      # eslint-disable-next-line relay/unused-fields
                      name
                      # eslint-disable-next-line relay/unused-fields
                      profilePhotoUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
  RecurringAppointmentModalQuery,
);

RecurringAppointmentModalContainer.getVariables = (props) => {
  const {appointmentId} = props;

  if (appointmentId) {
    refetchVariables.appointmentId = appointmentId;
    refetchVariables.isPartOfRecurringSequence = true;
  }

  return refetchVariables;
};

export default withRelay(RecurringAppointmentModalContainer, RecurringAppointmentModalQuery);
