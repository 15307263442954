import Cookies from 'js-cookie';
import React from 'react';
import {QueryRenderer} from 'react-relay';
import {
  authMiddleware,
  // batchMiddleware,
  errorMiddleware,
  loggerMiddleware,
  perfMiddleware,
  RelayNetworkLayer,
  retryMiddleware,
  urlMiddleware,
} from 'react-relay-network-modern/es';
import {Environment, RecordSource, Observable as RelayObservable, Store} from 'relay-runtime';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import {getCookieDomain} from './helpers';

window.SERVER_URL = process.env.NODE_ENV === 'development' ? 'localhost:3001' : process.env.REACT_APP_API_URL;

// Not making this function async because of tokenRefreshPromise not working properly with async/await
function getRefreshedAccessTokens() {
  const refreshToken = Cookies.get('OAUTH_REFRESH_TOKEN') || null;

  const fullUrl =
    process.env.NODE_ENV === 'development'
      ? `http://${window.SERVER_URL}`
      : process.env.REACT_APP_API_URL + `/auth/refreshAccessTokens`;

  const options = {
    redirect: 'follow',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: 'Bearer ' + refreshToken,
    },
  };

  return fetch(fullUrl, options)
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response;
      } else {
        return Promise.reject('Invalid response');
      }
    })
    .then((res) => res.json())
    .then((json) => {
      const {accessToken, refreshAccessToken} = json;
      const domain = getCookieDomain();
      Cookies.set('OAUTH_TOKEN', accessToken, {expires: 365, domain}); //, secure: !global.__DEV__});
      Cookies.set('OAUTH_REFRESH_TOKEN', refreshAccessToken, {expires: 365, domain}); //, secure: !global.__DEV__});

      return accessToken;
    })
    .catch((err) => {
      // Sentry.captureException(err);
    });
}

// Environment

const networkMiddlewares = [
  urlMiddleware({
    url: (req) => {
      return Promise.resolve(`//${window.SERVER_URL}/graphql`);
    },
  }),
  // batchMiddleware({
  //   batchUrl: requestMap =>
  //     Promise.resolve(`//${window.SERVER_URL}/graphql/batch`),
  //   batchTimeout: 10
  // }),
  global.__DEV__ ? loggerMiddleware() : null,
  global.__DEV__ ? errorMiddleware() : null,
  global.__DEV__ ? perfMiddleware() : null,
  retryMiddleware({
    fetchTimeout: 10000,
    // [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600]
    retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100,
    beforeRetry: ({forceRetry, abort, delay, attempt, lastError, req}) => {
      window.forceRelayRetry = forceRetry;
      console.log('call `forceRelayRetry()` for immediately retry! Or wait ' + delay + ' ms.');
    },
    statusCodes: [500, 503, 504],
  }),
  authMiddleware({
    token: () => Cookies.get('OAUTH_TOKEN'),
    tokenRefreshPromise: (req) => getRefreshedAccessTokens(),
    allowEmptyToken: true, // Allowing empty token, handles the case where user tries to refresh tokens with invalid
    // refreshToken, and we handle logOut
  }),
  (next) => async (req) => {
    // TODO: Send request ID to identify the request if error occurs

    const res = await next(req);

    // if (res.errors) {
    //   const errorMessage =
    //     req instanceof RelayNetworkLayerRequest ? formatGraphQLErrors(req, res.errors) : JSON.stringify(res.errors);
    //   Sentry.captureMessage(errorMessage);
    // }

    return res;
  },
];

export const subscriptions = new SubscriptionClient(
  `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.SERVER_URL}/subscriptions`,
  {
    timeout: 8000,
    reconnect: true,
    lazy: true, // connect on first created subscription
  },
);

function subscribeFn(operation, variables, cacheConfig) {
  const {text: query, name: operationName} = operation;
  const subscriptionId = Date.now();

  const observable = subscriptions.request({
    query,
    variables: {
      ...variables,
      subscriptionId,
    },
    operationName,
  });

  return RelayObservable.from(observable);
}

const networkOptions = {
  noThrow: true,
  subscribeFn,
};

export const network = new RelayNetworkLayer(networkMiddlewares, networkOptions);
export const store = new Store(new RecordSource());

export const environment = new Environment({network, store});

const getDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName ||
  WrappedComponent.name ||
  (WrappedComponent.render && WrappedComponent.render.displayName) ||
  'Component';

export function withRelay(WrappedComponent, Query) {
  return class RelayWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.displayName = `withRelay(${getDisplayName(WrappedComponent)})`;
    }

    render() {
      return (
        <QueryRenderer
          environment={environment}
          query={Query}
          variables={WrappedComponent.getVariables(this.props)}
          render={({error, props, retry}) => {
            if (props === null) {
              return null;
            }

            return <WrappedComponent {...this.props} {...props} retry={retry} error={error} />;
          }}
        />
      );
    }
  };
}
