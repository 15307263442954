import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {environment} from '../relay';

const mutation = graphql`
  mutation MarkNotificationsReadMutation($input: MarkNotificationsReadInput!) {
    markNotificationsRead(input: $input) {
      viewer {
        ... on Provider {
          unreadNotificationsCount
        }
      }
      notifications {
        id
        isRead
      }
    }
  }
`;

const MarkNotificationsReadMutation = (payload, success, failure) => {
  const {input} = payload;

  commitMutation(environment, {
    mutation,
    variables: {input},
    onCompleted: (response, errors) => (errors && errors.length ? failure(errors) : success(response)),
    onError: (err) => failure(err),
  });
};

export default MarkNotificationsReadMutation;
