/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type WorkHourRow_workHour$ref: FragmentReference;
declare export opaque type WorkHourRow_workHour$fragmentType: WorkHourRow_workHour$ref;
export type WorkHourRow_workHour = {|
  +id: string,
  +providerId: ?number,
  +dayOfTheWeek: ?number,
  +openTime: ?string,
  +closeTime: ?string,
  +isOpen: ?boolean,
  +$refType: WorkHourRow_workHour$ref,
|};
export type WorkHourRow_workHour$data = WorkHourRow_workHour;
export type WorkHourRow_workHour$key = {
  +$data?: WorkHourRow_workHour$data,
  +$fragmentRefs: WorkHourRow_workHour$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkHourRow_workHour",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "providerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayOfTheWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closeTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOpen",
      "storageKey": null
    }
  ],
  "type": "WorkHour",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '8a087791d736903680b152eb5f0c3602';

module.exports = node;
