// import '@babel/polyfill';
import 'react-app-polyfill/ie9';

import createFarceRouter from 'found/createFarceRouter';
import createRender from 'found/createRender';
import queryMiddleware from 'farce/queryMiddleware';
import BrowserProtocol from 'farce/BrowserProtocol';
import {Resolver} from 'found-relay';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import '../node_modules/react-vis/dist/style.css';
import 'semantic-ui-css/semantic.min.css';
import {register} from './serviceWorker';

import {environment} from './relay';
import routes from './routes';

const FullPageError = (props) => <div>{props.error.status === 404 ? 'Not found' : 'Error'}</div>;

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: routes,
  render: createRender({
    renderError: ({error}) => <FullPageError error={error} />,
  }),
});

// NOTE: if dsn is null or undefined Sentry does not send events
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  rations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.15,
});

ReactDOM.render(<Router resolver={new Resolver(environment)} />, document.getElementById('root'));

register();
// registerServiceWorker();
