/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VacationHours_viewer$ref = any;
export type VacationHoursFilterInput = {|
  includeNonActive?: ?boolean
|};
export type VacationHoursRefetchQueryVariables = {|
  filterBy?: ?VacationHoursFilterInput,
  orderBy?: ?$ReadOnlyArray<?$ReadOnlyArray<?string>>,
  page?: ?number,
  first?: ?number,
  limit?: ?number,
|};
export type VacationHoursRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: VacationHours_viewer$ref
  |}
|};
export type VacationHoursRefetchQuery = {|
  variables: VacationHoursRefetchQueryVariables,
  response: VacationHoursRefetchQueryResponse,
|};
*/


/*
query VacationHoursRefetchQuery(
  $filterBy: VacationHoursFilterInput
  $orderBy: [[String]]
  $page: Int
  $first: Int
  $limit: Int
) {
  viewer {
    __typename
    ...VacationHours_viewer_18RA4p
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment VacationHourRow_vacationHour on VacationHour {
  id
  provider {
    id
    name
  }
  startAt
  endAt
}

fragment VacationHours_viewer_18RA4p on User {
  __isUser: __typename
  ... on Provider {
    id
    vacationHours(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit) {
      edges {
        node {
          id
          ...VacationHourRow_vacationHour
          __typename
        }
        cursor
      }
      total
      currentPage
      start
      end
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBy"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v5 = [
  {
    "kind": "Variable",
    "name": "filterBy",
    "variableName": "filterBy"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "limit"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VacationHoursRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "VacationHours_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "VacationHoursRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "VacationHourConnection",
                "kind": "LinkedField",
                "name": "vacationHours",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VacationHourEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "VacationHour",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Provider",
                            "kind": "LinkedField",
                            "name": "provider",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endAt",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "start",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "end",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UFPageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "VacationHours_vacationHours",
                "kind": "LinkedHandle",
                "name": "vacationHours"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "20ba87bb958047971638c2f07c62ab97",
    "id": null,
    "metadata": {},
    "name": "VacationHoursRefetchQuery",
    "operationKind": "query",
    "text": "query VacationHoursRefetchQuery(\n  $filterBy: VacationHoursFilterInput\n  $orderBy: [[String]]\n  $page: Int\n  $first: Int\n  $limit: Int\n) {\n  viewer {\n    __typename\n    ...VacationHours_viewer_18RA4p\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment VacationHourRow_vacationHour on VacationHour {\n  id\n  provider {\n    id\n    name\n  }\n  startAt\n  endAt\n}\n\nfragment VacationHours_viewer_18RA4p on User {\n  __isUser: __typename\n  ... on Provider {\n    id\n    vacationHours(filterBy: $filterBy, orderBy: $orderBy, page: $page, first: $first, limit: $limit) {\n      edges {\n        node {\n          id\n          ...VacationHourRow_vacationHour\n          __typename\n        }\n        cursor\n      }\n      total\n      currentPage\n      start\n      end\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b01f2dd8df95a0c280bd3e2de8671d57';

module.exports = node;
