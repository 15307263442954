/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAppointmentInput = {|
  clientMutationId?: ?string,
  id: string,
  startAt?: ?string,
  endAt?: ?string,
  serviceId?: ?string,
  clientId?: ?string,
  providerId?: ?string,
  alternativeTimes?: ?$ReadOnlyArray<?any>,
  status?: ?string,
  fieldsData?: ?any,
|};
export type UpdateAppointmentMutationVariables = {|
  input: UpdateAppointmentInput
|};
export type UpdateAppointmentMutationResponse = {|
  +updateAppointment: ?{|
    +appointment: ?{|
      +id: string,
      +rowId: string,
      +serviceId: ?number,
      +service: ?{|
        +title: ?string
      |},
      +startAt: ?any,
      +endAt: ?any,
      +status: ?string,
      +clientId: ?number,
      +client: ?{|
        +id: string,
        +name: ?string,
      |},
    |}
  |}
|};
export type UpdateAppointmentMutation = {|
  variables: UpdateAppointmentMutationVariables,
  response: UpdateAppointmentMutationResponse,
|};
*/


/*
mutation UpdateAppointmentMutation(
  $input: UpdateAppointmentInput!
) {
  updateAppointment(input: $input) {
    appointment {
      id
      rowId
      serviceId
      service {
        title
        id
      }
      startAt
      endAt
      status
      clientId
      client {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAppointmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAppointmentPayload",
        "kind": "LinkedField",
        "name": "updateAppointment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "appointment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Service",
                "kind": "LinkedField",
                "name": "service",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAppointmentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAppointmentPayload",
        "kind": "LinkedField",
        "name": "updateAppointment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "appointment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Service",
                "kind": "LinkedField",
                "name": "service",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "236d1c59e5b678473a1b41ecaedf5f9e",
    "id": null,
    "metadata": {},
    "name": "UpdateAppointmentMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAppointmentMutation(\n  $input: UpdateAppointmentInput!\n) {\n  updateAppointment(input: $input) {\n    appointment {\n      id\n      rowId\n      serviceId\n      service {\n        title\n        id\n      }\n      startAt\n      endAt\n      status\n      clientId\n      client {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b3a6ce64807445f64a82fdbc66660c2a';

module.exports = node;
