import React from 'react';
import clsx from 'clsx';
import {useFragment} from 'relay-hooks';
import {withRouter} from 'found';
import graphql from 'babel-plugin-relay/macro';

import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Drawer} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import {withTranslation} from 'react-i18next';

// Icons
import Timeline from '@material-ui/icons/Timeline';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MoneyIcon from '@material-ui/icons/AttachMoneyOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsIcon from '@mui/icons-material/Settings';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ServicesIcon from '@material-ui/icons/RoomServiceRounded';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import SearchBar from './SearchBar';
import canAccess, {USER_SETS_CONDITION_ASSIGNED_TO_ORGANIZATION} from '../helpers/canAccess';
import {snakeCase} from 'lodash';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
  },
}));

function AppBarFragmentContainer(props) {
  const {pathname} = props.match.location;
  const splittedPathname = pathname.split('/', 2);
  let parsedPath = null;
  if (splittedPathname[1] === 'service') {
    parsedPath = '/services';
  } else {
    parsedPath = `/${splittedPathname[1]}`;
  }

  const viewer = useFragment(
    graphql`
      fragment AppBar_viewer on User {
        ... on Provider {
          unreadNotificationsCount
          role {
            sets
          }
        }
      }
    `,
    props.viewer,
  );

  const {t} = props;
  const {unreadNotificationsCount, role} = viewer;

  const items = canAccess(role.sets, 'payments', [USER_SETS_CONDITION_ASSIGNED_TO_ORGANIZATION])
    ? [
        {text: 'Analytics', icon: <Timeline />, path: '/analytics'},
        {text: 'Calendar', icon: <DateRangeOutlinedIcon />, path: '/calendar'},
        {text: 'Clients', icon: <PeopleIcon />, path: '/clients'},
        {text: 'Profile', icon: <AccountCircle />, path: '/myprofile'},
        {text: 'Services', icon: <ServicesIcon />, path: '/services'},
        {text: 'Staff Members', icon: <GroupsIcon />, path: '/staff-members'},
        {text: 'Settings', icon: <SettingsIcon />, path: '/settings'},
        {text: 'Payments', icon: <MoneyIcon />, path: '/payments'},
      ]
    : [
        {text: 'Analytics', icon: <Timeline />, path: '/analytics'},
        {text: 'Calendar', icon: <DateRangeOutlinedIcon />, path: '/calendar'},
        {text: 'Clients', icon: <PeopleIcon />, path: '/clients'},
        {text: 'Profile', icon: <AccountCircle />, path: '/myprofile'},
        {text: 'Services', icon: <ServicesIcon />, path: '/services'},
        {text: 'Staff Members', icon: <GroupsIcon />, path: '/staff-members'},
        {text: 'Settings', icon: <SettingsIcon />, path: '/settings'},
      ];

  const _activeItem = items.find((_item) => _item.path === parsedPath);

  const [open, setOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState(_activeItem && _activeItem.text);

  React.useEffect(() => {
    if (_activeItem) {
      setActiveItem(_activeItem.text);
    }
  }, [_activeItem, activeItem]); //only re-runs the effect if _activeItem changes

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleItemClick(item) {
    props.router.push(item.path || '/');

    setActiveItem(item.text);
  }

  function handleLogout() {
    props.router.push('/logout');
  }

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {activeItem && t(`titles.${snakeCase(activeItem)}`)}
          </Typography>
          <SearchBar />
          <IconButton style={{marginRight: '10px'}} color="inherit" onClick={() => props.onBellIconClick()}>
            <Badge badgeContent={unreadNotificationsCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Button onClick={handleLogout} color="inherit">
            {t('common.logout')}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {items.map((item) => (
            <ListItem button key={item.text} selected={activeItem === item.text} onClick={() => handleItemClick(item)}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={t(`titles.${item.text.toLowerCase()}`)} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
}

export default withTranslation()(withRouter(AppBarFragmentContainer));
