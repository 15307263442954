import './AppointmentModal.css';
import {Button /*Image/*/} from 'semantic-ui-react';

const SelectedServiceView = ({service, isDeselectButtonDisabled, t, onDeselectService}) => {
  return (
    <div style={{position: 'relative'}}>
      <div className="details-subcontainer">
        {/* <Image size="tiny" circular src={service.profilePhotoUrl} /> */}
        <div className="details-info">
          <div className="details-header">{service.title}</div>
          <p>
            {t('common.description')}: {service.description}
          </p>
          <p>
            {t('common.duration')}: {service.duration} {t('common.mins')}
          </p>
        </div>
      </div>
      <Button
        disabled={isDeselectButtonDisabled}
        size="mini"
        className="close-button"
        circular
        icon="close"
        onClick={onDeselectService}
      />
    </div>
  );
};

export default SelectedServiceView;
