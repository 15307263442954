// Empty values: undefined, null, '', [], {}
export default function isEmpty(v) {
  if (typeof v === 'undefined' || v === 'undefined' || v === null || v === 'null') {
    return true;
  } else if (typeof v === 'string' && v.length === 0) {
    return true;
  } else if (typeof v === 'object') {
    if (v.constructor === Object && Object.keys(v).length === 0) {
      return true;
    } else if (v.constructor === Array && v.length === 0) {
      return true;
    }
  }

  return false;
}
