import graphql from 'babel-plugin-relay/macro';
import moment from 'moment';
import React from 'react';
import {createFragmentContainer} from 'react-relay';
import {Table, Icon} from 'semantic-ui-react';
import {withTranslation} from 'react-i18next';

const renderPaymentIcon = (paymentMethod) => {
  if (!paymentMethod) {
    return 'N/A';
  }

  if (paymentMethod.toLowerCase().indexOf('visa') !== -1) {
    return <Icon name="cc visa" color="blue" />;
  } else if (paymentMethod.toLowerCase().indexOf('mastercard') !== -1) {
    return <Icon name="cc visa" color="blue" />;
  } else if (paymentMethod.toLowerCase().indexOf('paypal') !== -1) {
    return <Icon name="paypal" color="blue" />;
  } else if (paymentMethod.toLowerCase().indexOf('american express') !== -1) {
    return <Icon name="cc amex" color="blue" />;
  } else if (paymentMethod.toLowerCase().indexOf('bank transfer') !== -1) {
    return <Icon name="money bill alternate outline" color="blue" />;
  }
};

class HistoryRow extends React.Component {
  state = {};
  render() {
    const {t} = this.props;
    const {createdAt, amount, rowId, currency, invoiceLink, paymentMethod} = this.props.payment;
    const formattedDate = moment(createdAt).format('LLL');

    return (
      <Table.Row>
        <Table.Cell>{formattedDate}</Table.Cell>
        <Table.Cell>
          {paymentMethod && (
            <>
              {renderPaymentIcon(paymentMethod.brand)}
              ****{paymentMethod.last4}
            </>
          )}
        </Table.Cell>
        <Table.Cell>
          <a href={invoiceLink} download>
            {t('common.invoice')} #{rowId} ({moment(createdAt).format('LL')})
          </a>
        </Table.Cell>
        {/* TODO: Handle all currencies */}
        <Table.Cell>
          {(amount / 100).toFixed(2)} ({currency.toUpperCase()})
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default createFragmentContainer(withTranslation()(HistoryRow), {
  payment: graphql`
    fragment HistoryRow_payment on SubscriptionPayment {
      id
      rowId
      createdAt
      currency
      amount
      paymentMethod {
        brand
        last4
      }
    }
  `,
});
