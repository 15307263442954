/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type App_viewer$ref = any;
export type AppRefetchQueryVariables = {|
  appointmentId?: ?string
|};
export type AppRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: App_viewer$ref
  |}
|};
export type AppRefetchQuery = {|
  variables: AppRefetchQueryVariables,
  response: AppRefetchQueryResponse,
|};
*/


/*
query AppRefetchQuery {
  viewer {
    __typename
    ... on Provider {
      ...App_viewer_1UW6UZ
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment AppBar_viewer on User {
  __isUser: __typename
  ... on Provider {
    unreadNotificationsCount
    role {
      sets
      id
    }
  }
}

fragment App_viewer_1UW6UZ on User {
  __isUser: __typename
  __typename
  ... on Provider {
    ...AppBar_viewer
    settings {
      locale
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appointmentId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "appointmentId",
                    "variableName": "appointmentId"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "App_viewer"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProviderSettings",
                        "kind": "LinkedField",
                        "name": "settings",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locale",
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "unreadNotificationsCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "role",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "sets",
                                    "storageKey": null
                                  },
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "Provider",
                            "abstractKey": null
                          }
                        ],
                        "type": "User",
                        "abstractKey": "__isUser"
                      }
                    ],
                    "type": "Provider",
                    "abstractKey": null
                  }
                ],
                "type": "User",
                "abstractKey": "__isUser"
              }
            ],
            "type": "Provider",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/)
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0796a4372d021dfbe04e1894a62e0f6b",
    "id": null,
    "metadata": {},
    "name": "AppRefetchQuery",
    "operationKind": "query",
    "text": "query AppRefetchQuery {\n  viewer {\n    __typename\n    ... on Provider {\n      ...App_viewer_1UW6UZ\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment AppBar_viewer on User {\n  __isUser: __typename\n  ... on Provider {\n    unreadNotificationsCount\n    role {\n      sets\n      id\n    }\n  }\n}\n\nfragment App_viewer_1UW6UZ on User {\n  __isUser: __typename\n  __typename\n  ... on Provider {\n    ...AppBar_viewer\n    settings {\n      locale\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ea429c19f986394764e5276b9c0a04dd';

module.exports = node;
