import styled from 'styled-components';

export const CoverPhotoButton = styled.button`
  background-color: lightgray;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  height: 300px;
  width: 100%;

  &:hover {
    opacity: 0.6;
  }
`;

export const CoverIcon = styled.div`
  transition: 0.4s;
  opacity: 0;
  font-size: 80px;
  position: centered;

  ${CoverPhotoButton}:hover & {
    opacity: 1;
  }
`;

export const ProfilePhotoButton = styled.button`
  position: absolute;
  left: 40px;
  top: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: #dedede;
  background-image: url(${(props) => props.src});
  background-size: cover;
  height: 180px;
  width: 180px;
  border-radius: 90px;

  &:hover {
    opacity: 0.8;
  }
`;

export const AvatarIcon = styled.div`
  font-size: 50px;
  position: centered;
  transition: 0.4s;
  opacity: 0;

  ${ProfilePhotoButton}:hover & {
    opacity: 1;
  }
`;
