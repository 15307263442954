/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PersonalSettings_viewer$ref: FragmentReference;
declare export opaque type PersonalSettings_viewer$fragmentType: PersonalSettings_viewer$ref;
export type PersonalSettings_viewer = {|
  +id?: string,
  +settings?: ?{|
    +locale: ?string,
    +timezone: ?string,
    +viewType: ?string,
    +appointmentRequiresApproval: ?boolean,
    +emails: ?any,
    +notifications: ?{|
      +reminder: ?number,
      +changeOfAppointment: ?boolean,
    |},
  |},
  +$refType: PersonalSettings_viewer$ref,
|};
export type PersonalSettings_viewer$data = PersonalSettings_viewer;
export type PersonalSettings_viewer$key = {
  +$data?: PersonalSettings_viewer$data,
  +$fragmentRefs: PersonalSettings_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonalSettings_viewer",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProviderSettings",
          "kind": "LinkedField",
          "name": "settings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "locale",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timezone",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "viewType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "appointmentRequiresApproval",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emails",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProviderSettingsNotifications",
              "kind": "LinkedField",
              "name": "notifications",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reminder",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "changeOfAppointment",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Provider",
      "abstractKey": null
    }
  ],
  "type": "User",
  "abstractKey": "__isUser"
};
// prettier-ignore
(node/*: any*/).hash = '75cd0a5de2e6b9861bee12e3c19fd719';

module.exports = node;
