import React from 'react';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

function Dropdown({defaultValue, name, value, label, placeholder, options, onChange, search}) {
  return (
    <Form.Field>
      <label>{label || ''}</label>
      <Form.Dropdown
        defaultValue={defaultValue || null}
        selection
        search={search}
        name={name || null}
        value={value}
        placeholder={placeholder || ''}
        options={options || []}
        onChange={onChange}
      />
    </Form.Field>
  );
}

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.string,
};

export default Dropdown;
